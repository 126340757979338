/* eslint-disable react/prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-tabs */
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import DatePicker from 'react-datepicker';
import IcomoonReact from 'icomoon-react';
import moment from 'moment';

import Text from '../../../../shared/components/Text/Text';
import iconSet from '../../../../shared/images/teambuildr-selection.json';
import {
  BoxShadow,
} from '../../../../shared/GlobalStyles';

const DateSelectorContainer = styled('div')`
	display: flex;
	justify-content: center;
	border-radius: 6px;
	font-family: 'Nunito Sans';
  cursor: pointer;

	.react-datepicker__header {
		background: white;
		border-bottom: none;
	}
	.date-picker {
		border: none;
		height: 40px;
		font-weight: 400;
    width: 100%;
  }
	.react-datepicker__month-container {
		padding: 15px;
		font-family: 'Nunito Sans';
		font-size: 15px;
	}
	.react-datepicker {
		border: none;
		box-shadow: ${BoxShadow};
	}

	.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
		border-bottom-color: white;
	}
	.react-datepicker__day-names, .react-datepicker__week {
		margin-bottom: 15px;
	}
	.react-datepicker__day--outside-month {
		color: #BDBDBD;
	}
	.react-datepicker__day--inside-month {
		color: #444444;
	}
	.react-datepicker__day--selected {
		background-color: #FF6600;
		color: white;
	}
	.react-datepicker__day--keyboard-selected {
		background: none;
		color: #444444;
	}
   .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
    background-color: #FF6600;
    color: white;
  }
  
`;

const CustomButton = styled('button')`
    min-height: 39px;
    // padding-top: 12px;
    // padding-bottom: 12px;
    min-width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
`;

const TextContainer1 = styled('div')`
display: flex;
margin-right: 15px;
margin-top: 20px;
margin-bottom: 15px;
`;
const TextContainer2 = styled('div')`
display: flex;
margin-right: 15px;
margin-left: 15px;
`;

const FieldError = styled('div')`
  font-size: 14px;
  color: #ff6600;
  margin-top: 5px;
`;

const DatePickerAndErrorContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const TextAndPickerRow = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: #979797 solid 1px;
  width: 100%;
  min-height: 39px;
  justify-content: space-around;
  border-radius: 4px;
  padding-left: 12px;
  padding-right: 12px;
`;

const Wrapper = styled('div')`
display: flex;
flex-direction: column;
`;

/**
 * @param {Object} activeDate a Date or moment object indicating the default state of the picker
 * @param {Function} setActiveDate function for setting a new date
 * @returns a DatePicker component fully styled in the TeamBuildr theme
 */

const ReportDatePicker = ({
  setEndDate,
  setStartDate,
  startDate,
  endDate,
  reportType,
  formProps,
  labelText,
  capDateRange = false,
}) => {
  const placeholderText = reportType === 'multiDay' ? 'Start Date' : 'Select a Date';
  // eslint-disable-next-line react/prop-types
  const CustomStartDateButton = forwardRef(({ value, onClick }, ref) => (
    <CustomButton type='button' onClick={onClick} ref={ref} reportType={reportType}>
      <Text color='#979797' fontSize={16}>{value || placeholderText}</Text>
    </CustomButton>
  ));

  // eslint-disable-next-line react/prop-types
  const CustomEndDateButton = forwardRef(({ value, onClick }, ref) => (
    <CustomButton type='button' onClick={onClick} ref={ref} reportType={reportType}>
      <Text color='#979797' fontSize={16}>{value || 'End Date'}</Text>
    </CustomButton>
  ));

  return (
    <Wrapper>
      <TextContainer1 className='date-picker-label'>
        <Text fontSize={14} fontWeight={700}>
          {labelText || (reportType === 'multiDay' ? 'Pick a Date Range' : 'Select A Date')}
        </Text>
      </TextContainer1>
      <TextAndPickerRow>
        <DateSelectorContainer>
          <DatePicker
            name='questionnaire-date-picker'
            title=''
            className='date-picker'
            dateFormat='MMMM dd, yyyy'
            placeholderText={reportType === 'multiDay' ? 'Start Date' : 'Select a Date'}
            selected={startDate}
            popperPlacement='top-start'
            popperModifiers={{
              preventOverflow: {
                enabled: true,
              },
            }}
            maxDate={new Date()}
            onChange={(date) => {
              setStartDate(date);
            }}
            customInput={<CustomStartDateButton placeholderText={placeholderText} />}
            startDate={reportType === 'multiDay' ? startDate : null}
            endDate={reportType === 'multiDay' ? endDate : null}
            selectsStart={reportType === 'multiDay'}
            renderCustomHeader={({
              decreaseMonth,
              increaseMonth,
              date,
            }) => (
              <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '20px',
              }}
              >
                <button
                  type='button'
                  onClick={decreaseMonth}
                  style={{
                    border: 'none',
                    marginLeft: '10px',
                    background: 'rgba(95, 96, 100, 0.05)',
                    borderRadius: '5px',
                    width: '30px',
                    height: '30px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <IcomoonReact
                    iconSet={iconSet}
                    size={15}
                    icon='left-arrow'
                    color='#FF6600'
                  />
                </button>
                <span style={{
                  display: 'flex',
                  alignItems: 'center',
                  fontFamily: 'Nunito Sans',
                  fontWeight: '700',
                  fontSize: '16px',
                }}
                >
                  {date?.toLocaleString('en-US', {
                    month: 'long',
                    year: 'numeric',
                  })}
                </span>
                <button
                  type='button'
                  onClick={increaseMonth}
                  style={{
                    border: 'none',
                    marginRight: '10px',
                    background: 'rgba(95, 96, 100, 0.05)',
                    borderRadius: '5px',
                    width: '30px',
                    height: '30px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <IcomoonReact
                    iconSet={iconSet}
                    size={15}
                    icon='right-arrow'
                    color='#FF6600'
                  />
                </button>
              </div>
            )}
          />
        </DateSelectorContainer>
        {!formProps.values.singleDaySelect && (
        <>
          <TextContainer2>
            <IcomoonReact
              iconSet={iconSet}
              size={14}
              icon='alt-right-arrow'
              color='#979797'
            />
          </TextContainer2>
          <DateSelectorContainer>
            <DatePicker
              name='questionnaire-date-picker-end-date'
              className='date-picker'
              dateFormat='MMMM dd, yyyy'
              placeholderText='End Date'
              selected={endDate}
              popperPlacement='top-start'
              popperModifiers={{
                preventOverflow: {
                  enabled: true,
                },
              }}
              onChange={(date) => {
                setEndDate(date);
              }}
              customInput={<CustomEndDateButton />}
              startDate={startDate}
              endDate={endDate}
              selectsEnd
              minDate={startDate}
              maxDate={capDateRange ? moment(startDate).add(30, 'days').toDate() : ''}
              renderCustomHeader={({
                decreaseMonth,
                increaseMonth,
                date,
              }) => (
                <div style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '20px',
                }}
                >
                  <button
                    type='button'
                    onClick={decreaseMonth}
                    style={{
                      border: 'none',
                      marginLeft: '10px',
                      background: 'rgba(95, 96, 100, 0.05)',
                      borderRadius: '5px',
                      width: '30px',
                      height: '30px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <IcomoonReact
                      iconSet={iconSet}
                      size={15}
                      icon='left-arrow'
                      color='#FF6600'
                    />
                  </button>
                  <span style={{
                    display: 'flex',
                    alignItems: 'center',
                    fontFamily: 'Nunito Sans',
                    fontWeight: '700',
                    fontSize: '16px',
                  }}
                  >
                    {date?.toLocaleString('en-US', {
                      month: 'long',
                      year: 'numeric',
                    })}
                  </span>
                  <button
                    type='button'
                    onClick={increaseMonth}
                    style={{
                      border: 'none',
                      marginRight: '10px',
                      background: 'rgba(95, 96, 100, 0.05)',
                      borderRadius: '5px',
                      width: '30px',
                      height: '30px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <IcomoonReact
                      iconSet={iconSet}
                      size={15}
                      icon='right-arrow'
                      color='#FF6600'
                    />
                  </button>
                </div>
              )}
            />
          </DateSelectorContainer>
        </>
        )}
      </TextAndPickerRow>
      <DatePickerAndErrorContainer>
        {((formProps.errors.startDate && formProps.touched.startDate) || (formProps.errors.endDate && formProps.touched.endDate)) && <FieldError className='text-center'>Please select a start and end date</FieldError>}
        {(formProps.errors.selectedDate && formProps.touched.selectedDate) && <FieldError className='text-center'>Please select a date</FieldError>}
      </DatePickerAndErrorContainer>
    </Wrapper>
  );
};

ReportDatePicker.propTypes = {
  setEndDate: PropTypes.func.isRequired,
  setStartDate: PropTypes.func.isRequired,
  startDate: PropTypes.shape({
    date: PropTypes.object,
    queryFormat: PropTypes.string,
  }),
  endDate: PropTypes.shape({
    date: PropTypes.object,
    queryFormat: PropTypes.string,
  }),
  reportType: PropTypes.string.isRequired,
  labelText: PropTypes.string,
  capDateRange: PropTypes.bool,
};

ReportDatePicker.defaultProps = {
  startDate: {
    date: null,
    queryFormat: null,
  },
  endDate: {
    date: null,
    queryFormat: null,
  },
  labelText: null,
  capDateRange: false,
};

export default ReportDatePicker;
