import moment from 'moment';
import update from 'immutability-helper';

import {
  FETCH_GROUPS_FULFILLED,
  FETCH_GROUPS_REJECTED,
  FETCH_GROUP_ANALYSIS_FULFILLED,
  FETCH_GROUP_ANALYSIS_REJECTED,
  FETCH_METRIC_CARD_DATA_FULFILLED,
  FETCH_METRIC_CARD_DATA_REJECTED,
  FETCH_PERFORMANCE_OVERVIEW_FULFILLED,
  FETCH_PERFORMANCE_OVERVIEW_REJECTED,
  SET_DAY_RANGE,
  SET_END_DATE_CAL,
  SET_END_DATE_DATA,
  SET_GROUP,
  SET_GROUPS,
  SET_SELECTED_ATHLETE_GROUP,
  SET_SELECTED_ATHLETE_PERFORMANCE,
  SET_START_DATE_CAL,
  SET_START_DATE_DATA,
  SET_METRIC_FILTERS_PERFORMANCE,
  SET_METRIC_FILTERS_GROUP,
  SET_GROUP_FILTER_RADIO,
  SET_FLAGGED_ATHLETES,
  SET_FLAGGED_ATHLETES_GROUP,
  SET_FLAGGED_ATHLETES_PERFORMANCE,
  FETCH_FP_TEMPLATES_START,
  FETCH_FP_TEMPLATES_FULFILLED,
  FETCH_FP_TEMPLATES_REJECTED,
  SET_ACTIVE_FP_DASH,
  CLEAR_ACTIVE_FP_DASH,
  CREATE_FP_TEMPLATE_START,
  CREATE_FP_TEMPLATE_FULFILLED,
  CREATE_FP_TEMPLATE_REJECTED,
  SET_EDIT_FP_DASH_MODAL,
  EDIT_FP_TEMPLATE_START,
  EDIT_FP_TEMPLATE_FULFILLED,
  EDIT_FP_TEMPLATE_REJECTED,
  SET_DELETE_FP_TEMPLATE_MODAL,
  DELETE_FP_TEMPLATE_START,
  DELETE_FP_TEMPLATE_FULFILLED,
  DELETE_FP_TEMPLATE_REJECTED,
  SET_SHARE_FP_DASH_MODAL,
  FETCH_SHARE_FP_DASH_LINK_START,
  FETCH_SHARE_FP_DASH_LINK_FULFILLED,
  FETCH_SHARE_FP_DASH_LINK_REJECTED,
  CLEAR_SHARE_FP_DASH_LINK,
  FETCH_PUBLIC_FP_PERFORMANCE_CHART_DATA_FULFILLED,
  FETCH_PUBLIC_FP_PERFORMANCE_CHART_DATA_REJECTED,
  FETCH_PUBLIC_FP_PERFORMANCE_CARD_DATA_FULFILLED,
  FETCH_PUBLIC_FP_PERFORMANCE_CARD_DATA_REJECTED,
  FETCH_PUBLIC_FP_GROUP_ANALYSIS_DATA_FULFILLED,
  FETCH_PUBLIC_FP_GROUP_ANALYSIS_DATA_REJECTED,
  SET_DUPLICATE_FP_DASH_MODAL,
  DUPLICATE_FP_DASH_START,
  DUPLICATE_FP_DASH_FULFILLED,
  DUPLICATE_FP_DASH_REJECTED,
  SET_ABOUT_FP_DASH_MODAL,
  SET_NEXT_LOCATION,
  SET_KEY_PERFORMANCE_METRICS,
  CLEAR_FP_DASH_DATA,
  CLEAR_FP_DASH_MODALS,
} from './actionTypes';

const initialState = {
  dayRange: 7,
  endDateCal: moment(),
  endDateData: moment().format('YYYY-MM-DD'),
  flaggedAthletesData: [],
  flaggedAthletesDataGroup: [],
  flaggedAthletesDataPerformance: [],
  groupAnalysisAthleteData: [],
  groupAnalysisAverageData: [],
  groupFilterRadio: 'all',
  group: null,
  groups: [],
  metricCardData: [],
  metricCardError: null,
  metricFiltersGroup: ['PeakPower'],
  metricFiltersPerformance: ['PeakPower'],
  keyPerformanceMetrics: [],
  performanceOverviewData: [],
  performanceOverviewError: null,
  selectedAthleteGroup: null,
  selectedAthletePerformance: null,
  startDateCal: moment().subtract(6, 'days'),
  startDateData: moment()
    .subtract(6, 'days')
    .format('YYYY-MM-DD'),
  templates: [],
  templatesError: null,
  activeTemplate: null,
  createTemplateError: null,
  editTemplate: {},
  editError: null,
  deleteTemplate: {},
  deleteError: null,
  shareTemplate: {},
  shareDashLink: null,
  shareDashLinkError: null,
  duplicateTemplate: {},
  duplicateError: null,
  aboutTemplate: null,
  nextLocation: null,
  dashBGColor: null,
  dashCreator: null,
  dashDescription: null,
  dashLogo: null,
  dashTitle: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_GROUPS_FULFILLED: {
      return {
        ...state,
        groups: action.payload,
      };
    }
    case FETCH_GROUPS_REJECTED: {
      return {
        ...state,
        groups: [],
      };
    }
    case FETCH_PERFORMANCE_OVERVIEW_FULFILLED: {
      return {
        ...state,
        performanceOverviewData: action.payload,
        performanceOverviewError: null,
      };
    }
    case FETCH_PERFORMANCE_OVERVIEW_REJECTED: {
      return {
        ...state,
        performanceOverviewData: [],
        flaggedAthletesDataPerformance: [],
        performanceOverviewError: action.payload,
      };
    }
    case FETCH_METRIC_CARD_DATA_FULFILLED: {
      return {
        ...state,
        metricCardData: action.payload,
        metricCardError: null,
      };
    }
    case FETCH_METRIC_CARD_DATA_REJECTED: {
      return {
        ...state,
        metricCardData: [],
        metricCardError: action.payload,
      };
    }
    case FETCH_GROUP_ANALYSIS_FULFILLED: {
      return {
        ...state,
        groupAnalysisAthleteData: action.payload.athlete,
        groupAnalysisAverageData: action.payload.average,
        flaggedAthletesDataGroup: action.payload.flagged,
        groupAnalysisError: null,
      };
    }
    case FETCH_GROUP_ANALYSIS_REJECTED: {
      return {
        ...state,
        groupAnalysisAthleteData: [],
        groupAnalysisAverageData: [],
        flaggedAthletesDataGroup: [],
        groupAnalysisError: action.payload,
      };
    }
    case SET_SELECTED_ATHLETE_PERFORMANCE: {
      return {
        ...state,
        selectedAthletePerformance: action.payload.athlete,
      };
    }
    case SET_SELECTED_ATHLETE_GROUP: {
      return {
        ...state,
        selectedAthleteGroup: action.payload.athlete,
      };
    }
    case SET_METRIC_FILTERS_PERFORMANCE: {
      return {
        ...state,
        metricFiltersPerformance: action.payload,
      };
    }
    case SET_METRIC_FILTERS_GROUP: {
      return {
        ...state,
        metricFiltersGroup: action.payload,
      };
    }
    case SET_KEY_PERFORMANCE_METRICS: {
      return {
        ...state,
        keyPerformanceMetrics: action.payload,
      };
    }
    case SET_GROUP_FILTER_RADIO: {
      return {
        ...state,
        groupFilterRadio: action.payload,
      };
    }
    case SET_GROUP: {
      return {
        ...state,
        group: action.payload,
      };
    }
    case SET_GROUPS: {
      return {
        ...state,
        groups: action.payload,
      };
    }
    case SET_START_DATE_CAL: {
      return {
        ...state,
        startDateCal: action.payload,
      };
    }
    case SET_START_DATE_DATA: {
      return {
        ...state,
        startDateData: action.payload,
      };
    }
    case SET_END_DATE_CAL: {
      return {
        ...state,
        endDateCal: action.payload,
      };
    }
    case SET_END_DATE_DATA: {
      return {
        ...state,
        endDateData: action.payload,
      };
    }
    case SET_DAY_RANGE: {
      return {
        ...state,
        dayRange: action.payload,
      };
    }
    case SET_FLAGGED_ATHLETES: {
      return {
        ...state,
        flaggedAthletesData: action.payload,
      };
    }
    case SET_FLAGGED_ATHLETES_GROUP: {
      return {
        ...state,
        flaggedAthletesDataGroup: action.payload,
      };
    }
    case SET_FLAGGED_ATHLETES_PERFORMANCE: {
      return {
        ...state,
        flaggedAthletesDataPerformance: action.payload,
      };
    }
    case FETCH_FP_TEMPLATES_START: {
      return {
        ...state,
        templatesError: null,
      };
    }
    case FETCH_FP_TEMPLATES_FULFILLED: {
      return {
        ...state,
        templates: action.payload,
        templatesError: null,
      };
    }
    case FETCH_FP_TEMPLATES_REJECTED: {
      return {
        ...state,
        templates: [],
        templatesError: action.payload,
      };
    }
    case SET_ACTIVE_FP_DASH: {
      return {
        ...state,
        activeTemplate: action.payload,
      };
    }
    case CLEAR_ACTIVE_FP_DASH: {
      return {
        ...state,
        activeTemplate: null,
      };
    }
    case CREATE_FP_TEMPLATE_START: {
      return {
        ...state,
        createTemplateError: action.payload,
      };
    }
    case CREATE_FP_TEMPLATE_FULFILLED: {
      return {
        ...state,
        activeTemplate: action.payload,
        templates: [action.payload, ...state.templates],
        createTemplateError: null,
      };
    }
    case CREATE_FP_TEMPLATE_REJECTED: {
      return {
        ...state,
        createTemplateError: action.payload,
      };
    }
    case SET_EDIT_FP_DASH_MODAL: {
      return {
        ...state,
        editTemplate: action.payload.template,
      };
    }
    case EDIT_FP_TEMPLATE_START: {
      return {
        ...state,
        editError: null,
      };
    }
    case EDIT_FP_TEMPLATE_FULFILLED: {
      return update(state, {
        templates: {
          [action.payload.index]: {
            $set: action.payload.data,
          },
        },
        editTemplate: {
          $set: {},
        },
        editError: {
          $set: null,
        },
      });
    }
    case EDIT_FP_TEMPLATE_REJECTED: {
      return {
        ...state,
        editError: action.payload,
      };
    }
    case SET_DELETE_FP_TEMPLATE_MODAL: {
      return {
        ...state,
        deleteTemplate: action.payload.template,
      };
    }
    case DELETE_FP_TEMPLATE_START: {
      return {
        ...state,
        deleteError: null,
      };
    }
    case DELETE_FP_TEMPLATE_FULFILLED: {
      return update(state, {
        templates: {
          $splice: [[action.payload.index, 1]],
        },
        deleteTemplate: {
          $set: {},
        },
      });
    }
    case DELETE_FP_TEMPLATE_REJECTED: {
      return {
        ...state,
        deleteError: action.payload,
      };
    }
    case SET_SHARE_FP_DASH_MODAL: {
      return {
        ...state,
        shareTemplate: action.payload.template,
      };
    }
    case FETCH_SHARE_FP_DASH_LINK_START: {
      return {
        ...state,
        shareDashLinkError: null,
      };
    }
    case FETCH_SHARE_FP_DASH_LINK_FULFILLED: {
      return {
        ...state,
        shareDashLink: action.payload,
        shareDashLinkError: null,
      };
    }
    case FETCH_SHARE_FP_DASH_LINK_REJECTED: {
      return {
        ...state,
        shareDashLink: null,
        shareDashLinkError: action.payload,
      };
    }
    case CLEAR_SHARE_FP_DASH_LINK: {
      return {
        ...state,
        shareDashLink: null,
        shareDashLinkError: null,
      };
    }
    case FETCH_PUBLIC_FP_PERFORMANCE_CHART_DATA_FULFILLED: {
      return {
        ...state,
        performanceOverviewData: action.payload.dashboardData,
        performanceOverviewError: null,
        dashBGColor: action.payload.account.primaryColor,
        dashCreator: action.payload.creator,
        dashDescription: action.payload.dashboardDescription,
        dashLogo: action.payload.account.logo,
        dashTitle: action.payload.dashboardTitle,
        dayRange: action.payload.dayRange,
        startDateCal: moment(action.payload.startDate),
        endDateCal: moment(action.payload.endDate),
        metricFiltersPerformance: action.payload.forcePlateMetrics.map(
          (metric) => metric.replace(/\s/g, ''),
        ),
        group: action.payload.group,
      };
    }
    case FETCH_PUBLIC_FP_PERFORMANCE_CHART_DATA_REJECTED: {
      return {
        ...state,
        performanceOverviewData: [],
        performanceOverviewError: action.payload,
      };
    }
    case FETCH_PUBLIC_FP_PERFORMANCE_CARD_DATA_FULFILLED: {
      return {
        ...state,
        metricCardData: action.payload.dashboardData,
        keyPerformanceMetrics: action.payload.forcePlateMetrics,
        metricCardError: null,
      };
    }
    case FETCH_PUBLIC_FP_PERFORMANCE_CARD_DATA_REJECTED: {
      return {
        ...state,
        metricCardData: [],
        metricCardError: action.payload,
      };
    }
    case FETCH_PUBLIC_FP_GROUP_ANALYSIS_DATA_FULFILLED: {
      return {
        ...state,
        groupAnalysisAthleteData: action.payload.athleteData,
        groupAnalysisAverageData: action.payload.groupMetricsData,
        flaggedAthletesDataGroup: action.payload.flaggedAthleteData,
        groupAnalysisError: null,
        dashBGColor: action.payload.account.primaryColor,
        dashCreator: action.payload.creator,
        dashDescription: action.payload.dashboardDescription,
        dashLogo: action.payload.account.logo,
        dashTitle: action.payload.dashboardTitle,
        dayRange: action.payload.dayRange,
        startDateCal: moment(action.payload.startDate),
        endDateCal: moment(action.payload.endDate),
        metricFiltersGroup: action.payload.forcePlateMetrics.map((metric) => metric.replace(/\s/g, '')),
        group: action.payload.group,
      };
    }
    case FETCH_PUBLIC_FP_GROUP_ANALYSIS_DATA_REJECTED: {
      return {
        ...state,
        groupAnalysisAthleteData: [],
        groupAnalysisAverageData: [],
        flaggedAthletesDataGroup: [],
        groupAnalysisError: action.payload,
      };
    }
    case SET_DUPLICATE_FP_DASH_MODAL: {
      return {
        ...state,
        duplicateTemplate: action.payload.template,
      };
    }
    case DUPLICATE_FP_DASH_START: {
      return {
        ...state,
        duplicateError: null,
      };
    }
    case DUPLICATE_FP_DASH_FULFILLED: {
      return update(state, {
        templates: {
          $unshift: [action.payload],
        },
      });
    }
    case DUPLICATE_FP_DASH_REJECTED: {
      return {
        ...state,
        duplicateError: action.payload,
      };
    }
    case SET_ABOUT_FP_DASH_MODAL: {
      return {
        ...state,
        aboutTemplate: action.payload.template,
      };
    }
    case SET_NEXT_LOCATION: {
      return {
        ...state,
        nextLocation: action.payload,
      };
    }
    case CLEAR_FP_DASH_DATA: {
      return {
        ...state,
        endDateCal: moment(),
        endDateData: moment().format('YYYY-MM-DD'),
        flaggedAthletesData: [],
        flaggedAthletesDataGroup: [],
        flaggedAthletesDataPerformance: [],
        groupAnalysisAthleteData: [],
        groupAnalysisAverageData: [],
        groupFilterRadio: 'all',
        group: null,
        groups: [],
        metricCardData: [],
        metricCardError: null,
        metricFiltersGroup: [],
        metricFiltersPerformance: [],
        keyPerformanceMetrics: [],
        performanceOverviewData: [],
        performanceOverviewError: null,
        selectedAthleteGroup: null,
        selectedAthletePerformance: null,
        startDateCal: moment().subtract(6, 'days'),
        startDateData: moment()
          .subtract(6, 'days')
          .format('YYYY-MM-DD'),
      };
    }
    case CLEAR_FP_DASH_MODALS: {
      return {
        ...state,
        aboutTemplate: null,
        deleteTemplate: {},
        duplicateTemplate: {},
        editTemplate: {},
        shareTemplate: {},
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
