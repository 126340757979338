import {
  SET_SIDE_BAR_OPEN,
  SET_IS_SIDE_BAR_EXPANDED,
  FETCH_CONNECTION_DATA_START,
  FETCH_CONNECTION_DATA_FULFILLED,
  FETCH_CONNECTION_DATA_REJECTED,
  UPDATE_MATCHED_USERS_START,
  UPDATE_MATCHED_USERS_FULFILLED,
  UPDATE_MATCHED_USERS_REJECTED,
  CONNECT_GYMSTUDIO_ACCOUNT_START,
  CONNECT_GYMSTUDIO_ACCOUNT_FULFILLED,
  CONNECT_GYMSTUDIO_ACCOUNT_REJECTED,
  FETCH_QUICK_STATS_START,
  FETCH_QUICK_STATS_FULFILLED,
  FETCH_QUICK_STATS_REJECTED,
  DELETE_GYMSTUDIO_CONNECTION_START,
  DELETE_GYMSTUDIO_CONNECTION_FULFILLED,
  DELETE_GYMSTUDIO_CONNECTION_REJECTED,
  SET_SELECTED_TAB,
  SET_ACTIVE_GYM_STUDIO_MODAL,
  CLEAR_CONNECT_MODAL_ERROR,
} from './actionTypes';

import { axiosAuthed } from '../../../shared/utils/setCommonHeaders';
import { integrationConnectionTracker, integrationDisconnectionTracker } from '../../../shared/utils/amplitudeHelper';

export const setSideBarOpen = (bool) => (
  {
    type: SET_SIDE_BAR_OPEN,
    payload: bool,
  }
);
export const setIsSideBarExpandedGlobal = (bool) => (
  {
    type: SET_IS_SIDE_BAR_EXPANDED,
    payload: bool,
  }
);

export const fetchConnectionData = (accountCode, fetchUsers = true) => (dispatch) => {
  dispatch({ type: FETCH_CONNECTION_DATA_START });

  return axiosAuthed.get(`/accounts/${accountCode}/integrations/gymstudio/connection`)
    .then((connectionResponse) => {
      const connectionData = connectionResponse.data;
      // Only fetch users if requested (e.g. gym overview page)
      if (fetchUsers && connectionData?.connectedAt) {
        return axiosAuthed.get(`/accounts/${accountCode}/integrations/gymstudio/users`)
          .then((getUsersResponse) => {
            dispatch({
              type: FETCH_CONNECTION_DATA_FULFILLED,
              payload: {
                connection: connectionData,
                users: getUsersResponse.data,
              },
            });
            return connectionData;
          });
      }

      dispatch({
        type: FETCH_CONNECTION_DATA_FULFILLED,
        payload: {
          connection: connectionData,
          users: [],
        },
      });
      return connectionData;
    })
    .catch((error) => {
      dispatch({
        type: FETCH_CONNECTION_DATA_REJECTED,
        payload: error,
      });
    });
};

export const updateMatchedUsers = (accountCode) => (dispatch) => {
  dispatch({ type: UPDATE_MATCHED_USERS_START });

  axiosAuthed.post(`/accounts/${accountCode}/integrations/gymstudio/users`)
    .then((matchedUsersResponse) => {
      dispatch({
        type: UPDATE_MATCHED_USERS_FULFILLED,
        payload: matchedUsersResponse.data,
      });
      // Immediately fetch updated connection data
      return dispatch(fetchConnectionData(accountCode));
    })
    .catch((error) => {
      dispatch({
        type: UPDATE_MATCHED_USERS_REJECTED,
        payload: error,
      });
    });
};

export const connectGymStudioAccount = (accountCode, apiKey) => (dispatch) => {
  dispatch({ type: CONNECT_GYMSTUDIO_ACCOUNT_START });

  axiosAuthed.post(`/accounts/${accountCode}/integrations/gymstudio/connection`, { apiKey })
    .then((response) => {
      dispatch({
        type: CONNECT_GYMSTUDIO_ACCOUNT_FULFILLED,
        payload: response.data,
      });

      // Amplitude tracking
      integrationConnectionTracker(
        'GymStudio',
        'GymStudio Integration',
        'User attempted to connect GymStudio account to TeamBuildr',
        'Success',
        null,
      );

      // Fetch matched users upon successful connection
      dispatch(updateMatchedUsers(accountCode));
    })
    .catch((err) => {
      const errorMessage = err.response?.headers?.message
      || err.response?.data?.message
      || 'An unexpected error occurred';

      // Amplitude tracking
      integrationConnectionTracker(
        'GymStudio',
        'GymStudio Integration',
        'User attempted to connect GymStudio account to TeamBuildr',
        'Failed',
        errorMessage,
      );
      dispatch({
        type: CONNECT_GYMSTUDIO_ACCOUNT_REJECTED,
        payload: errorMessage,
      });
    });
};

export const fetchQuickStats = (accountCode, date) => (dispatch) => {
  dispatch({ type: FETCH_QUICK_STATS_START });

  return Promise.all([
    axiosAuthed.get(`/accounts/${accountCode}/integrations/gymstudio/quick-stats`),
    axiosAuthed.get(`/accounts/${accountCode}/integrations/gymstudio/schedule?startDate=${date}&endDate=${date}`),
  ])
    .then(([quickStatsResponse, scheduleResponse]) => {
      dispatch({
        type: FETCH_QUICK_STATS_FULFILLED,
        payload: {
          quickStats: quickStatsResponse.data,
          schedule: scheduleResponse.data.scheduleItems,
        },
      });
    })
    .catch((error) => {
      dispatch({
        type: FETCH_QUICK_STATS_REJECTED,
        payload: error,
      });
    });
};

export const deleteGymStudioConnection = (accountCode) => (dispatch) => {
  dispatch({ type: DELETE_GYMSTUDIO_CONNECTION_START });

  axiosAuthed.delete(`/accounts/${accountCode}/integrations/gymstudio/connection`)
    .then(() => {
      dispatch({ type: DELETE_GYMSTUDIO_CONNECTION_FULFILLED });

      // Amplitude tracking
      integrationConnectionTracker(
        'GymStudio',
        'GymStudio Integration',
        'User attempted to disconnect GymStudio account to TeamBuildr',
        'Success',
        null,
      );
    })
    .catch((error) => {
      dispatch({ type: DELETE_GYMSTUDIO_CONNECTION_REJECTED, payload: error });

      // Amplitude tracking
      integrationDisconnectionTracker(
        'GymStudio',
        'GymStudio Integration',
        'User attempted to disconnect GymStudio account to TeamBuildr',
        'Failed',
        error,
      );
    });
};

export const setSelectedTab = (selectedTab) => ({
  type: SET_SELECTED_TAB,
  payload: selectedTab,
});
export const setActiveGymStudioModal = (activeModal) => (
  {
    type: SET_ACTIVE_GYM_STUDIO_MODAL,
    payload: activeModal,
  }
);
export const clearGymStudioError = () => ({
  type: CLEAR_CONNECT_MODAL_ERROR,
});
