import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import { BoxShadow } from '../../../../shared/GlobalStyles';

const ModalContainer = styled('div')`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  width: 500px;
  padding: 24px;
  border-radius: 8px;
  font-family: "Nunito Sans";
  box-shadow: ${BoxShadow};
  z-index: 1;
`;

const HeaderContainer = styled('div')`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
`;

const Header = styled('div')`
  font-size: 22px;
  color: #444444;
`;

const Description = styled('div')`
  font-size: 20px;
  text-align: center;
`;

const InvalidTokenModal = ({ noData }) => (
  <ModalContainer>
    <HeaderContainer>
      <Header>{noData ? 'No Data Found' : 'Invalid Token'}</Header>
    </HeaderContainer>
    <Description>
      {noData
        ? 'The shared dashboard has no associated data. Please check the link you were given and try again'
        : 'This token is either expired or invalid. Please check the link you were given and try again.'}
    </Description>
  </ModalContainer>
);

InvalidTokenModal.propTypes = {
  noData: PropTypes.bool,
};

InvalidTokenModal.defaultProps = {
  noData: false,
};

export default InvalidTokenModal;
