/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React, { useState, useRef } from 'react';
import { toast } from 'react-toastify';
import styled from '@emotion/styled';
import Logger from 'js-logger';
import ResizeObserver from 'react-resize-observer';
import { useSelector, useDispatch } from 'react-redux';
import { animated, useSpring } from 'react-spring';
import IcomoonReact from 'icomoon-react';
import { useTheme } from 'emotion-theming';
import { Form } from 'formik';

import iconSet from '../../../../shared/images/teambuildr-selection.json';
import Text from '../../../../shared/_TBComponents/Text';
import Button from '../../../../shared/_TBComponents/Button';
import FormHandler from '../../../../shared/components/FormHandler/FormHandler';
import {
  fetchCompletionReport,
} from '../../ducks/reportingActions';
import Spinner from '../../../../shared/_TBComponents/Spinner';
import { TransitionGlobal } from '../../../../shared/GlobalStyles';
import { reportInteractionTracker } from '../../../../shared/utils/amplitudeHelper';
import CompletionREportFormContent from './CompletionReportFormContent';

const SlideContainer = styled('div')`
  display: block;
  height: auto;
  height: ${(props) => `${props.height}px`};
  position: relative;
  width: 100%;
  min-height: 135px;
  transition: ${TransitionGlobal};
  z-index: 1000;
`;

const SpinnerContainer = styled('div')`
  display: flex;
  height: 100%;
  flex: 1;
  position: absolute;
  top: 0;
  justify-content: center;
  width: 100%;
  z-index: 0;
  left: 0;
  margin-top: 40px;
`;

const FieldError = styled('div')`
  margin-bottom: 10px;
  font-size: 14px;
  color: #ff6600;
`;

const NavigationWrapper = styled('div')`
  display: flex;
  width: 100%;
  margin-bottom: -20px;
  z-index: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  padding: 20px;
  justify-content: end;

  p {
    opacity: .5;
    :hover {
      opacity: 1;
    }
  }
`;

const defaultSlideStyle = { position: 'absolute', width: '100%' };

const CompletionReportForm = ({
  closeModal,
}) => {
  const accountCode = useSelector((state) => state.auth.data.currentUser.accountCode);
  const completionReportError = useSelector((state) => state.reporting.data.completionReportError);
  const completionReportForm = useSelector((state) => state.reporting.data.completionReportForm);
  const isLoadingCompletionReport = useSelector(
    (state) => state.reporting.ui.isLoadingCompletionReport,
  );
  const sidebarFilter = useSelector((state) => state.reporting.data.sidebarFilter);

  const [slideDimensions, setSlideDimensions] = useState({
    height: 0,
    width: 0,
  });

  const slideRef = useRef();
  const theme = useTheme();

  const dispatch = useDispatch();

  const fetchReport = (values) => {
    if (sidebarFilter.filterIds.length) {
      if (values.rangeType) {
        dispatch(fetchCompletionReport(sidebarFilter, accountCode, values));
      } else {
        toast.error('You must select a date type before continuing');

        // Amplitude tracker.
        reportInteractionTracker('Report Error', 'Completion', 'Date has not been selected');
      }
    } else {
      toast.error('You must select filters before continuing');

      // Amplitude tracker.
      reportInteractionTracker('Report Error', 'Completion', 'Reporting Filter has not been selected.');
    }
  };

  const formAnimation = useSpring({
    opacity: isLoadingCompletionReport ? 0 : 1,
    zIndex: 1,
  });

  const spinnerAnimation = useSpring({ opacity: isLoadingCompletionReport ? 1 : 0, zIndex: 0 });

  return (
    <>
      <NavigationWrapper>
        <Text onClick={closeModal} styledTextProps={{ lineHeight: 1 }}>
          <IcomoonReact
            iconSet={iconSet}
            size={13}
            icon='remove'
          />
        </Text>
      </NavigationWrapper>
      <FormHandler
        initialValues={
          completionReportForm || (
            {
              startDate: {
                date: null,
                queryFormat: '',
              },
              endDate: {
                date: null,
                queryFormat: '',
              },
              showAverages: true,
              exerType: 1,
            }
          )
        }
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            setSubmitting(false);
            Logger.debug('Form Values:', values);
            fetchReport(values);
          });
        }}
      >
        {(formProps) => (
          <Form
            style={{ width: '100%' }}
          >
            <animated.div style={formAnimation}>
              {completionReportError && (
              <FieldError className='text-center'>
                {completionReportError === 'should have required property \'filterIds\''
                  ? 'Please select athletes, groups, or calendars from the filter.'
                  : completionReportError === 'should be integer'
                    ? 'Please select at least one exercise.'
                    : completionReportError}
              </FieldError>
              )}
              <SlideContainer
                height={slideDimensions.height}
              >
                <animated.div
                  ref={slideRef}
                  style={{ ...defaultSlideStyle }}
                >
                  <ResizeObserver
                    onResize={(rect) => {
                      setSlideDimensions(rect);
                    }}
                  />
                  <CompletionREportFormContent
                    formProps={formProps}
                  />
                </animated.div>
              </SlideContainer>
            </animated.div>
            <animated.div style={spinnerAnimation}>
              <SpinnerContainer>
                <Spinner />
              </SpinnerContainer>
            </animated.div>
            <Button
              bottom
              bgColor={theme.colors.green}
              fontSize='14px'
              fullWidth
              large
              name='Run Report'
              noBorder
              onClick={() => ''}
              square
              styledWrapperProps={{
                bottom: 0,
                left: 0,
                position: 'absolute',
              }}
              txtColor='#FFFFFF'
              type='submit'
              upperCase
            />
          </Form>
        )}
      </FormHandler>
    </>
  );
};

export default CompletionReportForm;
