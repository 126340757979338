import React from 'react';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';
import IcomoonReact from 'icomoon-react';

import PropTypes from 'prop-types';
import iconSet from '../../../../../shared/images/teambuildr-selection.json';
import { setTriOptionModal } from '../../../../sport-coach-dashboard/ducks/sportCoachDashboardActions';
import { setLMTriOptionModal } from '../../../../load-monitoring-dashboard/ducks/loadMonitoringDashboardActions';
import { setFPTriOptionModal } from '../../../../force-plate-dashboard/ducks/forcePlateDashboardActions';

const MenuContainer = styled('div')`
  position: absolute;
  bottom: 0px;
  right: 0px;
  margin-bottom: 10px;
  margin-right: 20px;
  border-radius: 50%;
  background-color: #1787fb;
  width: 55px;
  height: 55px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: ${(props) => (props.isBlurred ? 'default' : 'pointer')};
  filter: ${(props) => (props.isBlurred ? 'blur(1.2px)' : null)};
`;

const SharedTemplateTriOptionMenuButton = ({ dashboard }) => {
  const dispatch = useDispatch();
  let setTriOptionModalShowing = () => {};

  if (dashboard === 'Load Monitoring') {
    setTriOptionModalShowing = setLMTriOptionModal;
  }

  if (dashboard === 'Force Plate') {
    setTriOptionModalShowing = setFPTriOptionModal;
  }

  if (dashboard === 'Sport Coach') {
    setTriOptionModalShowing = setTriOptionModal;
  }

  const handleMenuClick = () => {
    dispatch(setTriOptionModalShowing(true));
  };

  return (
    <MenuContainer onClick={handleMenuClick}>
      <IcomoonReact
        iconSet={iconSet}
        size={25}
        icon='dots'
        color='#FFFFFF'
        style={{ rotate: '90deg' }}
      />
    </MenuContainer>
  );
};

SharedTemplateTriOptionMenuButton.defaultProps = {
  dashboard: 'Wearables',
};

SharedTemplateTriOptionMenuButton.propTypes = {
  dashboard: PropTypes.string,
};

export default SharedTemplateTriOptionMenuButton;
