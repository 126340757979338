/* eslint-disable no-restricted-globals */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React, { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useSortBy, useTable, usePagination } from 'react-table';
import { useSticky } from 'react-table-sticky';
import IcomoonReact from 'icomoon-react';
import iconSet from '../../images/teambuildr-selection.json';
import TBTooltip from '../TBTooltip/TBTooltip';
import {
  setShowAllForDownload,
  setIsChartShowingAllData,
} from '../../../modules/load-monitoring-dashboard/ducks/loadMonitoringDashboardActions';

const Styles = styled.div`
  background: #F5F3F3;
  font-family: nunito sans;
  height: max-content;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;

  .paginationContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }

  .pagination {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    margin-top: 10px;
    flex-wrap: wrap;
  }
  .paginationButton {
    padding: 5px;
    display: flex;
    justify-content: center;
    border: 1px solid #D7D7D7;
    border-radius: 4px;
    background: #EBEBEB;
  }

  & .notSticky {

    /** code for seudo element scroll indicator arrow */ 
    thead {
      @keyframes blink {
        0% {
          opacity: 0;
        }
        50% {
          opacity: ${(props) => (props.rowIsScrollable ? 1 : 0)};
        }
        100% {
          opacity: 0;
        }
      }
      ::after {
        content: '';
        border: solid 2em transparent;
        // border-top-color: #EBEBEB;
        // border-top-color: grey;
        border-top-color: white;
        bottom: -1.5em;
        color: grey;
        opacity: ${(props) => (props.rowIsScrollable ? '.7' : '0')};
        height: 0;
        top: 0;
        // top: ${(props) => (props.headerAndSubHeaderHeight
    ? `${props.headerAndSubHeaderHeight - 20}px`
    : 0)};
        pointer-events: none;
        position: absolute;
        right: ${(props) => (props.containerWidth < 300 ? '-15px' : 0)};
        text-align: center;
        -webkit-transform: translate(-50%,0);
        -ms-transform: translate(-50%,0);
        transform: translate(-50%,0);
        -webkit-transition: all .2s ease-out;
        transition: all .2s ease-out;
        width: 0;
       // animation: ${(props) => props.rowIsScrollable && 'blink 2s infinite'};
        rotate: 90degrees;
        rotate: -90deg;
      }
    }
  }

  & .sticky {
    overflow-x: scroll;
    // z-index: 3;

     tbody {
      //z-index: 3;
    } 

    /** code for seudo element scroll indicator arrow */ 
    thead {
      @keyframes blink {
        0% {
          opacity: 0;
        }
        50% {
          opacity: ${(props) => (props.isScrollable ? 1 : 0)};
        }
        100% {
          opacity: 0;
        }
      }
      ::after {
        content: '';
        border: solid 2em transparent;
        // border-top-color: #EBEBEB;
        // border-top-color: grey;
        border-top-color: white;
        bottom: -1.5em;
        color: grey;
        opacity: ${(props) => (props.isScrollable ? '.7' : '0')};
        height: 0;
        top: 0;
        // top: ${(props) => (props.headerAndSubHeaderHeight
    ? `${props.headerAndSubHeaderHeight - 20}px`
    : 0)};
        pointer-events: none;
        position: absolute;
        right: 0;
        text-align: center;
        -webkit-transform: translate(-50%,0);
        -ms-transform: translate(-50%,0);
        transform: translate(-50%,0);
        -webkit-transition: all .2s ease-out;
        transition: all .2s ease-out;
        width: 0;
       // animation: ${(props) => props.isScrollable && 'blink 2s infinite'};
        rotate: 90degrees;
        rotate: -90deg;
      }
    }

    tfoot {
      td:first-child {
         position: sticky;
         left: 0;
      }
    }

    [data-sticky-td] {
      position: sticky;
    }
  }

  .athleteColumnHeaderCell {
    border-bottom: none;
    background: #F5F3F3;
    color: #F5F3F3;
    box-shadow: -18px 49px 6px #F5F3F3;

  }

  .noHoverCategoryColumnTitleCell {
    background-color: #424242;
    color: white;
    font-size: 16px;
    font-weight: 900;
    border-right: 8px solid #E0E0E0;
    min-width: 270px;
    max-width: 270px;
  }

  .categoryColumnTitleCell {
    background-color: #424242;
    color: white;
    font-size: 16px;
    font-weight: 900;
    border-right: 8px solid #E0E0E0;
    cursor: pointer;
    min-width: 270px;
    max-width: 270px;
  }

  .categoryColumnTitleCell:hover {
    opacity: 0.7;
  }

  .categoryColumnTotalScoresTitleCell {
    background-color: #424242;
    color: white;
    font-size: 16px;
    font-weight: 900;
    border-top-right-radius: 24px;
    cursor: pointer;
    min-width: 270px;
    max-width: 270px;
  }

  .disabledCategoryColumnTotlaScoresTitleCell {
    background-color: #424242;
    color: white;
    font-size: 16px;
    font-weight: 900;
    border-top-right-radius: 24px;
    cursor: auto;
    min-width: 270px;
    max-width: 270px;
  }

  .title {
    font-family: nunito sans;
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;
    background-color: #EBEBEB;
    color: #000000;
    text-align: center;
    cursor: pointer;
  }

  .disabledTitle {
    font-family: nunito sans;
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;
    background-color: #EBEBEB;
    color: #BDBDBD;
    text-align: center;
    cursor: auto;
  }
  .monthAverageTitle {
    font-family: nunito sans;
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;
    background-color: #EBEBEB;
    color: #000000;
    text-align: center;
    border-right: 8px solid #E0E0E0;
    cursor: pointer;
  }

  .disabledMonthAverageTitle {
    font-family: nunito sans;
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;
    background-color: #EBEBEB;
    color: #BDBDBD;
    text-align: center;
    border-right: 8px solid #E0E0E0;
    cursor: auto;
  }

  .rawValueTitle {
    font-family: nunito sans;
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;
    background-color: #D7D7D7;
    color: #000000;
    text-align: center;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 90px;
    max-width: 90px;
  }

  .disabledRawValueTitle {
    font-family: nunito sans;
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;
    background-color: #D7D7D7;
    color: #BDBDBD;
    text-align: center;
    cursor: auto;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 90px;
    max-width: 90px;
  }

  .athleteNameTitle {
    font-family: nunito sans;
    font-size: 15px;
    font-weight: 900;
    background-color: #EBEBEB;
    text-align: center;
    border-top-left-radius: 24px;
    color: #000000;
    font-size: 12px;
    cursor: pointer;
    min-width: 180px;
    max-width: 180px;
  }

  .monthAverageFooterColumn {
    border-right: 8px solid #E0E0E0;
    font-size: 20px;
  }

  .footerCells {
    font-size: 20px;
  }

  table {
    border-spacing: 0;
    border-collapse: separate;

    tr {
      :first-child {
        th:last-child {
          border-top-right-radius: 20px;
        }
      }
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      background: white;
      padding: 15px;
      border-bottom: 1px solid #E0E0E0;
      border-right: 1px solid #E0E0E0;
      text-align: center;

      :last-child {
        border-right: 0;
      }
    }

    tfoot {
      tr:first-child {
        border-bottom-right-radius: 24px;

        td {
         border-top: 5px solid #E0E0E0;
         height: 61px;
         min-height: 61px;
         border-bottom: none;
        }
      }
      td:first-child {
         background: #EBEBEB;
         color: #000000;
         font-size: 12px;
         font-weight: 900;
         border-bottom: none;
      }
      tr:last-child {
        display: none;
      }
      font-weight: 700;
    }
  }
`;

const Table = ({
  containerWidth,
  athletesInClickedZone,
  columns,
  data,
  /** getHeaderProps = defaultPropGetter,
  getColumnProps = defaultPropGetter,
  getRowProps = defaultPropGetter,
  getCellProps = defaultPropGetter,
  getHeaderProps,
  getColumnProps,
  getRowProps, */
  getCellProps,
  getHeaderProps,
}) => {
  /** might use this hide column functionality later on in dashboard functionality */
  /** const filteredColoumns = columns.filter((column, i) => (i > 4 && column.id !== 'totalScores'));

  const getDefaultHiddenColumns = () => {
    const array = [];
    // eslint-disable-next-line array-callback-return
    filteredColoumns?.map((filteredColumn) => {
      array.push(String(filteredColumn.id),
        filteredColumn.columns.map((subColumn) => array.push(String(subColumn.id))));
    });
    return array;
  }; */

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
    /** setHiddenColumns, */
    /** state: { hiddenColumns }, */
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 5 },
      /** initialState: { hiddenColumns: getDefaultHiddenColumns() }, */
    },
    useSticky,
    useSortBy,
    usePagination,
  );
  /** Render the UI for your table */
  const dispatch = useDispatch();
  const showAllForDownload = useSelector(
    (state) => state.loadMonitoringDashboard.data.showAllForDownload,
  );

  React.useEffect(() => {
    if (showAllForDownload) {
      setPageSize(data.length);
    }
  }, [showAllForDownload]);

  React.useEffect(() => {
    setPageSize(5);
    dispatch(setShowAllForDownload(false));
  }, []);

  React.useEffect(() => {
    if (pageSize === data?.length) {
      dispatch(setIsChartShowingAllData(true));
    } else {
      dispatch(setIsChartShowingAllData(false));
    }
  }, [pageSize]);

  return (
    <>
      {/** migth use this hidden column functionality later on when building sports science dash */}
      {/* <div style={{ display: hiddenColumns === '' && 'none' }} role='button' onClick={() => setHiddenColumns([])}> view all columns</div>
      <div style={{ display: hiddenColumns === '' && 'none' }} role='button' onClick={() => setHiddenColumns(getDefaultHiddenColumns())}> hide columns</div> */}
      <div
        id='reportTable'
        style={{ maxWidth: containerWidth }}
        className={containerWidth < 485 ? 'notSticky' : 'sticky'}
      >
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup, i) => (
              <tr {...headerGroup.getHeaderGroupProps()} data-a-wrap>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps([
                      {
                        className: column.className,
                        /** style: column.style, */
                      },
                      /** getColumnProps(column), */
                      getHeaderProps(column),
                    ])}
                    data-cols-width='30'
                    data-a-wrap
                    data-f-bold
                    data-fill-color={column?.style?.fillColor || 'FF424242'}
                    data-b-a-s='thin'
                    data-b-a-c='FF000000'
                    data-f-color={
                      column?.style?.fillColor ? 'FF000000;' : 'FFFFFFFF'
                    }
                    data-a-h='center'
                    data-a-v='center'
                  >
                    {column.render('Header')}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <IcomoonReact
                            iconSet={iconSet}
                            size={10}
                            color='black'
                            icon='down-arrow'
                            style={{ marginLeft: '5px' }}
                          />
                        ) : (
                          <IcomoonReact
                            iconSet={iconSet}
                            size={10}
                            color='black'
                            icon='up-arrow'
                            style={{ marginLeft: '5px' }}
                          />
                        )
                      ) : (
                        ''
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td
                      {...cell.getCellProps([
                        {
                          /** className: cell.column.className, */
                          style: cell.column.style,
                        },
                        /** getColumnProps(cell.column), */
                        getCellProps(cell),
                      ])}
                      data-fill-color={
                        getCellProps(cell)?.style?.fillColor || null
                      }
                      data-f-color={getCellProps(cell)?.style?.fontColor}
                      data-b-a-s='thin'
                      data-b-a-c='FF000000'
                      data-a-wrap
                    >
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            {footerGroups.map((group) => (
              <tr {...group.getFooterGroupProps()}>
                {group.headers.map((column) => (
                  <td
                    data-a-wrap
                    {...column.getFooterProps([
                      {
                        className:
                          column.Header === '28 Day Average'
                          && column.id !== 'totalScoreMonthlyAverage'
                            ? 'monthAverageFooterColumn'
                            : 'footerCells',
                      },
                    ])}
                  >
                    {column.render('Footer')}
                  </td>
                ))}
              </tr>
            ))}
          </tfoot>
        </table>
        {/* </div> */}
        {/** might use this hidden column functionality later */}
        {/* <pre>
        <code>{JSON.stringify({ hiddenColumns }, null, 2)}</code>
      </pre> */}
      </div>
      {!athletesInClickedZone?.length && (
        <>
          {pageCount > 1 ? (
            <div className='paginationContainer'>
              <div className='pagination'>
                <button
                  type='button'
                  onClick={() => gotoPage(0)}
                  disabled={!canPreviousPage}
                  className='paginationButton'
                >
                  <IcomoonReact
                    iconSet={iconSet}
                    size={12}
                    color='black'
                    icon='left-arrow'
                    style={{}}
                  />
                  <IcomoonReact
                    iconSet={iconSet}
                    size={12}
                    color='black'
                    icon='left-arrow'
                    style={{}}
                  />
                </button>
                {' '}
                <button
                  type='button'
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                  className='paginationButton'
                >
                  <IcomoonReact
                    iconSet={iconSet}
                    size={12}
                    color='black'
                    icon='left-arrow'
                    style={{}}
                  />
                </button>
                {' '}
                <button
                  type='button'
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                  className='paginationButton'
                >
                  <IcomoonReact
                    iconSet={iconSet}
                    size={12}
                    color='black'
                    icon='right-arrow'
                    style={{}}
                  />
                </button>
                {' '}
                <button
                  type='button'
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                  className='paginationButton'
                >
                  <IcomoonReact
                    iconSet={iconSet}
                    size={12}
                    color='black'
                    icon='right-arrow'
                    style={{}}
                  />
                  <IcomoonReact
                    iconSet={iconSet}
                    size={12}
                    color='black'
                    icon='right-arrow'
                    style={{}}
                  />
                </button>
                {' '}
                <span>
                  Page
                  {' '}
                  <strong>
                    {pageIndex + 1}
                    &nbsp;
                    of
                    &nbsp;
                    {pageOptions.length}
                  </strong>
                  {' '}
                  Athletes
                </span>
              </div>
              <button
                type='button'
                onClick={() => setPageSize(data.length)}
                className='paginationButton'
                style={{
                  fontSize: '13px',
                  fontWeight: 700,
                  padding: '3px',
                  minWidth: '90px',
                  maxWidth: '90px',
                  alignItems: 'center',
                  gap: '7px',
                }}
              >
                <span>Show All </span>
                <IcomoonReact
                  iconSet={iconSet}
                  size={12}
                  color='black'
                  icon='down-arrow'
                  style={{}}
                />
              </button>
            </div>
          ) : (
            <>
              {pageSize > 5 && (
                <button
                  type='button'
                  onClick={() => {
                    setPageSize(5);
                    dispatch(setShowAllForDownload(false));
                  }}
                  className='paginationButton'
                  style={{
                    fontSize: '13px',
                    fontWeight: 700,
                    padding: '3px',
                    minWidth: '90px',
                    maxWidth: '100px',
                    alignItems: 'center',
                    gap: '7px',
                    marginTop: '10px',
                  }}
                >
                  <span>Show Less </span>
                  <IcomoonReact
                    iconSet={iconSet}
                    size={12}
                    color='black'
                    icon='up-arrow'
                  />
                </button>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

const SharedSingleDayQuestionnaireGrid = ({
  containerWidth,
  dataRows,
  teamAverageTotals,
  showAverages,
  showTotals,
  setCurrentQuestionInDonutChartQuestionnairePicker,
  setColumnHeaderHeight,
  rowIsScrollable,
  athletesInClickedZone,
}) => {
  const dispatch = useDispatch();
  const chartRef = useRef(null);
  const [headerAndSubHeaderHeight, setHeaderAndSubHeaderHeight] = useState(0);
  const [isScrollable, setIsScrollable] = useState(false);

  const handleScroll = (e) => {
    /** targetting the sticky container div scroll properties */
    const scrollWidth = chartRef.current?.childNodes[0]?.scrollWidth;
    const offsetWidth = chartRef.current?.childNodes[0]?.offsetWidth;
    const scrollLeft = chartRef.current?.childNodes[0]?.scrollLeft;
    setIsScrollable(offsetWidth + scrollLeft !== scrollWidth);
  };

  React.useEffect(() => {
    if (chartRef.current) {
      const scrollWidth = chartRef.current.childNodes[0]?.scrollWidth;
      const offsetWidth = chartRef.current.childNodes[0]?.offsetWidth;
      const scrollLeft = chartRef.current.childNodes[0]?.scrollLeft;
      setIsScrollable(offsetWidth + scrollLeft !== scrollWidth);
    }
  }, []);

  React.useEffect(() => {
    if (chartRef.current) {
      setHeaderAndSubHeaderHeight(
        chartRef.current.childNodes[0].childNodes[0].childNodes[0].clientHeight,
      );
      setColumnHeaderHeight(
        chartRef.current.childNodes[0].childNodes[0].childNodes[0].childNodes[0]
          .clientHeight,
      );
    }
  }, []);

  const formatColumns = () => {
    const formattedData = dataRows[0].questions?.map((question, i) => ({
      Header: question.title,
      Footer: () => null,
      id: question.id,
      // className: 'categoryColumnTitleCell',
      className:
        question.title === 'Volume Load' || question.title === 'Exertion Score'
          ? 'noHoverCategoryColumnTitleCell'
          : 'categoryColumnTitleCell',
      columns: [
        {
          Header: question.abbr,
          accessor: (row) => row.questions[i].response.value,
          Cell: ({ row }) => (
            <TBTooltip
              textToDisplay={row.original.questions[i].response.value}
              textToHover={row.original.questions[i].response.value}
              textContainerPadding='0px'
              textContainerMinWidth={showAverages ? '' : '100%'}
              textContainerMaxWidth='90px'
              fontSize={
                isNaN(row.original.questions[i].response.value)
                && row.original.questions[i].response.value !== '-'
                  ? '12px'
                  : '17px'
              }
              fontColor={row.original.questions[i].response.textColor}
            />
          ),
          Footer: () => {
            const total = teamAverageTotals.questions.map(
              (object) => object.response.value,
            );
            return <>{total[i]}</>;
          },
          id: question.id,
          className: 'rawValueTitle',
          style: {
            fontWeight: 700,
            // fontSize: '17px',
            minWidth: '90px',
            maxWidth: '90px',
            fillColor: 'FFD7D7D7',
          },
        },
        ...(showAverages
          ? [
            {
              Header: '7 Day Average',
              accessor: (row) => row.questions[i].weekAverage.value,
              Footer: () => {
                const total = teamAverageTotals.questions.map(
                  (object) => object.weekAverage.value,
                );
                return <>{total[i]}</>;
              },
              id: `${question.id} WeekAverage`,
              className: !showAverages ? 'disabledTitle' : 'title',
              style: {
                fontWeight: 700,
                fontSize: '17px',
                minWidth: '90px',
                maxWidth: '90px',
                fillColor: 'FFD7D7D7',
              },
            },
            {
              Header: '28 Day Average',
              accessor: (row) => row.questions[i].monthAverage.value,
              Footer: () => {
                const total = teamAverageTotals.questions.map(
                  (object) => object.monthAverage.value,
                );
                return <>{total[i]}</>;
              },
              id: `${question.id} MonthAverage`,
              className: !showAverages
                ? 'disabledMonthAverageTitle'
                : 'monthAverageTitle',
              style: {
                /** style for the whole column
                   * className is style for just the header
                   */
                borderRight: '8px solid #E0E0E0',
                fontWeight: 700,
                fontSize: '17px',
                minWidth: '90px',
                maxWidth: '90px',
                fillColor: 'FFD7D7D7',
              },
            },
          ]
          : []),
      ],
    }));
    return formattedData;
  };

  const athleteNameColumn = [
    {
      Header: 'Name',
      Footer: () => null,
      id: 'name',
      sticky: containerWidth < 485 ? () => null : 'left',
      className: 'athleteColumnHeaderCell',
      columns: [
        {
          Header: 'Athlete',
          accessor: 'name',
          Footer: 'Team Average',
          className: 'athleteNameTitle',
          style: {
            /** style for the whole column
             * className is style for just the header
             */
            boxShadow: '0px 0px 16px rgb(48 49 51 / 5%)',
            borderBottom: '1px solid #E0E0E0',
            borderRight: '1px solid #E0E0E0',
            fillColor: 'FFD7D7D7',
            height: '58px',
          },
          Cell: ({ row }) => (
            <TBTooltip
              textToDisplay={row.original.name}
              textToHover={row.original.name}
              textContainerPadding='0px'
              textContainerMaxWidth='175px'
              fontSize='12px'
              fontColor='black'
            />
          ),
        },
      ],
    },
  ];

  const totalScoresColumn = [
    {
      Header: 'Total Scores',
      Footer: () => null,
      id: 'totalScores',
      className: !showTotals
        ? 'disabledCategoryColumnTotlaScoresTitleCell'
        : 'categoryColumnTotalScoresTitleCell',
      columns: [
        {
          Header: 'Total Score',
          accessor: (row) => row.totalScores.value.value,
          Footer: () => {
            const total = teamAverageTotals.totalScores.value;
            return <>{total}</>;
          },
          id: 'totalScoreRawValue',
          className: !showTotals ? 'disabledRawValueTitle' : 'rawValueTitle',
          style: {
            fontWeight: 700,
            fontSize: '17px',
            minWidth: '90px',
            maxWidth: '90px',
            fillColor: 'FFD7D7D7',
          },
        },
        ...(showAverages
          ? [
            {
              Header: '7 Day Average',
              accessor: (row) => row.totalScores.pastWeekAverageTotal.value,
              Footer: () => {
                const total = teamAverageTotals.totalScores.pastWeekAverageTotal;
                return <>{total}</>;
              },
              id: 'totalScoreWeeklyAverage',
              className:
                  !showAverages || !showTotals ? 'disabledTitle' : 'title',
              style: {
                fontWeight: 700,
                fontSize: '17px',
                minWidth: '90px',
                maxWidth: '90px',
                fillColor: 'FFD7D7D7',
              },
            },
            {
              Header: '28 Day Average',
              accessor: (row) => row.totalScores.pastMonthAverageTotal.value,
              Footer: () => {
                const total = teamAverageTotals.totalScores.pastMonthAverageTotal;
                return <>{total}</>;
              },
              id: 'totalScoreMonthlyAverage',
              className:
                  !showAverages || !showTotals ? 'disabledTitle' : 'title',
              style: {
                fontWeight: 700,
                fontSize: '17px',
                minWidth: '90px',
                maxWidth: '90px',
                fillColor: 'FFD7D7D7',
              },
            },
          ]
          : []),
      ],
    },
  ];

  const formattedMiddleColumns = formatColumns();

  const columns = showTotals
    ? [...athleteNameColumn, ...formattedMiddleColumns, ...totalScoresColumn]
    : [...athleteNameColumn, ...formattedMiddleColumns];

  const memoizedColumns = React.useMemo(() => columns, [
    dataRows,
    teamAverageTotals,
    containerWidth,
  ]);
  const memoizedDataRows = React.useMemo(() => dataRows, [
    dataRows,
    teamAverageTotals,
    containerWidth,
  ]);

  const getCellColor = (cellInfo) => {
    const columnId = cellInfo?.column?.id;
    const rowQuestionArray = cellInfo?.row?.original.questions;
    const currentCellResponseInfoArray = rowQuestionArray?.filter(
      (question) => question.id === columnId,
    );
    const cellBackGroundColor = columnId === 'totalScoreRawValue'
      ? cellInfo.row.original.totalScores.value.backgroundColor
      : currentCellResponseInfoArray[0]?.response.backgroundColor;
    return cellBackGroundColor;
  };

  const getCellTextColor = (cellInfo) => {
    if (
      (cellInfo.column.Header === '7 Day Average'
        || cellInfo.column.Header === '28 Day Average')
      && cellInfo.value === '-'
      && !showAverages
    ) {
      return '#E0E0E0';
    }
    if (
      cellInfo.column.Header === 'Total Score'
      && cellInfo.value === '-'
      && !showTotals
    ) {
      return '#E0E0E0';
    }
    if (
      (cellInfo.column.id === 'totalScoreMonthlyAverage'
        || cellInfo.column.id === 'totalScoreWeeklyAverage')
      && cellInfo.value === '-'
      && !showTotals
    ) {
      return '#E0E0E0';
    }
    const columnId = cellInfo?.column?.id;
    const rowQuestionArray = cellInfo?.row?.original.questions;
    const currentCellResponseInfoArray = rowQuestionArray?.filter(
      (question) => question.id === columnId,
    );
    const cellTextColor = columnId === 'totalScoreRawValue'
      ? cellInfo.row.original.totalScores.value.textColor
      : currentCellResponseInfoArray[0]?.response.textColor;
    return cellTextColor;
  };

  const getCellFillColor = (cellInfo) => {
    const cellBackGroundColor = getCellColor(cellInfo);
    /** Convert RGB -> ARGB so '#d7d7d7' becomes 'FFD7D7D7' */
    return cellBackGroundColor?.toUpperCase().replace('#', 'FF');
  };

  const getCellFontColor = (cellInfo) => {
    const cellTextColor = getCellTextColor(cellInfo);
    /** Convert RGB -> ARGB so '#d7d7d7' becomes 'FFD7D7D7' */
    return cellTextColor?.toUpperCase().replace('#', 'FF');
  };

  const handleHeaderClick = (headerInfo) => {
    /** disabling ability to click total scores because there is no filter threshold for totals scores currently */
    /** if (headerInfo.className === 'categoryColumnTitleCell' || headerInfo.className === 'categoryColumnTotalScoresTitleCell') { */
    if (headerInfo.className === 'categoryColumnTitleCell') {
      const id = headerInfo.Header === 'Total Scores'
        ? 'Total Scores'
        : parseInt(headerInfo.id.split('_')[0], 10);
      const displayText = headerInfo.Header === 'Total Scores'
        ? 'Total Scores?'
        : headerInfo.Header;

      dispatch(
        setCurrentQuestionInDonutChartQuestionnairePicker({ id, displayText }),
      );
    } else {
      headerInfo.toggleSortBy();
    }
  };

  return (
    <>
      <Styles
        ref={chartRef}
        onScroll={handleScroll}
        isScrollable={isScrollable}
        rowIsScrollable={rowIsScrollable}
        headerAndSubHeaderHeight={headerAndSubHeaderHeight}
        containerWidth={containerWidth}
      >
        <Table
          containerWidth={containerWidth}
          athletesInClickedZone={athletesInClickedZone}
          columns={memoizedColumns}
          data={memoizedDataRows}
          getCellProps={(cellInfo) => ({
            style: {
              backgroundColor: getCellColor(cellInfo),
              color: getCellTextColor(cellInfo),
              fillColor: getCellFillColor(cellInfo),
              fontColor: getCellFontColor(cellInfo),
            },
            /** onClick: () => console.log(cellInfo), */
          })}
          getHeaderProps={(headerInfo) => ({
            onClick: () => ((headerInfo.Header.includes('Average') && !showAverages)
              || (headerInfo.Header.includes('Total Score') && !showTotals)
              || (headerInfo.id === 'totalScoreMonthlyAverage' && !showTotals)
              || (headerInfo.id === 'totalScoreWeeklyAverage' && !showTotals)
              || headerInfo.Header === 'Name'
              || headerInfo.Header === 'Volume Load'
              || headerInfo.Header === 'Exertion Score'
              ? null
              : handleHeaderClick(headerInfo)),
          })}
        />
      </Styles>
    </>
  );
};

export default SharedSingleDayQuestionnaireGrid;
