/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import styled from '@emotion/styled';
import { batch, useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { BoxShadow } from '../../../../shared/GlobalStyles';
import ForcePlateDatePicker from '../../../force-plate-dashboard/components/presentational/ForcePlateDatePicker';
import ForcePlateDropDown from '../../../force-plate-dashboard/components/presentational/ForcePlateDropDown';
import ExerciseFilterGroup from './ExerciseFilterGroup';
import SCAnalysisTableNoData from './SCAnalysisTableNoData';
import SCAnalysisTable from './SCAnalysisTable';
import {
  setGroup,
  setSelectedAthleteGroup,
  setSelectedAthletePerformance,
  setStartDateCal,
  setStartDateData,
  setEndDateCal,
  setEndDateData,
  fetchSCDashData,
} from '../../ducks/sportCoachDashboardActions';
import Spinner from '../../../../shared/components/Spinner/Spinner';
import { dashboardTracker } from '../../../../shared/utils/amplitudeHelper';

const DashboardContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin: 15px;
  background-color: #FFFFFF;
  border-radius: 10px;
  padding: 16px;
  box-shadow: ${BoxShadow};
  position: relative;
`;

const OptionsHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 10px;
`;

const MenuOptionsContainer = styled('div')`
  display: flex;
  flex-wrap: wrap;
`;

const DateOptionsContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;

  .downloadBtn {
    border: 1px solid #808080;
    border-radius: 50%;
    height: 35px;
    width: 35px;
    padding: 3px;
    margin-left: 10px;
  }
`;

const ColorNote = styled('div')`
  color: #444444;
  font-family: 'Nunito Sans';
  font-size: 14px;
  font-weight: normal;
`;

const NoDataModal = styled('div')`
  position: absolute;
  text-align: center;
  top: 50%;
  left: 50%;
  margin: -45px 0px 0px -182px;
  height: 119px;
  width: 400px;
  background-color: #FFFFFF;
  border-radius: 8px;
  box-shadow: ${BoxShadow};
  font-family: 'Nunito Sans';
  font-size: 19px;
  color: #424242;
  padding: 15px;
`;

const ModalTitle = styled('div')`
  font-weight: bold;
`;

const ModalMessage = styled('div')`
  font-weight: 300;
`;

const AnalysisComponent = () => {
  const athleteList = useSelector((state) => state.sportCoachDashboard.data.athleteList);
  const cardData = useSelector((state) => state.sportCoachDashboard.data.cardData);
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const dateType = useSelector((state) => state.sportCoachDashboard.data.dateType);
  const endDateCal = useSelector((state) => state.sportCoachDashboard.data.endDateCal);
  const endDateData = useSelector((state) => state.sportCoachDashboard.data.endDateData);
  const exerciseFiltersGroup = useSelector(
    (state) => state.sportCoachDashboard.data.exerciseFiltersGroup,
  );
  const exercises = useSelector((state) => state.sportCoachDashboard.data.exercises);
  const group = useSelector((state) => state.sportCoachDashboard.data.group);
  const groups = useSelector((state) => state.sportCoachDashboard.data.groups);
  const isGroupAnalysisLoading = useSelector((
    state,
  ) => state.sportCoachDashboard.ui.isGroupAnalysisLoading);
  const maxType = useSelector((state) => state.sportCoachDashboard.data.maxType);
  const selectedAthleteGroup = useSelector((
    state,
  ) => state.sportCoachDashboard.data.selectedAthleteGroup);
  const startDateCal = useSelector((state) => state.sportCoachDashboard.data.startDateCal);
  const startDateData = useSelector((state) => state.sportCoachDashboard.data.startDateData);

  const hasData = cardData.length > 0;

  const dispatch = useDispatch();

  // Logic to populate athlete dropdown
  const athleteDropdownList = hasData && [...athleteList];
  athleteDropdownList.length > 0 && athleteDropdownList.unshift({ fullName: 'View Group', userId: null });

  const exerIds = exercises.length > 0 && exercises.map((item) => item.id);

  // Manipulate and filter the data so it plays nicely with the GA table
  // Creating a new array with data attached to any athlete found in athleteList
  // and adding null data if exercise isn't found in return data
  // Data is filtered based on exerciseFiltersGroup and selectedAthleteGroup
  const manipulatedData = [];
  hasData && athleteList.forEach((athlete) => {
    if (
      !selectedAthleteGroup
    || (selectedAthleteGroup.fullName === 'View Group')
    || (selectedAthleteGroup && selectedAthleteGroup.fullName !== 'View Group' && selectedAthleteGroup.userId === athlete.userId)
    ) {
      const athleteResults = [];

      exerciseFiltersGroup.forEach((exerciseName) => {
        const exerciseData = cardData.find((exercise) => exercise.metricName === exerciseName);
        if (exerciseData) {
          const athleteExerciseData = exerciseData.athleteData.find(
            (resultData) => resultData.userId === athlete.userId,
          );
          if (athleteExerciseData) {
            athleteResults.push({
              exerName: exerciseName,
              newestMax: athleteExerciseData.newestMax,
              oldestMax: athleteExerciseData.oldestMax,
              percentChange: athleteExerciseData.percentChange,
              color: athleteExerciseData.color,
            });
          } else {
            athleteResults.push({
              exerName: exerciseName,
              newestMax: null,
              oldestMax: null,
              percentChange: null,
              color: null,
            });
          }
        } else {
        // If exercise data not found, push null data
          athleteResults.push({
            exerName: exerciseName,
            newestMax: null,
            oldestMax: null,
            percentChange: null,
            color: null,
          });
        }
      });

      manipulatedData.push({
        userId: athlete.userId,
        fullName: athlete.fullName,
        pic: athlete.pic,
        results: athleteResults,
      });
    }
  });

  // Ensure consitency with group average data by manipulating and
  // filtering it similar to above
  const groupAverageData = [];
  hasData && exerciseFiltersGroup.forEach((exerName) => {
    const exercise = hasData && cardData.find((exer) => exer.metricName === exerName);

    if (exercise) {
      groupAverageData.push({
        metricName: exercise.metricName,
        percentChange: exercise.percentChange,
        color: exercise.color,
        newestAverage: exercise.newestAverage,
        oldestAverage: exercise.oldestAverage,
      });
    } else {
    // Add exercise with null values if not found in cardData
      groupAverageData.push({
        metricName: exerName,
        percentChange: null,
        color: null,
        newestAverage: null,
        oldestAverage: null,
      });
    }
  });

  const setSelectedGroup = (option) => {
    // Amplitude tracking
    dashboardTracker('Sport Coach Dashboard', 'Group Analysis - Group Picker', 'Group selected');
    batch(() => {
      dispatch(setGroup(option));
      dispatch(setSelectedAthleteGroup(null, false));
      dispatch(setSelectedAthletePerformance(null, false));
      dispatch(
        fetchSCDashData(
          currentUser.accountCode,
          startDateData,
          endDateData,
          option.id,
          exerIds,
          dateType,
          maxType,
        ),
      );
    });
  };

  const setAthlete = (option) => {
    if (option.fullName === 'View Group') {
      // Amplitude tracking
      dashboardTracker('Sport Coach Dashboard', 'Group Analysis - Athlete Picker', 'Athlete selection reset');
      dispatch(setSelectedAthleteGroup(null, false));
    } else {
      // Amplitude tracking
      dashboardTracker('Sport Coach Dashboard', 'Group Analysis - Athlete Picker', 'Athlete selected');
      dispatch(setSelectedAthleteGroup(option, true));
    }
  };

  const setStartDate = (date) => {
    // Amplitude tracking
    dashboardTracker('Sport Coach Dashboard', 'Group Analysis - Date Picker', 'Start date selected');
    batch(() => {
      dispatch(setStartDateData(moment(date).format('YYYY-MM-DD')));
      dispatch(setStartDateCal(moment(date)));
      dispatch(
        fetchSCDashData(
          currentUser.accountCode,
          moment(date).format('YYYY-MM-DD'),
          endDateData,
          group?.id,
          exerIds,
          dateType,
          maxType,
        ),
      );
    });
  };

  const setEndDate = (date) => {
    // Amplitude tracking
    dashboardTracker('Sport Coach Dashboard', 'Group Analysis - Date Picker', 'End date selected');
    batch(() => {
      dispatch(setEndDateData(moment(date).format('YYYY-MM-DD')));
      dispatch(setEndDateCal(moment(date)));
      dispatch(
        fetchSCDashData(
          currentUser.accountCode,
          startDateData,
          moment(date).format('YYYY-MM-DD'),
          group?.id,
          exerIds,
          dateType,
          maxType,
        ),
      );
    });
  };

  const [columnHeaderHeight, setColumnHeaderHeight] = useState(null);
  const containerWidth = 1000;

  return (
    <>
      <DashboardContainer>
        <OptionsHeader>
          <MenuOptionsContainer>
            <ForcePlateDropDown
              list={groups}
              label='name'
              selectItemFunction={setSelectedGroup}
              headerWidthPx={175}
              placeholder='Select a Group'
              defaultOption={group || {}}
              readOnly
            />
            {hasData && (
            <ForcePlateDropDown
              list={athleteDropdownList}
              label='fullName'
              selectItemFunction={setAthlete}
              headerWidthPx={175}
              placeholder='Select an Athlete'
              defaultOption={selectedAthleteGroup || {}}
            />
            )}
            <ExerciseFilterGroup />
          </MenuOptionsContainer>
          <DateOptionsContainer>
            <ForcePlateDatePicker
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              startDate={startDateCal.toDate()}
              endDate={endDateCal.toDate()}
            />
          </DateOptionsContainer>
        </OptionsHeader>
        <ColorNote>
          <span style={{ fontWeight: 'bold' }}>NOTE: </span>
          The column background color is determined by the amount of percent change.
          The percent change column will be red if performance drops more than 3% and
          green if there is any improvement in performance.
        </ColorNote>
        {isGroupAnalysisLoading ? (
          <Spinner />
        ) : !hasData ? (
          <>
            <SCAnalysisTableNoData
              setColumnHeaderHeight={setColumnHeaderHeight}
              containerWidth={containerWidth}
              dataRows={['', '', '', '', '']}
              rowIsScrollable
            />
            <NoDataModal>
              <ModalTitle>
                {!group && !hasData ? 'Select a Group' : 'No Data Available'}
              </ModalTitle>
              <ModalMessage>
                {!group && !hasData
                  ? 'Select a group using the dropdown above to get started'
                  : 'There is no data available, please adjust your selection above'}
              </ModalMessage>
            </NoDataModal>
          </>
        ) : manipulatedData.length > 0 ? (
          <SCAnalysisTable
            setColumnHeaderHeight={setColumnHeaderHeight}
            containerWidth={containerWidth}
            dataRows={manipulatedData}
            groupAverageData={groupAverageData}
            rowIsScrollable
          />
        ) : null}
      </DashboardContainer>
    </>
  );
};

export default AnalysisComponent;
