// authored by Joshua Beedle
import styled from '@emotion/styled';
import { typography, color } from 'styled-system';

const HeaderText = styled('h1')`
  color: #444444;
  ${color}
  font-family: 'Nunito Sans';
  font-size: 20pt;
  margin-bottom: 0px;
  ${typography}
`;

export default HeaderText;
