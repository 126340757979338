/* eslint-disable no-restricted-globals */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useSortBy, useTable, usePagination } from 'react-table';
import { useSticky } from 'react-table-sticky';
import IcomoonReact from 'icomoon-react';
import moment from 'moment';

import { metricOptions, metricUnits, metricValueDefaults } from '../../metricConstants';
import iconSet from '../../../../shared/images/teambuildr-selection.json';
import TBTooltip from '../../../../shared/components/TBTooltip/TBTooltip';

const Styles = styled.div`
  background: #ffffff;
  font-family: nunito sans;
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  align-items: flex-start;

  & .sticky {
    overflow-x: auto;
    margin: 25px 10px;
    filter: blur(1.2px);

    tfoot {
      td:first-child {
        position: sticky;
        left: 0;
      }
    }

    [data-sticky-td] {
      position: sticky;
    }
  }

  .athleteColumnHeaderCell {
    border-bottom: none;
  }

  .noHoverCategoryColumnTitleCell {
    background-color: #bdbdbd1a;
    color: #444444;
    font-size: 16px;
    font-weight: bold;
    border-right: 1px solid #bdbdbd1a;
    border-top-left-radius: 8px;
    min-width: 270px;
    max-width: 270px;
  }

  .rawValueTitle {
    font-family: "Nunito Sans";
    font-size: 16px;
    font-weight: normal;
    background-color: #bdbdbd1a;
    color: #444444;
    text-align: left;
    min-width: 100px;
    max-width: 100px;
  }

  .athleteNameTitle {
    font-family: "Nunito Sans";
    font-size: 16px;
    font-weight: bold;
    text-align: left;
    color: #444444;
    min-width: 180px;
    max-width: 180px;
    position: relative;
  }
  // Fixes sticky athlete title column not hidding content from other columns
  .athleteNameTitle::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 180px;
    height: 100%;
    background-color: #bdbdbd1a;
    border-top-left-radius: 8px;
    border-right: 1px solid #e0e0e0;
  }

  .footerCells {
    font-size: 16px;
    font-family: "Nunito Sans";
    font-weight: normal;
    text-align: left;
    color: #444444;
  }

  table {
    border-spacing: 0;
    border-collapse: separate;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      background: white;
      padding: 15px;
      border-bottom: 1px solid #e0e0e0;
      border-right: 1px solid #bdbdbd1a;
      text-align: center;
    }

    tfoot {
      tr:first-child {
        td {
          border-top: 1px solid #e0e0e0;
          height: 61px;
          min-height: 61px;
          border-bottom: none;
          background: #fcfcfc;
          border-bottom-left-radius: 8px;
        }
      }
      td:first-child {
        color: #444444;
        font-size: 16px;
        font-weight: normal;
        text-align: left;
        font-family: "Nunito Sans";
      }
      tr:last-child {
        display: none;
      }
    }
  }
`;

const Table = ({
  containerWidth, columns, data, getCellProps,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    prepareRow,
    page,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 5 },
    },
    useSticky,
    useSortBy,
    usePagination,
  );

  return (
    <>
      <div id='reportTable' style={{ display: 'flex' }} className='sticky'>
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup, i) => (
              <tr {...headerGroup.getHeaderGroupProps()} data-a-wrap>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps([
                      {
                        className: column.className,
                      },
                    ])}
                    data-cols-width='30'
                    data-a-wrap
                    data-f-bold
                    data-fill-color={column?.style?.fillColor || 'FF424242'}
                    data-b-a-s='thin'
                    data-b-a-c='FF000000'
                    data-f-color={
                      column?.style?.fillColor ? 'FF000000;' : 'FFFFFFFF'
                    }
                    data-a-h='center'
                    data-a-v='center'
                  >
                    {column.render('Header')}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <IcomoonReact
                            iconSet={iconSet}
                            size={10}
                            color='black'
                            icon='down-arrow'
                            style={{ marginLeft: '5px' }}
                          />
                        ) : (
                          <IcomoonReact
                            iconSet={iconSet}
                            size={10}
                            color='black'
                            icon='up-arrow'
                            style={{ marginLeft: '5px' }}
                          />
                        )
                      ) : (
                        ''
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td
                      {...cell.getCellProps([
                        {
                          style: cell.column.style,
                        },
                        getCellProps(cell),
                      ])}
                      data-fill-color={
                        getCellProps(cell)?.style?.fillColor || null
                      }
                      data-f-color={getCellProps(cell)?.style?.fontColor}
                      data-b-a-s='thin'
                      data-b-a-c='FF000000'
                      data-a-wrap
                    >
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            {footerGroups.map((group) => (
              <tr {...group.getFooterGroupProps()}>
                {group.headers.map((column) => (
                  <td
                    data-a-wrap
                    {...column.getFooterProps([{ className: 'footerCells' }])}
                  >
                    {column.render('Footer')}
                  </td>
                ))}
              </tr>
            ))}
          </tfoot>
        </table>
        <div
          style={{
            display: 'flex',
            textAlign: 'center',
            background: '#5F8BDC1A',
            color: '#5F8BDC',
            border: '1px dashed #5F8BDC',
            borderRadius: '5px',
            width: '250px',
            alignItems: 'center',
            justifyContent: 'center',
            fontFamily: 'NunitoSans',
            fontSize: '16px',
            fontWeight: 'bold',
          }}
        >
          <span>+Add Metric</span>
        </div>
      </div>
    </>
  );
};

const GroupAnalysisTableNoData = ({
  containerWidth,
  dataRows,
  setColumnHeaderHeight,
  rowIsScrollable,
}) => {
  const metricFiltersGroup = useSelector(
    (state) => state.forcePlateDashboard.data.metricFiltersGroup,
  );
  const endDateData = useSelector(
    (state) => state.forcePlateDashboard.data.endDateData,
  );
  const endDateCal = useSelector(
    (state) => state.forcePlateDashboard.data.endDateCal,
  );
  const isReadOnlyView = useSelector(
    (state) => state.forcePlateDashboard.ui.isReadOnlyView,
  );
  const formattedDate = moment(
    isReadOnlyView ? endDateCal : endDateData,
  ).format('MMM DD');

  const chartRef = useRef(null);
  const [headerAndSubHeaderHeight, setHeaderAndSubHeaderHeight] = useState(0);
  const [isScrollable, setIsScrollable] = useState(false);

  const handleScroll = (e) => {
    /** targetting the sticky container div scroll properties */
    const scrollWidth = chartRef.current?.childNodes[0]?.scrollWidth;
    const offsetWidth = chartRef.current?.childNodes[0]?.offsetWidth;
    const scrollLeft = chartRef.current?.childNodes[0]?.scrollLeft;
    setIsScrollable(offsetWidth + scrollLeft !== scrollWidth);
  };

  React.useEffect(() => {
    if (chartRef.current) {
      const scrollWidth = chartRef.current.childNodes[0]?.scrollWidth;
      const offsetWidth = chartRef.current.childNodes[0]?.offsetWidth;
      const scrollLeft = chartRef.current.childNodes[0]?.scrollLeft;
      setIsScrollable(offsetWidth + scrollLeft !== scrollWidth);
    }
  }, []);

  React.useEffect(() => {
    if (chartRef.current) {
      setHeaderAndSubHeaderHeight(
        chartRef.current.childNodes[0].childNodes[0].childNodes[0].clientHeight,
      );
      setColumnHeaderHeight(
        chartRef.current.childNodes[0].childNodes[0].childNodes[0].childNodes[0]
          .clientHeight,
      );
    }
  }, []);

  const athleteNameColumn = [
    {
      Header: '',
      Footer: () => null,
      id: 'name',
      sticky: 'left',
      className: 'athleteColumnHeaderCell',
      columns: [
        {
          Header: 'Name',
          accessor: 'name',
          Footer: 'Group Average',
          className: 'athleteNameTitle',
          style: {
            /** style for the whole column
             * className is style for just the header
             */
            borderRight: '1px solid #E0E0E0',
            height: '58px',
          },
          Cell: ({ row }) => (
            <TBTooltip
              textToDisplay={row.original.name}
              textContainerPadding='0px'
              textContainerMaxWidth='175px'
              fontSize='12px'
              fontColor='black'
            />
          ),
        },
      ],
    },
  ];

  const formatColumns = () => {
    const formattedData = !!metricFiltersGroup.length
      && metricFiltersGroup.map((metric) => {
        const metricName = metricOptions.find(
          (option) => option.replace(/\s/g, '') === metric,
        );
        const metricUnit = metricUnits[metricName];
        return {
          Header: metricUnit ? `${metricName} (${metricUnit})` : metricName,
          Footer: () => null,
          id: `${metricName}`,
          className: 'noHoverCategoryColumnTitleCell',
          columns: [
            {
              Header: `${formattedDate}`,
              accessor: 'date',
              Cell: ({ row }) => (
                <TBTooltip
                  textToDisplay=''
                  textContainerPadding='0px'
                  textContainerMaxWidth='90px'
                  fontSize='12px'
                  fontColor='black'
                />
              ),
              Footer: metricValueDefaults[metricName],
              id: `${metricName}-date`,
              className: 'rawValueTitle',
              style: {
                fontWeight: 700,
                minWidth: '90px',
                maxWidth: '90px',
                fillColor: 'FFBDBDBD1A',
              },
            },
            {
              Header: 'Avg',
              accessor: 'avg',
              Cell: ({ row }) => (
                <TBTooltip
                  textToDisplay=''
                  textContainerPadding='0px'
                  textContainerMaxWidth='90px'
                  fontSize='12px'
                  fontColor='black'
                />
              ),
              Footer: metricValueDefaults[metricName],
              id: `${metricName}-average`,
              className: 'rawValueTitle',
              style: {
                fontWeight: 700,
                minWidth: '90px',
                maxWidth: '90px',
                fillColor: 'FFBDBDBD1A',
              },
            },
            {
              Header: 'Deviation',
              accessor: 'deviation',
              Cell: ({ row }) => (
                <TBTooltip
                  textToDisplay=''
                  textContainerPadding='0px'
                  textContainerMaxWidth='90px'
                  fontSize='12px'
                  fontColor='black'
                />
              ),
              Footer: '0.00',
              id: `${metricName}-deviation`,
              className: 'rawValueTitle',
              style: {
                fontWeight: 700,
                minWidth: '90px',
                maxWidth: '90px',
                fillColor: 'FFBDBDBD1A',
              },
            },
          ],
        };
      });

    return formattedData;
  };

  const formattedMiddleColumns = formatColumns();

  const columns = [...athleteNameColumn, ...formattedMiddleColumns];

  const memoizedColumns = React.useMemo(() => columns, [
    dataRows,
    containerWidth,
  ]);
  const memoizedDataRows = React.useMemo(() => dataRows, [
    dataRows,
    containerWidth,
  ]);

  return (
    <>
      <Styles
        ref={chartRef}
        onScroll={handleScroll}
        isScrollable={isScrollable}
        rowIsScrollable={rowIsScrollable}
        headerAndSubHeaderHeight={headerAndSubHeaderHeight}
        containerWidth={containerWidth}
      >
        <Table
          containerWidth={containerWidth}
          columns={memoizedColumns}
          data={memoizedDataRows}
          getCellProps={(cellInfo) => ({
            style: {
              backgroundColor: 'FFFFFFFF',
              color: 'FF444444',
              fillColor: 'FFFFFFFF',
              fontColor: 'FF444444',
            },
          })}
        />
      </Styles>
    </>
  );
};

export default GroupAnalysisTableNoData;
