export const FETCH_SELECTIONS_START = 'FETCH_SELECTIONS_START';
export const FETCH_SELECTIONS_FULFILLED = 'FETCH_SELECTIONS_FULFILLED';
export const FETCH_SELECTIONS_REJECTED = 'FETCH_SELECTIONS_REJECTED';

export const FETCH_EXERCISES_START = 'FETCH_EXERCISES_START';
export const FETCH_EXERCISES_FULFILLED = 'FETCH_EXERCISES_FULFILLED';
export const FETCH_EXERCISES_REJECTED = 'FETCH_EXERCISES_REJECTED';

export const FETCH_MAXREPORT_START = 'FETCH_MAXREPORT_START';
export const FETCH_MAXREPORT_FULFILLED = 'FETCH_MAXREPORT_FULFILLED';
export const FETCH_MAXREPORT_REJECTED = 'FETCH_MAXREPORT_REJECTED';

export const FETCH_MAXREPORT_CSV_START = 'FETCH_MAXREPORT_CSV_START';
export const FETCH_MAXREPORT_CSV_FULFILLED = 'FETCH_MAXREPORT_CSV_FULFILLED';
export const FETCH_MAXREPORT_CSV_REJECTED = 'FETCH_MAXREPORT_CSV_REJECTED';

export const FETCH_QUESTIONNAIRE_REPORT_SINGLE_DAY_START = 'FETCH_QUESTIONNAIRE_REPORT_SINGLE_DAY_START';
export const FETCH_QUESTIONNAIRE_REPORT_SINGLE_DAY_FULFILLED = 'FETCH_QUESTIONNAIRE_REPORT_SINGLE_DAY_FULFILLED';
export const FETCH_QUESTIONNAIRE_REPORT_SINGLE_DAY_REJECTED = 'FETCH_QUESTIONNAIRE_REPORT_SINGLE_DAY_REJECTED';

export const FETCH_QUESTIONNAIRE_REPORT_MULTIDAY_START = 'FETCH_QUESTIONNAIRE_REPORT_MULTIDAY_START';
export const FETCH_QUESTIONNAIRE_REPORT_MULTIDAY_FULFILLED = 'FETCH_QUESTIONNAIRE_REPORT_MULTIDAY_FULFILLED';
export const FETCH_QUESTIONNAIRE_REPORT_MULTIDAY_REJECTED = 'FETCH_QUESTIONNAIRE_REPORT_MULTIDAY_REJECTED';

export const FETCH_QUESTIONNAIRES_FOR_ACCOUNT_START = 'FETCH_QUESTIONNAIRES_FOR_ACCOUNT_START';
export const FETCH_QUESTIONNAIRES_FOR_ACCOUNT_FULFILLED = 'FETCH_QUESTIONNAIRES_FOR_ACCOUNT_FULFILLED';
export const FETCH_QUESTIONNAIRES_FOR_ACCOUNT_REJECTED = 'FETCH_QUESTIONNAIRES_FOR_ACCOUNT_REJECTED';

export const FETCH_QUESTIONS_FOR_ACCOUNT_START = 'FETCH_QUESTIONS_FOR_ACCOUNT_START';
export const FETCH_QUESTIONS_FOR_ACCOUNT_FULFILLED = 'FETCH_QUESTIONS_FOR_ACCOUNT_FULFILLED';
export const FETCH_QUESTIONS_FOR_ACCOUNT_REJECTED = 'FETCH_QUESTIONS_FOR_ACCOUNT_REJECTED';

// export const FETCH_QUESTIONNAIREREPORT_CSV_START = 'FETCH_QUESTIONNAIREREPORT_CSV_START';
// export const FETCH_QUESTIONNAIREREPORT_CSV_FULFILLED = 'FETCH_QUESTIONNAIREREPORT_CSV_FULFILLED';
// export const FETCH_QUESTIONNAIREREPORT_CSV_REJECTED = 'FETCH_QUESTIONNAIREREPORT_CSV_REJECTED';
export const SET_CURRENT_QUESTION_IN_QUESTIONNAIRE_PICKER = 'SET_CURRENT_QUESTION_IN_QUESTIONNAIRE_PICKER';

export const SET_ACTIVE_REDUX_MODAL_FOR_QUESTIONNAIRE_ATHLETE_ZONE = 'SET_ACTIVE_REDUX_MODAL_FOR_QUESTIONNAIRE_ATHLETE_ZONE';
export const SET_ATHLETES_IN_CLICKED_ZONE = 'SET_ATHLETES_IN_CLICKED_ZONE';

export const SEARCH_SELECTION = 'SEARCH_SELECTION';

export const SET_ACTIVE_REPORT = 'SET_ACTIVE_REPORT';

export const SET_MODAL_OPEN = 'SET_MODAL_OPEN';

export const SET_SIDEBAR_WIDTH = 'SET_SIDEBAR_WIDTH';

export const SET_LAYOUT_HEIGHT = 'SET_LAYOUT_HEIGHT';

export const SET_IS_REPORT_OPEN = 'SET_IS_REPORT_OPEN';

export const SAVE_SIDEBAR_FILTER = 'SAVE_SIDEBAR_FILTER';

export const CLEAR_REPORT = 'CLEAR_REPORT';

export const FETCH_ACTIVITY_REPORT_START = 'FETCH_ACTIVITY_REPORT_START';
export const FETCH_ACTIVITY_REPORT_FULFILLED = 'FETCH_ACTIVITY_REPORT_FULFILLED';
export const FETCH_ACTIVITY_REPORT_REJECTED = 'FETCH_ACTIVITY_REPORT_REJECTED';

export const FETCH_OPT_OUT_REPORT_START = 'FETCH_OPT_OUT_REPORT_START';
export const FETCH_OPT_OUT_REPORT_FULFILLED = 'FETCH_OPT_OUT_REPORT_FULFILLED';
export const FETCH_OPT_OUT_REPORT_REJECTED = 'FETCH_OPT_OUT_REPORT_REJECTED';

export const FETCH_OPT_OUT_REPORT_CSV_START = 'FETCH_OPT_OUT_REPORT_CSV_START';
export const FETCH_OPT_OUT_REPORT_CSV_FULFILLED = 'FETCH_OPT_OUT_REPORT_CSV_FULFILLED';
export const FETCH_OPT_OUT_REPORT_CSV_REJECTED = 'FETCH_OPT_OUT_REPORT_CSV_REJECTED';

export const FETCH_RESULTS_REPORT_START = 'FETCH_RESULTS_REPORT_START';
export const FETCH_RESULTS_REPORT_FULFILLED = 'FETCH_RESULTS_REPORT_FULFILLED';
export const FETCH_RESULTS_REPORT_REJECTED = 'FETCH_RESULTS_REPORT_REJECTED';

export const FETCH_GROUPS_START = 'FETCH_GROUPS_START';
export const FETCH_GROUPS_FULFILLED = 'FETCH_GROUPS_FULFILLED';
export const FETCH_GROUPS_REJECTED = 'FETCH_GROUPS_REJECTED';

export const FETCH_ATHLETES_START = 'FETCH_ATHLETES_START';
export const FETCH_ATHLETES_FULFILLED = 'FETCH_ATHLETES_FULFILLED';
export const FETCH_ATHLETES_REJECTED = 'FETCH_ATHLETES_REJECTED';

export const SET_LATEST_ACTIVITY_FILTER = 'SET_LATEST_ACTIVITY_FILTER';
export const SET_SCHEDULED_WORKOUT_FILTER = 'SET_SCHEDULED_WORKOUT_FILTER';

export const DOWNLOAD_ACTIVITY_REPORT_START = 'DOWNLOAD_ACTIVITY_REPORT_START';
export const DOWNLOAD_ACTIVITY_REPORT_FULFILLED = 'DOWNLOAD_ACTIVITY_REPORT_FULFILLED';
export const DOWNLOAD_ACTIVITY_REPORT_REJECTED = 'DOWNLOAD_ACTIVITY_REPORT_REJECTED';

export const FETCH_RAW_DATA_REPORT_START = 'FETCH_RAW_DATA_REPORT_START';
export const FETCH_RAW_DATA_REPORT_FULFILLED = 'FETCH_RAW_DATA_REPORT_FULFILLED';
export const FETCH_RAW_DATA_REPORT_REJECTED = 'FETCH_RAW_DATA_REPORT_REJECTED';

export const SET_MOBILE_SIDEBAR_ACTIVE = 'SET_MOBILE_SIDEBAR_ACTIVE';

export const FETCH_EVALUATIONS_START = 'FETCH_EVALUATIONS_START';
export const FETCH_EVALUATIONS_FULFILLED = 'FETCH_EVALUATIONS_FULFILLED';
export const FETCH_EVALUATIONS_REJECTED = 'FETCH_EVALUATIONS_REJECTED';

export const FETCH_EVALUATION_REPORT_GA_START = 'FETCH_EVALUATION_REPORT_GA_START';
export const FETCH_EVALUATION_REPORT_GA_FULFILLED = 'FETCH_EVALUATION_REPORT_GA_FULFILLED';
export const FETCH_EVALUATION_REPORT_GA_REJECTED = 'FETCH_EVALUATION_REPORT_GA_REJECTED';

export const FETCH_EVALUATION_REPORT_OVERVIEW_START = 'FETCH_EVALUATION_REPORT_OVERVIEW_START';
export const FETCH_EVALUATION_REPORT_OVERVIEW_FULFILLED = 'FETCH_EVALUATION_REPORT_OVERVIEW_FULFILLED';
export const FETCH_EVALUATION_REPORT_OVERVIEW_REJECTED = 'FETCH_EVALUATION_REPORT_OVERVIEW_REJECTED';
export const RESET_EVALUATION_REPORT_GA_ERROR = 'RESET_EVALUATION_REPORT_GA_ERROR';

export const SET_SHOW_ALL_FOR_DOWNLOAD = 'SET_SHOW_ALL_FOR_DOWNLOAD';

export const REPORT_READY_FOR_PRINT = 'REPORT_READY_FOR_PRINT';

export const FETCH_COMPARISON_REPORT_START = 'FETCH_COMPARISON_REPORT_START';
export const FETCH_COMPARISON_REPORT_FULFILLED = 'FETCH_COMPARISON_REPORT_FULFILLED';
export const FETCH_COMPARISON_REPORT_REJECTED = 'FETCH_COMPARISON_REPORT_REJECTED';
export const SET_COMPARISON_REPORT_ERROR = 'SET_COMPARISON_REPORT_ERROR';
export const RESET_COMPARISON_REPORT_ERROR = 'RESET_COMPARISON_REPORT_ERROR';

export const FETCH_COMPLETION_REPORT_START = 'FETCH_COMPLETION_REPORT_START';
export const FETCH_COMPLETION_REPORT_FULFILLED = 'FETCH_COMPLETION_REPORT_FULFILLED';
export const FETCH_COMPLETION_REPORT_REJECTED = 'FETCH_COMPLETION_REPORT_REJECTED';

export const DOWNLOAD_COMPLETION_CSV_START = 'DOWNLOAD_COMPLETION_CSV_START';
export const DOWNLOAD_COMPLETION_CSV_FULFILLED = 'DOWNLOAD_COMPLETION_CSV_FULFILLED';
export const DOWNLOAD_COMPLETION_CSV_REJECTED = 'DOWNLOAD_COMPLETION_CSV_REJECTED';

export const CLEAR_REPORT_ERRORS = 'CLEAR_REPORT_ERRORS';

export const SET_IS_PRINTING = 'SET_IS_PRINTING';
