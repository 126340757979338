// authored by Joshua Beedle
import React from 'react';
import PropTypes from 'prop-types';

import Wrapper from './Wrapper';

/**
 * @param {String} darkTheme  bool to determine if it should be a darker color
 * @param {Boolean} large bool to determine the size of the spinner
 * @param {String} saving bool to determin which class name and path to use
*/

const Spinner = ({
  darkTheme,
  large,
  saving,
}) => {
  const isDarkTheme = darkTheme ? 'dark-saving-path' : 'saving-path';
  return (
    <Wrapper className='spinner-container'>
      <svg className={saving ? 'saving-spinner' : 'spinner'} width={large ? '65px' : '16px'} height={large ? '65px' : '16px'} viewBox='0 0 66 66' xmlns='http://www.w3.org/2000/svg'>
        <circle className={saving ? isDarkTheme : 'path'} fill='none' strokeWidth='6' strokeLinecap='round' cx='33' cy='33' r='30' />
      </svg>
    </Wrapper>
  );
};

Spinner.propTypes = {
  darkTheme: PropTypes.bool,
  large: PropTypes.bool,
  saving: PropTypes.bool,
};

Spinner.defaultProps = {
  darkTheme: false,
  large: true,
  saving: false,
};

export default Spinner;
