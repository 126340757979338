import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import styled from '@emotion/styled';

import { deleteGymStudioConnection } from '../../../ducks/gymStudioActions';
import { featureClickTracker } from '../../../../../shared/utils/amplitudeHelper';

const ModalContent = styled('div')`
  text-align: center;
  font-family: 'Nunito Sans', sans-serif;
`;

const Title = styled('h2')`
  font-size: 24px;
  font-weight: 900;
  color: #444444;
  text-align: left;
`;

const Description = styled('p')`
  font-size: 14px;
  font-weight: 600;
  color: #666666;
  margin: ${(props) => (props.bottomText ? '12px 0' : '32px 0')};
  text-align: left;
  font-family: 'Nunito Sans', sans-serif;
`;

const InstructionContainer = styled('div')`
  padding-left: 20px;
`;

const UserInstructions = styled('li')`
  color: #CC0404;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  text-indent: -20px;
  padding-left: 5px;
`;

const InputLabel = styled('label')`
  font-size: 16px;
  color: #444444;
  font-weight: 600;
  display: block;
  text-align: left;
  margin-bottom: 8px;
`;

const Divider = styled('div')`
  width: 100%;
  height: 1px;
  background-color: #9E9E9E;
  margin-bottom: 8px;
`;

const InputField = styled(Field)`
  width: 100%;
  padding: 12px;
  border: 1px solid ${(props) => (props.error ? '#CC0404' : '#9E9E9E')};
  border-radius: 4px;
  font-size: 14px;
  color: #444444;
  box-shadow: 
    0 1px 2px rgba(81, 81, 81, 0.2),
    inset 0 2px 4px rgba(98, 98, 98, 0.16);
  
  &:focus {
    border-color: ${(props) => (props.error ? '#CC0404' : '#444444')};
  }
`;

const ErrorText = styled('div')`
  color: #CC0404;
  font-family: 'Nunito Sans';
  font-size: 13px;
  font-weight: 700;
  margin-top: 4px;
  text-align: left;
`;

const ButtonContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 32px;
  gap: 12px;
`;

const RemoveButton = styled('button')`
  background-color: #CC0404;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  padding: 12px 24px;
  border: none;
  border-radius: 4px;
  width: 100%;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-transform: uppercase;

  &:hover {
    background-color: #A00000;
  }
`;

const KeepButton = styled('button')`
  background: #FFFFFF;
  color: #333333;
  font-size: 14px;
  font-weight: 600;
  padding: 12px 24px;
  border: 1px solid #B1B1B1;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  transition: background-color 0.3s ease;
`;

const DeleteConnectionModal = ({ onRequestClose }) => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.auth.data.currentUser);

  const validationSchema = Yup.object({
    confirmation: Yup.string()
      .oneOf(['DELETE'], 'You must type DELETE to confirm')
      .required('Please type DELETE to confirm'),
  });

  return (
    <ModalContent>
      <Title>Are you sure you want to remove your GymStudio connection?</Title>
      <Description>
        What happens to your TeamBuildr account after disconnecting GymStudio:
        <InstructionContainer>
          <UserInstructions>
            Athletes will remain users in both TeamBuildr and GymStudio,
            however they will lose the connection between the two platforms
            and they will NOT be able to view their class schedule
            or book classes through TeamBuildr.
          </UserInstructions>
          <UserInstructions>
            You will no longer be able to view your gym analytics through TeamBuildr.
          </UserInstructions>
          <UserInstructions>Workout access on failed payments will be removed.</UserInstructions>
        </InstructionContainer>
      </Description>

      <Formik
        initialValues={{ confirmation: '' }}
        validationSchema={validationSchema}
        onSubmit={() => {
          dispatch(deleteGymStudioConnection(currentUser?.accountCode));
          onRequestClose();
        }}
      >
        {({ errors, touched }) => (
          <Form style={{ paddingBottom: '0px' }}>
            <InputLabel htmlFor='confirmation'>Confirm removal</InputLabel>
            <Divider />
            <Description bottomText>
              To disconnect GymStudio from TeamBuildr, type DELETE below:
            </Description>
            <InputField
              id='confirmation'
              name='confirmation'
              type='text'
              placeholder='Type DELETE'
              autoComplete='off'
              error={errors.confirmation && touched.confirmation}
            />
            {errors.confirmation && touched.confirmation && (
              <ErrorText>{errors.confirmation}</ErrorText>
            )}

            <Description>
              This can be reconnected at any time by generating a new
              API key in your GymStudio integrations page.
            </Description>

            <ButtonContainer>
              <RemoveButton type='submit' fullWidth>Remove Connection</RemoveButton>
              <KeepButton
                type='button'
                fullWidth
                onClick={() => {
                  onRequestClose();

                  // Amplitude tracking
                  featureClickTracker(
                    'GymStudio',
                    'GymStudio - Keep Connection',
                    'GymStudio - User clicked to keep connection with GymStudio.',
                  );
                }}
              >
                Keep connection
              </KeepButton>
            </ButtonContainer>
          </Form>
        )}
      </Formik>
    </ModalContent>
  );
};

DeleteConnectionModal.propTypes = {
  onRequestClose: PropTypes.func.isRequired,
};

export default DeleteConnectionModal;
