/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React from 'react';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import Shimmer from 'react-shimmer-effect';
import {
  PieChart, Pie, Cell,
} from 'recharts';
import { useTheme } from 'emotion-theming';

import Text from '../../../../shared/_TBComponents/Text';

const DonutChart = ({
  arrayForDonutChartCount, containerWidth,
}) => {
  const theme = useTheme();
  const arrayOfAllQuestions = useSelector((state) => state.reporting.data.questionnaireReport?.arrayOfAllQuestions);
  const idOfCurrentQuestionInQuestionnairePicker = useSelector((state) => state.reporting.data.currentQuestionInQuestionnairePicker?.id);
  const abbreviationForCurrentQuestion = arrayOfAllQuestions.filter((question) => question.id === idOfCurrentQuestionInQuestionnairePicker)[0]?.abbr;
  const formattedAbbreviationOfCurrentQuestionInPicker = idOfCurrentQuestionInQuestionnairePicker === 'Total Scores' ? 'Total Scores' : abbreviationForCurrentQuestion?.toUpperCase();

  const isNewQuestionLoading = false;

  const DonutChartContainer = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
    min-width: 200px;
    width: ${(props) => (props.containerWidth <= 820 && '100%')};
    min-height: 259px;
    background: #FFFFFF;
    box-shadow: 0px 0px 1px rgba(48, 49, 51, 0.05), 0px 8px 16px rgba(48, 49, 51, 0.1);
    border-radius: 24px;
  `;

  const PieChartContainer = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 189px;
    height: 170px;
    margin-bottom: 30px;
    padding-top: 15px;
  `;

  const DonutChartContainerPlaceholderShimmer = styled('div')`
    min-width: 200px;
    min-height: 259px;
    max-width: 300px;
    border-radius: 24px;
  `;

  const TextContainer = styled('span')`
    display: flex:
    justify-content: center;
    align-items: center;
    margin: 15px;
    width: 225px;
    text-align: center;
`;

  const CustomLabel = styled('div')`
    box-shadow: 2px 2px 4px 2px rgba(0,0,0,0.08);
    background-color: #ffffff;
    width: 20px;
    height: 20px;
    display: flex;
    border-radius: 3px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
`;

  const LabelText = styled('div')`
    font-family: Nunito Sans;
    font-size: 12px;
    font-weight: bold;
`;

  const data02 = arrayForDonutChartCount;

  const sumOfArray = () => {
    let sum = 0;
    // eslint-disable-next-line no-return-assign
    arrayForDonutChartCount.map((objectInArray) => sum += objectInArray.value);
    return sum;
  };

  const defaultData = [
    { name: 'A1', value: 1 },
    { name: 'A2', value: 1 },
    { name: 'B1', value: 1 },
    { name: 'B2', value: 1 },

  ];

  // [red, green, yellow]
  const COLORS = ['#FF3737', '#0DCC8A', '#FFCB47'];

  const renderCustomizedLabel = ({
    cx, cy, midAngle, outerRadius, value,
  // eslint-disable-next-line consistent-return
  }) => {
    const RADIAN = Math.PI / 180;
    const radius = outerRadius;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    if (value !== 0) {
      return (
        <foreignObject style={{ pointerEvents: 'none' }} x={x} y={y} width='50' height='50'>
          <CustomLabel>
            <LabelText>
              {value}
            </LabelText>
          </CustomLabel>
        </foreignObject>
      );
    }
  };

  return (
    <DonutChartContainer containerWidth={containerWidth}>
      {isNewQuestionLoading ? (
        <Shimmer>
          <DonutChartContainerPlaceholderShimmer />
        </Shimmer>
      ) : (
        <>
          {arrayForDonutChartCount?.length && sumOfArray() !== 0 ? (
            <>
              <PieChartContainer>
                <Text styledTextProps={{ fontSize: '12px', fontWeight: 700 }}>Athletes In Each Zone</Text>
                <Text styledTextProps={{ fontSize: '12px', fontWeight: 700 }}>{formattedAbbreviationOfCurrentQuestionInPicker}</Text>
                <PieChart
                  width={200}
                  height={200}
                >
                  <Pie
                    isAnimationActive={false}
                    data={data02}
                    dataKey='value'
                    cx='50%'
                    cy='50%'
                    innerRadius={35}
                    outerRadius={60}
                    fill='#82ca9d'
                    label={renderCustomizedLabel}
                    labelLine={false}
                  >
                    {data02.map((entry, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} style={{ cursor: 'auto' }} /** onClick={() => handleColorClick(entry)} */ />
                    ))}
                  </Pie>
                </PieChart>
              </PieChartContainer>
            </>
          ) : (
            <>
              <PieChartContainer>
                <Text styledTextProps={{ fontSize: '12px', fontWeight: 700 }}>Athletes In Each Zone</Text>
                <PieChart width={200} height={200}>
                  <Pie data={defaultData} dataKey='value' cx='50%' cy='50%' innerRadius={35} outerRadius={60} fill='grey' />
                </PieChart>
              </PieChartContainer>
              <TextContainer>
                <Text styledTextProps={{ color: theme.colors.lightText[0], fontSize: '12px', fontWeight: 700 }}>No Status</Text>
              </TextContainer>
            </>
          )}
        </>
      )}
    </DonutChartContainer>
  );
};

export default DonutChart;
