/* eslint-disable max-len */
import React, { useState, useLayoutEffect } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import IcomoonReact from 'icomoon-react';
import { FixedSizeList as List } from 'react-window';
import iconSet from '../../images/teambuildr-selection.json';
import { BoxShadow } from '../../GlobalStyles';
import Text from '../Text/Text';
import SearchBarAndListComponent from './SearchBarAndListComponent';
import FlaggedAthletesModal from '../../../modules/force-plate-dashboard/components/presentational/FlaggedAthletesModal';

const ItemPickerContainer = styled('div')`
  display: flex;
  flex-direction: column;
  background: white;
  z-index: 100;
  position: absolute;
  box-shadow: ${BoxShadow};
  background: white;
  border-radius: 12px;
  max-width: ${(props) => props.itemPickerContainerMaxWidth}px;
  top: ${(props) => (props.small ? '30px' : '40px')};
  left: ${(props) => props.absoluteLeftPositioningOfItemPickerContainer}px;
`;

const ItemContainer = styled('div')`
  display: flex;
  padding: 10px;
  cursor: pointer;
  border-radius: inherit;
  align-items: center;
  &:hover {
    background-color: lightgrey;
  }
  img {
    height: 30px;
    width: 30px;
  }
  h3 {
    color: black;
  }
  svg {
    visibility: hidden;
  }
  @media screen and (orientation: portrait) {
    // padding: 10px 20px!important;
  }
`;

const ItemNameContainer = styled('div')``;

const TextOverflowEllipse = styled('h3')`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 80%;
  font-family: "Nunito Sans";
  font-size: ${(props) => (props.small ? '12px' : '16px')};
  color: ${(props) => (props.pickerFontColor ? props.pickerFontColor : '#444444')};
  margin-bottom: 0px;
  font-weight: 700;
  // text-align: center;
`;

const ItemSelectorContainer = styled('div')`
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-between;
min-width:  ${(props) => (props.minWidth ? `${props.minWidth}px` : '196px')};
// height:  ${(props) => (props.small ? '21px' : '47px')};
height:  ${(props) => (props.small ? '21px' : `${props.height}px`)};
max-width: ${(props) => (props.small ? '120px' : '200px')};
border-radius: 12px;
padding: ${(props) => (props.small ? '8px' : '8px 16px 8px 16px')};
background-color: ${(props) => (props.pickerButtonColor ? props.pickerButtonColor : '#444444')};
box-shadow: 0px 0px 1px rgba(48, 49, 51, 0.05), 0px 4px 8px rgba(48, 49, 51, 0.1);
position: relative;
opacity: 1;
/* cursor: pointer; */


 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #FFFFFF;
  box-shadow: 0px 0px 1px rgb(48 49 51 / 5%), 0px 4px 8px rgb(48 49 51 / 10%);
  color: #444444;
  font-family: 'Nunito Sans';
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  border-radius: 6px;
  padding: 8px;
  position: absolute;
  z-index: 1;
  bottom: 105%;
  left: 50%;
  margin-left: -60px;
}

 .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: white transparent transparent transparent;
}

:hover .tooltiptext {
  visibility: visible;
}

.tooltiptextBottom {
  visibility: hidden;
  width: 120px;
  background-color: #FFFFFF;
  box-shadow: 0px 0px 1px rgb(48 49 51 / 5%), 0px 4px 8px rgb(48 49 51 / 10%);
  color: #444444;
  font-family: 'Nunito Sans';
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  border-radius: 6px;
  padding: 8px;
  position: absolute;
  z-index: 1;
  top: 105%;
  left: 50%;
  margin-left: -60px;
}

 .tooltiptextBottom::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent white transparent;
}

:hover .tooltiptextBottom {
  visibility: visible;
}

`;

const Overlay = styled('div')`
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  // z-index: 999;
`;

/**
 * @param {Object} data an object that's passed in from the 'itemData' prop in the list component
 * seen below
 * @param {Integer} index the index of the current data object in the passed array
 * @param {Object} style necessary object that styles each individual container in accordance with
 * the requirements of the list
 * @returns a formatted list of dynamically rendered athlete rows
 */
const ItemRenderer = ({ data, index, style }) => {
  const { list, reduxFunction } = data;
  const item = list[index];
  return (
    <ItemContainer
      onClick={() => reduxFunction(item)}
      id={item.id}
      style={style}
    >
      <ItemNameContainer>
        <Text fontSize={16} fontWeight={700}>
          {item.displayText}
          {/* { (item.first && item.last) ? `${item.first} ${item.last}` : `${item.name}`} */}
        </Text>
      </ItemNameContainer>
    </ItemContainer>
  );
};

/**
 *
 * @param {Array} list the list of object to be passed into a generated windowed list
 * each list object can have as many properties as you want but each object will require at least the following two...
 * required list object properties {id: number, displayText : string }
 * @param {Function} reduxFunction the function to be called when the user clicks on
 * an athlete row in the windowed list
 * @param {Object} visibleContainerItem the item that is generated in the selector container
 * i.e. the currently selected item
 * @returns
 */
const Picker = ({
  list,
  reduxFunction,
  visibleContainerItem,
  small,
  minWidth,
  itemPickerContainerMaxWidth,
  absoluteLeftPositioningOfItemPickerContainer,
  height,
  addSearchBar,
  searchDiscription,
  icon,
  pickerButtonColor,
  pickerFontColor,
  disabled = false,
}) => {
  // state that manages whether the dropdown athlete list is showing
  const [isSelectorShowing, setIsSelectorShowing] = useState(false);

  const [addToolTip, setAddToolTip] = useState(false);

  const [isToolTipOnBottom, setIsToolTipOnBottom] = useState(false);

  const ref = React.createRef();

  const toolTipRef = React.createRef();

  const handleMouseEnter = () => {
    if (addToolTip && toolTipRef.current.getBoundingClientRect().top < 45) {
      setIsToolTipOnBottom(true);
    }
  };

  const handleMouseLeave = () => {
    if (addToolTip) {
      setIsToolTipOnBottom(false);
    }
  };

  useLayoutEffect(() => {
    if (
      ref?.current.childNodes[0].clientWidth
      < ref?.current.childNodes[0].scrollWidth
    ) {
      setAddToolTip(true);
    }
  }, [ref]);

  const handleClick = () => {
    if (!disabled) {
      setIsSelectorShowing(!isSelectorShowing);
    }
  };

  /**
   *
   * @param {Object} shownItem the object that appears as the item that's selected
   * from the list
   * @returns a formatted item for the selector container
   */

  return (
    <>
      <ItemSelectorContainer
        style={{ cursor: disabled ? 'default' : 'pointer' }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        ref={ref}
        small={small}
        minWidth={minWidth}
        height={height}
        pickerButtonColor={pickerButtonColor}
        onClick={handleClick}
        // onClick={!isMetricPicker ? handleClick : undefined}
        isSelectorShowing={isSelectorShowing}
      >
        {addToolTip && (
          <span
            className={isToolTipOnBottom ? 'tooltiptextBottom' : 'tooltiptext'}
            ref={toolTipRef}
          >
            {/* {visibleContainerItem?.name ? (
            `${visibleContainerItem.name}`
          ) : (
            `${visibleContainerItem.first} ${visibleContainerItem.last}`
          )} */}
            {visibleContainerItem}
          </span>
        )}
        {/* {visibleContainerItem?.name ? (
          <TextOverflowEllipse small={small} pickerFontColor={pickerFontColor}>
            { `${visibleContainerItem.name}`}
          </TextOverflowEllipse>
        ) : (
          <TextOverflowEllipse small={small}>
            {`${visibleContainerItem.first} ${visibleContainerItem.last}`}
          </TextOverflowEllipse>
        )} */}
        <TextOverflowEllipse small={small} pickerFontColor={pickerFontColor}>
          {visibleContainerItem}
        </TextOverflowEllipse>
        {icon && (
          <IcomoonReact
            color={icon.color}
            iconSet={iconSet}
            size={small ? 12 : icon.size}
            icon={icon.name}
          />
        )}
        {isSelectorShowing ? (
          <ItemPickerContainer
            small={small}
            itemPickerContainerMaxWidth={itemPickerContainerMaxWidth}
            absoluteLeftPositioningOfItemPickerContainer={
              absoluteLeftPositioningOfItemPickerContainer
            }
          >
            {addSearchBar ? (
              <SearchBarAndListComponent
                placeholder={searchDiscription}
                listdata={list}
                reduxFunction={reduxFunction}
                itemPickerContainerMaxWidth={itemPickerContainerMaxWidth}
              />
            ) : (
              <List
                height={list.length < 7 ? list.length * 55 : 400}
                itemData={{
                  list,
                  reduxFunction,
                }}
                itemCount={list.length}
                itemSize={55}
                width={280}
                style={{
                  borderRadius: '8px',
                  maxWidth: itemPickerContainerMaxWidth,
                }}
              >
                {ItemRenderer}
              </List>
            )}
          </ItemPickerContainer>
        ) : null}
      </ItemSelectorContainer>
      {isSelectorShowing ? (
        <Overlay
          onClick={() => {
            setIsSelectorShowing(false);
          }}
        />
      ) : null}
    </>
  );
};

ItemRenderer.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  index: PropTypes.number.isRequired,
  style: PropTypes.instanceOf(Object).isRequired,
};

Picker.propTypes = {
  /** list should be array of objects with at least two properties {id: number, displayText : string etc. } */
  list: PropTypes.instanceOf(Array).isRequired,
  reduxFunction: PropTypes.func.isRequired,
  visibleContainerItem: PropTypes.string.isRequired,
  // isMetricPicker: PropTypes.bool,
  // isGroupPicker: PropTypes.bool,
  small: PropTypes.bool,
  addSearchBar: PropTypes.bool,
  searchDiscription: PropTypes.string,
  icon: PropTypes.instanceOf(Object),
  pickerButtonColor: PropTypes.string,
  pickerFontColor: PropTypes.string,
  minWidth: PropTypes.number,
  height: PropTypes.number,
  itemPickerContainerMaxWidth: PropTypes.number,
  absoluteLeftPositioningOfItemPickerContainer: PropTypes.number,
  disabled: PropTypes.bool,
};

Picker.defaultProps = {
  // isMetricPicker: false,
  // isGroupPicker: false,
  small: false,
  addSearchBar: false,
  searchDiscription: '',
  icon: null,
  pickerButtonColor: '#444444',
  pickerFontColor: '#ffffff',
  minWidth: 119,
  itemPickerContainerMaxWidth: 280,
  height: 47,
  absoluteLeftPositioningOfItemPickerContainer: 0,
  disabled: false,
};

export default Picker;
