import {
  FETCH_GROUPS_START,
  FETCH_GROUPS_FULFILLED,
  FETCH_GROUPS_REJECTED,
  FETCH_PERFORMANCE_OVERVIEW_START,
  FETCH_PERFORMANCE_OVERVIEW_FULFILLED,
  FETCH_PERFORMANCE_OVERVIEW_REJECTED,
  SET_IS_METRIC_INFO_MODAL_SHOWING,
  SET_IS_FP_LEGEND_INFO_MODAL_SHOWING,
  SET_SELECTED_ATHLETE_PERFORMANCE,
  FETCH_METRIC_CARD_DATA_START,
  FETCH_METRIC_CARD_DATA_FULFILLED,
  FETCH_METRIC_CARD_DATA_REJECTED,
  SET_FORCE_PLATE_VIEW,
  FETCH_GROUP_ANALYSIS_START,
  FETCH_GROUP_ANALYSIS_FULFILLED,
  FETCH_GROUP_ANALYSIS_REJECTED,
  SET_SELECTED_ATHLETE_GROUP,
  SET_IS_METRIC_FILTERS_PERFORMANCE_SHOWING,
  SET_IS_METRIC_FILTERS_GROUP_SHOWING,
  SET_SHOW_FLAGGED_ATHLETES_MODAL_PERFORMANCE,
  SET_SHOW_FLAGGED_ATHLETES_MODAL_GROUP,
  SET_FLAGGED_ATHLETES_VIEW,
  SET_NEW_FP_DASH,
  FETCH_FP_TEMPLATES_START,
  FETCH_FP_TEMPLATES_FULFILLED,
  FETCH_FP_TEMPLATES_REJECTED,
  SET_SAVE_FP_TEMPLATE_MODAL,
  CREATE_FP_TEMPLATE_START,
  CREATE_FP_TEMPLATE_FULFILLED,
  CREATE_FP_TEMPLATE_REJECTED,
  SET_IS_UNSAVED_FP_DASH_MODAL,
  SET_EDIT_FP_DASH_MODAL,
  SET_DELETE_FP_TEMPLATE_MODAL,
  DELETE_FP_TEMPLATE_START,
  DELETE_FP_TEMPLATE_FULFILLED,
  DELETE_FP_TEMPLATE_REJECTED,
  SET_ABOUT_FP_DASH_MODAL,
  SET_SHARE_FP_DASH_MODAL,
  FETCH_SHARE_FP_DASH_LINK_START,
  FETCH_SHARE_FP_DASH_LINK_FULFILLED,
  FETCH_SHARE_FP_DASH_LINK_REJECTED,
  FETCH_PUBLIC_FP_PERFORMANCE_CHART_DATA_START,
  FETCH_PUBLIC_FP_PERFORMANCE_CHART_DATA_FULFILLED,
  FETCH_PUBLIC_FP_PERFORMANCE_CHART_DATA_REJECTED,
  FETCH_PUBLIC_FP_PERFORMANCE_CARD_DATA_START,
  FETCH_PUBLIC_FP_PERFORMANCE_CARD_DATA_FULFILLED,
  FETCH_PUBLIC_FP_PERFORMANCE_CARD_DATA_REJECTED,
  FETCH_PUBLIC_FP_GROUP_ANALYSIS_DATA_START,
  FETCH_PUBLIC_FP_GROUP_ANALYSIS_DATA_FULFILLED,
  FETCH_PUBLIC_FP_GROUP_ANALYSIS_DATA_REJECTED,
  SET_DUPLICATE_FP_DASH_MODAL,
  DUPLICATE_FP_DASH_START,
  DUPLICATE_FP_DASH_FULFILLED,
  DUPLICATE_FP_DASH_REJECTED,
  SET_FP_TRI_OPTION_MODAL,
  SET_IS_FP_DASH_DISPLAYED,
  CLEAR_FP_DASH_DATA,
  CLEAR_FP_DASH_MODALS,
} from './actionTypes';

const initialState = {
  forcePlateView: 'performance',
  infoModalMetric: '',
  isAthleteSelectedGroup: false,
  isAthleteSelectedPerformance: false,
  isFlaggedAthletesModalShowingGroup: false,
  isFlaggedAthletesModalShowingPerformance: false,
  isFlaggedAthletesView: false,
  isGroupAnalysisLoading: false,
  isGroupsLoading: false,
  isLegendInfoModalShowing: false,
  isMetricFiltersGroupShowing: false,
  isMetricFiltersPerformanceShowing: false,
  isMetricInfoModalShowing: false,
  isMetricLoading: false,
  isPerformanceLoading: false,
  isNewDash: false,
  fpTemplatesLoading: false,
  saveModalShowing: false,
  isSavingTemplateLoading: false,
  unsavedModalShowing: false,
  editModalShowing: false,
  isEditTemplateLoading: false,
  deleteModalShowing: false,
  isDeletingTemplateLoading: false,
  shareModalShowing: false,
  isShareDashLinkLoading: false,
  duplicateModalShowing: false,
  isDuplicatingTemplateLoading: false,
  aboutModalShowing: false,
  triOptionModalShowing: false,
  isReadOnlyView: false,
  isDashboardDisplayed: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_GROUPS_START: {
      return {
        ...state,
        isGroupsLoading: true,
      };
    }
    case FETCH_GROUPS_FULFILLED: {
      return {
        ...state,
        isGroupsLoading: false,
      };
    }
    case FETCH_GROUPS_REJECTED: {
      return {
        ...state,
        isGroupsLoading: false,
      };
    }
    case FETCH_PERFORMANCE_OVERVIEW_START: {
      return {
        ...state,
        isPerformanceLoading: true,
        isReadOnlyView: false,
      };
    }
    case FETCH_PERFORMANCE_OVERVIEW_FULFILLED: {
      return {
        ...state,
        isPerformanceLoading: false,
      };
    }
    case FETCH_PERFORMANCE_OVERVIEW_REJECTED: {
      return {
        ...state,
        isPerformanceLoading: false,
      };
    }
    case FETCH_METRIC_CARD_DATA_START: {
      return {
        ...state,
        isMetricLoading: true,
        isReadOnlyView: false,
      };
    }
    case FETCH_METRIC_CARD_DATA_FULFILLED: {
      return {
        ...state,
        isMetricLoading: false,
      };
    }
    case FETCH_METRIC_CARD_DATA_REJECTED: {
      return {
        ...state,
        isMetricLoading: false,
      };
    }
    case FETCH_GROUP_ANALYSIS_START: {
      return {
        ...state,
        isGroupAnalysisLoading: true,
        isReadOnlyView: false,
      };
    }
    case FETCH_GROUP_ANALYSIS_FULFILLED: {
      return {
        ...state,
        isGroupAnalysisLoading: false,
      };
    }
    case FETCH_GROUP_ANALYSIS_REJECTED: {
      return {
        ...state,
        isGroupAnalysisLoading: false,
      };
    }
    case SET_IS_METRIC_INFO_MODAL_SHOWING: {
      return {
        ...state,
        infoModalMetric: action.payload.metric,
        isMetricInfoModalShowing: action.payload.bool,
      };
    }
    case SET_IS_FP_LEGEND_INFO_MODAL_SHOWING: {
      return {
        ...state,
        isLegendInfoModalShowing: action.payload,
      };
    }
    case SET_SELECTED_ATHLETE_PERFORMANCE: {
      return {
        ...state,
        isAthleteSelectedPerformance: action.payload.bool,
      };
    }
    case SET_SELECTED_ATHLETE_GROUP: {
      return {
        ...state,
        isAthleteSelectedGroup: action.payload.bool,
      };
    }
    case SET_FORCE_PLATE_VIEW: {
      return {
        ...state,
        forcePlateView: action.payload,
      };
    }
    case SET_IS_METRIC_FILTERS_PERFORMANCE_SHOWING: {
      return {
        ...state,
        isMetricFiltersPerformanceShowing: action.payload,
      };
    }
    case SET_IS_METRIC_FILTERS_GROUP_SHOWING: {
      return {
        ...state,
        isMetricFiltersGroupShowing: action.payload,
      };
    }
    case SET_SHOW_FLAGGED_ATHLETES_MODAL_PERFORMANCE: {
      return {
        ...state,
        isFlaggedAthletesModalShowingPerformance: action.payload,
      };
    }
    case SET_SHOW_FLAGGED_ATHLETES_MODAL_GROUP: {
      return {
        ...state,
        isFlaggedAthletesModalShowingGroup: action.payload,
      };
    }
    case SET_FLAGGED_ATHLETES_VIEW: {
      return {
        ...state,
        isFlaggedAthletesView: action.payload,
      };
    }
    case SET_NEW_FP_DASH: {
      return {
        ...state,
        isNewDash: action.payload,
      };
    }
    case FETCH_FP_TEMPLATES_START: {
      return {
        ...state,
        lmTemplatesLoading: true,
      };
    }
    case FETCH_FP_TEMPLATES_FULFILLED: {
      return {
        ...state,
        lmTemplatesLoading: false,
      };
    }
    case FETCH_FP_TEMPLATES_REJECTED: {
      return {
        ...state,
        lmTemplatesLoading: false,
      };
    }
    case SET_SAVE_FP_TEMPLATE_MODAL: {
      return {
        ...state,
        saveModalShowing: action.payload,
      };
    }
    case CREATE_FP_TEMPLATE_START: {
      return {
        ...state,
        isSavingTemplateLoading: true,
      };
    }
    case CREATE_FP_TEMPLATE_FULFILLED: {
      return {
        ...state,
        isSavingTemplateLoading: false,
      };
    }
    case CREATE_FP_TEMPLATE_REJECTED: {
      return {
        ...state,
        isSavingTemplateLoading: true,
      };
    }
    case SET_IS_UNSAVED_FP_DASH_MODAL: {
      return {
        ...state,
        unsavedModalShowing: action.payload,
      };
    }
    case SET_EDIT_FP_DASH_MODAL: {
      return {
        ...state,
        editModalShowing: action.payload.bool,
      };
    }
    case SET_DELETE_FP_TEMPLATE_MODAL: {
      return {
        ...state,
        deleteModalShowing: action.payload.bool,
      };
    }
    case DELETE_FP_TEMPLATE_START: {
      return {
        ...state,
        isDeletingTemplateLoading: true,
      };
    }
    case DELETE_FP_TEMPLATE_FULFILLED: {
      return {
        ...state,
        isDeleteTemplateLoading: false,
      };
    }
    case DELETE_FP_TEMPLATE_REJECTED: {
      return {
        ...state,
        isDeletingTemplateLoading: false,
      };
    }
    case SET_ABOUT_FP_DASH_MODAL: {
      return {
        ...state,
        aboutModalShowing: action.payload.bool,
      };
    }
    case SET_SHARE_FP_DASH_MODAL: {
      return {
        ...state,
        shareModalShowing: action.payload.bool,
      };
    }
    case FETCH_SHARE_FP_DASH_LINK_START: {
      return {
        ...state,
        isShareDashLinkLoading: true,
      };
    }
    case FETCH_SHARE_FP_DASH_LINK_FULFILLED: {
      return {
        ...state,
        isShareDashLinkLoading: false,
      };
    }
    case FETCH_SHARE_FP_DASH_LINK_REJECTED: {
      return {
        ...state,
        isShareDashLinkLoading: false,
      };
    }
    case FETCH_PUBLIC_FP_PERFORMANCE_CHART_DATA_START: {
      return {
        ...state,
        isPerformanceLoading: true,
        isReadOnlyView: true,
      };
    }
    case FETCH_PUBLIC_FP_PERFORMANCE_CHART_DATA_FULFILLED: {
      return {
        ...state,
        isPerformanceLoading: false,
      };
    }
    case FETCH_PUBLIC_FP_PERFORMANCE_CHART_DATA_REJECTED: {
      return {
        ...state,
        isPerformanceLoading: false,
      };
    }
    case FETCH_PUBLIC_FP_PERFORMANCE_CARD_DATA_START: {
      return {
        ...state,
        isMetricLoading: true,
        isReadOnlyView: true,
      };
    }
    case FETCH_PUBLIC_FP_PERFORMANCE_CARD_DATA_FULFILLED: {
      return {
        ...state,
        isMetricLoading: false,
      };
    }
    case FETCH_PUBLIC_FP_PERFORMANCE_CARD_DATA_REJECTED: {
      return {
        ...state,
        isMetricLoading: false,
      };
    }
    case FETCH_PUBLIC_FP_GROUP_ANALYSIS_DATA_START: {
      return {
        ...state,
        isGroupAnalysisLoading: true,
        isReadOnlyView: true,
      };
    }
    case FETCH_PUBLIC_FP_GROUP_ANALYSIS_DATA_FULFILLED: {
      return {
        ...state,
        isGroupAnalysisLoading: false,
      };
    }
    case FETCH_PUBLIC_FP_GROUP_ANALYSIS_DATA_REJECTED: {
      return {
        ...state,
        isGroupAnalysisLoading: false,
      };
    }
    case SET_DUPLICATE_FP_DASH_MODAL: {
      return {
        ...state,
        duplicateModalShowing: action.payload.bool,
      };
    }
    case DUPLICATE_FP_DASH_START: {
      return {
        ...state,
        isDuplicatingTemplateLoading: true,
      };
    }
    case DUPLICATE_FP_DASH_FULFILLED: {
      return {
        ...state,
        isDuplicatingTemplateLoading: false,
      };
    }
    case DUPLICATE_FP_DASH_REJECTED: {
      return {
        ...state,
        isDuplicatingTemplateLoading: false,
      };
    }
    case SET_FP_TRI_OPTION_MODAL: {
      return {
        ...state,
        triOptionModalShowing: action.payload,
      };
    }
    case SET_IS_FP_DASH_DISPLAYED: {
      return {
        ...state,
        isDashboardDisplayed: action.payload,
      };
    }
    case CLEAR_FP_DASH_DATA: {
      return {
        ...state,
        forcePlateView: 'performance',
      };
    }
    case CLEAR_FP_DASH_MODALS: {
      return {
        ...state,
        aboutModalShowing: false,
        deleteModalShowing: false,
        duplicateModalShowing: false,
        editModalShowing: false,
        saveModalShowing: false,
        shareModalShowing: false,
        triOptionModalShowing: false,
        unsavedModalShowing: false,
        isFlaggedAthletesModalShowingGroup: false,
        isFlaggedAthletesModalShowingPerformance: false,
        isLegendInfoModalShowing: false,
        infoModalMetric: '',
        isMetricInfoModalShowing: false,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
