export const dummyData = [
  {
    date: '2023-05-10',
    values: [
      {
        metricTitle: 'Volume Load',
        metricId: 'volumeLoad',
        chartType: 'bar',
        value: 0,
      },
      {
        metricTitle: 'Exertion Score',
        metricId: 'exertionScore',
        chartType: 'line',
        value: 0,
      },
      {
        metricTitle: 'How many hours of sleep did you get?',
        metricId: 6573,
        chartType: 'line',
        value: 0,
      },
      {
        metricTitle: 'Rate the quality of your sleep',
        metricId: 6585,
        chartType: 'line',
        value: 0,
      },
      {
        metricTitle: 'How fatigued are you?',
        metricId: 6587,
        chartType: 'line',
        value: 0,
      },
      {
        metricTitle: 'What did you have for breakfast this morning?',
        metricId: 6870,
        chartType: 'line',
        value: 0,
      },
    ],
  },
  {
    date: '2023-05-09',
    values: [
      {
        metricTitle: 'Volume Load',
        metricId: 'volumeLoad',
        chartType: 'bar',
        value: 0,
      },
      {
        metricTitle: 'Exertion Score',
        metricId: 'exertionScore',
        chartType: 'line',
        value: 0,
      },
      {
        metricTitle: 'How many hours of sleep did you get?',
        metricId: 6573,
        chartType: 'line',
        value: null,
      },
      {
        metricTitle: 'Rate the quality of your sleep',
        metricId: 6585,
        chartType: 'line',
        value: 0,
      },
      {
        metricTitle: 'How fatigued are you?',
        metricId: 6587,
        chartType: 'line',
        value: 0,
      },
      {
        metricTitle: 'What did you have for breakfast this morning?',
        metricId: 6870,
        chartType: 'line',
        value: 0,
      },
    ],
  },
  {
    date: '2023-05-03',
    values: [
      {
        metricTitle: 'Volume Load',
        metricId: 'volumeLoad',
        chartType: 'bar',
        value: 0,
      },
      {
        metricTitle: 'Exertion Score',
        metricId: 'exertionScore',
        chartType: 'line',
        value: 0,
      },
      {
        metricTitle: 'How many hours of sleep did you get?',
        metricId: 6573,
        chartType: 'line',
        value: 0,
      },
      {
        metricTitle: 'Rate the quality of your sleep',
        metricId: 6585,
        chartType: 'line',
        value: 0,
      },
      {
        metricTitle: 'How fatigued are you?',
        metricId: 6587,
        chartType: 'line',
        value: 0,
      },
      {
        metricTitle: 'What did you have for breakfast this morning?',
        metricId: 6870,
        chartType: 'line',
        value: 0,
      },
    ],
  },
  {
    date: '2023-05-01',
    values: [
      {
        metricTitle: 'Volume Load',
        metricId: 'volumeLoad',
        chartType: 'bar',
        value: 0,
      },
      {
        metricTitle: 'Exertion Score',
        metricId: 'exertionScore',
        chartType: 'line',
        value: 0,
      },
      {
        metricTitle: 'How many hours of sleep did you get?',
        metricId: 6573,
        chartType: 'line',
        value: 0,
      },
      {
        metricTitle: 'Rate the quality of your sleep',
        metricId: 6585,
        chartType: 'line',
        value: 0,
      },
      {
        metricTitle: 'How fatigued are you?',
        metricId: 6587,
        chartType: 'line',
        value: null,
      },
      {
        metricTitle: 'What did you have for breakfast this morning?',
        metricId: 6870,
        chartType: 'line',
        value: 0,
      },
    ],
  },
];

export const dummyData2 = [
  {
    date: '2023-03-08',
    values: [
      {
        metricTitle: 'Volume Load',
        metricId: 'volumeLoad',
        chartType: 'bar',
        value: 810,
      },
      {
        metricTitle: 'Exertion Score',
        metricId: 'exertionScore',
        chartType: 'line',
        value: 5.1,
      },
      {
        metricTitle: 'How Many Hours Of Sleep Did You Get?',
        metricId: 29203,
        chartType: 'line',
        value: 5,
      },
      {
        metricTitle: 'How Stressed Are You?',
        metricId: 29204,
        chartType: 'line',
        value: 6,
      },
      {
        metricTitle: 'Academic Related Stress?',
        metricId: 29208,
        chartType: 'line',
        value: 3,
      },
      {
        metricTitle: 'What is Your Hydration Status?',
        metricId: 29208,
        chartType: 'line',
        value: 3,
      },
      {
        metricTitle: 'Rate the Quality Of Your Sleep?',
        metricId: 29208,
        chartType: 'line',
        value: 5,
      },
    ],
  },
  {
    date: '2023-03-06',
    values: [
      {
        metricTitle: 'Volume Load',
        metricId: 'volumeLoad',
        chartType: 'bar',
        value: 5575,
      },
      {
        metricTitle: 'Exertion Score',
        metricId: 'exertionScore',
        chartType: 'line',
        value: 7,
      },
      {
        metricTitle: 'How Many Hours Of Sleep Did You Get?',
        metricId: 29203,
        chartType: 'line',
        value: 6,
      },
      {
        metricTitle: 'Academic Related Stress?',
        metricId: 29208,
        chartType: 'line',
        value: 6,
      },
      {
        metricTitle: 'What is Your Hydration Status?',
        metricId: 29208,
        chartType: 'line',
        value: 8,
      },
      {
        metricTitle: 'Rate the Quality Of Your Sleep?',
        metricId: 29208,
        chartType: 'line',
        value: 2,
      },
    ],
  },
  {
    date: '2023-02-22',
    values: [
      {
        metricTitle: 'Volume Load',
        metricId: 'volumeLoad',
        chartType: 'bar',
        value: 3000,
      },
      {
        metricTitle: 'Exertion Score',
        metricId: 'exertionScore',
        chartType: 'line',
        value: 9,
      },
      {
        metricTitle: 'How Many Hours Of Sleep Did You Get?',
        metricId: 29203,
        chartType: 'line',
        value: 8,
      },
      {
        metricTitle: 'How Stressed Are You?',
        metricId: 29204,
        chartType: 'line',
        value: 2,
      },
      {
        metricTitle: 'Academic Related Stress?',
        metricId: 29208,
        chartType: 'line',
        value: 3,
      },
      {
        metricTitle: 'What is Your Hydration Status?',
        metricId: 29208,
        chartType: 'line',
        value: 9,
      },
      {
        metricTitle: 'Rate the Quality Of Your Sleep?',
        metricId: 29208,
        chartType: 'line',
        value: 7,
      },
    ],
  },
  {
    date: '2023-02-21',
    values: [
      {
        metricTitle: 'Volume Load',
        metricId: 'volumeLoad',
        chartType: 'bar',
        value: 2580,
      },
      {
        metricTitle: 'Exertion Score',
        metricId: 'exertionScore',
        chartType: 'line',
        value: 3,
      },
      {
        metricTitle: 'Do You Feel Like You Are Progressing?',
        metricId: 29212,
        chartType: 'line',
        value: 6,
      },
      {
        metricTitle: 'How Motivated Are You?',
        metricId: 29208,
        chartType: 'line',
        value: 4,
      },
      {
        metricTitle: 'Academic Related Stress?',
        metricId: 29208,
        chartType: 'line',
        value: 2,
      },
      {
        metricTitle: 'What is Your Hydration Status?',
        metricId: 29208,
        chartType: 'line',
        value: 3,
      },
      {
        metricTitle: 'Rate the Quality Of Your Sleep?',
        metricId: 29208,
        chartType: 'line',
        value: 3,
      },
    ],
  },
  {
    date: '2023-02-14',
    values: [
      {
        metricTitle: 'Volume Load',
        metricId: 'volumeLoad',
        chartType: 'bar',
        value: 5000,
      },
      {
        metricTitle: 'Exertion Score',
        metricId: 'exertionScore',
        chartType: 'line',
        value: 6,
      },
      {
        metricTitle: 'How Motivated Are You?',
        metricId: 29208,
        chartType: 'line',
        value: 4,
      },
      {
        metricTitle: 'How Many Hours Of Sleep Did You Get?',
        metricId: 29203,
        chartType: 'line',
        value: 7,
      },
      {
        metricTitle: 'How Stressed Are You?',
        metricId: 29204,
        chartType: 'line',
        value: 2,
      },
      {
        metricTitle: 'Academic Related Stress?',
        metricId: 29208,
        chartType: 'line',
        value: 4,
      },
      {
        metricTitle: 'What is Your Hydration Status?',
        metricId: 29208,
        chartType: 'line',
        value: 2,
      },
      {
        metricTitle: 'Rate the Quality Of Your Sleep?',
        metricId: 29208,
        chartType: 'line',
        value: 6,
      },
    ],
  },
  {
    date: '2023-01-18',
    values: [
      {
        metricTitle: 'Volume Load',
        metricId: 'volumeLoad',
        chartType: 'bar',
        value: 1000,
      },
      {
        metricTitle: 'Exertion Score',
        metricId: 'exertionScore',
        chartType: 'line',
        value: 9,
      },
      {
        metricTitle: 'How Many Hours Of Sleep Did You Get?',
        metricId: 29203,
        chartType: 'line',
        value: 5,
      },
      {
        metricTitle: 'How Stressed Are You?',
        metricId: 29204,
        chartType: 'line',
        value: 3,
      },
      {
        metricTitle: 'Academic Related Stress?',
        metricId: 29208,
        chartType: 'line',
        value: 0,
      },
      {
        metricTitle: 'What is Your Hydration Status?',
        metricId: 29208,
        chartType: 'line',
        value: 0,
      },
      {
        metricTitle: 'Rate the Quality Of Your Sleep?',
        metricId: 29208,
        chartType: 'line',
        value: 0,
      },
    ],
  },
  {
    date: '2023-01-10',
    values: [
      {
        metricTitle: 'Volume Load',
        metricId: 'volumeLoad',
        chartType: 'bar',
        value: 0,
      },
      {
        metricTitle: 'Exertion Score',
        metricId: 'exertionScore',
        chartType: 'line',
        value: 0,
      },
      {
        metricTitle: 'How Many Hours Of Sleep Did You Get?',
        metricId: 29203,
        chartType: 'line',
        value: 0,
      },
      {
        metricTitle: 'How Stressed Are You?',
        metricId: 29204,
        chartType: 'line',
        value: 0,
      },
      {
        metricTitle: 'How Motivated Are You?',
        metricId: 29208,
        chartType: 'line',
        value: 0,
      },
      {
        metricTitle: 'Do You Feel Like You Are Progressing?',
        metricId: 29212,
        chartType: 'line',
        value: 0,
      },
      {
        metricTitle: 'Academic Related Stress?',
        metricId: 29219,
        chartType: 'line',
        value: 0,
      },
      {
        metricTitle: 'What is Your Hydration Status?',
        metricId: 29228,
        chartType: 'line',
        value: 0,
      },
      {
        metricTitle: 'Rate the Quality Of Your Sleep?',
        metricId: 29238,
        chartType: 'line',
        value: 0,
      },
      {
        metricTitle: 'Sports Related Stress?',
        metricId: 29248,
        chartType: 'line',
        value: 0,
      },
      {
        metricTitle: 'How Confident Are You?',
        metricId: 29258,
        chartType: 'line',
        value: 0,
      },
    ],
  },
];

const data = [
  {
    name: '7/7/2022',
    Hydration: 10,
    RPE: 1,
    AcademicStress: 4,
    SportsRelatedStress: 1,
    SleepHours: 11,
    VolumeLoad: 3000,
    SleepQuality: 7,
    DietRating: 8,
    Soreness: 4,
    Confidence: 7,
  },
  {
    name: '7/8/2022',
    Hydration: 8,
    RPE: 6,
    AcademicStress: 8,
    SportsRelatedStress: 4,
    SleepHours: 5,
    VolumeLoad: 6000,
    SleepQuality: 7,
    DietRating: 5,
    Soreness: 6,
    Confidence: 5,
  },
  {
    name: '7/9/2022',
    Hydration: 10,
    RPE: 1,
    AcademicStress: 1,
    SportsRelatedStress: 1,
    SleepHours: 11,
    VolumeLoad: 1000,
    SleepQuality: 7,
    DietRating: 8,
    Soreness: 2,
    Confidence: 9,
  },
  {
    name: '7/10/2022',
    Hydration: 3,
    RPE: 6,
    AcademicStress: 1,
    SportsRelatedStress: 6,
    SleepHours: 5,
    VolumeLoad: 4000,
    SleepQuality: 7,
    DietRating: 6,
    Soreness: 4,
    Confidence: 7,
  },
  {
    name: '7/11/2022',
    Hydration: 1,
    RPE: 9,
    AcademicStress: 8,
    SportsRelatedStress: 8,
    SleepHours: 3,
    VolumeLoad: 12000,
    SleepQuality: 3,
    DietRating: 4,
    Soreness: 8,
    Confidence: 3,
  },
  {
    name: '7/12/2022',
    Hydration: 8,
    RPE: 4,
    AcademicStress: 1,
    SportsRelatedStress: 7,
    SleepHours: 8,
    VolumeLoad: 3000,
    SleepQuality: 7,
    DietRating: 8,
    Soreness: 2,
    Confidence: 8,
  },
  {
    name: '7/13/2022',
    Hydration: 5,
    RPE: 5,
    AcademicStress: 1,
    SportsRelatedStress: 1,
    SleepHours: 6,
    VolumeLoad: 2000,
    SleepQuality: 10,
    DietRating: 8,
    Soreness: 3,
    Confidence: 8,
  },
];
