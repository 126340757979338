import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useTheme } from 'emotion-theming';
import TableToExcel from '@linways/table-to-excel';
import IcomoonReact from 'icomoon-react';

import Text from '../../../../shared/_TBComponents/Text';
import Button from '../../../../shared/_TBComponents/Button';
import iconSet from '../../../../shared/images/teambuildr-selection.json';
import { reportViewTracker } from '../../../../shared/utils/amplitudeHelper';

const NavigationWrapper = styled('div')`
  display: flex;
  width: 100%;
  margin-bottom: -20px;
  z-index: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  padding: 20px;
  justify-content: end;

  p {
    opacity: .5;
    :hover {
      opacity: 1;
    }
  }
`;

const QuestionnaireDownloadReport = ({
  closeModal,
}) => {
  const theme = useTheme();

  const exportFile = () => {
    // Amplitude tracking
    reportViewTracker('Questionnaire Report', true);
    const tb = document.getElementById('betaQuestionnaireReportTable');
    TableToExcel.convert(tb, {
      name: 'questionnaire-report.xlsx',
      sheet: {
        name: 'Sheet 1',
      },
    });
    closeModal();
  };

  return (
    <>
      <NavigationWrapper>
        <Text onClick={closeModal} styledTextProps={{ lineHeight: 1 }}>
          <IcomoonReact
            iconSet={iconSet}
            size={13}
            icon='remove'
          />
        </Text>
      </NavigationWrapper>
      <Text styledTextProps={{ fontSize: '18px', textAlign: 'center' }}>
        Would you like to download this questionnaire report as an excel file?
      </Text>
      <Button
        bottom
        bgColor={theme.colors.green}
        fontSize='14px'
        fullWidth
        large
        name='download report'
        noBorder
        onClick={exportFile}
        square
        styledWrapperProps={{
          bottom: 0,
          left: 0,
          position: 'absolute',
        }}
        txtColor='#FFFFFF'
        type='button'
        upperCase
      />
    </>
  );
};

QuestionnaireDownloadReport.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default QuestionnaireDownloadReport;
