/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { useReactToPrint } from 'react-to-print';

import Button from '../../../../shared/components/Button/Button';
import { BoxShadow } from '../../../../shared/GlobalStyles';
import ForcePlateDatePicker from './ForcePlateDatePicker';
import PerformanceOverviewChart from './PerformanceOverviewChart';
import MetricsContainers from './MetricsContainers';
import PerformanceFilter from './PerformanceFilter';
import ForcePlateDropDown from './ForcePlateDropDown';

import {
  setFlaggedAthletesPerformance,
  setIsLegendInfoModalShowing,
  setIsMetricInfoModalShowing,
  setSelectedAthletePerformance,
  setShowFlaggedAthletesModalPerformance,
} from '../../ducks/forcePlateDashboardActions';
import { dashboardTracker } from '../../../../shared/utils/amplitudeHelper';

const DashboardContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin: 15px 15px 7px 15px;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 16px;
  box-shadow: ${BoxShadow};
  height: 100%;
  max-height: 600px;
  position: relative;
  @media print {
    @page {
      size: A4 landscape;
      margin: 6px;
    }
    width: 1000px;
    max-height: unset;
  }
`;

const OptionsHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 10px;
`;

const MenuOptionsContainer = styled('div')`
  display: flex;
  flex-wrap: wrap;
`;

const DateOptionsContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;

  .printBtn {
    border: 1px solid #808080;
    border-radius: 50%;
    height: 35px;
    width: 35px;
    padding: 3px;
    margin-left: 10px;
  }
`;

const LegendAndIcon = styled('div')`
  display: flex;
  width: 215px;
  margin-left: 17px;
  filter: ${(props) => (props.isBlurred ? 'blur(1.2px)' : null)};
  align-items: center;

  button {
    background: transparent !important;
  }

  svg {
    border: 1px solid #808080;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    padding: 3px;
  }
`;

const LegendTitle = styled('div')`
  font-family: "Nunito Sans";
  font-size: 16px;
  font-weight: 400;
  margin-right: 5px;
`;

const ViewOnlyPerformanceOverview = () => {
  const endDateCal = useSelector(
    (state) => state.forcePlateDashboard.data.endDateCal,
  );
  const flaggedAthletesDataPerformance = useSelector(
    (state) => state.forcePlateDashboard.data.flaggedAthletesDataPerformance,
  );
  const group = useSelector((state) => state.forcePlateDashboard.data.group);
  const metricCardData = useSelector(
    (state) => state.forcePlateDashboard.data.metricCardData,
  );
  const performanceOverviewData = useSelector(
    (state) => state.forcePlateDashboard.data.performanceOverviewData,
  );
  const selectedAthletePerformance = useSelector(
    (state) => state.forcePlateDashboard.data.selectedAthletePerformance,
  );
  const startDateCal = useSelector(
    (state) => state.forcePlateDashboard.data.startDateCal,
  );

  const dispatch = useDispatch();

  const hasMetricData = metricCardData.length > 0;
  const hasPerformanceData = performanceOverviewData.length > 0;
  const data = hasPerformanceData && [...performanceOverviewData];
  const orderedData = hasPerformanceData
    && performanceOverviewData.sort((a, b) => new Date(a.date) - new Date(b.date));

  const endDateObject = orderedData && Object.values(orderedData).pop();

  const lastObject = hasPerformanceData && Object.values(data).pop();
  const athletes = hasPerformanceData
    && lastObject.athleteData.map((obj) => ({
      fullName: obj.fullName,
      userId: obj.userId,
    }));
  athletes && athletes.unshift({ fullName: 'View Group', userId: 0 });

  const printRef = useRef();

  const [readyPrint, setReadyPrint] = useState(false);

  const setAthlete = (option) => {
    if (option.fullName === 'View Group') {
      // KPI tracking
      dashboardTracker(
        'Force Plate Dashboard',
        'View Only Performance Overview - Athlete Picker',
        'Athlete selection reset',
      );
      dispatch(setSelectedAthletePerformance(null, false));
    } else {
      // KPI tracking
      dashboardTracker(
        'Force Plate Dashboard',
        'View Only Performance Overview - Athlete Picker',
        'Athlete selected',
      );
      dispatch(setSelectedAthletePerformance(option, true));
    }
  };

  const handleLegendInfoClick = () => {
    // KPI tracking
    dashboardTracker(
      'Force Plate Dashboard',
      'View Only Performance Overview - Key Performance Metrics Legend',
      'Legend info button clicked',
    );
    batch(() => {
      dispatch(setIsMetricInfoModalShowing(false, ''));
      dispatch(setIsLegendInfoModalShowing(true));
    });
  };

  /** Print functionality - ensure chart resized prior to
   * grabbing value of printRef.current to be printed */
  const handleReactPrint = useReactToPrint({
    content: () => printRef.current,
    onAfterPrint: () => {
      setReadyPrint(false);
    },
  });

  const handlePrint = () => {
    setReadyPrint(true);
  };

  useEffect(() => {
    if (readyPrint) {
      setTimeout(() => {
        handleReactPrint();
      }, 1500);
    }
  }, [readyPrint]);

  useEffect(() => {
    if (endDateObject?.flaggedAthletes?.length > 0) {
      dispatch(setFlaggedAthletesPerformance(endDateObject.flaggedAthletes));
    }
  }, [endDateObject]);

  useEffect(() => {
    if (flaggedAthletesDataPerformance?.length > 0) {
      dispatch(setShowFlaggedAthletesModalPerformance(true));
    } else {
      dispatch(setShowFlaggedAthletesModalPerformance(false));
    }
  }, [flaggedAthletesDataPerformance]);

  const emptyFunction = () => undefined;

  return (
    <>
      <DashboardContainer ref={printRef} readyPrint={readyPrint}>
        <OptionsHeader>
          <MenuOptionsContainer>
            <ForcePlateDropDown
              disabled
              list={['']}
              label='name'
              selectItemFunction={emptyFunction}
              headerWidthPx={175}
              placeholder='Select a Group'
              defaultOption={group || {}}
              readOnly
            />
            {athletes && (
              <ForcePlateDropDown
                list={athletes}
                label='fullName'
                selectItemFunction={setAthlete}
                headerWidthPx={175}
                placeholder='Select an Athlete'
                defaultOption={selectedAthletePerformance || {}}
              />
            )}
            {!readyPrint && <PerformanceFilter />}
          </MenuOptionsContainer>
          <DateOptionsContainer>
            <ForcePlateDatePicker
              disabled
              setStartDate={emptyFunction}
              setEndDate={emptyFunction}
              startDate={startDateCal.toDate()}
              endDate={endDateCal.toDate()}
            />
            {!readyPrint && (
              <Button
                className='printBtn'
                disabled={!hasPerformanceData}
                iconOnly
                icon='printer'
                onClick={() => {
                  handlePrint();
                  // KPI tracking
                  dashboardTracker(
                    'Force Plate Dashboard',
                    'View Only Performance Overview - Print',
                    'Print button clicked',
                  );
                }}
              />
            )}
          </DateOptionsContainer>
        </OptionsHeader>
        <PerformanceOverviewChart readyPrint={readyPrint} />
      </DashboardContainer>
      <LegendAndIcon isBlurred={!hasMetricData}>
        <LegendTitle>Key Performance Metrics</LegendTitle>
        <Button
          iconOnly
          icon='info'
          customColor='#00000000'
          noBorder
          onClick={handleLegendInfoClick}
          rounded
          disabled={!hasMetricData}
        />
      </LegendAndIcon>
      <MetricsContainers />
    </>
  );
};

export default ViewOnlyPerformanceOverview;
