import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import Shimmer from 'react-shimmer-effect';
import styled from '@emotion/styled';

import {
  fetchConnectionData, fetchQuickStats, setActiveGymStudioModal,
} from '../ducks/gymStudioActions';
import ModalHandler from '../components/presentational/ModalHandler';
import UserStatsCard from './components/presentational/UserStatsCard';
import ScheduleCard from './components/presentational/ScheduleCard';
import Button from '../../../shared/components/Button/Button';
import HeaderText from '../../../shared/components/HeaderText/HeaderText';
import Text from '../../../shared/components/Text/Text';
import GymStudioButton from '../../../shared/_TBComponents/Button';
import { featureClickTracker } from '../../../shared/utils/amplitudeHelper';

const MainContainer = styled('main')`
  flex: 10;
  position: relative;
  max-width: 1450px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 15px;
    height: 100%;
    background: linear-gradient(to right, rgba(128, 128, 128, 0.075), transparent);
    z-index: 2;
    pointer-events: none;
  }
`;

const DashboardHeader = styled('div')`
  display: flex;
  width: 100%;
  height: 104px;
  background-color: #ffffff;
  padding: 48px ${(props) => (props.connected ? '32px' : '48px')} 16px ${(props) => (props.connected ? '32px' : '48px')};
  align-items: center;
  justify-content: space-between;

  ${(props) => !props.connected && `
    @media only screen and (max-width: 1350px) {
      height: 148px;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      padding: 55px 24px 16px 24px;
    }
  `}

  @media (max-width: 767px) {
    padding: 36px 24px 16px 24px;
  }
`;

const HeaderContainer = styled('div')`
  display: flex;
  flex-direction: column;
`;

const StyledText = styled(Text)`
  font-size: 14px;

  @media (max-width: 767px) {
    font-size: 12px;
  }
`;

const IconButtonContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`;

const ButtonWrapper = styled('div')`
  transform: scale(1.6);
`;

const Container = styled('div')`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  padding: ${(props) => (props.connected ? '0px' : '32px 48px')};
  justify-content: flex-start;
  flex-direction: column;
  position: relative;

  @media only screen and (max-width: 1350px) {
    padding: ${(props) => (props.connected ? '0px' : '24px')};
  }
`;

const StatsContainer = styled('div')`
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: 100%;
  padding: 40px;

  @media (max-width: 1024px) {
    flex-direction: column;
    gap: 24px;
    padding: 32px;
  }
`;

const UsersCardContainer = styled('div')`
  display: flex;
  flex-wrap: wrap;
  justify-content: ${({ isSideBarExpanded }) => (isSideBarExpanded ? 'space-between' : 'flex-start')};
  width: 100%;
  height: fit-content;
  gap: 12px;

  & > * {
    flex: ${({ isSideBarExpanded }) => (isSideBarExpanded ? '1 1 auto' : '1 1 48%')};

    @media (max-width: 767px) {
      flex: 1 1 100%;
      justify-content: center;
    }
  }
`;

const ShimmerStatsDiv = styled('div')`
  width: 300px;
  height: 30px;
  border-radius: 8px;
`;

const QuickStatisticsDashboard = ({ currentTab }) => {
  const [initialLoading, setInitialLoading] = useState(true);
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const {
    isStudioConnected, connectionData, quickStats, schedule,
  } = useSelector((state) => state.gymStudio.data);
  const { loadingQuickStats, isSideBarExpanded } = useSelector((state) => state.gymStudio.ui);

  const dispatch = useDispatch();
  const today = moment().format('dddd, MMMM D, YYYY');
  const formattedDate = moment().format('YYYY-MM-DD');

  useEffect(() => {
    if (!currentUser?.accountCode) return;
    setInitialLoading(true);

    dispatch(fetchConnectionData(currentUser.accountCode, false)).then((response) => {
      const isConnected = response?.connectedAt;
      // Fetch user data if connected
      if (isConnected) {
        dispatch(fetchQuickStats(currentUser.accountCode, formattedDate));
        dispatch(setActiveGymStudioModal(''));
      } else {
        // Show modal if not connected
        dispatch(setActiveGymStudioModal('gym-connect'));
      }
    })
      .finally(() => {
        setInitialLoading(false);
      });
  }, [currentUser?.accountCode, formattedDate]);

  const openInfoModal = () => {
    dispatch(setActiveGymStudioModal('info'));

    // Amplitude tracking
    featureClickTracker(
      'GymStudio',
      'Gymstudio - Info Modal Button',
      'GymStudio - User clicked the info modal button.',
    );
  };

  const scheduleItemsArray = Array.isArray(schedule)
    ? schedule
      .map((item) => {
        let instructor = 'No instructor';

        if (item.substituteTeachers?.[0]) {
          instructor = `Substitute: ${item.substituteTeachers[0].firstName} ${item.substituteTeachers[0].lastName}`;
        } else if (item.teachers?.[0]) {
          instructor = `${item.teachers[0].firstName} ${item.teachers[0].lastName}`;
        }

        return {
          id: item.id,
          itemType: item.appointmentId ? 'appointment' : 'class',
          borderColor: item.appointmentId ? '#F6C300' : '#F5874E',
          name: item.lesson?.name || item.workshop?.name || item.appointment?.name || 'N/A',
          time: `${moment(item.startDate).format('h:mm A')} - ${moment(item.endDate).format('h:mm A')}`,
          instructor,
          location: item.room?.location?.name || item.schedule?.externalLocation || 'N/A',
          spots: `${item.booked}/${item.capacity}`,
          category: item.appointment?.category?.name || 'N/A',
        };
      })
    : [];

  return (
    <>
      <MainContainer>
        <DashboardHeader connected>
          <HeaderContainer>
            <HeaderText
              fontSize='24px'
              fontWeight='900'
              letterSpacing='normal'
              style={{ marginRight: '20px' }}
            >
              Quick Statistics
            </HeaderText>
            <StyledText>
              {today}
            </StyledText>
          </HeaderContainer>
          <IconButtonContainer>
            {isStudioConnected && (
            <GymStudioButton
              name='View my studio'
              icon='gs-house'
              bgColor='#FFFFFF'
              txtColor='#333333'
              fontSize='14px'
              styledTextProps={{
                fontWeight: '700',
                letterSpacing: '0.5px',
              }}
              styledWrapperProps={{
                maxHeight: '40px',
                minWidth: '170px',
              }}
              borderColor='#CFCFCF'
              customBorderRadius='4px'
              onClick={() => {
                // Amplitude tracking
                featureClickTracker(
                  'GymStudio',
                  'Gymstudio - View My Studio Button',
                  'GymStudio - User clicked to view their studio on GymStudio',
                );
                window.open(connectionData?.studioAdminUrl, '_blank')
              }}
            />
            )}
            <ButtonWrapper>
              <Button
                icon='info2'
                iconOnly
                onClick={openInfoModal}
              />
            </ButtonWrapper>
          </IconButtonContainer>
        </DashboardHeader>
        <Container connected>
          <StatsContainer>
            <UsersCardContainer isSideBarExpanded={isSideBarExpanded}>
              <UserStatsCard
                icon='gs-group'
                iconBgColor='#0AA36E'
                number={
                  !isStudioConnected || initialLoading || loadingQuickStats
                    ? null
                    : quickStats?.totalNewMembers ?? 0
                }
                text={!isStudioConnected || initialLoading || loadingQuickStats ? <Shimmer><ShimmerStatsDiv /></Shimmer> : 'New members'}
              />
              <UserStatsCard
                icon='gs-checkmark'
                iconBgColor='#444444'
                number={
                  !isStudioConnected || initialLoading || loadingQuickStats
                    ? null
                    : quickStats?.totalSignIns ?? 0
                }
                text={!isStudioConnected || initialLoading || loadingQuickStats ? <Shimmer><ShimmerStatsDiv /></Shimmer> : 'Total sign-ins'}
              />
              <UserStatsCard
                icon='gs-remove-round-2'
                iconBgColor='#444444'
                number={
                  !isStudioConnected || initialLoading || loadingQuickStats
                    ? null
                    : quickStats?.totalCancellations ?? 0
                }
                text={!isStudioConnected || initialLoading || loadingQuickStats ? <Shimmer><ShimmerStatsDiv /></Shimmer> : 'Total cancellations'}
              />
            </UsersCardContainer>
            <ScheduleCard
              scheduleItems={scheduleItemsArray}
              isStudioConnected={isStudioConnected}
              loadingQuickStats={loadingQuickStats}
              initialLoading={initialLoading}
            />
          </StatsContainer>
        </Container>
      </MainContainer>
      <ModalHandler currentTab={currentTab} />
    </>
  );
};

QuickStatisticsDashboard.propTypes = {
  currentTab: PropTypes.string.isRequired,
};

export default QuickStatisticsDashboard;
