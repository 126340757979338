import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Form } from 'formik';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import Select from 'react-select';
import { DateRangePicker } from 'react-dates';

import Text from '../../../../shared/_TBComponents/Text';
import Toggle from '../../../../shared/_TBComponents/Toggle';

const FormColumns = styled('div')`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  justify-content: ${(props) => (props.dataSetType !== 1 ? 'center' : '')};
  padding-top: ${(props) => (props.dataSetType !== 1 ? '8px' : '')};

  label {
    font-size: 12px;
  }
  
  .toggle-label {
    justify-content: ${(props) => (props.dataSetType !== 1 ? 'center' : '')};
  }
`;

const ToggleWrapper = styled('div')`
  padding-top: 20px;
`;

const MaxExerciseFilterContainer = styled('div')`
  padding-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const ColumnContent = styled('div')`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  padding: 0px 20px 10px 0px;
  min-width: 50%;
  max-width: 50%;
`;

const DatePickerContainer = styled('div')`
  display: flex;
  justify-content: center;

  tr:first-of-type {
    .CalendarDay, .CalendarDay__selected {
      border-top: 1px solid #e4e7e7;
    }
    .CalendarDay__outside {
      border-top: 0px;
      border-bottom: 1px solid #e4e7e7;
    }
  }

  & tr:last-of-type {
    border-right: 0px;
    .CalendarDay__outside {
      border-left: 1px solid #e4e7e7;
    }
    .CalendarDay__outside ~ .CalendarDay__outside{
      border-left: 0px solid #e4e7e7;
    }
  }

  .CalendarDay__selected {
    border-top: 0!important;
    border-right: 0px!important;
    border-color: #e4e7e7;
    .day-container {
      background-color: #0dcc8a!important;
    }
  }

  .DateRangePickerInput, .DateInput_input, .DateInput {
    border-radius: 4px;
  }

  .DateInput_input {
    padding: 5px 8px;
  }
  
  & button {
    border: 1px solid ${(props) => props.theme.noBorderColor};
    color: ${(props) => props.theme.lightTextColor};
    font-weight: 600;
  }

  & .CalendarMonth_caption {
    font-family: 'Nunito Sans';
    font-weight: 300;
  }
`;

const ModalWrapper = styled('div')`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const TitlePlusErrorWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-content: center;
`;

const InnerFolderFormWrapper = styled('div')`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 5px;

  .formik-form {
    width: 100%;
  }
`;

const FormGroup = styled('div')`
  width: 100%;

  label {
    width: 100%;
  }

  button {
    margin-bottom: 20px;
  }

  .maxes-select {
    width: 70%;
  }

  @media screen and (max-width: 600px) {
    a {
      font-size: 11px;
    }
  }
`;

const FieldError = styled('div')`
  font-size: 14px;
  color: #ff6600;
  margin-top: 5px;
  margin-left: 8px;
`;

const TextBoxOuterWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 15px;
`;

const TextBoxContainer = styled('div')`
  border-color: ${(props) => props.borderColor};
  border-radius: 4px;
  border-width: ${(props) => props.borderWidth};
  border-style: solid;
  height: 140px;
  margin-left: ${(props) => props.marginLeft};
  margin-right: ${(props) => props.marginRight};
  cursor: pointer;
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const TextBoxTitle = styled('div')`
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  margin-left: 10px;
  margin-right: 10px;
`;

const RawDataReportModalSlideOne = ({ formProps }) => {
  const theme = useTheme();
  const [focusedRangeDateInput, setFocusedRangeDateInput] = useState(null);
  const maxExerciseTypeOptions = [
    {
      name: 'Exercises + Health',
      id: 1,
    },
    {
      name: 'Exercises Only',
      id: 2,
    },
    {
      name: 'Health Only',
      id: 3,
    },
  ];
  const onClickTextBox = (selectedDataSetType) => {
    formProps.setFieldValue('dataSetType', selectedDataSetType);
    if (selectedDataSetType === 2) {
      // Reset tags and advanced workout data to false if sport science is selected
      formProps.setFieldValue('showAdvancedWorkoutData', false);
      formProps.setFieldValue('showTags', false);
      formProps.setFieldValue('newFormat', false);
    }
  };
  return (
    <>
      <ModalWrapper>
        <InnerFolderFormWrapper>
          <Form className='formik-form'>
            <FormGroup>
              <TitlePlusErrorWrapper>
                <Text
                  styledWrapperProps={{
                    margin: '10px 0px 5px',
                  }}
                >
                  <strong>Select Data Set</strong>
                </Text>
                {formProps.errors.dataSetType && (
                  <FieldError className='error-text'>{formProps.errors.dataSetType}</FieldError>
                )}
              </TitlePlusErrorWrapper>
              <TextBoxOuterWrapper>
                <TextBoxContainer
                  onClick={() => onClickTextBox(1)}
                  borderColor={formProps.values.dataSetType === 1 ? theme.colors.green : 'hsl(0,0%,80%)'}
                  borderWidth={formProps.values.dataSetType === 1 ? '2px' : '1px'}
                  marginRight='10px'
                >
                  <TextBoxTitle>
                    Completed Workouts
                  </TextBoxTitle>
                </TextBoxContainer>
                <TextBoxContainer
                  onClick={() => onClickTextBox(2)}
                  borderColor={formProps.values.dataSetType === 2 ? theme.colors.green : 'hsl(0,0%,80%)'}
                  borderWidth={formProps.values.dataSetType === 2 ? '2px' : '1px'}
                  marginRight='10px'
                >
                  <TextBoxTitle>
                    Sport Science
                  </TextBoxTitle>
                </TextBoxContainer>
                <TextBoxContainer
                  onClick={() => onClickTextBox(3)}
                  borderColor={formProps.values.dataSetType === 3 ? theme.colors.green : 'hsl(0,0%,80%)'}
                  borderWidth={formProps.values.dataSetType === 3 ? '2px' : '1px'}
                  marginRight='10px'
                >
                  <TextBoxTitle>
                    Maxes & Health
                  </TextBoxTitle>
                </TextBoxContainer>
              </TextBoxOuterWrapper>
            </FormGroup>
            <FormGroup>
              <TitlePlusErrorWrapper>
                <Text
                  styledWrapperProps={{
                    margin: '10px 0px 5px',
                  }}
                >
                  <strong>Select Date Range</strong>
                </Text>
                {formProps.errors.rangeDates && (
                  <FieldError className='error-text'>{formProps.errors.rangeDates}</FieldError>
                )}
              </TitlePlusErrorWrapper>
              <DatePickerContainer>
                <DateRangePicker
                  minimumNights={0}
                  startDate={formProps.values.rangeDates.startDate}
                  startDateId='startDate'
                  endDate={formProps.values.rangeDates.endDate}
                  endDateId='endDate'
                  onDatesChange={({ startDate, endDate }) => formProps.setFieldValue('rangeDates', { startDate, endDate })}
                  focusedInput={focusedRangeDateInput}
                  onFocusChange={(focusedInput) => setFocusedRangeDateInput(focusedInput)}
                  hideKeyboardShortcutsPanel
                  isOutsideRange={(day) => day.isAfter(new Date())}
                />
              </DatePickerContainer>
            </FormGroup>
            {formProps.values.dataSetType === 3 && (
              <FormGroup>
                <MaxExerciseFilterContainer>
                  <Text
                    styledWrapperProps={{
                      margin: '10px 0px 5px',
                    }}
                  >
                    <strong>Select Exercise Type</strong>
                  </Text>
                  <Select
                    className='maxes-select'
                    name='type'
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    options={maxExerciseTypeOptions}
                    defaultValue={maxExerciseTypeOptions[0]}
                    onChange={(options) => {
                      formProps.setFieldValue('maxExerciseType', options.id);
                    }}
                    isOptionSelected={(option) => formProps.values.maxExerciseType === option.id}
                  />
                </MaxExerciseFilterContainer>
              </FormGroup>
            )}
            <FormGroup>
              <ToggleWrapper>
                <FormColumns dataSetType={formProps.values.dataSetType}>
                  <ColumnContent>
                    <Toggle
                      defaultChecked={formProps.values.showExternalIds}
                      offColor='#4D4D4D'
                      offLabel='Show External IDs'
                      onChange={() => {
                        formProps.setFieldValue(
                          'showExternalIds',
                          !formProps.values.showExternalIds,
                        );
                      }}
                    />
                  </ColumnContent>

                  {formProps.values.dataSetType === 1 && (
                  <ColumnContent>
                    <Toggle
                      defaultChecked={formProps.values.showAdvancedWorkoutData}
                      offColor='#4D4D4D'
                      offLabel='Show Advanced Workout Data'
                      onChange={() => {
                        formProps.setFieldValue(
                          'showAdvancedWorkoutData',
                          !formProps.values.showAdvancedWorkoutData,
                        );
                      }}
                    />
                  </ColumnContent>
                  )}

                  {formProps.values.dataSetType === 1 && (
                  <ColumnContent>
                    <Toggle
                      defaultChecked={formProps.values.showTags}
                      offColor='#4D4D4D'
                      offLabel='Show Tags'
                      onChange={() => {
                        formProps.setFieldValue(
                          'showTags',
                          !formProps.values.showTags,
                        );
                      }}
                    />
                  </ColumnContent>
                  )}

                  {formProps.values.dataSetType === 1 && (
                    <ColumnContent>
                      <Toggle
                        defaultChecked={formProps.values.newFormat}
                        offColor='#4D4D4D'
                        offLabel='Use Row-Based Format'
                        onChange={() => {
                          formProps.setFieldValue(
                            'newFormat',
                            !formProps.values.newFormat,
                          );
                        }}
                      />
                    </ColumnContent>
                  )}

                  {formProps.values.dataSetType === 3 && (
                  <ColumnContent>
                    <Toggle
                      defaultChecked={formProps.values.includeGeneratedPRs}
                      offColor='#4D4D4D'
                      offLabel='Include Workout Generated Maxes/PRs'
                      onChange={() => {
                        formProps.setFieldValue(
                          'includeGeneratedPRs',
                          !formProps.values.includeGeneratedPRs,
                        );
                      }}
                    />
                  </ColumnContent>
                  )}

                </FormColumns>
              </ToggleWrapper>
            </FormGroup>
          </Form>
        </InnerFolderFormWrapper>
      </ModalWrapper>
    </>
  );
};
RawDataReportModalSlideOne.propTypes = {
  formProps: PropTypes.instanceOf(Object).isRequired,
};

export default RawDataReportModalSlideOne;
