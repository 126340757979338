/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Logger from 'js-logger';
import moment from 'moment';
import Select from 'react-select';

import Text from '../../../../shared/_TBComponents/Text';
import Toggle from '../../../../shared/_TBComponents/Toggle';
import QuestionnaireReportDatePicker from './QuestionnaireReportDatePicker';

const ToggleRow = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 10px;
  margin-bottom: 20px;

  label {
    font-size: 13px;
    font-weight: unset;
  }
`;

const DateSelectAndTextContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ReportRunText = styled('div')`
  font-family: 'Nunito Sans';
  font-size: 12pt;
  color: #444444;
  margin-bottom: 15px;
  font-size: 14px;
  font-weight: 700;
  margin-top: 15px;
`;

const reportRunOptions = [
  {
    id: 1,
    label: 'Date exercises were completed',
  },
  {
    id: 2,
    label: 'Date exercises were assigned',
  },
];

const QuestionnaireModalFormSlideTwo = ({ formProps }) => {
  useEffect(() => {
    formProps.setErrors({});
    formProps.setTouched({});
  }, [formProps.values.reportType]);

  const reportTypeOptions = [
    { value: 'singleDay', label: 'Today' },
    { value: 'singleDaySelect', label: 'Single Date' },
    { value: 'multiDay', label: 'Custom' },
  ];

  const setStartDate = (date) => {
    if (formProps.values.singleDaySelect) {
      formProps.setFieldValue('selectedDate', {
        date,
        queryFormat: moment(date).format('YYYY-MM-DD'),
      });
    } else {
      formProps.setFieldValue('startDate', {
        date,
        queryFormat: moment(date).format('YYYY-MM-DD'),
      });
      formProps.setFieldValue('endDate', {
        date: null,
        queryFormat: '',
      });
    }
  };

  const setEndDate = (date) => {
    formProps.setFieldValue('endDate', {
      date,
      queryFormat: moment(date).format('YYYY-MM-DD'),
    });
  };

  const customStyles = {
    option: (styles) => ({
      ...styles,
      cursor: 'pointer',
    }),
    control: (styles) => ({
      ...styles,
      cursor: 'pointer',
    }),
    menuPortal: (base) => ({
      ...base, zIndex: 9999,
    }),
  };

  return (
    <>
      <ToggleRow>
        <Text styledTextProps={{ fontSize: '14px', fontWeight: 700 }}>Select Report Options</Text>
        <Toggle
          defaultChecked={formProps.values.showTotal}
          offColor='#4D4D4D'
          offLabel='Show Total'
          onChange={() => {
            formProps.setFieldValue(
              'showTotal',
              formProps.values.showTotal === false && true,
            );
          }}
        />
        <Toggle
          defaultChecked={formProps.values.showRangeAverages}
          offColor='#4D4D4D'
          offLabel='Show Averages'
          onChange={() => {
            formProps.setFieldValue(
              'showRangeAverages',
              formProps.values.showRangeAverages === false && true,
            );
          }}
        />
        <Toggle
          defaultChecked={formProps.values.showIncompleteData}
          offColor='#4D4D4D'
          offLabel='Show Incomplete Data'
          onChange={() => {
            formProps.setFieldValue(
              'showIncompleteData',
              formProps.values.showIncompleteData === false && true,
            );
          }}
        />
      </ToggleRow>
      <DateSelectAndTextContainer>
        <Text styledTextProps={{ fontSize: '14px', fontWeight: 700 }}>Time Frame</Text>
        <Select
          classNamePrefix='react-select'
          options={reportTypeOptions}
          name='reportType'
          onChange={(option) => {
            if (option.label === 'Single Date') {
              formProps.setFieldValue('singleDaySelect', true);
              formProps.setFieldValue('reportType', 'singleDay');
            } else {
              formProps.setFieldValue('reportType', option.value);
              formProps.setFieldValue('singleDaySelect', false);
            }
            Logger.debug('Questions Array', formProps.values.questionIds);
          }}
          value={(formProps.values.reportType === 'singleDay' && !formProps.values.singleDaySelect) ? reportTypeOptions[0] : (formProps.values.reportType === 'singleDay' && formProps.values.singleDaySelect) ? reportTypeOptions[1] : reportTypeOptions[2]}
        />
      </DateSelectAndTextContainer>
      <ReportRunText>Run Report Based On</ReportRunText>
      <Select
        className='item-select'
        options={reportRunOptions}
        menuPortalTarget={document.body}
        defaultValue={reportRunOptions[formProps?.values?.dateAssigned - 1] || reportRunOptions[0]}
        styles={customStyles}
        getOptionLabel={(option) => option.label}
        getOptionValue={(option) => option.id}
        onChange={(selectedType) => {
          formProps.setFieldValue('dateAssigned', selectedType.id);
        }}
      />
      {formProps.values.reportType === 'multiDay' && (
        <QuestionnaireReportDatePicker
          formProps={formProps}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          startDate={formProps.values.startDate.date}
          endDate={formProps.values.endDate.date}
          reportType={formProps.values.reportType}
        />
      )}
      {formProps.values.singleDaySelect && (
      <QuestionnaireReportDatePicker
        formProps={formProps}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        startDate={formProps.values.selectedDate.date}
        reportType={formProps.values.reportType}
      />
      )}
    </>
  );
};

QuestionnaireModalFormSlideTwo.propTypes = {
  formProps: PropTypes.instanceOf(Object).isRequired,
};

export default QuestionnaireModalFormSlideTwo;
