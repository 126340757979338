/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch, batch } from 'react-redux';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';
import PropTypes from 'prop-types';

import {
  setDeleteDashboardModal,
  deleteDashboard,
} from '../../../../sport-coach-dashboard/ducks/sportCoachDashboardActions';
import iconSet from '../../../../../shared/images/teambuildr-selection.json';

import { BoxShadow } from '../../../../../shared/GlobalStyles';
import Button from '../../../../../shared/components/Button/Button';
import { dashboardTracker } from '../../../../../shared/utils/amplitudeHelper';
import SharedTemplateModalContainer from './SharedTemplateModalContainer';
import {
  deleteLMTemplate,
  setDeleteLMDashboardModal,
} from '../../../../load-monitoring-dashboard/ducks/loadMonitoringDashboardActions';
import {
  deleteFPTemplate,
  setDeleteFPDashboardModal,
} from '../../../../force-plate-dashboard/ducks/forcePlateDashboardActions';

const ModalContainer = styled('div')`
  display: flex;
  z-index: 1003;
  justify-content: center;
  height: auto;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  transition: all 1s ease;
  width: 60vw;
  max-width: 600px;
  position: absolute;
  left: 50%;
  top: 50%;
  right: 50%;
  border-radius: 12px;
  background: white;
  @media only screen and (max-width: 768px) {
    width: 90vw;
  }
  .remove-div {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 20px;
    background: white;
    z-index: 1001;
    .remove-div-icon {
      cursor: pointer;
    }
  }
  overflow: hidden;
  transition: all 0.3s linear;
  transform: ${(props) => (props.isActive ? 'translate(-50%,-50%)' : 'translate(-50%, 300%)')};
  .opt-out-button {
    background: #cc0404;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }
`;

const ContentContainer = styled('div')`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  background: white;
  box-shadow: ${BoxShadow};
  scroll-behavior: smooth;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 40px;
`;

const TopBox = styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const MiddleBoxText = styled('div')`
  font-family: "Nunito Sans";
  margin-top: 20px;
`;

const IconContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  border: 1px solid lightgrey;
`;

const TopBoxText = styled('div')`
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  margin-top: 15px;
  color: black;
`;

const SharedTemplateDeleteModal = ({ dashboard }) => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  let deleteTemplate = {};
  let isModalShowing = false;
  let setDeleteModalShowing = () => {};
  let templates = [];
  let handleDeleteTemplate = () => {};
  if (dashboard === 'Load Monitoring') {
    deleteTemplate = useSelector(
      (state) => state.loadMonitoringDashboard.data.deleteTemplate,
    );
    isModalShowing = useSelector(
      (state) => state.loadMonitoringDashboard.ui.deleteModalShowing,
    );
    setDeleteModalShowing = setDeleteLMDashboardModal;
    templates = useSelector(
      (state) => state.loadMonitoringDashboard.data.templates,
    );
    handleDeleteTemplate = deleteLMTemplate;
  }
  if (dashboard === 'Force Plate') {
    deleteTemplate = useSelector(
      (state) => state.forcePlateDashboard.data.deleteTemplate,
    );
    isModalShowing = useSelector(
      (state) => state.forcePlateDashboard.ui.deleteModalShowing,
    );
    setDeleteModalShowing = setDeleteFPDashboardModal;
    templates = useSelector(
      (state) => state.forcePlateDashboard.data.templates,
    );
    handleDeleteTemplate = deleteFPTemplate;
  }
  if (dashboard === 'Sport Coach') {
    deleteTemplate = useSelector(
      (state) => state.sportCoachDashboard.ui.deleteDashboardModal,
    );
    isModalShowing = !!Object.keys(deleteTemplate).length;
    setDeleteModalShowing = setDeleteDashboardModal;
    templates = useSelector(
      (state) => state.sportCoachDashboard.data.templates,
    );
    handleDeleteTemplate = deleteDashboard;
  }

  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (Object.keys(deleteTemplate).length) {
      setIsSubmitting(false);
    }
  }, [deleteTemplate]);

  const handleClose = () => {
    dispatch(setDeleteModalShowing(false));
  };

  const handleClickDelete = () => {
    setIsSubmitting(true);
    const templateIndex = templates.findIndex(
      (template) => template.templateId === deleteTemplate.templateId,
    );
    batch(() => {
      dispatch(
        handleDeleteTemplate(
          currentUser.accountCode,
          deleteTemplate.templateId,
          templateIndex,
        ),
      );
      dispatch(setDeleteModalShowing(false));
    });
    // KPI tracking
    dashboardTracker(
      `${dashboard} Dashboard`,
      'Delete Saved Dashboard',
      'Dashboard deleted',
    );
  };

  return (
    <>
      <ModalContainer isActive={!!Object.keys(deleteTemplate).length}>
        <div
          className='remove-div'
          onClick={handleClose}
        >
          <div className='remove-div-icon'>
            <IcomoonReact
              iconSet={iconSet}
              size={15}
              icon='remove'
              color='black'
            />
          </div>
        </div>
        <ContentContainer>
          <TopBox>
            <IconContainer>
              <IcomoonReact
                iconSet={iconSet}
                size={40}
                icon='trashcan'
                color='black'
              />
            </IconContainer>
          </TopBox>
          <TopBoxText>Delete Dashboard</TopBoxText>
          <MiddleBoxText>
            Are you sure you want to delete this dashboard? Any coaches who have
            had access to this dashboard will no longer be able to view it.
          </MiddleBoxText>
        </ContentContainer>
        <Button
          bottom
          fullWidth
          cta='Delete'
          className='opt-out-button'
          large
          square
          noBorder
          primary
          onClick={handleClickDelete}
          disabled={isSubmitting}
        />
      </ModalContainer>
      <SharedTemplateModalContainer
        isModalShowing={isModalShowing}
        onClose={handleClose}
      />
    </>
  );
};

SharedTemplateDeleteModal.defaultProps = {
  dashboard: 'Wearables',
};

SharedTemplateDeleteModal.propTypes = {
  dashboard: PropTypes.string,
};

export default SharedTemplateDeleteModal;
