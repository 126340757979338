/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect, useRef } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import styled from '@emotion/styled';
import { useSelector, useDispatch } from 'react-redux';
import ResizeObserver from 'react-resize-observer';

import WearablesDashboard from '../wearables-dashboard/WearablesDashboard';
import LoadMonitoringDashboard from '../load-monitoring-dashboard/LoadMonitoringDashboard';
import ForcePlateDashboard from '../force-plate-dashboard/ForcePlateDashboard';
import SportCoachDashboard from '../sport-coach-dashboard/SportCoachDashboard';
import HabitTracking from '../habit-tracking/HabitTracking';
import HabitTrackingDashboard from '../habit-tracking-dashboard/HabitTrackingDashboard';
import { setHabitTrackingPageWrapperScrollTriggered } from '../habit-tracking/ducks/habitTrackingActions';

import Layout from '../../shared/components/Layout/Layout';
import InstantPaymentModalContainer from './components/presentational/InstantPaymentModalContainer';
import BillingDashboardWrapper from './components/presentational/BillingDashboardWrapper';
import SideBar from './components/presentational/SideBar';
import UpgradeModalContainer from './components/presentational/UpgradeModalContainer';
import MarketingModalContainer from './components/presentational/MarketingModalContainer';
import BookDemoModalContainer from './components/presentational/BookDemoModalContainer';

import { BoxShadow } from '../../shared/GlobalStyles';
import DeleteDashboardModalContainer from '../sport-coach-dashboard/components/presentational/DeleteDashboardModalContainer';
import AboutDashboardModalContainer from '../sport-coach-dashboard/components/presentational/AboutDashboardModalContainer';
import EditDashboardModalContainer from '../sport-coach-dashboard/components/presentational/EditDashboardModalContainer';
import ShareDashboardModalContainer from '../sport-coach-dashboard/components/presentational/ShareDashboardModalContainer';
import SaveDashboardModalContainer from '../sport-coach-dashboard/components/presentational/SaveDashboardModalContainer';
import TriOptionModalContainer from '../sport-coach-dashboard/components/presentational/TriOptionModalContainer';
import UnsavedDashModalContainer from '../sport-coach-dashboard/components/presentational/UnsavedDashModalContainer';
import DuplicateDashboardModalContainer from '../sport-coach-dashboard/components/presentational/DuplicateDashboardModalContainer';
import SCLegendInfoModal from '../sport-coach-dashboard/components/presentational/SCLegendInfoModal';
import SharedTemplateUnsavedModal from './components/presentational/sharedTemplateComponents/SharedTemplateUnsavedModal';
import { fetchRegionStatusToggle } from '../recovery-radar/ducks/RecoveryRadarActions';
import RecoveryRadar from '../recovery-radar/RecoveryRadar';
import { SportsScienceModal, SportsScienceErrorModal } from './components/presentational/SportsScienceModal';
import { setSideBarOpen } from './ducks/sportsScienceActions';

const FlexContainer = styled('div')`
  display: flex;
  height: 100%;
`;

const PageWrapper = styled('div')`
  display: flex;
  height: calc(100% - 49px);
  flex: 1;
  margin-top: 49px;
  transition: all 0.5s ease;
  @media only screen and (max-width : 768px) {
    height: calc(100%);
  }
  background: #F5F5F5;
  overflow-y: scroll;
  @media screen and (max-width: 768px) {
    width: ${(props) => (!props.isSideBarActive ? '100%' : '0%')} ;
  }
`;

const HabitTrackingPageWrapper = styled('div')`
display: flex;
flex: 1;
margin-top: 49px;
transition: all 0.5s ease;
background: #F5F5F5;
overflow-x: hidden;
overflow-y: auto;
@media screen and (max-width: 768px) {
  width: ${(props) => (!props.isSideBarActive ? '100%' : '0%')} ;
}
`;

const ForcePlateWrapper = styled('div')`
  display: flex;
  flex: 1;
  margin-top: 49px;
  transition: all 0.5s ease;
  background: #F5F5F5;
  overflow-x: hidden;
  overflow-y: auto;
  @media screen and (max-width: 768px) {
    width: ${(props) => (!props.isSideBarActive ? '100%' : '0%')} ;
  }

  @media print {
    @page {
      size: landscape;
      margin: 15mm;
    }
  }
`;

const SCDashboardWrapper = styled('div')`
  display: flex;
  flex: 1;
  margin-top: 49px;
  transition: all 0.5s ease;
  background: #F5F5F5;
  overflow-x: hidden;
  overflow-y: auto;
  @media screen and (max-width: 768px) {
    width: ${(props) => (!props.isSideBarActive ? '100%' : '0%')} ;
  }

  @media print {
    @page {
      size: landscape;
      margin: 15mm;
    }
  }
`;

const SideBarReturnButton = styled('div')`
  width: 140px;
  height: 35px;
  background: white;
  position: absolute;
  bottom: 20px;
  display: none;
  border-radius: 50px;
  @media screen and (max-width: 714px) {
    display: flex;
  }
  cursor: pointer;
  box-shadow: ${BoxShadow};
  font-size: 10px;
  font-family: 'Nunito Sans';
  align-items: center;
  justify-content: center;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
`;

const ModuleText = styled('div')`
  color: #FF8533;
  font-weight: bold;
`;

const SportsScience = ({ match }) => {
  const accountCode = useSelector((state) => state.auth.data.currentUser.accountCode);
  const habitTrackingPageWrapperRef = useRef(null);
  const habitTrackingPageWrapperScrollTriggered = useSelector((state) => state.habitTracking.data.habitTrackingPageWrapperScrollTriggered);
  const isLegendInfoModalShowing = useSelector(
    (state) => state.sportCoachDashboard.ui.isLegendInfoModalShowing,
  );
  const isSideBarOpen = useSelector((state) => state.sportsScience.data.isSideBarOpen);
  const recentlyCreatedHabit = useSelector((state) => state.habitTracking.data.recentlyCreatedHabit);
  const unsavedLMDashModalShowing = useSelector((state) => state.loadMonitoringDashboard.ui.unsavedModalShowing);
  const unsavedFPDashModalShowing = useSelector((state) => state.forcePlateDashboard.ui.unsavedModalShowing);
  const [isSideBarActive, setIsSideBarActive] = useState(true);

  const dispatch = useDispatch();

  /** track if side bar is open in global state */
  useEffect(() => {
    dispatch(setSideBarOpen(isSideBarActive));
  }, [isSideBarActive]);

  /** track if new habit was added and scroll to bottom of page */
  useEffect(() => {
    if (recentlyCreatedHabit) {
      habitTrackingPageWrapperRef.current?.scrollTo({
        top: habitTrackingPageWrapperRef.current?.scrollHeight,
        behavior: 'smooth',
      });
    }
  }, [recentlyCreatedHabit]);

  /** track when scroll is triggered */
  const onScroll = (event) => {
    if (event.target === event.currentTarget && !habitTrackingPageWrapperScrollTriggered) {
      dispatch(setHabitTrackingPageWrapperScrollTriggered(true));
    }
  };

  // Fetch the state of the region/status toggle for recovery radar
  useEffect(() => {
    if (accountCode) {
      dispatch(fetchRegionStatusToggle(accountCode));
    }
  }, [accountCode]);

  return (
    <Layout fullWidth>
      <ResizeObserver
        onResize={(rect) => {
          if (!isSideBarOpen && rect?.width > 714) {
            /** track if side bar is open in global state */
            dispatch(setSideBarOpen(true));
          }
          if (isSideBarOpen && rect?.width <= 714) {
            dispatch(setSideBarOpen(false));
          }
        }}
      />
      <div className='overflow-scroll-boi'>
        <InstantPaymentModalContainer />
        <UpgradeModalContainer />
        <MarketingModalContainer />
        <BookDemoModalContainer />
        <DeleteDashboardModalContainer />
        <AboutDashboardModalContainer />
        <EditDashboardModalContainer />
        <ShareDashboardModalContainer />
        <SaveDashboardModalContainer />
        <TriOptionModalContainer />
        <UnsavedDashModalContainer />
        <DuplicateDashboardModalContainer />
        {unsavedLMDashModalShowing && <SharedTemplateUnsavedModal dashboard='Load Monitoring' />}
        {unsavedFPDashModalShowing && <SharedTemplateUnsavedModal dashboard='Force Plate' />}
        {isLegendInfoModalShowing && (
        <SCLegendInfoModal />
        )}
      </div>
      <Helmet>
        <title>Sport Science Hub | TeamBuildr</title>
      </Helmet>
      <FlexContainer>
        <SideBar isSideBarActive={isSideBarActive} setIsSideBarActive={setIsSideBarActive} />
        <Switch>
          <Redirect exact from={match.path} to={`${match.path}/wearables-dashboard`} />
          <Route path={`${match.path}/wearables-dashboard`}>
            <PageWrapper isSideBarActive={isSideBarActive}>
              <WearablesDashboard />
            </PageWrapper>
          </Route>
          <Route path={`${match.path}/load-monitoring-dashboard`}>
            <SCDashboardWrapper isSideBarActive={isSideBarActive}>
              <LoadMonitoringDashboard />
            </SCDashboardWrapper>
          </Route>
          <Route path={`${match.path}/sport-coach-dashboard`}>
            <SCDashboardWrapper isSideBarActive={isSideBarActive}>
              <SportCoachDashboard />
            </SCDashboardWrapper>
          </Route>
          <Route path={`${match.path}/habit-tracking`}>
            <HabitTrackingPageWrapper onScroll={onScroll} ref={habitTrackingPageWrapperRef}>
              <HabitTracking isSideBarActive={isSideBarActive} setIsSideBarActive={setIsSideBarActive} scrollTriggered={habitTrackingPageWrapperScrollTriggered} />
            </HabitTrackingPageWrapper>
          </Route>
          <Route path={`${match.path}/force-plate-dashboard`}>
            <ForcePlateWrapper isSideBarActive={isSideBarActive}>
              <ForcePlateDashboard />
            </ForcePlateWrapper>
          </Route>
          <Route path={`${match.path}/recovery-radar`}>
            <ForcePlateWrapper isSideBarActive={isSideBarActive}>
              <RecoveryRadar />
            </ForcePlateWrapper>
          </Route>
          <Route path={`${match.path}/consistency-coach`}>
            <ForcePlateWrapper isSideBarActive={isSideBarActive}>
              <HabitTrackingDashboard />
            </ForcePlateWrapper>
          </Route>
          <Route path={`${match.path}/billing`}>
            <BillingDashboardWrapper />
          </Route>
        </Switch>
        {!isSideBarActive ? (
          <SideBarReturnButton onClick={() => setIsSideBarActive(true)}>
            <ModuleText>RETURN TO SIDEBAR</ModuleText>
          </SideBarReturnButton>
        ) : null}
        <SportsScienceModal />
        <SportsScienceErrorModal />
      </FlexContainer>
    </Layout>
  );
};

SportsScience.propTypes = { match: PropTypes.instanceOf(Object).isRequired };

export default SportsScience;
