/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { batch, useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';
import Select from 'react-select';
import Checkbox from '@material-ui/core/Checkbox';
import { useTheme } from 'emotion-theming';

import { metricOptions } from './metricConstants';
import iconSet from '../../shared/images/teambuildr-selection.json';
import Button from '../../shared/components/Button/Button';
import { dashboardTracker } from '../../shared/utils/amplitudeHelper';
import {
  setForcePlateView,
  setIsDashboardDisplayed,
  setIsNewFPDash,
  setKeyPerformanceMetrics,
  setMetricFiltersGroup,
  setMetricFiltersPerformance,
} from './ducks/forcePlateDashboardActions';

const NewContainer = styled('div')`
  width: 100%;
  padding: 20px;
  display: flex;
  height: auto;
`;

const BackgroundContainer = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  background: white;
  border-radius: 8px;
  display: flex;
  justify-content: center;
`;

const UpperContainer = styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 200px;
  border-bottom: 2px solid #eeeeee;
`;

const PlusIconContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 75px;
  height: 75px;
  border: 1px solid black;
`;

const Header = styled('div')`
  font-size: 23px;
  font-family: "Nunito Sans";
  color: black;
  margin-top: 10px;
`;

const SubHeader = styled('div')`
  font-size: 16px;
  font-family: "Nunito Sans";
  color: ${(props) => props.color};
`;

const SubHeaderContainer = styled('div')`
  font-size: 16px;
  font-family: "Nunito Sans";
  margin-top: 10px;
  display: flex;
  width: 100%;
  .item-select {
    width: 100%;
  }
`;

const CenterContainer = styled('div')`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 85%;
  height: 100%;
`;

const SelectBoxContainer = styled('div')`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin-top: 35px;
`;

const SelectBoxText = styled('div')`
  display: flex;
  color: ${(props) => props.color};
  margin-bottom: 5px;
  font-size: 16px;
`;

const LowerBox = styled('div')`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 500px;
  margin-top: 20px;
`;

const RightBox = styled('div')`
  display: flex;
  width: 48%;
  height: 100%;
  flex-direction: column;
`;

const LeftBox = styled('div')`
  display: flex;
  width: 48%;
  height: 100%;
  flex-direction: column;
`;

const ButtonContainer = styled('div')`
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  .load-button {
    background-color: #444444;
    border-radius: 4px;
  }
  width: 100%;
`;

const BoxTitle = styled('div')`
  display: flex;
  height: 25px;
  width: 100%;
  font-size: 16px;
  font-family: "Nunito Sans";
`;

const SearchAndIcon = styled('div')`
  display: flex;
  align-items: center;
  border: 1px solid #eeeeee;
  border-radius: 50px;
  background: white;
  transition: max-width 0.5s ease;
  overflow: hidden;
  width: 100%;
`;

const SearchIconContainer = styled('div')`
  padding-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 5px;
`;

const SearchContainer = styled('input')`
  display: flex;
  height: 40px;
  border: none;
  background: none;
  padding-left: 5px;
  width: 100%;
  cursor: ${(props) => (props.disabled ? 'default' : 'text')};
`;

const SearchAndFilter = styled('div')`
  display: flex;
  width: 100%;
  margin-top: 10px;
`;

const MetricsBox = styled('div')`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: lightgrey transparent;
  background: #fcfcfc;
  padding: 15px;
  margin-top: 20px;
  border: 1px solid #eeeeee;
  border-radius: 4px;
  height: 100%;
`;

const AvailableMetric = styled('div')`
  display: flex;
  min-height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${(props) => props.color};
  padding-left: 15px;
`;

const RemoveContainer = styled('div')`
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
`;

const NewFPDashForm = ({
  selectedChartMetrics,
  setSelectedChartMetrics,
  selectKeyMetricOptions,
  keyMetricSelection,
  setKeyMetricSelection,
  selectedKeyMetrics,
  setSelectedKeyMetrics,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [searchedList, setSearchedList] = useState([]);
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [chartMetricOptionsDisabled, setChartMetricOptionsDisabled] = useState(
    false,
  );
  const [areChecksDisabled, setAreChecksDisabled] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const searchedArray = [];
    metricOptions.forEach((metric) => {
      if (metric.toLowerCase().includes(searchTerm.toLowerCase())) {
        searchedArray.push(metric);
      }
    });
    if (searchedArray.length) {
      setSearchedList(searchedArray);
    } else {
      setSearchedList([]);
    }
  }, [searchTerm]);

  /**
   * Every time the list of selected metrics changes, the
   * component makes a new determination of whether or not
   * additional metrics can be selected. We
   * want a maximum of 2 metrics for the performance overview chart.
   */
  useEffect(() => {
    if (selectedChartMetrics.length >= 2) {
      setChartMetricOptionsDisabled(true);
    } else if (selectedChartMetrics.length < 2) {
      setChartMetricOptionsDisabled(false);
    }
  }, [selectedChartMetrics]);

  const emptyFunction = () => undefined;

  // Note: Function does not operate properly without option parameter
  const isOptionDisabled = (option) => !!chartMetricOptionsDisabled;

  const isDefaultKPIs = keyMetricSelection.label === 'Default';

  /** Similar to chart metrics, dictate
   * whether or not new metrics can be added */
  useEffect(() => {
    if (selectedKeyMetrics.length >= 5) {
      setAreChecksDisabled(true);
    } else if (selectedKeyMetrics.length < 5) {
      setAreChecksDisabled(false);
    }
  }, [selectedKeyMetrics]);

  // Renders selected metrics and allows removal in order to allow new metric selection(s)
  const metricOptionsResolver = (metric) => (
    <AvailableMetric color={theme.colors.text}>
      <div>{metric}</div>
      <RemoveContainer
        disabled={isDefaultKPIs}
        onClick={
          isDefaultKPIs
            ? emptyFunction
            : () => {
              const newSelectedMetrics = [...selectedKeyMetrics];
              const index = newSelectedMetrics.indexOf(metric);
              if (index > -1) {
                newSelectedMetrics.splice(index, 1);
              }
              setSelectedKeyMetrics(newSelectedMetrics);
            }
        }
      >
        <IcomoonReact
          iconSet={iconSet}
          size={15}
          icon='remove'
          color='#9E9E9E'
        />
      </RemoveContainer>
    </AvailableMetric>
  );

  const submitDisabled = !selectedChartMetrics.length
    || (keyMetricSelection.label === 'Custom' && !selectedKeyMetrics.length);

  const submitMetrics = (chartMetricsArray, keyMetricsArray) => {
    const formattedChartMetrics = chartMetricsArray.map((metric) => metric.replace(/\s/g, ''));
    const formattedKeyMetrics = keyMetricsArray.map((metric) => metric.replace(/\s/g, ''));
    batch(() => {
      dispatch(setMetricFiltersPerformance(formattedChartMetrics));
      dispatch(setMetricFiltersGroup(formattedKeyMetrics));
      dispatch(setKeyPerformanceMetrics(keyMetricsArray));
      dispatch(setIsNewFPDash(false));
      dispatch(setIsDashboardDisplayed(true));
      dispatch(setForcePlateView('performance'));
    });
  };

  const handleClickCheckbox = (metric) => {
    const newSelectedMetrics = [...selectedKeyMetrics];
    if (!newSelectedMetrics.includes(metric)) {
      newSelectedMetrics.push(metric);
    } else {
      const index = newSelectedMetrics.indexOf(metric);
      if (index > -1) {
        newSelectedMetrics.splice(index, 1);
      }
    }
    setSelectedKeyMetrics(newSelectedMetrics);
  };

  return (
    <NewContainer>
      <BackgroundContainer>
        <CenterContainer>
          <UpperContainer>
            <PlusIconContainer>
              <IcomoonReact
                iconSet={iconSet}
                size={25}
                icon='plus'
                color='black'
              />
            </PlusIconContainer>
            <Header>New Dashboard</Header>
          </UpperContainer>
          <SelectBoxContainer>
            <SelectBoxText color={theme.colors.text}>
              Select up to 2 metrics to populate Performance Overview chart upon
              loading of dashboard.
            </SelectBoxText>
            <Select
              /**
               * react-select memoizes options; need to force re-render
               * when chartMetricOptionsDisabled changes in order for this
               * to reflect disabled status properly.
               * defaultValue ensures prior selections maintained and displayed upon re-render
               */
              key={chartMetricOptionsDisabled ? 'disabled' : 'enabled'}
              defaultValue={[...selectedChartMetrics]}
              className='item-select'
              options={metricOptions}
              isMulti
              menuPortalTarget={document.body}
              styles={{
                menuPortal: (base) => ({
                  ...base,
                  zIndex: 9999,
                }),
              }}
              getOptionLabel={(option) => option}
              getOptionValue={(option) => option}
              isOptionDisabled={isOptionDisabled}
              onChange={(selectedMetrics) => {
                setSelectedChartMetrics(selectedMetrics);
              }}
            />
          </SelectBoxContainer>
          <SelectBoxContainer>
            <SelectBoxText color={theme.colors.text}>
              Select up to 5 metrics to populate KPIs and Group Analysis upon
              loading of dashboard.
            </SelectBoxText>
            <Select
              className='item-select'
              options={selectKeyMetricOptions}
              defaultValue={selectKeyMetricOptions[0]}
              menuPortalTarget={document.body}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.id}
              onChange={(option) => {
                if (option.id === 1) {
                  setSelectedKeyMetrics([
                    'Jump Height',
                    'Takeoff Velocity',
                    'Peak Power',
                    'L|R Concentric Impulse',
                    'mRSI',
                  ]);
                  setSearchTerm('');
                }
                setKeyMetricSelection(option);
              }}
            />
          </SelectBoxContainer>
          <SubHeaderContainer>
            <SubHeader color={theme.colors.darkGray}>
              {isDefaultKPIs
                ? 'Default metrics include Jump Height, Takeoff Velocity, Peak Power, L|R Concentric Impulse, and mRSI. Switch to Custom to modify selections.'
                : 'Select up to 5 custom metrics.'}
            </SubHeader>
          </SubHeaderContainer>
          <LowerBox>
            <LeftBox>
              <BoxTitle>All Metrics</BoxTitle>
              <SearchAndFilter>
                <SearchAndIcon
                  id='search-container'
                  onClick={
                    isDefaultKPIs
                      ? emptyFunction
                      : () => setIsSearchFocused(true)
                  }
                  isFocused={isSearchFocused}
                >
                  <SearchIconContainer id='search-icon-container'>
                    <IcomoonReact
                      iconSet={iconSet}
                      size={20}
                      icon='magnifying-glass-alt'
                      color='#9E9E9E'
                    />
                  </SearchIconContainer>
                  <SearchContainer
                    disabled={isDefaultKPIs}
                    placeholder='Search'
                    id='search-container-input'
                    value={searchTerm}
                    onFocus={
                      isDefaultKPIs
                        ? emptyFunction
                        : () => setIsSearchFocused(true)
                    }
                    onChange={(e) => {
                      setSearchTerm(e.target.value);
                    }}
                  />
                </SearchAndIcon>
              </SearchAndFilter>
              <MetricsBox>
                {!searchTerm.length
                  ? metricOptions.map((metric) => (
                    <AvailableMetric color={theme.colors.text}>
                      <div>{metric}</div>
                      <div>
                        <Checkbox
                          disabled={
                              isDefaultKPIs
                              || (areChecksDisabled
                                && !selectedKeyMetrics.includes(metric))
                            }
                          key={metric}
                          checked={!!selectedKeyMetrics.includes(metric)}
                          onChange={() => handleClickCheckbox(metric)}
                          color='primary'
                        />
                      </div>
                    </AvailableMetric>
                  ))
                  : searchedList.map((metric) => (
                    <AvailableMetric color={theme.colors.text}>
                      <div>{metric}</div>
                      <div>
                        <Checkbox
                          disabled={
                              isDefaultKPIs
                              || (areChecksDisabled
                                && !selectedKeyMetrics.includes(metric))
                            }
                          checked={selectedKeyMetrics.includes(metric)}
                          onChange={() => handleClickCheckbox(metric)}
                          color='primary'
                        />
                      </div>
                    </AvailableMetric>
                  ))}
              </MetricsBox>
            </LeftBox>
            <RightBox>
              <BoxTitle>Selected Metrics</BoxTitle>
              <MetricsBox>
                {selectedKeyMetrics.map((metric) => metricOptionsResolver(metric))}
              </MetricsBox>
            </RightBox>
          </LowerBox>
          <ButtonContainer>
            <Button
              bottom
              fullWidth
              cta='Load Dashboard'
              large
              customTextColor='#FFFFFF'
              square
              className='load-button'
              disabled={submitDisabled}
              noBorder
              primary
              onClick={() => {
                submitMetrics(selectedChartMetrics, selectedKeyMetrics);
                // KPI tracking
                dashboardTracker(
                  'Force Plate Dashboard',
                  'New Dashboard',
                  'Load dashboard clicked',
                );
              }}
            />
          </ButtonContainer>
        </CenterContainer>
      </BackgroundContainer>
    </NewContainer>
  );
};

export default NewFPDashForm;
