import React from 'react';
import styled from '@emotion/styled';
import { useTheme } from 'emotion-theming';
import { Formik, Form, Field } from 'formik';
import IcomoonReact from 'icomoon-react';
import * as Yup from 'yup';

import { useSelector } from 'react-redux';
import NoHoverButton from './NoHoverButton';
import HeaderText from '../../../shared/components/HeaderText/HeaderText';
import Text from '../../../shared/components/Text/Text';
import useConversations from '../components/hooks/useConversations';
import iconSet from '../../../shared/images/teambuildr-selection.json';

const ModalWrapper = styled('div')`
  display: flex;
  width: 100%;
  .modal-button {
    align-items: center;
  }
  .support-title {
    font-size: 24px;
    margin-bottom: 10px;
  }
  .knowledg-basetext {
    margin-bottom: 15px;
    margin-top: 15px;
  }
`;

const FieldError = styled('div')`
  font-size: 14px;
  color: #ff6600;
`;

const TitlePlusErrorWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const NavigationWrapperFolder = styled('div')`
  position: absolute;
  display: flex;
  justify-content: flex-end;
  height: 20px;
  width: 100%;
  z-index: 1;
  margin-left: -60px;
  margin-top: -25px;
  .back-arrow-div {
    cursor: pointer;
  }
`;

const FormGroup = styled('div')`
  label {
    width: 100%;
  }
  button {
    margin-bottom: 20px;
  }
  .easy-join {
    margin-bottom: 15px;
  }
  .subject-text {
    margin-bottom: 5px;
    font-weight: bold;
  }
  .formgroup-text {
    margin-bottom: 5px;
    margin-top: 10px;
  }
  .caveat-text {
    font-size: 11px;
    margin-top: 5px;
  }

  z-index: 1;
  @media screen and (max-width: 600px) {
    a {
      font-size: 11px;
    }
  }
  .form-control {
    min-height: 30px;
    width: 100%;
  }
`;

export const ModalContentContainer = styled('div')`
`;

const SupportTextWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -10px;
  height: 130px;

  .support-icon-text {
    min-width: 200px;
    display: flex;
    justify-content: center;
    font-size: 26px;
    font-weight: 300;
    margin-top: 10px;
  }
`;

const supportSchema = Yup.object().shape({
  body: Yup.string()
    .required('Message is required'),
});

const ModalSupportContent = ({
  onRequestClose,
}) => {
  const { handleSupportSubmission } = useConversations();
  const theme = useTheme();
  const reportMessage = useSelector((state) => state.conversations.data.reportMessage);
  const isSupportSubmitting = useSelector((state) => state.conversations.ui.isSupportSubmitting);

  const handleSubmit = (values) => {
    handleSupportSubmission(values);
  };

  return (
    <ModalWrapper>
      <NavigationWrapperFolder>
        <Text
          className='back-arrow-div'
          onClick={() => {
            onRequestClose();
          }}
        >
          <IcomoonReact
            iconSet={iconSet}
            size={10}
            icon='remove'
          />
        </Text>
      </NavigationWrapperFolder>
      <ModalContentContainer>
        <Formik
          validationSchema={supportSchema}
          initialValues={{
            subject: `Reporting Offensive Message (Message ID ${reportMessage})`,
            body: '',
            page: '/messaging',
          }}
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
              handleSubmit(values);
              setSubmitting(false);
            });
          }}
          render={({
            errors,
            touched,
            handleSubmit,
            handleChange,
            values,
          }) => (
            <Form>
              <SupportTextWrapper>
                <IcomoonReact
                  iconSet={iconSet}
                  size={60}
                  icon='question-mark-circle'
                />
                <Text className='support-icon-text'>Support</Text>
              </SupportTextWrapper>
              <div className='divider-line' />
              <Text
                fontWeight='600'
                fontSize={theme.textFontSizes[2]}
                style={{ marginBottom: '16px'}}
              >
                Please report any offensive content using the form below.
              </Text>
              <FormGroup className='form-group'>
                <TitlePlusErrorWrapper>
                  <Text className='subject-text'>Subject</Text>
                  {errors.subject && touched.subject ? <FieldError className='error-text'>{errors.subject}</FieldError> : null}
                </TitlePlusErrorWrapper>
                <Field
                  className='form-control'
                  id='subject-field'
                  name='subject'
                  value={`Reporting Offensive Message (Message ID ${reportMessage})`}
                  disabled
                />
              </FormGroup>
              <FormGroup className='form-group'>
                <TitlePlusErrorWrapper>
                  <Text className='subject-text'>Message</Text>
                  {errors.body && touched.body ? <FieldError className='error-text'>{errors.body}</FieldError> : null}
                </TitlePlusErrorWrapper>
                <Field
                  onChange={handleChange}
                  name='body'
                  component='textarea'
                  className='form-control'
                  placeholder='Describe your problem or suggestion here...'
                  value={values.body}
                />
              </FormGroup>
              <NoHoverButton
                bottom
                fullWidth
                cta={!isSupportSubmitting ? 'Submit' : 'Submitting...'}
                customColor='#10cd8c'
                className='modal-button'
                large
                noBorder
                primary
                square
                disabled={isSupportSubmitting}
                onClick={handleSubmit}
              />
            </Form>
          )}
        />
      </ModalContentContainer>
    </ModalWrapper>
  );
};

export default ModalSupportContent;
