/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import Helmet from 'react-helmet';
import { batch, useDispatch, useSelector } from 'react-redux';

import HeaderText from '../../shared/components/HeaderText/HeaderText';
import Settings from './components/Settings';
import {
  fetchGroups,
  fetchViewedFeatures,
  setOnboardingModalOpen,
  setRecoveryRadarView,
  setViewedFeature,
} from './ducks/RecoveryRadarActions';
import { Nunito, TransitionGlobal } from '../../shared/GlobalStyles';
import Overview from './components/Overview';
import Analysis from './components/Analysis';
import History from './components/History';
import RecoveryRadarModal from './RecoveryRadarModal';
import { dashboardTracker } from '../../shared/utils/amplitudeHelper';
import OnboardingModal from '../../shared/_TBComponents/OnboardingModal';
import { createViewedFeaturePayload, FEATURES } from '../../shared/utils/onboardingStatus';
// Temporary onboarding images
import onboardingImageOne from '../../shared/images/rr-onboarding-one.png';
import onboardingImageTwo from '../../shared/images/rr-onboarding-two.png';
import onboardingImageThree from '../../shared/images/rr-onboarding-three.png';
import onboardingImageFour from '../../shared/images/rr-onboarding-four.png';
import onboardingImageFive from '../../shared/images/rr-onboarding-five.png';
import Icon from '../../shared/_TBComponents/Icon';

const Container = styled('div')`
  width: 100%;
`;

const PageWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: 1450px;
`;

const DashboardHeader = styled('div')`
  display: flex;
  width: 100%;
  background-color: #ffffff;
  padding-top: 30px;
  padding-left: 20px;
  justify-content: space-between;

  h1 {
    padding-bottom: 8px;
  }
`;

const MenuContainer = styled('div')`
  display: flex;
`;

const MenuItem = styled('button')`
  border: none;
  margin-left: 10px;
  margin-right: 12px;
  font-family: ${Nunito};
  font-weight: 400;
  font-size: 16px;
  border-bottom: ${(props) => (props.isActive ? '3px solid #1787FB!important' : '3px solid white')};
  color: ${(props) => (!props.isActive ? '#9E9E9E' : '#444444')};
`;

const InfoWrapper = styled('div')`
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 20px;
  border: 2px solid #9E9E9E;
  margin-left: 10px;
  margin-bottom: 8px;
  cursor: ${(props) => (props.disabled ? 'cursor' : 'pointer')};
    :hover {
      opacity: ${(props) => (props.disabled ? '1' : '.7')};
    }
  transition: ${TransitionGlobal};
  @media only screen and (max-width: 601px) {
    display: none;
  }
`;

const HeaderContainer = styled('div')`
  display: flex;
  align-items: center;
`;

const RecoveryRadar = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const isOnboardingModalShowing = useSelector((state) => state.recoveryRadar.ui.isOnboardingModalShowing);
  const recoveryRadarView = useSelector((state) => state.recoveryRadar.ui.recoveryRadarView);

  // UPDATE CONTENT & IMAGES
  const content = [
    {
      image: onboardingImageOne,
      title: 'Accessing Recovery Radar',
      description: 'Welcome to Recovery Radar, where athletes can log any pain or soreness they experience, enabling coaches to monitor needed adjustments. To get started, athletes log data on the TeamBuildr mobile app by navigating to Recovery Radar in the navigation drawer or by selecting “More” in the Workout Entry screen.',
      gradientType: 'primary',
    },
    {
      image: onboardingImageTwo,
      title: 'Reporting and Tracking Pain and Soreness',
      description: 'To log pain or soreness, athletes open Recovery Radar on the mobile app. On the body heat map, athletes tap a specific area to report, selecting either Pain or Soreness and choosing a severity level (1 to 7). Optional fields like Region and Status allow athletes to add context for coaches. Each entry saves automatically in the Areas Selected section, building a structured record that coaches can view and analyze.',
      gradientType: 'primary',

    },
    {
      image: onboardingImageThree,
      title: 'Overview of Recovery Data',
      description: 'In the Overview tab, coaches can view a high-level summary of pain and soreness data at a group and individual level. To begin, select a group from the dropdown menu in the top left corner. Use the date selector in the top right to choose a 7-day or 28-day range, showing trends for each body part. A line graph tracks changes over time, while a body map highlights frequently reported areas. Below, quick stat cards display Total Reported Items, Average Severity, and the number of Athletes to Review.',
      gradientType: 'primary',

    },
    {
      image: onboardingImageFour,
      title: 'Analyzing Athlete Recovery',
      description: 'To review individual reports, open the Group Analysis tab and select a group from the dropdown menu. The table shows each athlete’s reported body part, severity, 28-day average, and any notes. For a detailed view, click on an athlete’s name to open the Athlete History tab, which displays their body heat map and a list of reported issues over time.',
      gradientType: 'primary',
    },
    {
      image: onboardingImageFive,
      title: 'Athlete Alerts',
      description: 'Athlete Alerts help coaches quickly identify athletes with significant increases in average severity, allowing them to prioritize athletes needing immediate attention. Click "Review" in the red alert banner to filter these athletes into the Group Analysis table for closer monitoring.',
      gradientType: 'primary',
    },
  ];

  useEffect(() => {
    if (currentUser) {
      dispatch(fetchGroups(currentUser.accountCode));
    }
  }, [currentUser]);

  // Check if the user has viewed the dashboard before to display onboarding modal or not
  useEffect(() => {
    dispatch(fetchViewedFeatures());
  }, []);

  const handleMenuClick = (view) => {
    if (view !== recoveryRadarView) {
      dispatch(setRecoveryRadarView(view));
      // Amplitude tracking
      let title;
      switch (view) {
        case 'analysis':
          title = 'Group Analysis';
          break;
        case 'history':
          title = 'Athlete History';
          break;
        case 'settings':
          title = 'Settings';
          break;
        default:
          title = 'Overview';
      }
      dashboardTracker('Recovery Radar', title, 'Menu item clicked');
    }
  };

  const handleClose = () => {
    const viewedFeaturePayload = createViewedFeaturePayload(currentUser.accountCode, currentUser.id, FEATURES.RECOVERY_RADAR);
    batch(() => {
      dispatch(setOnboardingModalOpen(false));
      dispatch(setViewedFeature(viewedFeaturePayload));
    });
  };

  const handleOpenOnboarding = () => {
    dispatch(setOnboardingModalOpen(true));
  };

  return (
    <Container>
      <Helmet>
        <title>Recovery Radar | TeamBuildr</title>
      </Helmet>
      <PageWrapper>
        <DashboardHeader>
          <HeaderContainer>
            <HeaderText
              fontWeight='300'
              letterSpacing='normal'
            >
              Recovery Radar
            </HeaderText>
            <InfoWrapper onClick={handleOpenOnboarding}>
              <Icon
                icon='info'
                size='12px'
                styledProps={{
                  display: 'flex',
                }}
              />
            </InfoWrapper>
          </HeaderContainer>
          <MenuContainer>
            <MenuItem
              isActive={recoveryRadarView === 'overview'}
              onClick={() => handleMenuClick('overview')}
            >
              Overview
            </MenuItem>
            <MenuItem
              isActive={recoveryRadarView === 'analysis'}
              onClick={() => handleMenuClick('analysis')}
            >
              Group Analysis
            </MenuItem>
            <MenuItem
              isActive={recoveryRadarView === 'history'}
              onClick={() => handleMenuClick('history')}
            >
              Athlete History
            </MenuItem>
            <MenuItem
              isActive={recoveryRadarView === 'settings'}
              onClick={() => handleMenuClick('settings')}
            >
              Settings
            </MenuItem>
          </MenuContainer>
        </DashboardHeader>
        {recoveryRadarView === 'overview' && (
          <Overview />
        )}
        {recoveryRadarView === 'analysis' && (
          <Analysis />
        )}
        {recoveryRadarView === 'history' && (
          <History />
        )}
        {recoveryRadarView === 'settings' && (
          <Settings />
        )}
      </PageWrapper>
      <RecoveryRadarModal />
      <OnboardingModal
        badgeText='GET STARTED'
        content={content}
        dashName='Recovery Radar'
        isActive={isOnboardingModalShowing}
        maxHeight={null}
        minHeight={null}
        onClose={handleClose}
        onPrimaryAction={handleClose}
        onSecondaryAction={handleClose}
        primaryButtonText='GET STARTED'
        showSingleButton
      />
    </Container>
  );
};

export default RecoveryRadar;
