/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-plusplus */
import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { useSortBy, useTable, usePagination } from 'react-table';
import { useSticky } from 'react-table-sticky';
import IcomoonReact from 'icomoon-react';

import { useSelector } from 'react-redux';
import iconSet from '../../../../shared/images/teambuildr-selection.json';

const Styles = styled.div`
  align-items: flex-start;
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  font-family: nunito sans;
  max-height: 85vh;
  min-width: 85vw;
  pointer-events: auto;
  width: 100%;

  @media screen and (max-width: 565px) {
    max-height: 80vh;
  }

  .noHoverCategoryColumnTitleCell {
    position: sticky;
    top: 0;
    z-index: 3;
  }

  .footerCells {
    bottom: 0;
    color: #444444;
    font-size: 16px;
    font-family: 'Nunito Sans';
    font-weight: 700 !important;
    position: sticky;
    z-index: 3;
  }

  .paginationContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
  }

  .pagination {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
  }

  .paginationButton {
    font-family: 'Nunito Sans';
    font-weight: bold;
    font-size: 16px;
    padding: 5px;
    display: flex;
    justify-content: center;
    border: none;
  }

  & .sticky {
    overflow-x: auto;
    margin: 25px 0px;
    width: 100%;

    tfoot {
      td:first-child {
         position: sticky;
         left: 0;
         z-index: 4;
         border-bottom-left-radius: 8px;
      }
    }

    [data-sticky-td] {
      position: sticky;
    }
  }

  .athleteColumnHeaderCell {
    border-bottom: none;
    border-right: none;
    display: none;
  }

  .noHoverCategoryColumnTitleCell {
    background-color: #F0F0F0;
    border-right: 1px solid #BDBDBD1A;
    color: #444444;
    font-size: 16px;
    font-weight: 700;
  }

  .rawValueTitle {
    background-color: #F0F0F0;
    color: #444444;
    cursor: pointer;
    font-family: 'Nunito Sans';
    font-size: 16px;
    font-weight: 700;
    left: 0;
    position: sticky;
    text-wrap: nowrap;
    top: 0;
  }

  .athleteNameTitle {
    background-color: #F0F0F0;
    border-top-left-radius: 8px;
    color: #444444;
    cursor: pointer;
    font-family: 'Nunito Sans';
    font-size: 16px;
    font-weight: bold;
    left: 0;
    position: sticky;
    text-align: left;
    text-wrap: nowrap;
    top: 0;
    z-index: 4;
  }

  .dateTitle {
    background-color: #F0F0F0;
    border-top-left-radius: 8px;
    color: #444444;
    cursor: ${(props) => (props.pointer ? 'pointer' : 'default')};
    font-family: 'Nunito Sans';
    font-size: 16px;
    font-weight: bold;
    left: 0;
    position: sticky;
    text-wrap: nowrap;
    top: 0;
    z-index: 4;
  }

  td:has(div):has(.averageRow) {
    background-color: #BDBDBD1A;
    font-weight: 700 !important;
  }

  td:has(div):has(.percentGreen) {
    background-color: #CFF5E8;
  }
  
  td:has(div):has(.percentRed) {
    background-color: #FFCDCD;
  }
  
  td:has(div):has(.percentYellow) {
    background-color: #FFF5DA;
  }
  
  th:last-child {
    border-right: none;
    border-top-right-radius: 8px;
  }

  td.footerCells:last-child {
    border-bottom-right-radius: 8px;
  }

  table {
    border-spacing: 0;
    border-collapse: separate;
    cursor: default;
    table-layout: auto;
    width: 100%;

    tr {
      :last-child {
        td {
          border-bottom: ${(props) => (props.showAverages ? '0' : '')};
          :first-child {
            border-bottom-left-radius: ${(props) => (props.showAverages ? '' : '8px')};
          }
          :last-child {
            border-bottom-right-radius: ${(props) => (props.showAverages ? '' : '8px')};
          }
        }
      }
    }

    th,
    td {
      margin: 0;
      background: white;
      padding: 15px;
      border-bottom: 1px solid #E0E0E0;
      border-right: 1px solid #E0E0E0;
      text-align: center;
    }

    tfoot {
      tr:first-child {
        td {
         border-top: 1px solid #E0E0E0;
         height: 61px;
         min-height: 61px;
         border-bottom: none;
         background: #F0F0F0;
        }
      }
      td:first-child {
         color: #444444;
         font-size: 16px;
         font-weight: normal;
         text-align: left;
         font-family: 'Nunito Sans';
      }
      tr:last-child {
        display: none;
      }
    }
  }
`;

const Table = ({
  columns,
  data,
  getCellProps,
  getHeaderProps,
  showAverages,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable({
    columns,
    data,
    initialState: { pageIndex: 0, pageSize: 50 },
  },
  useSticky,
  useSortBy,
  usePagination);

  const renderPageButtons = () => {
    const buttons = [];
    for (let i = 0; i < pageCount; i++) {
      buttons.push(
        <button
          className='paginationButton'
          type='button'
          key={i}
          onClick={() => gotoPage(i)}
          style={{ color: pageIndex === i ? '#FF6600' : '#616161' }}
        >
          {i + 1}
        </button>,
      );
    }
    return buttons;
  };

  return (
    <>
      <div id='completionReportTable' style={{ display: 'flex', width: '100%', zIndex: 0 }} className='sticky'>
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup, i) => (
              <tr {...headerGroup.getHeaderGroupProps()} data-a-wrap>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps([
                      {
                        className: column.className,
                      },
                      getHeaderProps(column),
                    ])}
                    data-cols-width='30'
                    data-a-wrap
                    data-f-bold
                    data-fill-color={column?.style?.fillColor || 'FF424242'}
                    data-b-a-s='thin'
                    data-b-a-c='FF000000'
                    data-f-color={column?.style?.fillColor ? 'FF000000;' : 'FFFFFFFF'}
                    data-a-h='center'
                    data-a-v='center'
                  >
                    {column.render('Header')}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? (
                            <IcomoonReact
                              iconSet={iconSet}
                              size={10}
                              color='black'
                              icon='down-arrow'
                              style={{ marginLeft: '5px' }}
                            />
                          )
                          : (
                            <IcomoonReact
                              iconSet={iconSet}
                              size={10}
                              color='black'
                              icon='up-arrow'
                              style={{ marginLeft: '5px' }}
                            />
                          )
                        : ''}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td
                      {...cell.getCellProps([
                        {
                          style: cell.column.style,
                        },
                        getCellProps(cell),

                      ])}
                      data-fill-color={getCellProps(cell)?.style?.fillColor || null}
                      data-f-color={getCellProps(cell)?.style?.fontColor}
                      data-b-a-s='thin'
                      data-b-a-c='FF000000'
                      data-a-wrap
                    >
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
          {showAverages && (
          <tfoot>
            {footerGroups.map((group) => (
              <tr {...group.getFooterGroupProps()}>
                {group.headers.map((column) => <td data-a-wrap {...column.getFooterProps([{ className: 'footerCells' }])}>{column.render('Footer')}</td>)}
              </tr>
            ))}
          </tfoot>
          )}
        </table>
      </div>
      <>
        {pageCount > 1 ? (
          <div className='paginationContainer'>
            <div className='pagination'>
              <button className='paginationButton' type='button' onClick={() => previousPage()} disabled={!canPreviousPage}>
                {'<'}
              </button>
              {' '}
              {renderPageButtons()}
              {' '}
              <button className='paginationButton' type='button' onClick={() => nextPage()} disabled={!canNextPage}>
                {'>'}
              </button>
            </div>
          </div>
        ) : (null)}
      </>
    </>
  );
};

const CompletionReportTable = ({
  averageData,
  containerWidth,
  dataRows,
  rowIsScrollable,
  setColumnHeaderHeight,
}) => {
  const filterType = useSelector((state) => state.reporting.data.sidebarFilter.filterType.value);
  const showAverages = useSelector(
    (state) => state.reporting.data.completionReportForm.showAverages,
  );

  const showAvgs = showAverages && filterType !== '1';

  const chartRef = useRef(null);
  const [headerAndSubHeaderHeight, setHeaderAndSubHeaderHeight] = useState(0);
  const [isScrollable, setIsScrollable] = useState(false);

  const handleScroll = (e) => {
    /** targetting the sticky container div scroll properties */
    const scrollWidth = chartRef.current?.childNodes[0]?.scrollWidth;
    const offsetWidth = chartRef.current?.childNodes[0]?.offsetWidth;
    const scrollLeft = chartRef.current?.childNodes[0]?.scrollLeft;
    setIsScrollable(offsetWidth + scrollLeft !== scrollWidth);
  };

  React.useEffect(() => {
    if (chartRef.current) {
      const scrollWidth = chartRef.current.childNodes[0]?.scrollWidth;
      const offsetWidth = chartRef.current.childNodes[0]?.offsetWidth;
      const scrollLeft = chartRef.current.childNodes[0]?.scrollLeft;
      setIsScrollable(offsetWidth + scrollLeft !== scrollWidth);
    }
  }, []);

  React.useEffect(() => {
    if (chartRef.current) {
      setHeaderAndSubHeaderHeight(
        chartRef.current.childNodes[0].childNodes[0].childNodes[0].clientHeight,
      );
      setColumnHeaderHeight(
        chartRef.current.childNodes[0].childNodes[0].childNodes[0].childNodes[0].clientHeight,
      );
    }
  }, []);

  const renderFooter = (key) => {
    if (showAverages && averageData) {
      if (key === 'percent' && averageData[key] !== null) {
        return () => `${averageData[key]}%`;
      }
      return averageData[key] ?? '-';
    }
    return null;
  };

  const AthleteNameColumn = [
    {
      Header: '',
      Footer: () => null,
      id: 'name',
      className: 'athleteColumnHeaderCell',
      columns: [
        {
          Header: 'Athlete',
          accessor: 'name',
          Footer: showAverages ? 'Average' : null,
          className: 'athleteNameTitle',
          style: {
            /** style for the whole column
             * className is style for just the header
            */
            borderLeft: '1px solid #E0E0E0',
            borderRight: '1px solid #E0E0E0',
            color: '#444444',
            fontFamily: 'Nunito Sans',
            fontSize: '16px',
            fontWeight: 'normal',
            height: '58px',
            left: '0',
            position: 'sticky',
            textAlign: 'left',
            textWrap: 'nowrap',
          },
          Cell: ({ row }) => (
            <div
              style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <div
                style={{
                  borderRadius: '50%',
                  height: '35px',
                  marginRight: '5px',
                  overflow: 'hidden',
                  width: '35px',
                }}
              >
                <img
                  alt={`${row.original.name} icon`}
                  src={row.original.pic}
                  style={{ height: '100%' }}
                />
              </div>
              <div>
                {row.original.name}
              </div>
            </div>
          ),
        },
      ],
    },
  ];

  const DateColumn = [
    {
      Header: '',
      Footer: () => null,
      id: 'date',
      className: 'athleteColumnHeaderCell',
      columns: [
        {
          Header: 'Date',
          id: showAverages ? 'datesWithAvgs' : 'date',
          accessor: 'date',
          Footer: () => null,
          className: 'dateTitle',
          style: {
            /** style for the whole column
             * className is style for just the header
            */
            borderLeft: '1px solid #E0E0E0',
            borderRight: '1px solid #E0E0E0',
            color: '#444444',
            fontFamily: 'Nunito Sans',
            fontSize: '16px',
            fontWeight: 'normal',
            height: '58px',
            left: '0',
            position: 'sticky',
            textAlign: 'left',
            textWrap: 'nowrap',
          },
          Cell: ({ row }) => (
            <div
              className={row.original.average ? 'averageRow' : null}
              style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
              }}
            >
              {row.original.date && (
                <div>
                  {row.original.date}
                </div>
              )}
              {row.original.average && (
                <div>
                  AVERAGE
                </div>
              )}
            </div>
          ),
        },
      ],
    },
  ];

  const AthleteDataColumns = [
    {
      Header: '',
      Footer: () => null,
      id: 'empty',
      className: 'athleteColumnHeaderCell',
      columns: [
        {
          Header: 'Name',
          Footer: () => null,
          accessor: (row) => row.name,
          Cell: ({ row }) => (
            <div className={row.original.average ? 'averageRow' : null}>
              {row.original.name ?? '-'}
            </div>
          ),
          id: 'name',
          className: 'rawValueTitle',
          style: {
            fontWeight: 400,
            fillColor: 'FFBDBDBD1A',
            flexGrow: 1,
            textWrap: 'nowrap',
          },
        },
        {
          Header: 'Assigned',
          Footer: () => null,
          accessor: (row) => row.data.assigned,
          Cell: ({ row }) => (
            <div className={row.original.average ? 'averageRow' : null}>
              {row.original.data.assigned ?? '-'}
            </div>
          ),
          id: 'assigned',
          className: 'rawValueTitle',
          style: {
            fontWeight: 400,
            fillColor: 'FFBDBDBD1A',
            flexGrow: 1,
            textWrap: 'nowrap',
          },
        },
        {
          Header: 'Completed',
          accessor: (row) => row.data.completed,
          Cell: ({ row }) => (
            <div className={row.original.average ? 'averageRow' : null}>
              {row.original.data.completed ?? '-'}
            </div>
          ),
          Footer: () => null,
          id: 'completed',
          className: 'rawValueTitle',
          style: {
            fontWeight: 400,
            fillColor: 'FFBDBDBD1A',
            flexGrow: 1,
            textWrap: 'nowrap',
          },
        },
        {
          Header: 'Percent',
          accessor: (row) => row.data.percentCompleted,
          Cell: ({ row }) => {
            if (row.original.data.percentColor === '#0DCC8A') {
              return (
                <div className={row.original.average ? 'averageRow percentGreen' : 'percentGreen'}>
                  {`${row.original.data.percentCompleted}%`}
                </div>
              );
            }
            if (row.original.data.percentColor === '#FFCB47') {
              return (
                <div className={row.original.average ? 'averageRow percentYellow' : 'percentYellow'}>
                  {`${row.original.data.percentCompleted}%`}
                </div>
              );
            }
            if (row.original.data.percentColor === '#FF3737') {
              return (
                <div className={row.original.average ? 'averageRow percentRed' : 'percentRed'}>
                  {`${row.original.data.percentCompleted}%`}
                </div>
              );
            }
            return (
              <div className={row.original.average ? 'averageRow' : null}>
                {row.original.data.percentCompleted ? `${row.original.data.percentCompleted}%` : '-'}
              </div>
            );
          },
          Footer: () => null,
          id: 'percent',
          className: 'rawValueTitle',
          style: {
            fontWeight: 400,
            fillColor: 'FFBDBDBD1A',
            flexGrow: 1,
            textWrap: 'nowrap',
          },
        },
      ],
    },
  ];

  const DataColumns = [
    {
      Header: '',
      Footer: () => null,
      id: 'empty',
      className: 'athleteColumnHeaderCell',
      columns: [
        {
          Header: 'Assigned',
          Footer: renderFooter('assignedAverage'),
          accessor: (row) => row.data.assigned,
          Cell: ({ row }) => (
            <div>
              {row.original.data.assigned ?? '-'}
            </div>
          ),
          id: 'assigned',
          className: 'rawValueTitle',
          style: {
            fontWeight: 400,
            fillColor: 'FFBDBDBD1A',
            flexGrow: 1,
            textWrap: 'nowrap',
          },
        },
        {
          Header: 'Completed',
          accessor: (row) => row.data.completed,
          Cell: ({ row }) => (
            <div>
              {row.original.data.completed ?? '-'}
            </div>
          ),
          Footer: renderFooter('completedAverage'),
          id: 'completed',
          className: 'rawValueTitle',
          style: {
            fontWeight: 400,
            fillColor: 'FFBDBDBD1A',
            flexGrow: 1,
            textWrap: 'nowrap',
          },
        },
        {
          Header: 'Percent',
          accessor: (row) => row.data.percent,
          Cell: ({ row }) => {
            if (row.original.data.percentColor === '#0DCC8A') {
              return (
                <div className='percentGreen'>
                  {`${row.original.data.percent}%`}
                </div>
              );
            }
            if (row.original.data.percentColor === '#FFCB47') {
              return (
                <div className='percentYellow'>
                  {`${row.original.data.percent}%`}
                </div>
              );
            }
            if (row.original.data.percentColor === '#FF3737') {
              return (
                <div className='percentRed'>
                  {`${row.original.data.percent}%`}
                </div>
              );
            }
            return (
              <div>
                {row.original.data.percent ? `${row.original.data.percent}%` : '-'}
              </div>
            );
          },
          Footer: renderFooter('percent'),
          id: 'percent',
          className: 'rawValueTitle',
          style: {
            fontWeight: 400,
            fillColor: 'FFBDBDBD1A',
            flexGrow: 1,
            textWrap: 'nowrap',
          },
        },
        {
          Header: 'Fully Completed Days',
          accessor: (row) => row.data.fullyCompleted,
          Cell: ({ row }) => (
            <div>
              {row.original.data.fullyCompleted ?? '-'}
            </div>
          ),
          Footer: renderFooter('fullyCompletedAverage'),
          id: 'fullyCompleted',
          className: 'rawValueTitle',
          style: {
            fontWeight: 400,
            fillColor: 'FFBDBDBD1A',
            flexGrow: 1,
            textWrap: 'nowrap',
          },
        },
        {
          Header: 'Partially Completed Days',
          accessor: (row) => row.data.partiallyCompleted,
          Cell: ({ row }) => (
            <div>
              {row.original.data.partiallyCompleted ?? '-'}
            </div>
          ),
          Footer: renderFooter('partialCompletedAverage'),
          id: 'partiallyCompleted',
          className: 'rawValueTitle',
          style: {
            fontWeight: 400,
            fillColor: 'FFBDBDBD1A',
            flexGrow: 1,
            textWrap: 'nowrap',
          },
        },
        {
          Header: 'Non-Completed Days',
          accessor: (row) => row.data.nonCompleted,
          Cell: ({ row }) => (
            <div>
              {row.original.data.nonCompleted ?? '-'}
            </div>
          ),
          Footer: renderFooter('nonCompletedAverage'),
          id: 'nonCompleted',
          className: 'rawValueTitle',
          style: {
            fontWeight: 400,
            fillColor: 'FFBDBDBD1A',
            flexGrow: 1,
            textWrap: 'nowrap',
          },
        },
        {
          Header: 'Total Assigned Days',
          accessor: (row) => row.data.totalAssignedDays,
          Cell: ({ row }) => (
            <div>
              {row.original.data.totalAssignedDays ?? '-'}
            </div>
          ),
          Footer: renderFooter('totalAssignedDays'),
          id: 'totalAssignedDays',
          className: 'rawValueTitle',
          style: {
            fontWeight: 400,
            fillColor: 'FFBDBDBD1A',
            flexGrow: 1,
            textWrap: 'nowrap',
          },
        },
      ],
    },
  ];

  const columns = filterType === '1' ? [...DateColumn, ...AthleteDataColumns] : [...AthleteNameColumn, ...DataColumns];

  const memoizedColumns = React.useMemo(
    () => columns,
    [dataRows, containerWidth],
  );
  const memoizedDataRows = React.useMemo(
    () => dataRows,
    [dataRows, containerWidth],
  );

  const handleHeaderClick = (headerInfo) => {
    if (headerInfo.Header !== '' && headerInfo.id !== 'datesWithAvgs') {
      headerInfo.toggleSortBy();
    }
  };

  return (
    <>
      <Styles
        className='stylesDiv'
        containerWidth={containerWidth}
        headerAndSubHeaderHeight={headerAndSubHeaderHeight}
        isScrollable={isScrollable}
        onScroll={handleScroll}
        ref={chartRef}
        rowIsScrollable={rowIsScrollable}
        showAverages={showAvgs}
        pointer={!showAverages && filterType === '1'}
      >
        <Table
          containerWidth={containerWidth}
          columns={memoizedColumns}
          data={memoizedDataRows}
          getCellProps={() => ({
            style: {
              backgroundColor: 'FFFFFFFF',
              color: 'FF444444',
              fillColor: 'FFFFFFFF',
              fontColor: 'FF444444',
            },
          })}
          getHeaderProps={(headerInfo) => ({
            onClick: () => handleHeaderClick(headerInfo),
          })}
          showAverages={showAvgs}
        />
      </Styles>
    </>
  );
};

export default CompletionReportTable;
