/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';
import Select from 'react-select';
import { createPortal } from 'react-dom';
import moment from 'moment-timezone';
import { animated, useSpring } from 'react-spring';

import PropTypes from 'prop-types';
import { BoxShadow } from '../../../../../shared/GlobalStyles';

import iconSet from '../../../../../shared/images/teambuildr-selection.json';
import {
  setShareDashboardModal,
  fetchShareDashLink as fetchShareSCDashLink,
  clearShareDashLink as clearShareSCDashLink,
} from '../../../../sport-coach-dashboard/ducks/sportCoachDashboardActions';
import Button from '../../../../../shared/components/Button/Button';
import ShareDatePicker from '../../../../sport-coach-dashboard/components/presentational/ShareDatePicker';
import Text from '../../../../../shared/components/Text/Text';
import { dashboardTracker } from '../../../../../shared/utils/amplitudeHelper';
import SharedTemplateModalContainer from './SharedTemplateModalContainer';
import {
  clearShareLMDashLink,
  fetchShareLMDashLink,
  setShareLMDashModal,
} from '../../../../load-monitoring-dashboard/ducks/loadMonitoringDashboardActions';
import {
  clearShareFPDashLink,
  fetchShareFPDashLink,
  setShareFPDashModal,
} from '../../../../force-plate-dashboard/ducks/forcePlateDashboardActions';

const dayRangeOptions = [
  { label: '7 Days', value: 7 },
  { label: '28 Days', value: 28 },
];

const ModalContainer = styled('div')`
  z-index: 1003;
  min-height: 270px;
  width: 50vw;
  overflow: auto;
  @media only screen and (max-width: 768px) {
    width: 90vw;
  }
  min-width: 430px;
  max-width: 600px;
  max-height: calc(100vh - 20px);
  position: absolute;
  border-radius: 12px;
  left: 50%;
  top: 50%;
  right: 50%;
  .print-button {
    background: #424242;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
    min-height: 48px;
  }
  transition: all 0.3s linear;
  transform: ${(props) => (props.isActive ? 'translate(-50%,-50%)' : 'translate(-50%, 300%)')};
  user-select: none;
`;

const RemoveDiv = styled('div')`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  z-index: 1001;
  color: #444444;
  background: white;
  .remove-div-icon {
    cursor: pointer;
  }
`;

const ContentContainer = styled('div')`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding-left: 40px;
  padding-right: 40px;
  max-height: ${(props) => props.customHeight};
  .print-button {
    position: absolute;
    bottom: 0;
    background: #10cd8c;
  }
  transition: max-height 0.5s linear;
`;

const ModalHeader = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 40px;
`;

const HeaderIconContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
  height: 52px;
  width: 52px;
  border-radius: 50%;
`;

const ModalHeaderText = styled('div')`
  font-size: 24px;
  font-family: "Nunito Sans";
  font-weight: 400;
  margin-top: 10px;
`;

const DashTitle = styled('div')`
  font-size: 16px;
  font-family: "Nunito Sans";
`;

const DashLink = styled('div')`
  font-size: 16px;
  font-family: "Nunito Sans";
`;

const DashTitleContainer = styled('div')`
  width: 100%;
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  flex-direction: column;
`;

const DashLinkContainer = styled('div')`
  width: 100%;
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  flex-direction: column;
`;

const DashTitleBox = styled('input')`
  height: 40px;
  display: flex;
  align-items: center;
  border: 1px solid #eeeeee;
  border-radius: 4px;
  padding-left: 10px;
`;

const DashLinkBox = styled('div')`
  height: 40px;
  display: flex;
  align-items: center;
  border: 1px solid #eeeeee;
  border-radius: 4px;
  padding-left: 10px;
  user-select: none;
  cursor: ${(props) => (props.hasLink ? 'pointer' : 'default')};
  background: lightgray;
  overflow: visible;
  white-space: wrap;
  height: auto;
  padding: 10px;
`;

const DashDescription = styled('div')`
  font-size: 16px;
  font-family: "Nunito Sans";
`;

const DashDateContainer = styled('div')`
  width: 100%;
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  flex-direction: column;
  .day-range-select {
    width: 90%;
  }
`;

const DashDescriptionBox = styled('textarea')`
  height: 60px;
  display: flex;
  align-items: center;
  border: 1px solid #eeeeee;
  border-radius: 4px;
  padding-left: 10px;
  padding-top: 8px;
`;

const SlidesContainer = styled('div')`
  overflow: hidden;
  width: 100%;
  max-height: ${(props) => props.customHeight};
  transition: max-height 1s linear;
`;

const Slides = styled('div')`
  white-space: nowrap;
  transition: transform 0.5s ease;
  transform: ${(props) => `translateX(-${props.activeSlide * 100}%)`};
  height: 100%;
`;

const Slide = styled('div')`
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  height: 100%;
`;

const DownloadLinkDescText = styled('div')`
  font-family: "Nunito Sans";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #616161;
  margin-top: 15px;
  margin-bottom: 15px;
`;

const DashDateWrapper = styled('div')`
  display: flex;
`;

const SlideIndicators = styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
  height: 40px;
  align-items: center;
  background: white;
`;

const SlideIndicator = styled('div')`
  width: 7px;
  height: 7px;
  border: 1px solid grey;
  background: ${(props) => (props.isActive ? 'grey' : 'none')};
  border-radius: 50%;
  margin-left: 5px;
  margin-right: 5px;
  transition: background-color 0.5s ease;
`;

const Slide2Block2 = styled('div')`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const BlockText = styled('div')`
  font-size: 16px;
  font-family: "Nunito Sans";
  font-weight: 500;
`;

const PortalContainer = styled('div')`
  width: 100%;
  background: white;
  box-shadow: ${BoxShadow};
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  overflow: auto;
`;

const SlideCenterDiv = styled('div')``;

const ErrorBox = styled('div')`
  display: flex;
  flex-direction: column;
`;

const MappedError = styled('div')`
  font-family: "Nunito Sans";
  font-size: ${(props) => (props.linkError ? '16px' : '14px')};
  color: #c0392b;
  margin-top: ${(props) => (props.linkError ? '10px' : '0px')};
`;

const ButtonContainer = styled('div')`
  border-radius: 12px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: white;
`;

const RelativePortalContainer = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const DatePickerDiv = styled('div')`
  display: flex;
  border: 1px solid #eeeeee;
  border-radius: 8px;
  width: 90%;
  height: 40px;
  justify-content: space-between;
  overflow: hidden;
  align-items: center;
  padding-left: 12px;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
`;

const DatePickerDiv2 = styled('div')`
  display: flex;
  border: 1px solid #eeeeee;
  border-radius: 8px;
  width: 100%;
  height: 40px;
  justify-content: space-between;
  overflow: hidden;
  align-items: center;
  padding-left: 12px;
  cursor: pointer;
`;

const DatePickerText = styled('div')`
  font-size: 16px;
  font-family: "Nunito Sans";
  font-weight: 400;
  color: ${(props) => (props.disabled ? '#9e9e9e' : '#444444')};
`;

const TextAndCopy = styled('div')`
  display: flex;
  align-items: center;
`;

const DashDescriptionContainer = styled('div')`
  width: 100%;
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  flex-direction: column;
`;

const ShareDashboardModal = ({ dashboard }) => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  let shareTemplate = {};
  let activeTemplate = null;
  let isModalShowing = false;
  let setShareModalShowing = () => {};
  let group = null;
  let groups = [];
  let fetchShareDashLink = () => {};
  let clearShareDashLink = () => {};
  let shareDashLink = null;
  let shareDashLinkError = null;
  let shareDashLinkLoading = false;
  let dateType = null;
  let startDateCal = null;
  let endDateCal = null;
  let loadMonitoringFormValues = {};
  let dayRange = 7;
  let metricFiltersPerformance = [];
  let keyPerformanceMetrics = [];
  let metricFiltersGroup = [];
  let exercises = [];
  let maxType = null;

  if (dashboard === 'Load Monitoring') {
    shareTemplate = useSelector(
      (state) => state.loadMonitoringDashboard.data.shareTemplate,
    );
    activeTemplate = useSelector(
      (state) => state.loadMonitoringDashboard.data.activeTemplate,
    );
    isModalShowing = useSelector(
      (state) => state.loadMonitoringDashboard.ui.shareModalShowing,
    );
    setShareModalShowing = setShareLMDashModal;
    fetchShareDashLink = fetchShareLMDashLink;
    clearShareDashLink = clearShareLMDashLink;
    shareDashLink = useSelector(
      (state) => state.loadMonitoringDashboard.data.shareDashLink,
    );
    shareDashLinkError = useSelector(
      (state) => state.loadMonitoringDashboard.data.shareDashLinkError,
    );
    shareDashLinkLoading = useSelector(
      (state) => state.loadMonitoringDashboard.ui.isShareDashLinkLoading,
    );
    group = useSelector(
      (state) => state.loadMonitoringDashboard.data.currentGroupInGroupPicker,
    );
    groups = useSelector(
      (state) => state.loadMonitoringDashboard.data.reportableGroups,
    );
    loadMonitoringFormValues = useSelector(
      (state) => state.loadMonitoringDashboard.data.loadMonitoringFormValues,
    );
    dayRange = useSelector(
      (state) => state.loadMonitoringDashboard.data.dayRange,
    );
    endDateCal = useSelector(
      (state) => state.loadMonitoringDashboard.data.currentDateInDashDatePicker?.date,
    );
  }

  if (dashboard === 'Force Plate') {
    shareTemplate = useSelector(
      (state) => state.forcePlateDashboard.data.shareTemplate,
    );
    activeTemplate = useSelector(
      (state) => state.forcePlateDashboard.data.activeTemplate,
    );
    isModalShowing = useSelector(
      (state) => state.forcePlateDashboard.ui.shareModalShowing,
    );
    setShareModalShowing = setShareFPDashModal;
    fetchShareDashLink = fetchShareFPDashLink;
    clearShareDashLink = clearShareFPDashLink;
    shareDashLink = useSelector(
      (state) => state.forcePlateDashboard.data.shareDashLink,
    );
    shareDashLinkError = useSelector(
      (state) => state.forcePlateDashboard.data.shareDashLinkError,
    );
    shareDashLinkLoading = useSelector(
      (state) => state.forcePlateDashboard.ui.isShareDashLinkLoading,
    );
    startDateCal = useSelector(
      (state) => state.forcePlateDashboard.data.startDateCal,
    );
    endDateCal = useSelector(
      (state) => state.forcePlateDashboard.data.endDateCal,
    );
    metricFiltersPerformance = useSelector(
      (state) => state.forcePlateDashboard.data.metricFiltersPerformance,
    );
    metricFiltersGroup = useSelector(
      (state) => state.forcePlateDashboard.data.metricFiltersGroup,
    );
    keyPerformanceMetrics = useSelector(
      (state) => state.forcePlateDashboard.data.keyPerformanceMetrics,
    );
    group = useSelector((state) => state.forcePlateDashboard.data.group);
    groups = useSelector((state) => state.forcePlateDashboard.data.groups);
  }

  if (dashboard === 'Sport Coach') {
    shareTemplate = useSelector(
      (state) => state.sportCoachDashboard.data.shareTemplate,
    );
    activeTemplate = useSelector(
      (state) => state.sportCoachDashboard.data.template,
    );
    isModalShowing = useSelector(
      (state) => state.sportCoachDashboard.ui.isShareDashboardModalShowing,
    );
    setShareModalShowing = setShareDashboardModal;
    fetchShareDashLink = fetchShareSCDashLink;
    clearShareDashLink = clearShareSCDashLink;
    shareDashLink = useSelector(
      (state) => state.sportCoachDashboard.data.shareDashLink,
    );
    shareDashLinkError = useSelector(
      (state) => state.sportCoachDashboard.data.shareDashLinkError,
    );
    shareDashLinkLoading = useSelector(
      (state) => state.sportCoachDashboard.ui.isShareDashLinkLoading,
    );
    dateType = useSelector((state) => state.sportCoachDashboard.data.dateType);
    startDateCal = useSelector(
      (state) => state.sportCoachDashboard.data.startDateCal,
    );
    endDateCal = useSelector(
      (state) => state.sportCoachDashboard.data.endDateCal,
    );
    exercises = useSelector(
      (state) => state.sportCoachDashboard.data.exercises,
    );
    maxType = useSelector((state) => state.sportCoachDashboard.data.maxType);
    group = useSelector((state) => state.sportCoachDashboard.data.group);
    groups = useSelector((state) => state.sportCoachDashboard.data.groups);
  }

  const uniqueFilters = (filterArray) => {
    if (!filterArray) return [];
    const uniqueArr = [];
    filterArray.forEach((filterObj) => {
      if (!uniqueArr.some((obj) => obj.name === filterObj.name)) uniqueArr.push(filterObj);
    });
    return uniqueArr;
  };
  const chartForcePlateMetrics = !!Object.keys(shareTemplate).length && dashboard === 'Force Plate'
    ? uniqueFilters(shareTemplate.chartForcePlateMetrics).map((metric) => metric.name.replace(/\s/g, ''))
    : metricFiltersPerformance;

  const kpiForcePlateMetrics = !!Object.keys(shareTemplate).length && dashboard === 'Force Plate'
    ? uniqueFilters(shareTemplate.kpiForcePlateMetrics).map((metric) => metric.name.replace(/\s/g, ''))
    : keyPerformanceMetrics.map((metric) => metric.replace(/\s/g, ''));

  const groupAnalysisForcePlateMetrics = (dashboard === 'Force Plate' && activeTemplate && metricFiltersGroup)
    || kpiForcePlateMetrics;

  const [selectedStartDate, setSelectedStartDate] = useState(moment());
  const [selectedEndDate, setSelectedEndDate] = useState(moment());
  const [activeSlide, setActiveSlide] = useState(0);
  const [pickerOpen, setPickerOpen] = useState(false);
  const [pickerOpen2, setPickerOpen2] = useState(false);
  const [dscElement, setDscElement] = useState(null);
  const [dscElement2, setDscElement2] = useState(null);
  const [dashDescription, setDashDescription] = useState('');
  const [dashTitle, setDashTitle] = useState('');
  const [loadMonitoringDayRange, setLoadMonitoringDayRange] = useState(
    dayRangeOptions[0],
  );
  const [localGroup, setLocalGroup] = useState(null);
  const [copySuccess, setCopySuccess] = useState(false);
  const [errors, setErrors] = useState({
    errorsExist: false,
    errors: [
      {
        error: 'name error',
        msg: 'You must add a title for the shared dashboard',
        isShowing: false,
      },
      {
        error: 'select group error',
        msg: 'You must select a group',
        isShowing: false,
      },
      {
        error: 'API error',
        msg:
          'Unable to create shareable link, please check the form and try again',
        isShowing: false,
      },
    ],
  });

  /** When opening from template grid, template with group access marked private
   * should allow all user groups for template owner, empty array for non-owner (non-owners
   * theoretically shouldn't see these templates anyway)
   * Otherwise shareTemplate.groups will represent intersection of user's groups
   * and template's groups.
   * When viewing share modal from dashboard view, groups will already
   * be set as noted above */
  const groupOptions = () => {
    if (Object.keys(shareTemplate).length) {
      if (!shareTemplate.groups.length) {
        if (shareTemplate.createdBy === currentUser?.id) return groups;
        return [];
      }
      return shareTemplate.groups;
    }
    return groups;
  };

  const handleSelectStartDate = (date) => {
    setSelectedStartDate(date);
  };

  const handleSelectEndDate = (date) => {
    setSelectedEndDate(date);
  };

  const disabledStartDate = (date) => {
    if (selectedEndDate && moment(date).isSame(selectedEndDate, 'day')) {
      return false;
    }
    return selectedEndDate && moment(date).isAfter(selectedEndDate, 'day');
  };
  const disabledEndDate = (date) => {
    if (selectedStartDate && moment(date).isSame(selectedStartDate, 'day')) {
      return false;
    }
    return selectedStartDate && moment(date).isBefore(selectedStartDate, 'day');
  };

  const handleClose = () => {
    batch(() => {
      dispatch(setShareModalShowing(false));
      dispatch(clearShareDashLink());
    });
    setActiveSlide(0);
    setDashTitle('');
    setDashDescription('');
    setLocalGroup(group || null);
  };

  useEffect(() => {
    if (!isModalShowing) {
      setErrors({
        errorsExist: false,
        errors: [
          {
            error: 'name error',
            msg: 'You must add a title for the shared dashboard',
            isShowing: false,
          },
          {
            error: 'select group error',
            msg: 'You must select a group',
            isShowing: false,
          },
          {
            error: 'API error',
            msg:
              'Unable to create shareable link, please check the form and try again',
            isShowing: false,
          },
        ],
      });
    }
  }, [isModalShowing]);

  const copyAnimation = useSpring({ opacity: copySuccess ? 1 : 0 });

  const copyToClipboard = () => {
    navigator.clipboard.writeText(shareDashLink);
    setCopySuccess(true);
    setTimeout(() => {
      setCopySuccess(false);
    }, 3000);
  };

  const exerIds = exercises.length > 0
    ? exercises.map((item) => item.id)
    : shareTemplate?.exercises?.map((item) => item.id);

  // Properly set group, title, description, start date, and end date for select drop-down
  useEffect(() => {
    if (group) {
      setLocalGroup(group);
    }
  }, [group]);

  useEffect(() => {
    if (Object.keys(shareTemplate).length) {
      setDashTitle(shareTemplate?.title);
      setDashDescription(shareTemplate?.description);
      setLocalGroup(shareTemplate?.groups[0]);
    }
  }, [shareTemplate]);

  useEffect(() => {
    if (activeTemplate) {
      setDashTitle(activeTemplate?.title);
      setDashDescription(activeTemplate?.description);
    }
  }, [activeTemplate]);

  useEffect(() => {
    if (dashboard !== 'Load Monitoring') {
      setSelectedStartDate(startDateCal);
    }
  }, [startDateCal]);

  useEffect(() => {
    if (dashboard === 'Load Monitoring') {
      setLoadMonitoringDayRange(
        dayRangeOptions.find((option) => option.value === dayRange),
      );
    }
  }, [dayRange]);

  useEffect(() => {
    if (dashboard === 'Load Monitoring') {
      setSelectedStartDate(
        moment(selectedEndDate).subtract(
          loadMonitoringDayRange.value - 1,
          'days',
        ),
      );
    }
  }, [selectedEndDate, loadMonitoringDayRange]);

  useEffect(() => {
    if (dashboard === 'Load Monitoring') {
      setSelectedEndDate(endDateCal ? moment(endDateCal) : moment());
    } else {
      setSelectedEndDate(endDateCal);
    }
  }, [endDateCal]);

  // Update setErrors if the share link fetch failed
  useEffect(() => {
    if (shareDashLinkError) {
      const newErrors = { ...errors };
      newErrors.errors[2].isShowing = true;
      newErrors.errorsExist = true;
      setErrors(newErrors);
    } else {
      const newErrors = { ...errors };
      newErrors.errors[2].isShowing = false;
      newErrors.errorsExist = false;
      setErrors(newErrors);
    }
  }, [shareDashLinkError]);

  useEffect(() => {
    const dsc = document.getElementById(
      `${dashboard} date-selector-container-1`,
    );
    if (dsc) {
      setDscElement(dsc);
    }
    const dsc2 = document.getElementById(
      `${dashboard} date-selector-container-2`,
    );
    if (dsc2) {
      setDscElement2(dsc2);
    }
  }, []);

  const handleSubmit = () => {
    if (activeSlide === 0) {
      let submissionObj = {
        dashboardTitle: dashTitle,
        dashboardDescription: dashDescription,
        groupId: localGroup.id,
        endDate: selectedEndDate.format('YYYY-MM-DD'),
      };
      if (dashboard === 'Load Monitoring') {
        let isQuestionnaire;
        let itemIds;
        let showTotals;
        let showAverages;
        // SHARING EXISTING TEMPLATE FROM TEMPLATE SELECTION PAGE
        if (Object.keys(shareTemplate).length) {
          const sharedItemIds = shareTemplate?.isQuestionnaire
            ? [shareTemplate?.questionnaire.id]
            : shareTemplate?.questions.map((question) => question.id);
          isQuestionnaire = shareTemplate.isQuestionnaire;
          itemIds = sharedItemIds;
          showTotals = shareTemplate.showTotals;
          showAverages = shareTemplate.showAverages;
          // SHARING EXISTING TEMPLATE THAT IS CURRENTLY BEING VIEWED
        } else if (activeTemplate) {
          const activeItemIds = activeTemplate?.isQuestionnaire
            ? [activeTemplate?.questionnaire.id]
            : activeTemplate?.questions.map((question) => question.id);
          isQuestionnaire = activeTemplate.isQuestionnaire;
          itemIds = activeItemIds;
          showTotals = activeTemplate.showTotals;
          showAverages = activeTemplate.showAverages;
          // SHARING UNSAVED DASHBOARD
        } else {
          isQuestionnaire = !!loadMonitoringFormValues.questionnaire?.id;
          itemIds = loadMonitoringFormValues.questionnaire?.id
            ? [loadMonitoringFormValues.questionnaire.id]
            : loadMonitoringFormValues.arrayOfQuestions.map(
              (question) => question.id,
            );
          showTotals = loadMonitoringFormValues.showTotal;
          showAverages = loadMonitoringFormValues.showRangeAverages;
        }

        submissionObj = {
          ...submissionObj,
          daysToGoBack: loadMonitoringDayRange.value,
          isQuestionnaire,
          itemIds,
          showTotals,
          showAverages,
        };
      }
      if (dashboard === 'Force Plate') {
        submissionObj = {
          ...submissionObj,
          startDate: selectedStartDate.format('YYYY-MM-DD'),
          chartForcePlateMetrics,
          kpiForcePlateMetrics,
          groupAnalysisForcePlateMetrics,
        };
      }
      if (dashboard === 'Sport Coach') {
        submissionObj = {
          ...submissionObj,
          startDate: selectedStartDate.format('YYYY-MM-DD'),
          exerciseIds: exerIds,
          dateType,
          maxType,
        };
      }
      dispatch(fetchShareDashLink(currentUser?.accountCode, submissionObj));
      // KPI tracking
      dashboardTracker(
        `${dashboard} Dashboard`,
        'Share Saved Dashboard',
        'Shareable dashboard link created',
      );
      setTimeout(() => {
        setActiveSlide(1);
      }, 500);
      // On submit, reset/clear the modal and clear the share link in redux
    } else {
      handleClose();
    }
  };

  return (
    <>
      <ModalContainer
        onClick={(e) => {
          if (
            e.target !== 'floating-datepicker-selector'
            && (pickerOpen || pickerOpen2)
          ) {
            setPickerOpen(false);
            setPickerOpen2(false);
          }
        }}
        isActive={isModalShowing}
      >
        <RelativePortalContainer>
          <div id={`${dashboard} date-selector-container-1`} />
          <div id={`${dashboard} date-selector-container-2`} />
          <ButtonContainer>
            <div />
            <PortalContainer>
              <RemoveDiv>
                <div
                  className='remove-div-icon'
                  onClick={() => {
                    if (activeSlide === 1) {
                      dispatch(clearShareDashLink());
                      setActiveSlide(0);
                    }
                  }}
                >
                  <IcomoonReact
                    iconSet={iconSet}
                    size={18}
                    icon='left-arrow'
                    color={activeSlide === 1 ? 'black' : 'white'}
                  />
                </div>
                <ModalHeader>
                  <HeaderIconContainer>
                    <IcomoonReact iconSet={iconSet} size={20} icon='share' />
                  </HeaderIconContainer>
                  <ModalHeaderText>Share Dashboard</ModalHeaderText>
                  <ErrorBox>
                    {errors.errors[0].isShowing ? (
                      <MappedError>{errors.errors[0].msg}</MappedError>
                    ) : null}
                    {errors.errors[1].isShowing ? (
                      <MappedError>{errors.errors[1].msg}</MappedError>
                    ) : null}
                    {errors.errors[2].isShowing ? (
                      <MappedError linkError>{errors.errors[2].msg}</MappedError>
                    ) : null}
                  </ErrorBox>
                </ModalHeader>
                <div className='remove-div-icon' onClick={handleClose}>
                  <IcomoonReact
                    iconSet={iconSet}
                    size={15}
                    icon='remove'
                    color='black'
                  />
                </div>
              </RemoveDiv>
              <ContentContainer
                customHeight={activeSlide === 1 ? '200px' : '500px'}
              >
                <SlidesContainer customHeight='auto'>
                  <Slides activeSlide={activeSlide}>
                    {/* First slide */}
                    <Slide>
                      <DashTitleContainer>
                        <DashTitle>Title</DashTitle>
                        <DashTitleBox
                          onBlur={() => {
                            if (!dashTitle) {
                              const newErrors = { ...errors };
                              newErrors.errors[0].isShowing = true;
                              newErrors.errorsExist = true;
                              setErrors(newErrors);
                            }
                          }}
                          value={dashTitle}
                          onChange={(e) => {
                            if (
                              errors.errors[0].isShowing
                              && e.target.value.length
                            ) {
                              const newErrors = { ...errors };
                              newErrors.errors[0].isShowing = false;
                              newErrors.errorsExist = false;
                              setErrors(newErrors);
                            }
                            setDashTitle(e.target.value);
                          }}
                          placeholder='Title'
                        />
                      </DashTitleContainer>
                      <DashDateWrapper>
                        {dashboard === 'Load Monitoring' && (
                          <DashDateContainer>
                            <BlockText>Dashboard Day Range</BlockText>
                            <Select
                              className='day-range-select'
                              value={loadMonitoringDayRange}
                              // defaultValue={dayRangeOptions[0]}
                              options={dayRangeOptions}
                              menuPortalTarget={document.body}
                              styles={{
                                menuPortal: (base) => ({
                                  ...base,
                                  zIndex: 9999,
                                }),
                              }}
                              getOptionLabel={(option) => option.label}
                              getOptionValue={(option) => option.value}
                              onChange={(option) => {
                                setLoadMonitoringDayRange(option);
                              }}
                            />
                          </DashDateContainer>
                        )}
                        {dashboard !== 'Load Monitoring' && (
                          <DashDateContainer>
                            <DashDescription>Start Date</DashDescription>
                            <DatePickerDiv
                              onClick={
                                dashboard !== 'Load Monitoring'
                                  ? () => setPickerOpen(!pickerOpen)
                                  : () => undefined
                              }
                            >
                              <DatePickerText
                                disabled={dashboard === 'Load Monitoring'}
                              >
                                {selectedStartDate
                                  ? selectedStartDate.format('YYYY-MM-DD')
                                  : 'Choose Date'}
                              </DatePickerText>
                              {dscElement
                                ? createPortal(
                                  <ShareDatePicker
                                    id='floating-datepicker-selector'
                                    isDayPickerOpen={pickerOpen}
                                    setIsDayPickerOpen={setPickerOpen}
                                    activeDate={selectedStartDate}
                                    selectDate={handleSelectStartDate}
                                    disabledCondition={disabledStartDate}
                                    left={100}
                                  />,
                                  dscElement,
                                )
                                : null}
                            </DatePickerDiv>
                          </DashDateContainer>
                        )}
                        <DashDateContainer>
                          <DashDescription>
                            {dashboard === 'Load Monitoring'
                              ? 'Date'
                              : 'End Date'}
                          </DashDescription>
                          <DatePickerDiv2
                            onClick={() => setPickerOpen2(!pickerOpen2)}
                          >
                            <DatePickerText>
                              {selectedEndDate
                                ? selectedEndDate.format('YYYY-MM-DD')
                                : 'Choose Date'}
                            </DatePickerText>
                            {dscElement2
                              ? createPortal(
                                <ShareDatePicker
                                  id='floating-datepicker-selector2'
                                  isDayPickerOpen={pickerOpen2}
                                  setIsDayPickerOpen={setPickerOpen2}
                                  activeDate={selectedEndDate}
                                  selectDate={handleSelectEndDate}
                                  disabledCondition={
                                      dashboard === 'Load Monitoring'
                                        ? () => false
                                        : disabledEndDate
                                    }
                                  right={100}
                                />,
                                dscElement2,
                              )
                              : null}
                          </DatePickerDiv2>
                        </DashDateContainer>
                      </DashDateWrapper>
                      <Slide2Block2>
                        <BlockText>Group</BlockText>
                        <Select
                          className='item-select'
                          value={localGroup || null}
                          options={groupOptions()}
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          }}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          onChange={(option) => {
                            const newErrors = { ...errors };
                            newErrors.errors[1].isShowing = false;
                            setErrors(newErrors);
                            setLocalGroup(option);
                          }}
                          placeholder='Select a group'
                        />
                      </Slide2Block2>
                      <DashDescriptionContainer>
                        <DashDescription>Description</DashDescription>
                        <DashDescriptionBox
                          value={dashDescription}
                          onChange={(e) => {
                            setDashDescription(e.target.value);
                          }}
                          placeholder='Description (optional)'
                        />
                      </DashDescriptionContainer>
                    </Slide>
                    {/* Second slide */}
                    <Slide>
                      <SlideCenterDiv>
                        {shareDashLink && !shareDashLinkError && (
                          <>
                            <DownloadLinkDescText>
                              This link is specific to this dashboard and will
                              expire in 30 days
                            </DownloadLinkDescText>
                            <DashLinkContainer>
                              <TextAndCopy>
                                <DashLink>Download Link</DashLink>
                                <animated.div style={copyAnimation}>
                                  <Text
                                    fontSize={18}
                                    style={{ margin: '0px 0px 0px 10px' }}
                                  >
                                    Copied Dashboard URL!
                                  </Text>
                                </animated.div>
                              </TextAndCopy>
                              <DashLinkBox
                                hasLink={!!shareDashLink}
                                onClick={
                                  shareDashLink
                                    ? copyToClipboard
                                    : () => undefined
                                }
                              >
                                {shareDashLink}
                              </DashLinkBox>
                            </DashLinkContainer>
                          </>
                        )}
                      </SlideCenterDiv>
                    </Slide>
                  </Slides>
                </SlidesContainer>
              </ContentContainer>
            </PortalContainer>
            <SlideIndicators>
              <SlideIndicator isActive={activeSlide === 0} />
              <SlideIndicator isActive={activeSlide === 1} />
            </SlideIndicators>
            <Button
              bottom
              fullWidth
              cta={activeSlide === 0 ? 'Create Link' : 'Done'}
              className='print-button'
              large
              square
              customTextColor='#FFFFFF'
              disabled={errors.errorsExist === true}
              noBorder
              primary
              isLoading={shareDashLinkLoading}
              onClick={() => {
                if (activeSlide === 0) {
                  if (dashTitle.length) {
                    if (localGroup && Object.keys(localGroup).length) {
                      handleSubmit();
                    } else {
                      const newErrors = { ...errors };
                      newErrors.errors[1].isShowing = true;
                      setErrors(newErrors);
                    }
                  } else {
                    const newErrors = { ...errors };
                    newErrors.errors[0].isShowing = true;
                    setErrors(newErrors);
                  }
                } else {
                  handleClose();
                }
              }}
            />
          </ButtonContainer>
        </RelativePortalContainer>
      </ModalContainer>
      <SharedTemplateModalContainer
        isModalShowing={isModalShowing}
        onClose={handleClose}
      />
    </>
  );
};

ShareDashboardModal.defaultProps = {
  dashboard: 'Wearables',
};

ShareDashboardModal.propTypes = {
  dashboard: PropTypes.string,
};

export default ShareDashboardModal;
