/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React, { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useSortBy, useTable } from 'react-table';
import { useSticky } from 'react-table-sticky';
import IcomoonReact from 'icomoon-react';

import iconSet from '../../../../shared/images/teambuildr-selection.json';
import { setCurrentQuestionInQuestionnairePicker } from '../../ducks/reportingActions';
import Tooltip from '../../../../shared/_TBComponents/Tooltip';
import { BoxShadow } from '../../../../shared/GlobalStyles';

const Styles = styled.div`
  font-family: nunito sans;
  max-height: 90vh;
  display: flex;
  width: 100%;
  position: relative;
  pointer-events: auto;

  & .sticky {
    box-shadow: -95px 0px 16px -95px rgb(48 49 51 / 5%), 95px 0px 16px -95px rgb(48 49 51 / 5%);
    border-bottom-left-radius: 24px;
    border-bottom-left-radius: 24px;
    overflow: auto;

     tbody {
      position: relative;
    }

    tfoot {
      td:first-child {
         position: sticky;
         left: 0;
      }
    }

    [data-sticky-td] {
      position: sticky;
    }
  }

  .athleteColumnHeaderCell {
    border-bottom: none;
    background: white;
    color: white;
    box-shadow: -18px 49px 6px white;
    z-index: 5!important;
  }

  .categoryColumnTitleCell {
    background-color: #424242;
    color: white;
    font-size: 16px;
    font-weight: 900;
    border-right: 8px solid #E0E0E0;
    cursor: pointer;
    min-width: 270px;
    max-width: 270px;
    position: sticky;
    top: 0;
    z-index: 4;
  }

  .categoryColumnTotalScoresTitleCell {
    background-color: #424242;
    color: white;
    font-size: 16px;
    font-weight: 900;
    border-top-right-radius: 24px;
    cursor: pointer;
    min-width: 270px;
    max-width: 270px;
    position: sticky;
    top: 0;
    z-index: 4;
  }

  .title {
    font-family: nunito sans;
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;
    background-color: #EBEBEB;
    color: #000000;
    text-align: center;
    cursor: pointer;
    position: sticky;
    top: ${(props) => (props.top)};
    z-index: 4;
  }

  .monthAverageTitle {
    font-family: nunito sans;
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;
    background-color: #EBEBEB;
    color: #000000;
    text-align: center;
    border-right: 8px solid #E0E0E0;
    cursor: pointer;
    position: sticky;
    top: ${(props) => (props.top)};
    z-index: 4;
  }

  .rawValueTitle {
    font-family: nunito sans;
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;
    background-color: #D7D7D7;
    color: #000000;
    text-align: center;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 90px;
    max-width: 90px;
    position: sticky;
    top: ${(props) => (props.top)};
    z-index: 4;
  }

  .athleteNameTitle {
    font-family: nunito sans;
    font-size: 15px;
    font-weight: 900;
    background-color: #EBEBEB;
    text-align: center;
    border-top-left-radius: 24px;
    color: #000000;
    font-size: 12px;
    cursor: pointer;
    min-width: 180px;
    max-width: 180px;
    z-index: 5!important;
  }

  .monthAverageFooterColumn {
    border-right: 8px solid #E0E0E0;
    font-size: 20px;
  }

  .footerCells {
    font-size: 20px;
  }

  table {
    border-spacing: 0;
    border-collapse: separate;

    thead {
      tr:first-child {
        th:last-child {
          border-top-right-radius: 24px;
        }
      }
    }

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      background: white;
      padding: 15px;
      border-bottom: 1px solid #E0E0E0;
      border-right: 1px solid #E0E0E0;
      text-align: center;

      :last-child {
        border-right: 0!important;
      }
    }

    tfoot {
      tr:first-child {
        box-shadow: 0px 8px 16px rgb(48 49 51 / 10%);
        border-bottom-left-radius: 24px;
        border-bottom-right-radius: 24px;

        td {
         border-top: 5px solid #E0E0E0;
         height: 61px;
         min-height: 61px;
         border-bottom: none;
        }
      }
      td:first-child {
         background: #EBEBEB;
         color: #000000;
         font-size: 12px;
         font-weight: 900;
         border-bottom-left-radius: 24px;
         border-bottom: none;
      }
      tr:last-child {
        display: none;
      }
      font-weight: 700;
    }
  }
`;

const Table = ({
  containerWidth,
  columns,
  data,
  getCellProps,
  getHeaderProps,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  },
  useSticky,
  useSortBy);

  /** Render the UI for your table */
  return (
    <>
      <div id='betaQuestionnaireReportTable' style={{ width: 'fit-content', maxWidth: '1530px' }} className={containerWidth < 485 ? null : 'sticky'}>
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup, i) => (
              <tr {...headerGroup.getHeaderGroupProps()} data-a-wrap>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps([
                      {
                        className: column.className,
                      },
                      getHeaderProps(column),
                    ])}
                    data-cols-width='30'
                    data-a-wrap
                    data-f-bold
                    data-fill-color={column?.style?.fillColor || 'FF424242'}
                    data-b-a-s='thin'
                    data-b-a-c='FF000000'
                    data-f-color={column?.style?.fillColor ? 'FF000000;' : 'FFFFFFFF'}
                    data-a-h='center'
                    data-a-v='center'
                  >
                    {column.render('Header')}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? (
                            <IcomoonReact
                              iconSet={iconSet}
                              size={10}
                              color='black'
                              icon='down-arrow'
                              style={{ marginLeft: '5px' }}
                            />
                          )
                          : (
                            <IcomoonReact
                              iconSet={iconSet}
                              size={10}
                              color='black'
                              icon='up-arrow'
                              style={{ marginLeft: '5px' }}
                            />
                          )
                        : ''}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td
                      {...cell.getCellProps([
                        {
                          style: cell.column.style,
                        },
                        getCellProps(cell),
                      ])}
                      data-fill-color={getCellProps(cell)?.style?.fillColor || null}
                      data-f-color={getCellProps(cell)?.style?.fontColor}
                      data-b-a-s='thin'
                      data-b-a-c='FF000000'
                      data-a-wrap
                    >
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            {footerGroups.map((group) => (
              <tr {...group.getFooterGroupProps()}>
                {group.headers.map((column) => (
                  <td
                    data-a-wrap
                    {...column.getFooterProps([{ className: column.Header === '28 Day Average' && column.id !== 'totalScoreMonthlyAverage' ? 'monthAverageFooterColumn' : 'footerCells' }])}
                  >
                    {column.render('Footer')}
                  </td>
                ))}
              </tr>
            ))}
          </tfoot>
        </table>
      </div>
    </>
  );
};

const QuestionnaireGrid = ({ containerWidth }) => {
  const dataRows = useSelector((state) => state.reporting.data.questionnaireReport.dataRows);
  const questionnaireReportFormResponses = useSelector((state) => state.reporting.data.questionnaireReportForm);
  const showAverages = questionnaireReportFormResponses?.showRangeAverages;
  const showTotals = questionnaireReportFormResponses?.showTotal;
  const teamAverageTotals = useSelector((state) => state.reporting.data.questionnaireReport.teamAverageTotals);

  const dispatch = useDispatch();

  const chartRef = useRef(null);
  const [headerAndSubHeaderHeight, setHeaderAndSubHeaderHeight] = useState(0);
  const [isScrollable, setIsScrollable] = useState(false);

  const handleScroll = (e) => {
    /** targetting the sticky container div scroll properties */
    const scrollWidth = chartRef.current?.childNodes[0]?.scrollWidth;
    const offsetWidth = chartRef.current?.childNodes[0]?.offsetWidth;
    const scrollLeft = chartRef.current?.childNodes[0]?.scrollLeft;
    setIsScrollable(offsetWidth + scrollLeft !== scrollWidth);
  };

  React.useLayoutEffect(() => {
    if (chartRef.current) {
      setHeaderAndSubHeaderHeight(chartRef.current.childNodes[0].childNodes[0].childNodes[0].clientHeight);
      const scrollWidth = chartRef.current.childNodes[0]?.scrollWidth;
      const offsetWidth = chartRef.current.childNodes[0]?.offsetWidth;
      const scrollLeft = chartRef.current.childNodes[0]?.scrollLeft;

      setIsScrollable(!(offsetWidth + scrollLeft <= scrollWidth));
    }
  }, []);

  const handleResize = () => {
    if (chartRef.current) {
      setHeaderAndSubHeaderHeight(chartRef.current.childNodes[0].childNodes[0].childNodes[0].clientHeight);
      const scrollWidth = chartRef.current.childNodes[0]?.scrollWidth;
      const offsetWidth = chartRef.current.childNodes[0]?.offsetWidth;
      const scrollLeft = chartRef.current.childNodes[0]?.scrollLeft;

      setIsScrollable(offsetWidth + scrollLeft !== scrollWidth);
    }
  };

  React.useEffect(() => {
    // Add resize event listener
    window.addEventListener('resize', handleResize);

    // Cleanup function to remove the event listener when the component is unmounted
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const formatColumns = () => {
    const formattedData = dataRows[0].questions.map((question, i) => ({
      Header: question.title,
      Footer: () => null,
      id: question.id,
      className: 'categoryColumnTitleCell',
      columns: [
        {
          Header: question.abbr,
          accessor: (row) => row.questions[i]?.response?.value,
          Cell: ({ row }) => {
            const name = row.original.name.replace(/\s/g, '');

            // Only use tooltip if it is a text response, numbers will fit in the cell
            if (typeof row.original.questions[i]?.response?.value === 'string' && row.original.questions[i]?.response?.value !== '-') {
              return (
                <Tooltip
                  displayColor={row.original.questions[i].response.textColor}
                  displaySize='12px'
                  displayText={row.original.questions[i].response.value}
                  hoverText={row.original.questions[i].response.value}
                  id={`${name}_${row.original.questions[i].abbr}`}
                  tooltipStyles={{
                    backgroundColor: '#FFFFFF',
                    boxShadow: BoxShadow,
                    color: '#444444',
                    fontSize: '12px',
                    zIndex: 10,
                  }}
                />
              );
            }

            return (
              <div style={{ color: row.original.questions[i]?.response?.textColor ?? '#444444', fontSize: '17px' }}>
                {row.original.questions[i]?.response?.value ?? '-'}
              </div>
            );
          },
          Footer: () => {
            const total = teamAverageTotals.questions.map((object) => object.response.value);
            return (
              <>
                {total[i]}
              </>
            );
          },
          id: question.id,
          className: 'rawValueTitle',
          style: {
            fontWeight: 700,
            fontSize: '17px',
            minWidth: '90px',
            maxWidth: '90px',
            fillColor: 'FFD7D7D7',
          },
        },
        ...(showAverages
          ? [
            {
              Header: '7 Day Average',
              accessor: (row) => row.questions[i]?.weekAverage?.value,
              Cell: ({ row }) => (
                <div>
                  {row.original.questions[i]?.weekAverage?.value ?? '-'}
                </div>
              ),
              Footer: () => {
                const total = teamAverageTotals.questions.map((object) => object.weekAverage.value);
                return (
                  <>
                    {total[i]}
                  </>
                );
              },
              id: `${question.id} WeekAverage`,
              className: 'title',
              style: {
                fontWeight: 700,
                fontSize: '17px',
                minWidth: '90px',
                maxWidth: '90px',
                fillColor: 'FFD7D7D7',
              },
            },
            {
              Header: '28 Day Average',
              accessor: (row) => row.questions[i]?.monthAverage?.value,
              Cell: ({ row }) => (
                <div>
                  {row.original.questions[i]?.monthAverage?.value ?? '-'}
                </div>
              ),
              Footer: () => {
                const total = teamAverageTotals.questions.map((object) => object.monthAverage.value);
                return (
                  <>
                    {total[i]}
                  </>
                );
              },
              id: `${question.id} MonthAverage`,
              className: 'monthAverageTitle',
              style: {
                /** style for the whole column
                 * className is style for just the header
                */
                borderRight: '8px solid #E0E0E0',
                fontWeight: 700,
                fontSize: '17px',
                minWidth: '90px',
                maxWidth: '90px',
                fillColor: 'FFD7D7D7',
              },
            },
          ]
          : []),
      ],
    }));
    return formattedData;
  };

  const athleteNameColumn = [
    {
      Header: 'Name',
      Footer: () => null,
      id: 'name',
      sticky: containerWidth < 485 ? () => null : 'left',
      className: 'athleteColumnHeaderCell',
      columns: [
        {
          Header: 'Athlete',
          accessor: 'name',
          Footer: 'Team Average',
          className: 'athleteNameTitle',
          style: {
            /** style for the whole column
             * className is style for just the header
            */
            borderBottom: '1px solid #E0E0E0',
            borderRight: '1px solid #E0E0E0',
            boxShadow: '0px 0px 16px rgb(48 49 51 / 5%)',
            color: '#444444',
            fillColor: 'FFD7D7D7',
            fontSize: '12px',
            fontWeight: 700,
            textWrap: 'nowrap',
            zIndex: 5,
          },
          Cell: ({ row }) => (
            <div>
              {row.original.name}
            </div>
          ),
        },
      ],
    },
  ];

  const totalScoresColumn = [
    {
      Header: 'Total Scores',
      Footer: () => null,
      id: 'totalScores',
      className: 'categoryColumnTotalScoresTitleCell',
      columns: [
        {
          Header: 'Total Score',
          accessor: (row) => row.totalScores.value.value,
          Footer: () => {
            const total = teamAverageTotals.totalScores.value;
            return (
              <>
                {total}
              </>
            );
          },
          id: 'totalScoreRawValue',
          className: 'rawValueTitle',
          style: {
            fontWeight: 700,
            fontSize: '17px',
            minWidth: '90px',
            maxWidth: '90px',
            fillColor: 'FFD7D7D7',
          },
        },
        ...(showAverages
          ? [
            {
              Header: '7 Day Average',
              accessor: (row) => row.totalScores.pastWeekAverageTotal.value,
              Footer: () => {
                const total = teamAverageTotals.totalScores.pastWeekAverageTotal;
                return (
                  <>
                    {total}
                  </>
                );
              },
              id: 'totalScoreWeeklyAverage',
              className: 'title',
              style: {
                fontWeight: 700,
                fontSize: '17px',
                minWidth: '90px',
                maxWidth: '90px',
                fillColor: 'FFD7D7D7',
              },
            },
            {
              Header: '28 Day Average',
              accessor: (row) => row.totalScores.pastMonthAverageTotal.value,
              Footer: () => {
                const total = teamAverageTotals.totalScores.pastMonthAverageTotal;
                return (
                  <>
                    {total}
                  </>
                );
              },
              id: 'totalScoreMonthlyAverage',
              className: 'title',
              style: {
                fontWeight: 700,
                fontSize: '17px',
                minWidth: '90px',
                maxWidth: '90px',
                fillColor: 'FFD7D7D7',
              },
            },
          ]
          : []
        ),
      ],
    },
  ];

  const formattedMiddleColumns = formatColumns();

  const columns = showTotals ? [...athleteNameColumn, ...formattedMiddleColumns, ...totalScoresColumn] : [...athleteNameColumn, ...formattedMiddleColumns];

  const memoizedColumns = React.useMemo(
    () => columns,
    [dataRows, teamAverageTotals, containerWidth],
  );
  const memoizedDataRows = React.useMemo(
    () => dataRows,
    [dataRows, teamAverageTotals, containerWidth],
  );

  const getCellColor = (cellInfo) => {
    const columnId = cellInfo?.column?.id;
    const rowQuestionArray = cellInfo?.row?.original.questions;
    const currentCellResponseInfoArray = rowQuestionArray?.filter((question) => question.id === columnId);
    const cellBackGroundColor = columnId === 'totalScoreRawValue' ? cellInfo.row.original.totalScores.value.backgroundColor : currentCellResponseInfoArray[0]?.response.backgroundColor;
    return cellBackGroundColor;
  };

  const getCellTextColor = (cellInfo) => {
    if ((cellInfo.column.Header === '7 Day Average' || cellInfo.column.Header === '28 Day Average') && cellInfo.value === '-') {
      return '#444444';
    }
    if (cellInfo.column.Header === 'Total Score' && cellInfo.value === '-') {
      return '#444444';
    }
    if ((cellInfo.column.id === 'totalScoreMonthlyAverage' || cellInfo.column.id === 'totalScoreWeeklyAverage') && cellInfo.value === '-') {
      return '#444444';
    }
    const columnId = cellInfo?.column?.id;
    const rowQuestionArray = cellInfo?.row?.original.questions;
    const currentCellResponseInfoArray = rowQuestionArray?.filter((question) => question.id === columnId);
    const cellTextColor = columnId === 'totalScoreRawValue' ? cellInfo.row.original.totalScores.value.textColor : currentCellResponseInfoArray[0]?.response.textColor;
    return cellTextColor;
  };

  const getCellFillColor = (cellInfo) => {
    const cellBackGroundColor = getCellColor(cellInfo);
    // Convert RGB -> ARGB so '#d7d7d7' becomes 'FFD7D7D7'
    return cellBackGroundColor?.toUpperCase().replace('#', 'FF');
  };

  const getCellFontColor = (cellInfo) => {
    const cellTextColor = getCellTextColor(cellInfo);
    // Convert RGB -> ARGB so '#d7d7d7' becomes 'FFD7D7D7'
    return cellTextColor?.toUpperCase().replace('#', 'FF');
  };

  const handleHeaderClick = (headerInfo) => {
    if (headerInfo.className === 'categoryColumnTitleCell' || headerInfo.className === 'categoryColumnTotalScoresTitleCell') {
      const id = headerInfo.Header === 'Total Scores' ? 'Total Scores' : parseInt(headerInfo.id.split('_')[0], 10);
      const displayText = headerInfo.Header === 'Total Scores' ? 'Total Scores?' : headerInfo.Header;

      dispatch(setCurrentQuestionInQuestionnairePicker({ id, displayText }));
    } else {
      headerInfo.toggleSortBy();
    }
  };

  const getStickyTopVal = () => {
    const headerHeight = chartRef?.current?.childNodes[0]?.childNodes[0]?.childNodes[0]?.childNodes[0]?.clientHeight;
    return `${headerHeight}px`;
  };

  return (
    <>
      <Styles className='stylesDiv' ref={chartRef} onScroll={handleScroll} isScrollable={isScrollable} headerAndSubHeaderHeight={headerAndSubHeaderHeight} top={getStickyTopVal}>
        <Table
          containerWidth={containerWidth}
          columns={memoizedColumns}
          data={memoizedDataRows}
          getCellProps={(cellInfo) => ({
            style: {
              backgroundColor: getCellColor(cellInfo),
              color: getCellTextColor(cellInfo),
              fillColor: getCellFillColor(cellInfo),
              fontColor: getCellFontColor(cellInfo),
            },
          })}
          getHeaderProps={(headerInfo) => ({
            onClick: () => ((headerInfo.Header.includes('Average')) || (headerInfo.Header.includes('Total Score')) || (headerInfo.id === 'totalScoreMonthlyAverage') || (headerInfo.id === 'totalScoreWeeklyAverage') || (headerInfo.Header === 'Name') ? null : handleHeaderClick(headerInfo)),
          })}
        />

      </Styles>
    </>
  );
};

export default QuestionnaireGrid;
