export const FETCH_GROUPS_START = 'FETCH_GROUPS_START';
export const FETCH_GROUPS_FULFILLED = 'FETCH_GROUPS_FULFILLED';
export const FETCH_GROUPS_REJECTED = 'FETCH_GROUPS_START';

export const FETCH_PERFORMANCE_OVERVIEW_START = 'FETCH_PERFORMANCE_OVERVIEW_START';
export const FETCH_PERFORMANCE_OVERVIEW_FULFILLED = 'FETCH_PERFORMANCE_OVERVIEW_FULFILLED';
export const FETCH_PERFORMANCE_OVERVIEW_REJECTED = 'FETCH_PERFORMANCE_OVERVIEW_REJECTED';

export const FETCH_METRIC_CARD_DATA_START = 'FETCH_METRIC_CARD_DATA_START';
export const FETCH_METRIC_CARD_DATA_FULFILLED = 'FETCH_METRIC_CARD_DATA_FULFILLED';
export const FETCH_METRIC_CARD_DATA_REJECTED = 'FETCH_METRIC_CARD_DATA_REJECTED';

export const FETCH_GROUP_ANALYSIS_START = 'FETCH_GROUP_ANALYSIS_START';
export const FETCH_GROUP_ANALYSIS_FULFILLED = 'FETCH_GROUP_ANALYSIS_FULFILLED';
export const FETCH_GROUP_ANALYSIS_REJECTED = 'FETCH_GROUP_ANALYSIS_REJECTED';

export const SET_IS_METRIC_INFO_MODAL_SHOWING = 'SET_IS_METRIC_INFO_MODAL_SHOWING';

export const SET_IS_FP_LEGEND_INFO_MODAL_SHOWING = 'SET_IS_FP_LEGEND_INFO_MODAL_SHOWING';

export const SET_SELECTED_ATHLETE_PERFORMANCE = 'SET_SELECTED_ATHLETE_PERFORMANCE';
export const SET_SELECTED_ATHLETE_GROUP = 'SET_SELECTED_ATHLETE_GROUP';

export const SET_IS_METRIC_FILTERS_GROUP_SHOWING = 'SET_IS_METRIC_FILTERS_GROUP_SHOWING';
export const SET_IS_METRIC_FILTERS_PERFORMANCE_SHOWING = 'SET_IS_METRIC_FILTERS_PERFORMANCE_SHOWING';

export const SET_METRIC_FILTERS_PERFORMANCE = 'SET_METRIC_FILTERS_PERFORMANCE';
export const SET_METRIC_FILTERS_GROUP = 'SET_METRIC_FILTERS_GROUP';

export const SET_KEY_PERFORMANCE_METRICS = 'SET_KEY_PERFORMANCE_METRICS';

export const SET_GROUP_FILTER_RADIO = 'SET_GROUP_FILTER_RADIO';

export const SET_START_DATE_DATA = 'SET_START_DATE_DATA';
export const SET_END_DATE_DATA = 'SET_END_DATE_DATA';

export const SET_START_DATE_CAL = 'SET_START_DATE_CAL';
export const SET_END_DATE_CAL = 'SET_END_DATE_CAL';

export const SET_GROUP = 'SET_GROUP';

export const SET_GROUPS = 'SET_GROUPS';

export const SET_DAY_RANGE = 'SET_DAY_RANGE';

export const SET_FORCE_PLATE_VIEW = 'SET_FORCE_PLATE_VIEW';

export const SET_SHOW_FLAGGED_ATHLETES_MODAL_PERFORMANCE = 'SET_SHOW_FLAGGED_ATHLETES_MODAL_PERFORMANCE';
export const SET_SHOW_FLAGGED_ATHLETES_MODAL_GROUP = 'SET_SHOW_FLAGGED_ATHLETES_MODAL_GROUP';

export const SET_FLAGGED_ATHLETES_VIEW = 'SET_FLAGGED_ATHLETES_VIEW';

export const SET_FLAGGED_ATHLETES = 'SET_FLAGGED_ATHLETES';
export const SET_FLAGGED_ATHLETES_PERFORMANCE = 'SET_FLAGGED_ATHLETES_PERFORMANCE';
export const SET_FLAGGED_ATHLETES_GROUP = 'SET_FLAGGED_ATHLETES_GROUP';

export const SET_NEW_FP_DASH = 'SET_NEW_FP_DASH';
export const FETCH_FP_TEMPLATES_START = 'FETCH_FP_TEMPLATES_START';
export const FETCH_FP_TEMPLATES_FULFILLED = 'FETCH_FP_TEMPLATES_FULFILLED';
export const FETCH_FP_TEMPLATES_REJECTED = 'FETCH_FP_TEMPLATES_REJECTED';
export const SET_ACTIVE_FP_DASH = 'SET_ACTIVE_FP_DASH';
export const CLEAR_ACTIVE_FP_DASH = 'CLEAR_ACTIVE_FP_DASH';

export const SET_SAVE_FP_TEMPLATE_MODAL = 'SET_SAVE_FP_TEMPLATE_MODAL';
export const CREATE_FP_TEMPLATE_START = 'CREATE_FP_TEMPLATE_START';
export const CREATE_FP_TEMPLATE_FULFILLED = 'CREATE_FP_TEMPLATE_FULFILLED';
export const CREATE_FP_TEMPLATE_REJECTED = 'CREATE_FP_TEMPLATE_REJECTED';
export const SET_IS_UNSAVED_FP_DASH_MODAL = 'SET_IS_UNSAVED_FP_DASH_MODAL';

export const SET_EDIT_FP_DASH_MODAL = 'SET_EDIT_FP_DASH_MODAL';
export const EDIT_FP_TEMPLATE_START = 'EDIT_FP_TEMPLATE_START';
export const EDIT_FP_TEMPLATE_FULFILLED = 'EDIT_FP_TEMPLATE_FULFILLED';
export const EDIT_FP_TEMPLATE_REJECTED = 'EDIT_FP_TEMPLATE_REJECTED';

export const SET_DELETE_FP_TEMPLATE_MODAL = 'SET_DELETE_FP_TEMPLATE_MODAL';
export const DELETE_FP_TEMPLATE_START = 'DELETE_FP_TEMPLATE_START';
export const DELETE_FP_TEMPLATE_FULFILLED = 'DELETE_FP_TEMPLATE_FULFILLED';
export const DELETE_FP_TEMPLATE_REJECTED = 'DELETE_FP_TEMPLATE_REJECTED';

export const SET_SHARE_FP_DASH_MODAL = 'SET_SHARE_FP_DASH_MODAL';
export const FETCH_SHARE_FP_DASH_LINK_START = 'FETCH_SHARE_FP_DASH_LINK_START';
export const FETCH_SHARE_FP_DASH_LINK_FULFILLED = 'FETCH_SHARE_FP_DASH_LINK_FULFILLED';
export const FETCH_SHARE_FP_DASH_LINK_REJECTED = 'FETCH_SHARE_FP_DASH_LINK_REJECTED';
export const CLEAR_SHARE_FP_DASH_LINK = 'CLEAR_SHARE_FP_DASH_LINK';

export const FETCH_PUBLIC_FP_PERFORMANCE_CHART_DATA_START = 'FETCH_PUBLIC_FP_PERFORMANCE_CHART_DATA_START';
export const FETCH_PUBLIC_FP_PERFORMANCE_CHART_DATA_FULFILLED = 'FETCH_PUBLIC_FP_PERFORMANCE_CHART_DATA_FULFILLED';
export const FETCH_PUBLIC_FP_PERFORMANCE_CHART_DATA_REJECTED = 'FETCH_PUBLIC_FP_PERFORMANCE_CHART_DATA_REJECTED';

export const FETCH_PUBLIC_FP_PERFORMANCE_CARD_DATA_START = 'FETCH_PUBLIC_FP_PERFORMANCE_CARD_DATA_START';
export const FETCH_PUBLIC_FP_PERFORMANCE_CARD_DATA_FULFILLED = 'FETCH_PUBLIC_FP_PERFORMANCE_CARD_DATA_FULFILLED';
export const FETCH_PUBLIC_FP_PERFORMANCE_CARD_DATA_REJECTED = 'FETCH_PUBLIC_FP_PERFORMANCE_CARD_DATA_REJECTED';

export const FETCH_PUBLIC_FP_GROUP_ANALYSIS_DATA_START = 'FETCH_PUBLIC_FP_GROUP_ANALYSIS_DATA_START';
export const FETCH_PUBLIC_FP_GROUP_ANALYSIS_DATA_FULFILLED = 'FETCH_PUBLIC_FP_GROUP_ANALYSIS_DATA_FULFILLED';
export const FETCH_PUBLIC_FP_GROUP_ANALYSIS_DATA_REJECTED = 'FETCH_PUBLIC_FP_GROUP_ANALYSIS_DATA_REJECTED';

export const SET_DUPLICATE_FP_DASH_MODAL = 'SET_DUPLICATE_FP_DASH_MODAL';
export const DUPLICATE_FP_DASH_START = 'DUPLICATE_FP_DASH_START';
export const DUPLICATE_FP_DASH_FULFILLED = 'DUPLICATE_FP_DASH_FULFILLED';
export const DUPLICATE_FP_DASH_REJECTED = 'DUPLICATE_FP_DASH_REJECTED';

export const SET_FP_TRI_OPTION_MODAL = 'SET_FP_TRI_OPTION_MODAL';
export const SET_ABOUT_FP_DASH_MODAL = 'SET_FP_ABOUT_DASH_MODAL';
export const SET_NEXT_LOCATION = 'SET_NEXT_LOCATION';
export const CLEAR_FP_DASH_DATA = 'CLEAR_FP_DASH_DATA';

export const SET_IS_FP_DASH_DISPLAYED = 'SET_IS_FP_DASH_DISPLAYED';
export const CLEAR_FP_DASH_MODALS = 'CLEAR_FP_DASH_MODALS';
