/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-shadow */
/* eslint-disable max-len */
import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FieldArray } from 'formik';
import PropTypes from 'prop-types';
import IcomoonReact from 'icomoon-react';
import styled from '@emotion/styled';
import Logger from 'js-logger';
import moment from 'moment';
import Select from 'react-select';

import TBDatePicker from '../../../../shared/components/TBDatePicker/TBDatePicker';
import Text from '../../../../shared/components/Text/Text';
import Toggle from '../../../../shared/components/Toggle/Toggle';
import Button from '../../../../shared/components/Button/Button';
import FormLabel from '../../../../shared/components/FormLabel/FormLabel';
import Link from '../../../../shared/components/Link/Link';
import iconSet from '../../../../shared/images/teambuildr-selection.json';
import { dashboardTracker } from '../../../../shared/utils/amplitudeHelper';

const ToggleRow = styled('div')`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 20px;
  gap: 30px;
  flex-wrap: ${(props) => props.containerWidth && props.containerWidth < 750 && 'wrap'};
  margin-top: 30px;

  input {
    &.reportNameInput {
      background: #ffffff;
      box-shadow: 0px 1px 2px rgba(99, 95, 111, 0.2);
      border-radius: 4px;
      border: none;
      color: black;
      padding: 8px;
    }
  }
`;

const ToggleLabelContainer = styled('div')`
  display: flex;
  align-items: center;
  gap: 15px;
`;

const IndividualQuestionsFormGroup = styled('div')`
  width: 100%;
  background: ${(props) => (props.containerWidth < 424 ? '#F5F3F3' : '#FFFFFF')};
  box-shadow: ${(props) => (props.containerWidth < 424 ? 'none' : '0px 8px 16px rgba(48, 49, 51, 0.1)')};
  border-radius: 10px;
  padding: ${(props) => (props.containerWidth < 424 ? '5px' : '25px')};
  padding-bottom: ${(props) => props.containerWidth < 425 && '15px'};
  margin-bottom: 30px;

  label {
    padding-bottom: 8px;
  }

  button {
    margin-top: 8px;
  }
`;

const FormGroup = styled('div')`
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 8px 16px rgba(48, 49, 51, 0.1);
  border-radius: 10px;
  padding: ${(props) => (props.containerWidth < 424 ? '5px' : '25px')};
  margin-bottom: 30px;

  label {
    padding-bottom: 8px;
  }

  button {
    margin-top: 8px;
  }
`;

const FieldError = styled('div')`
  font-size: 14px;
  color: #ff6600;
`;

const LinkContainer = styled('div')`
  width: 100%;
  margin-top: 20px;

  @media screen and (max-width: 540px) {
    button {
      margin-left: 0px !important;
    }
  }
`;

const FormColumns = styled('div')`
  label {
    margin-bottom: 0px;
  }

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(210px, 1fr));
  grid-gap: ${(props) => (props.containerWidth < 424 ? '10px' : '25px')};
`;

const ColumnContent = styled('div')`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 15px 15px 0px 0px;
  position: relative;

  background: #ffffff;
  box-shadow: 0px 8px 16px rgba(48, 49, 51, 0.1);
  border-radius: 10px;
  padding: 20px;

  .remove-link {
    position: absolute;
    right: 0;
    margin-right: 16px;
  }

  @media screen and (max-width: 540px) {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }
`;
const FieldErrorAndQuestionsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
`;

const CardContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 15px;
`;

const TextContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
  padding-left: 5px;
  padding-right: 5px;
`;

const QuestionnaireOptionCard = styled('div')`
  display: flex;
  background: #ffffff;
  box-shadow: 0px 8px 16px rgba(48, 49, 51, 0.1);
  border-radius: 10px;
  padding: 20px;
  justify-content: space-between;
  margin-bottom: 15px;
  flex-wrap: wrap;
  cursor: pointer;

  :hover {
    background-color: #e0e0e0;
  }
  &.active {
    background-color: #e0e0e0;
  }
`;

const Column = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 15px;
  min-width: 50%;
  flex-wrap: wrap;
`;

const Wrapper = styled('div')`
  display: flex;
  width: 100%;
  background-color: #e8e8e8;
  border-radius: 20px;
  align-items: center;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  gap: 5px;
`;

const SearchBarContainer = styled('div')`
  display: flex;
  width: 100%;
  padding: 10px;
  border-radius: inherit;
  cursor: pointer;
  position: sticky;
  top: 0;
  background: white;
  h3 {
    color: black;
  }
`;
const StyledInput = styled('input')`
  width: 90%;
  background-color: #e8e8e8;
  border-width: 0px;
  ::placeholder {
    font-family: "Nunito Sans";
    font-size: 16px;
  }
`;

const FormPage2 = ({
  formProps,
  questions,
  questionnaires,
  containerWidth,
}) => {
  const loadMonitoringFormValues = useSelector(
    (state) => state.loadMonitoringDashboard.data.loadMonitoringFormValues,
  );
  useEffect(() => {
    /** watch for updates in the questions being chosen and deleted, update array of questions
     * based on which question id objects have values, those are the values to pass to the api
     * and those are the values we are validating
     */
    if (formProps.values.selectionType === 'selectIndividualQuestions') {
      const filteredArray = formProps.values.questionIds.filter(
        (question) => question.id !== '',
      );
      formProps.setFieldValue('arrayOfQuestions', filteredArray);
    }
  }, [formProps.values.questionIds]);

  const [activeDate, setActiveDate] = useState(formProps.values.date?.date);

  const setActiveDateAndFormValueDate = (date) => {
    setActiveDate(date);
    formProps.setFieldValue('date', {
      date,
      queryFormat: moment(date).format('YYYY-MM-DD'),
    });
  };

  const questionLimit = questions.length >= 9 ? 9 : questions.length;

  /** filter the options to make sure a user can't select the same quesiton 2x */
  const filteredOptionsArray = questions.filter(
    (question) => !formProps.values.questionIds.includes(question),
  );

  const conditionalSelectionTypeText = formProps.values.selectionType === 'selectAllQuestions'
    || (loadMonitoringFormValues.selectionType
      && loadMonitoringFormValues.selectionType === 'selectAllQuestions')
    ? 'Select Questionnaire'
    : 'Select Previously Saved Report';

  const handleOptionCardClick = async (questionnaire) => {
    // // Use async/await to wait for formProps.submitForm() to complete

    // // Any code you want to run after form submission
    const promises = [
      formProps.setFieldValue('arrayOfQuestions', questionnaire.questions),
      formProps.setFieldValue('questionnaireIds', [questionnaire.id]),
      formProps.setFieldValue('questionnaire', questionnaire),
      formProps.setFieldValue('selectionType', 'selectAllQuestions'),
    ];

    // Use Promise.all to wait for all promises to resolve
    Promise.all(promises).then(() => {
      // After all setFieldValue operations are completed, submit the form
      formProps.submitForm();
    });
  };

  const [filteredArray, setFilteredArray] = useState(questionnaires);
  const [searchWord, setSearchWord] = useState('');

  const handleFilter = (event) => {
    const localsearchWord = event.target.value;
    setSearchWord(event.target.value);
    const filteredData = questionnaires?.filter((item) => item.name?.toLowerCase().includes(localsearchWord.toLowerCase()));
    setFilteredArray(filteredData);
  };

  const resetList = () => {
    setSearchWord('');
    setFilteredArray(questionnaires);
  };

  return (
    <>
      <Text fontSize={20} fontWeight={700} style={{ marginTop: 25 }}>
        Select Dashboard Settings
      </Text>
      <ToggleRow containerWidth={containerWidth}>
        <Column>
          <Text fontWeight={700} fontSize='14px'>
            Select Date
          </Text>
          <TBDatePicker
            activeDate={activeDate}
            setActiveDate={setActiveDateAndFormValueDate}
            additionalStyles={{ justifyContent: 'flex-start', margin: '0px' }}
          />
        </Column>
        <Column>
          <Text fontSize={14} fontWeight={700}>
            Select Options
          </Text>
          <ToggleLabelContainer>
            <Toggle
              checked={formProps.values.showTotal}
              icons={false}
              onChange={() => {
                formProps.setFieldValue(
                  'showTotal',
                  formProps.values.showTotal === false && true,
                );
              }}
              value={!formProps.values.showTotal && true}
            />
            <Text fontSize={13}>Show Total</Text>
          </ToggleLabelContainer>
          <ToggleLabelContainer>
            <Toggle
              checked={formProps.values.showRangeAverages}
              icons={false}
              onChange={() => {
                formProps.setFieldValue(
                  'showRangeAverages',
                  formProps.values.showRangeAverages === false && true,
                );
              }}
              value={!formProps.values.showRangeAverages && true}
            />
            <Text fontSize={13}>Show Averages</Text>
          </ToggleLabelContainer>
        </Column>
      </ToggleRow>
      {formProps.values.selectionType === 'selectIndividualQuestions'
      || (!formProps?.values?.selectionType
        && loadMonitoringFormValues?.selectionType
        && loadMonitoringFormValues?.selectionType
          === 'selectIndividualQuestions') ? (
            <>
              <Text style={{ marginBottom: '20px' }} fontSize={20} fontWeight={700}>
                Select Individual Questions
              </Text>
              <Text style={{ marginBottom: '30px' }}>
                You may select up to 10 questions. When the report is generated
                please use the checkboxes in the table to toggle between available
                questions. Charts and tables will be shown based on the
                {' '}
                <strong>date completed</strong>
                .
              </Text>
              <IndividualQuestionsFormGroup containerWidth={containerWidth}>
                <FieldErrorAndQuestionsContainer>
                  <FieldArray
                    name='questionIds'
                    render={(arrayHelpers) => (
                      <>
                        <FormColumns containerWidth={containerWidth}>
                          {formProps.values.questionIds.length > 0
                        && formProps.values.questionIds?.map(
                          (questionIdValue, index) => (
                            <ColumnContent
                              key={index}
                              numberOfQuestions={
                                formProps.values.questionIds.length
                              }
                            >
                              <FormLabel>
                                Question #
                                {index + 1}
                              </FormLabel>
                              {index !== 0 && (
                                <Link
                                  className='remove-link'
                                  onClick={(e) => {
                                    e.preventDefault();
                                    arrayHelpers.remove(index);
                                  }}
                                  type='button'
                                >
                                  &#10005;
                                </Link>
                              )}
                              <Select
                                options={filteredOptionsArray}
                                getOptionLabel={(option) => option.title}
                                getOptionValue={(option) => option.id}
                                name='question'
                                onChange={(option) => {
                                  formProps.setFieldValue(
                                    `questionIds.${index}`,
                                    option,
                                  );
                                  Logger.debug(
                                    'Questions Array',
                                    formProps.values.questionIds,
                                  );
                                }}
                                isOptionSelected={(option) => formProps.values.questionId === option.id}
                                value={
                                  formProps.values.questionIds[index].id === ''
                                    ? null
                                    : formProps.values.questionIds[index]
                                }
                              />
                            </ColumnContent>
                          ),
                        )}
                        </FormColumns>
                        {formProps.values.questionIds.length <= questionLimit && (
                        <LinkContainer>
                          <Button
                            onClick={(e) => {
                              e.preventDefault();
                              arrayHelpers.push({ title: '', id: '' });
                            }}
                            cta='Add Question'
                            icon='plus'
                          />
                        </LinkContainer>
                        )}
                      </>
                    )}
                  />
                  {formProps.errors.arrayOfQuestions
                && formProps.touched.arrayOfQuestions && (
                  <FieldError
                    style={{ marginTop: '15px' }}
                    className='text-center'
                  >
                    {formProps.errors.arrayOfQuestions}
                  </FieldError>
                  )}
                </FieldErrorAndQuestionsContainer>
              </IndividualQuestionsFormGroup>
            </>
        ) : (
          <>
            <Text
              style={{ marginBottom: '0px', marginTop: '0px' }}
              fontSize={20}
              fontWeight={700}
            >
              {conditionalSelectionTypeText}
            </Text>
            <Text style={{ marginBottom: '30px' }}>
              Select questionnaire to generate dashboard. Charts and tables will
              be shown based on the
              {' '}
              <strong>date completed</strong>
              .
            </Text>
            <FormGroup containerWidth={containerWidth}>
              <TextContainer>
                <SearchBarContainer>
                  <Wrapper onClick={(e) => e.stopPropagation()}>
                    <IcomoonReact
                      iconSet={iconSet}
                      size={14}
                      icon='magnifying-glass-alt'
                      color='#444444'
                    />
                    <StyledInput
                      key='searchinput'
                      type='search'
                      placeholder='search questionnaires'
                      onChange={handleFilter}
                      value={searchWord}
                      autoFocus='autoFocus'
                    />
                    {searchWord && (
                    <IcomoonReact
                      onClick={() => resetList()}
                      iconSet={iconSet}
                      size={14}
                      icon='remove'
                      color='#444444'
                    />
                    )}
                  </Wrapper>
                </SearchBarContainer>
              </TextContainer>
              {(formProps.values.selectionType === 'selectAllQuestions'
              || (!formProps?.values?.selectionType
                && loadMonitoringFormValues.selectionType
                && loadMonitoringFormValues.selectionType
                  === 'selectAllQuestions')) && (
                  <CardContainer>
                    {filteredArray.map((questionnaire) => (
                      <QuestionnaireOptionCard
                        key={questionnaire.id}
                        onClick={() => {
                          handleOptionCardClick(questionnaire);
                          // Amplitude tracking
                          dashboardTracker(
                            'Load Monitoring',
                            'Run Questionnaire Report',
                            'Run report button',
                          );
                        }}
                        className={
                      (questionnaire?.id
                        === formProps.values.questionnaire?.id
                        || questionnaire?.id
                          === loadMonitoringFormValues.questionnaire?.id)
                      && 'active'
                    }
                      >
                        <Text>{questionnaire.name}</Text>
                        <Text style={{ display: 'none' }}>1 week ago</Text>
                      </QuestionnaireOptionCard>
                    ))}
                  </CardContainer>
              )}
              {formProps.errors.arrayOfQuestions
              && formProps.touched.arrayOfQuestions && (
                <FieldError
                  style={{ marginTop: '15px' }}
                  className='text-center'
                >
                  Please select a report
                </FieldError>
              )}
            </FormGroup>
          </>
        )}
    </>
  );
};

FormPage2.propTypes = {
  formProps: PropTypes.instanceOf(Object).isRequired,
  questions: PropTypes.instanceOf(Array),
  questionnaires: PropTypes.instanceOf(Array),
};

FormPage2.defaultProps = {
  questions: [],
  questionnaires: [],
};

export default FormPage2;
