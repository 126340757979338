/* eslint-disable no-plusplus */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import moment from 'moment';
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LineChart,
} from 'recharts';

import { metricOptions, metricUnits } from '../../metricConstants';
import {
  fetchMetricCardData,
  fetchPerformanceOverview,
  setDayRange,
  setEndDateCal,
  setEndDateData,
  setStartDateCal,
  setStartDateData,
} from '../../ducks/forcePlateDashboardActions';
import Spinner from '../../../../shared/components/Spinner/Spinner';
import { dashboardTracker } from '../../../../shared/utils/amplitudeHelper';

const ChartContainer = styled('div')`
  width: ${(props) => (props.readyPrint ? '960px' : '100%')};
  height: ${(props) => (props.readyPrint ? '640px' : '90%')};
  filter: ${(props) => (props.isBlurred ? 'blur(1.2px)' : null)};

  .custom-tooltip {
    background-color: #ffffff;
    padding: 8px 16px;
    border: solid 1px #eeeeee;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-width: 250px;
    justify-content: flex-start;
    font-family: "Nunito Sans";
    font-size: 13px;
    font-weight: normal;
  }
`;

const DaySelector = styled('div')`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
`;

const DayOption = styled('button')`
  border: none;
  font-family: "Nunito Sans";
  font-size: 16px;
  color: ${(props) => (!props.isActive ? '#9E9E9E' : null)};
  cursor: ${(props) => (props.readOnly ? 'default !important' : 'pointer')};
`;

const DaySpacer = styled('span')`
  color: #9e9e9e;
  font-family: "Nunito Sans";
  font-size: 16px;
  margin: 0px 5px;
`;

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className='custom-tooltip'>
        <p style={{ fontWeight: 'bold' }}>{label}</p>
        {payload.map((dataPoint) => (
          <p
            key={dataPoint.name}
            style={{ color: dataPoint.formatter || dataPoint.color }}
          >
            {`${dataPoint.name}: `}
            <span style={{ color: '#444444' }}>{dataPoint.value}</span>
          </p>
        ))}
      </div>
    );
  }

  return null;
};

const PerformanceOverviewChart = ({ readyPrint = false }) => {
  const selectedAthletePerformance = useSelector(
    (state) => state.forcePlateDashboard.data.selectedAthletePerformance,
  );
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const dayRange = useSelector(
    (state) => state.forcePlateDashboard.data.dayRange,
  );
  const endDateData = useSelector(
    (state) => state.forcePlateDashboard.data.endDateData,
  );
  const group = useSelector((state) => state.forcePlateDashboard.data.group);
  const isPerformanceLoading = useSelector(
    (state) => state.forcePlateDashboard.ui.isPerformanceLoading,
  );
  const metricFiltersPerformance = useSelector(
    (state) => state.forcePlateDashboard.data.metricFiltersPerformance,
  );
  const keyPerformanceMetrics = useSelector(
    (state) => state.forcePlateDashboard.data.keyPerformanceMetrics,
  );
  const performanceOverviewData = useSelector(
    (state) => state.forcePlateDashboard.data.performanceOverviewData,
  );
  const isReadOnlyView = useSelector(
    (state) => state.forcePlateDashboard.ui.isReadOnlyView,
  );

  const hasGroupData = performanceOverviewData.length > 0;
  const data = hasGroupData && [...performanceOverviewData];

  // Order and format the dates in the return data and add back athlete data
  const orderedData = hasGroupData && data.sort((a, b) => new Date(a.date) - new Date(b.date));
  const formattedData = orderedData
    && orderedData.map((obj) => ({
      ...obj,
      date: moment(obj.date).format('MMM DD'),
    }));

  const dispatch = useDispatch();

  const hasSecondMetric = !!metricFiltersPerformance[1];
  const metricName = (metric) => metricOptions.find((option) => option.replace(/\s/g, '') === metric);
  const defaultData = [];

  for (let i = dayRange, n = 0; i > n; i--) {
    const day = {
      date:
        i === 1
          ? moment().format('MMM DD')
          : moment()
            .subtract(i - 1, 'days')
            .format('MMM DD'),
      [metricFiltersPerformance[0]]: 0,
    };
    if (hasSecondMetric) {
      day[metricFiltersPerformance[1]] = 0;
    }
    defaultData.push(day);
  }

  const isSingleMetric = formattedData && formattedData[0].groupData[0].metrics.length === 1;
  const singleMetricName = isSingleMetric && formattedData[0].groupData[0].metrics[0].metricName;
  const singleMetricUnits = isSingleMetric && formattedData[0].groupData[0].metrics[0].units;

  const athleteIndex = formattedData
    && selectedAthletePerformance
    && formattedData[0].athleteData.findIndex(
      (obj) => obj.userId === selectedAthletePerformance.userId,
    );
  const barIndex = formattedData
    && selectedAthletePerformance
    && !isSingleMetric
    && formattedData[0].athleteData[athleteIndex].testResults.findIndex(
      (obj) => obj.metricName === formattedData[0].groupData[0].metrics[1].metricName,
    );
  const lineIndex = formattedData
    && selectedAthletePerformance
    && !isSingleMetric
    && formattedData[0].athleteData[athleteIndex].testResults.findIndex(
      (obj) => obj.metricName === formattedData[0].groupData[0].metrics[0].metricName,
    );

  const handleDayClick = (days) => {
    // Amplitude tracking
    dashboardTracker(
      'Force Plate Dashboard',
      'Performance Overview - Day Range Picker',
      `Range of ${days} days selected`,
    );
    batch(() => {
      dispatch(setDayRange(days));
      dispatch(setStartDateCal(moment().subtract(days - 1, 'days')));
      dispatch(
        setStartDateData(
          moment()
            .subtract(days - 1, 'days')
            .format('YYYY-MM-DD'),
        ),
      );
      dispatch(setEndDateCal(moment()));
      dispatch(setEndDateData(moment().format('YYYY-MM-DD')));
      dispatch(
        fetchPerformanceOverview(
          currentUser.accountCode,
          moment()
            .subtract(days - 1, 'days')
            .format('YYYY-MM-DD'),
          endDateData,
          group.id,
          metricFiltersPerformance,
        ),
      );
      dispatch(
        fetchMetricCardData(
          currentUser.accountCode,
          moment()
            .subtract(days - 1, 'days')
            .format('YYYY-MM-DD'),
          endDateData,
          group.id,
          keyPerformanceMetrics,
        ),
      );
    });
  };

  const emptyFunction = () => undefined;

  return (
    <>
      {isPerformanceLoading ? (
        <ChartContainer>
          <Spinner />
        </ChartContainer>
      ) : (
        <ChartContainer isBlurred={!hasGroupData} readyPrint={readyPrint}>
          {!readyPrint && !isReadOnlyView && (
            <DaySelector>
              <DayOption
                isActive={dayRange === 7}
                onClick={
                  isReadOnlyView ? emptyFunction : () => handleDayClick(7)
                }
                readOnly={isReadOnlyView}
              >
                7D
              </DayOption>
              <DaySpacer> | </DaySpacer>
              <DayOption
                isActive={dayRange === 14}
                onClick={
                  isReadOnlyView ? emptyFunction : () => handleDayClick(14)
                }
                readOnly={isReadOnlyView}
              >
                14D
              </DayOption>
              <DaySpacer> | </DaySpacer>
              <DayOption
                isActive={dayRange === 28}
                onClick={
                  isReadOnlyView ? emptyFunction : () => handleDayClick(28)
                }
                readOnly={isReadOnlyView}
              >
                28D
              </DayOption>
            </DaySelector>
          )}
          {hasGroupData ? (
            <>
              {isSingleMetric ? (
                <>
                  {selectedAthletePerformance ? (
                    <ResponsiveContainer
                      width='100%'
                      height='90%'
                      minHeight={380}
                      id='printableDiv'
                    >
                      <LineChart data={formattedData}>
                        <CartesianGrid strokeDasharray='3 3' />
                        <XAxis
                          dataKey='date'
                          angle={315}
                          height={50}
                          textAnchor='end'
                        />
                        <YAxis
                          yAxisId='left'
                          label={{
                            value:
                              singleMetricUnits === ''
                                ? `${singleMetricName} (%)`
                                : `${singleMetricName} (${singleMetricUnits})`,
                            angle: -90,
                            position: 'insideLeft',
                          }}
                        />
                        <Line
                          connectNulls
                          name={singleMetricName}
                          type='linear'
                          dataKey='groupData[0].metrics[0].averageValue'
                          yAxisId='left'
                          stroke='#1787FB'
                        />
                        <Line
                          connectNulls
                          name={`${selectedAthletePerformance.fullName} (${singleMetricName})`}
                          type='linear'
                          dataKey={`athleteData[${athleteIndex}].testResults[0].value`}
                          yAxisId='left'
                          stroke='#3DD6A1'
                        />
                        <Tooltip content={<CustomTooltip />} />
                        <Legend verticalAlign='top' height={30} />
                      </LineChart>
                    </ResponsiveContainer>
                  ) : (
                    <ResponsiveContainer
                      width='100%'
                      height='90%'
                      minHeight={380}
                      id='printableDiv'
                    >
                      <LineChart data={formattedData}>
                        <CartesianGrid strokeDasharray='3 3' />
                        <XAxis
                          dataKey='date'
                          angle={315}
                          height={50}
                          textAnchor='end'
                        />
                        <YAxis
                          yAxisId='left'
                          label={{
                            value:
                              singleMetricUnits === ''
                                ? `${singleMetricName} (%)`
                                : `${singleMetricName} (${singleMetricUnits})`,
                            angle: -90,
                            position: 'insideLeft',
                          }}
                        />
                        <Line
                          connectNulls
                          name={singleMetricName}
                          type='linear'
                          dataKey='groupData[0].metrics[0].averageValue'
                          yAxisId='left'
                          stroke='#1787FB'
                        />
                        <Tooltip content={<CustomTooltip />} />
                        <Legend verticalAlign='top' height={30} />
                      </LineChart>
                    </ResponsiveContainer>
                  )}
                </>
              ) : (
                <>
                  {selectedAthletePerformance ? (
                    <ResponsiveContainer
                      width='100%'
                      height='90%'
                      minHeight={380}
                      id='printableDiv'
                    >
                      <ComposedChart data={formattedData}>
                        <CartesianGrid strokeDasharray='3 3' />
                        <XAxis
                          dataKey='date'
                          angle={315}
                          height={50}
                          textAnchor='end'
                        />
                        <YAxis
                          yAxisId='left'
                          label={{
                            value:
                              formattedData[0].groupData[0].metrics[0].units
                              === ''
                                ? `${formattedData[0].groupData[0].metrics[0].metricName} (%)`
                                : `${formattedData[0].groupData[0].metrics[0].metricName} (${formattedData[0].groupData[0].metrics[0].units})`,
                            angle: -90,
                            position: 'insideLeft',
                          }}
                        />
                        <YAxis
                          yAxisId='right'
                          width={65}
                          orientation='right'
                          label={{
                            value:
                              formattedData[0].groupData[0].metrics[1].units
                              === ''
                                ? `${formattedData[0].groupData[0].metrics[1].metricName} (%)`
                                : `${formattedData[0].groupData[0].metrics[1].metricName} (${formattedData[0].groupData[0].metrics[1].units})`,
                            angle: 90,
                            position: 'insideRight',
                          }}
                        />
                        <defs>
                          <linearGradient
                            id='transparentOrangeGradient'
                            x1='0'
                            y1='0'
                            x2='0'
                            y2='1'
                          >
                            <stop
                              offset='0%'
                              stopColor='#FF6600'
                              stopOpacity={1}
                            />
                            <stop
                              offset='100%'
                              stopColor='#FF6600'
                              stopOpacity={0.5}
                            />
                          </linearGradient>
                        </defs>
                        <defs>
                          <linearGradient
                            id='transparentBlueGradient'
                            x1='0'
                            y1='0'
                            x2='0'
                            y2='1'
                          >
                            <stop
                              offset='0%'
                              stopColor='#ADCAFF'
                              stopOpacity={1}
                            />
                            <stop
                              offset='100%'
                              stopColor='#ADCAFF'
                              stopOpacity={0.5}
                            />
                          </linearGradient>
                        </defs>
                        <Bar
                          name={
                            formattedData[0].groupData[0].metrics[1].metricName
                          }
                          yAxisId='right'
                          dataKey='groupData[0].metrics[1].averageValue'
                          barSize={20}
                          fill='url(#transparentOrangeGradient)'
                          background={{ fill: 'transparent' }}
                          formatter='#FF6600'
                        />
                        <Bar
                          name={`${selectedAthletePerformance.fullName} (${formattedData[0].groupData[0].metrics[1].metricName})`}
                          yAxisId='right'
                          dataKey={`athleteData[${athleteIndex}].testResults[${barIndex}].value`}
                          barSize={20}
                          fill='url(#transparentBlueGradient)'
                          background={{ fill: 'transparent' }}
                          formatter='#ADCAFF'
                        />
                        <Line
                          connectNulls
                          name={
                            formattedData[0].groupData[0].metrics[0].metricName
                          }
                          type='linear'
                          dataKey='groupData[0].metrics[0].averageValue'
                          yAxisId='left'
                          stroke='#1787FB'
                        />
                        <Line
                          connectNulls
                          name={`${selectedAthletePerformance.fullName} (${formattedData[0].groupData[0].metrics[0].metricName})`}
                          type='linear'
                          dataKey={`athleteData[${athleteIndex}].testResults[${lineIndex}].value`}
                          yAxisId='left'
                          stroke='#3DD6A1'
                        />
                        <Tooltip content={<CustomTooltip />} />
                        <Legend verticalAlign='top' height={45} />
                      </ComposedChart>
                    </ResponsiveContainer>
                  ) : (
                    <ResponsiveContainer
                      width='100%'
                      height='90%'
                      minHeight={380}
                      id='printableDiv'
                    >
                      <ComposedChart data={formattedData}>
                        <CartesianGrid strokeDasharray='3 3' />
                        <XAxis
                          dataKey='date'
                          angle={315}
                          height={50}
                          textAnchor='end'
                        />
                        <YAxis
                          yAxisId='left'
                          label={{
                            value:
                              formattedData[0].groupData[0].metrics[0].units
                              === ''
                                ? `${formattedData[0].groupData[0].metrics[0].metricName} (%)`
                                : `${formattedData[0].groupData[0].metrics[0].metricName} (${formattedData[0].groupData[0].metrics[0].units})`,
                            angle: -90,
                            position: 'insideLeft',
                          }}
                        />
                        <YAxis
                          yAxisId='right'
                          width={65}
                          orientation='right'
                          label={{
                            value:
                              formattedData[0].groupData[0].metrics[1].units
                              === ''
                                ? `${formattedData[0].groupData[0].metrics[1].metricName} (%)`
                                : `${formattedData[0].groupData[0].metrics[1].metricName} (${formattedData[0].groupData[0].metrics[1].units})`,
                            angle: 90,
                            position: 'insideRight',
                          }}
                        />
                        <defs>
                          <linearGradient
                            id='transparentOrangeGradient'
                            x1='0'
                            y1='0'
                            x2='0'
                            y2='1'
                          >
                            <stop
                              offset='0%'
                              stopColor='#FF6600'
                              stopOpacity={1}
                            />
                            <stop
                              offset='100%'
                              stopColor='#FF6600'
                              stopOpacity={0.5}
                            />
                          </linearGradient>
                        </defs>
                        <Bar
                          name={
                            formattedData[0].groupData[0].metrics[1].metricName
                          }
                          yAxisId='right'
                          dataKey='groupData[0].metrics[1].averageValue'
                          barSize={20}
                          fill='url(#transparentOrangeGradient)'
                          background={{ fill: 'transparent' }}
                          formatter='#FF6600'
                        />
                        <Line
                          connectNulls
                          name={
                            formattedData[0].groupData[0].metrics[0].metricName
                          }
                          type='linear'
                          dataKey='groupData[0].metrics[0].averageValue'
                          yAxisId='left'
                          stroke='#1787FB'
                        />
                        <Tooltip content={<CustomTooltip />} />
                        <Legend verticalAlign='top' height={30} />
                      </ComposedChart>
                    </ResponsiveContainer>
                  )}
                </>
              )}
            </>
          ) : (
            <ResponsiveContainer width='100%' height='90%' minHeight={380}>
              <LineChart data={defaultData}>
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis
                  dataKey='date'
                  angle={315}
                  height={50}
                  textAnchor='end'
                />
                <YAxis
                  yAxisId='left'
                  label={{
                    value: metricUnits[metricName(metricFiltersPerformance[0])]
                      ? `${metricName(metricFiltersPerformance[0])} (${
                        metricUnits[metricName(metricFiltersPerformance[0])]
                      })`
                      : metricName(metricFiltersPerformance[0]),
                    angle: -90,
                    position: 'insideLeft',
                  }}
                  domain={[0, 5000]}
                />
                {hasSecondMetric && (
                  <YAxis
                    yAxisId='right'
                    orientation='right'
                    width={65}
                    label={{
                      value: metricUnits[
                        metricName(metricFiltersPerformance[1])
                      ]
                        ? `${metricName(metricFiltersPerformance[1])} (${
                          metricUnits[metricName(metricFiltersPerformance[1])]
                        })`
                        : metricName(metricFiltersPerformance[1]),
                      angle: 90,
                      position: 'insideRight',
                    }}
                    domain={[0, 5000]}
                  />
                )}
                <Line
                  type='linear'
                  dataKey='peakPower'
                  dot={false}
                  stroke='#444444'
                />
              </LineChart>
            </ResponsiveContainer>
          )}
        </ChartContainer>
      )}
    </>
  );
};

export default PerformanceOverviewChart;
