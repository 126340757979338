/** This is used for creating an empty table when
 * a single athlete is selected and no data is found.
 * It takes in an array of questions that would normally
 * be part of the API response for table data.
 * This instead relies on loadMonitoringFormValues.arrayOfQuestions (from Redux)
 * for unsaved dashboards, activeTemplate.questions for saved templates,
 * or readOnlyQuestions for the view-only dashboard view.
 * Also pass in the currentAthleteInAthletePicker value from Redux. 
 * This creates a table with the appropriate Athlete name,
 * column, and sub-column headers, and dashes in other td cells. */

const dummyAthleteData = (questionsArray, currentAthleteInAthletePicker) => {
  const mappedQuestions = questionsArray.length
    ? questionsArray.map((question) => {
      const { id, title, abbr } = question;
      return {
        id,
        title,
        abbr,
        description: '',
        value: '-',
        average: '-',
        response: {
          value: '-',
          backgroundColor: '#ffffff',
          textColor: '#000000',
        },
      };
    })
    : [];

  return [
    {
      id: currentAthleteInAthletePicker.isAthlete
        ? currentAthleteInAthletePicker.userId
        : null,
      name: currentAthleteInAthletePicker.isAthlete
        ? currentAthleteInAthletePicker.name
        : null,
      responses: [
        {
          date: '-',
          questions: [
            {
              id: 'volumeLoad',
              title: 'Volume Load',
              abbr: 'Load',
              description: 'Volume Load for the day',
              value: '-',
              average: '-',
              response: {
                value: '-',
                backgroundColor: '#ffffff',
                textColor: '#000000',
              },
            },
            {
              id: 'exertionScore',
              title: 'Exertion Score',
              abbr: 'Exertion',
              description: 'Exertion for the day',
              value: '-',
              average: '-',
              response: {
                value: '-',
                backgroundColor: '#ffffff',
                textColor: '#000000',
              },
            },
            ...mappedQuestions,
          ],
          totalScore: '-',
          totalScoreAverage: '-',
        },
      ],
    },
  ];
};

export default dummyAthleteData;
