/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import ResizeObserver from 'react-resize-observer';
import PropTypes from 'prop-types';
import LoadMonitoringForm from './components/presentational/LoadMonitoringForm';
import Spinner from '../../shared/components/Spinner/Spinner';

const Wrapper = styled('div')`
  display: flex;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  justify-content: flex-start;
  align-items: center;
  background: #f5f3f3;
`;

const Container = styled('div')`
  display: flex;
  width: 100%;
  height: 100%;
  max-width: ${(props) => props.activePage && props.activePage !== 3 && '1200px'};
  justify-content: center;
  align-items: ${(props) => props.activePage && props.activePage !== 3 && 'center'};
  flex-direction: column;
  position: relative;
  margin-left: ${(props) => (props.activePage && props.activePage !== 3 ? '40px' : '0px')};
  margin-right: ${(props) => (props.activePage && props.activePage !== 3 ? '40px' : '0px')};
`;

const NewDashboardComponent = ({ activePage, setActivePage, formikRef }) => {
  // Contains form for creating new Load Monitoring Dashboard
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const isLoginLoading = useSelector((state) => state.auth.ui.isLoginLoading);

  const isLoadingQuestionnairesForAccount = useSelector(
    (state) => state.loadMonitoringDashboard.ui.isLoadingQuestionnairesForAccount,
  );
  const isLoadingQuestionsForAccount = useSelector(
    (state) => state.loadMonitoringDashboard.ui.isLoadingQuestionsForAccount,
  );
  const isLoadingReportableGroups = useSelector(
    (state) => state.loadMonitoringDashboard.ui.isLoadingReportableGroups,
  );

  const isSubscriptionStatusLoading = useSelector(
    (state) => state.sportsScience.ui.isStatusLoading,
  );
  const isContentLoading = isLoadingQuestionnairesForAccount
    || isLoadingQuestionsForAccount
    || isLoadingReportableGroups
    || isLoginLoading
    || isSubscriptionStatusLoading;

  const [containerWidth, setContainerWidth] = useState({});

  useEffect(() => {
    setActivePage(1);
  }, []);

  return (
    <Wrapper activePage={activePage}>
      <Container activePage={activePage} containerWidth={containerWidth}>
        <ResizeObserver
          onResize={(rect) => {
            setContainerWidth(rect?.width);
          }}
        />
        {isContentLoading || !currentUser?.id ? (
          <Spinner />
        ) : (
          <LoadMonitoringForm
            formikRef={formikRef}
            isLoadingQuestionnairesForAccount={
              isLoadingQuestionnairesForAccount
            }
            isLoadingQuestionsForAccount={isLoadingQuestionsForAccount}
            containerWidth={containerWidth}
            activePage={activePage}
            setActivePage={setActivePage}
          />
        )}
      </Container>
    </Wrapper>
  );
};
NewDashboardComponent.propTypes = {
  activePage: PropTypes.number.isRequired,
  setActivePage: PropTypes.func.isRequired,
};

export default NewDashboardComponent;
