import React from 'react';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import styled from '@emotion/styled';
import { useTheme } from 'emotion-theming';

import { setSelectedTab, setActiveGymStudioModal } from '../../../ducks/gymStudioActions';
import MarketingBanner from './MarketingBanner';
import MarketingCard from './MarketingCard';
import HeaderText from '../../../../../shared/components/HeaderText/HeaderText';
import GymStudioButton from '../../../../../shared/_TBComponents/Button';
import CardBackgound1 from '../../../../../shared/images/marketing-card-1.png';
import CardBackgound2 from '../../../../../shared/images/marketing-card-2.png';
import CardBackgound3 from '../../../../../shared/images/marketing-card-3.png';
import { featureClickTracker } from '../../../../../shared/utils/amplitudeHelper';

const MainContainer = styled('main')`
  flex: 10;
  overflow: auto;
  position: relative;
  max-width: 1450px;
  margin-bottom: 20px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 15px;
    height: 100%;
    background: linear-gradient(to right, rgba(128, 128, 128, 0.075), transparent);
    z-index: 2;
    pointer-events: none;
  }
`;

const DashboardHeader = styled('div')`
  display: flex;
  width: 100%;
  height: 104px;
  background-color: #ffffff;
  padding: 48px 48px 16px 48px;
  align-items: center;
  justify-content: space-between;

    @media only screen and (max-width: 1350px) {
      height: 148px;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      padding: 55px 24px 16px 24px;
    }
`;

const ButtonContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  gap: 12px;
  max-height: 40px;

  @media only screen and (max-width: 1350px) {
    align-self: flex-end;
  }

  @media only screen and (max-width: 1024px) {
    width: 100%;
  }
`;

const Container = styled('div')`
  display: flex;
  width: 100%;
  height: 100%;
  padding: ${(props) => (props.connected ? '0px' : '32px 48px')};
  justify-content: flex-start;
  flex-direction: column;
  position: relative;

  @media only screen and (max-width: 1350px) {
    padding: ${(props) => (props.connected ? '0px' : '24px')};
  }
`;

const CardContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 32px;

  @media only screen and (max-width: 1024px) {
    flex-direction: column;
    gap: 24px;
  }
`;

const MarketingPage = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isResponsive = useMediaQuery({ query: '(max-width: 1024px)' });

  const handlePreviewClick = () => {
    dispatch(setSelectedTab('quick-stats'));

    // Amplitude tracking
    featureClickTracker(
      'GymStudio',
      'GymStudio - Quick Stats Preview CTA',
      'GymStudio - User clicked to preview the Quick Stats module.',
    );
  };

  const openGymConnectModal = () => {
    dispatch(setActiveGymStudioModal('gym-connect'));

    // Amplitude tracking
    featureClickTracker(
      'GymStudio',
      'GymStudio - I have a GymStudio account CTA',
      'GymStudio - User clicked initial CTA to setup integration.',
    );
  };

  return (
    <MainContainer>
      <DashboardHeader>
        <HeaderText
          fontSize='24px'
          fontWeight='900'
          letterSpacing='normal'
          style={{ marginRight: '20px' }}
        >
          Welcome to GymStudio & TeamBuildr!
        </HeaderText>
        <ButtonContainer>
          <GymStudioButton
            name='I have a GymStudio account'
            bgColor={theme.colors.gymStudio}
            txtColor='#FFFFFF'
            fontSize='14px'
            styledTextProps={{ fontWeight: '700', letterSpacing: '0' }}
            borderColor={theme.colors.gymStudio}
            customBorderRadius='4px'
            fullWidth={isResponsive}
            onClick={openGymConnectModal}
          />
          <GymStudioButton
            name='Create a GymStudio account'
            bgColor='#FFFFFF'
            txtColor='#444444'
            fontSize='14px'
            styledTextProps={{ fontWeight: '700', letterSpacing: '0' }}
            borderColor='#9E9E9E'
            customBorderRadius='4px'
            fullWidth={isResponsive}
            onClick={() => {
              // Amplitude tracking
              featureClickTracker(
                'GymStudio',
                'GymStudio - Create a GymStudio Account CTA',
                'GymStudio - User clicked secondary CTA to create a GymStudio account.',
              );

              const gymstudioUrl = `https://${
                process.env.ENVIRONMENT === 'LOCAL' || process.env.ENVIRONMENT === 'STAGING'
                  ? 'demo.gymstudio.com/sign-up'
                  : 'app.gymstudio.com/signup'
              }`;
              window.open(gymstudioUrl, '_blank');
            }}
          />
        </ButtonContainer>
      </DashboardHeader>
      <Container>
        <MarketingBanner onPreviewClick={handlePreviewClick} style={{ marginBottom: '32px' }} />
        <CardContainer>
          <MarketingCard
            backgroundImage={CardBackgound1}
            overlayColor='#06283D99'
            icon='gs-layout'
            iconBgColor='#06283D'
            title='Gym Overview & Statistics'
            description="Sync your gym's data and get instant access to personalized statistics in seconds"
            isResponsive={isResponsive}
          />
          <MarketingCard
            backgroundImage={CardBackgound2}
            overlayColor='#FF853399'
            icon='gs-group'
            iconBgColor='#FF8533'
            title='Member Workout Access'
            description='Connect workout access in TeamBuildr to successful payment status in GymStudio'
            isResponsive={isResponsive}
          />
          <MarketingCard
            backgroundImage={CardBackgound3}
            overlayColor='#06283D99'
            icon='gs-calendar'
            iconBgColor='#06283D'
            title='Member Booking & Scheduling'
            description='Your users will be able to view and book classes through the TeamBuildr app'
            isResponsive={isResponsive}
          />
        </CardContainer>
      </Container>
    </MainContainer>
  );
};

export default MarketingPage;
