// List of metrics used in Performance Overview Chart, Key Performance Metrics, and Group Analysis
export const metricOptions = [
  'Peak Power',
  'Jump Height',
  'Takeoff Velocity',
  'mRSI',
  'Flight Time',
  'Stiffness',
  'Positive Impulse',
  'Braking RFD (Rate of Force Development)',
  'Countermovement Depth',
  'Peak Relative Power',
  'Peak Landing Force',
  'Braking Impulse',
  'L|R Braking Impulse',
  'L|R Landing Impulse',
  'L|R Landing RFD',
  'L|R Concentric Impulse',
  'Left - Average Propulsive Force',
  'Right - Average Propulsive Force',
  'Left - Average Landing Force',
  'Right - Average Landing Force',
];

// Provides fallback metric units for metrics that don't have data
export const metricUnits = {
  'Jump Height': 'cm',
  'Takeoff Velocity': 'm/s',
  'Peak Power': 'W',
  'L|R Concentric Impulse': '',
  'L|R Braking Impulse': '',
  'L|R Landing Impulse': '',
  'L|R Landing RFD': '',
  mRSI: '',
  'Flight Time': 's',
  Stiffness: 'N',
  'Peak Landing Force': 'N',
  'Left - Average Propulsive Force': 'N',
  'Right - Average Propulsive Force': 'N',
  'Left - Average Landing Force': 'N',
  'Right - Average Landing Force': 'N',
  'Positive Impulse': 'N.s',
  'Braking Impulse': 'N.s',
  'Braking RFD': 'N/s',
  'Countermovement Depth': 'm',
  'Peak Relative Power': 'W/kg',
};

// Provides fallback zero values for metrics that don't have data
export const metricValueDefaults = {
  'Jump Height': '00.00',
  'L|R Concentric Impulse': '00.00',
  'L|R Braking Impulse': '00.00',
  'L|R Landing Impulse': '00.00',
  'L|R Landing RFD': '00.00',
  'Peak Relative Power': '00.00',
  'Countermovement Depth': '00.00',
  'Takeoff Velocity': '0.00',
  'Braking Impulse': '0.00',
  'Flight Time': '0.00',
  mRSI: '0.00',
  'Peak Power': '0000',
  'Peak Landing Force': '0000',
  Stiffness: '0000',
  'Positive Impulse': '0000',
  'Braking RFD': '0000',
  'Left - Average Propulsive Force': '0000',
  'Right - Average Propulsive Force': '0000',
  'Left - Average Landing Force': '0000',
  'Right - Average Landing Force': '0000',
};

export const metricDescriptions = {
  'Jump Height':
    'Jump Height refers to the peak vertical displacement achieved by an athlete and can be determined using a variety of metrics and calculations. To better understand how Jump Height is estimated/calculated, please refer to your device\'s support guides.',
  'Takeoff Velocity': `Takeoff Velocity is the peak speed an athlete reached during the takeoff phase or beginning of a jump.
    \nThis metric may also be referred to as "Vertical Velocity at Takeoff".`,
  'Peak Power': `Peak Power is the maximal power output achieved during the propulsive portion of a jump.
    \nThis metric may also be referred to as "Peak Propulsive Power".`,
  mRSI: `Modified Reactive Strength Index (mRSI) refers to the ratio of Jump Height and Time to Takeoff.
    \nThis metric may also be referred to as “Reactive Strength Index Modified”.`,
  'Flight Time':
    'Flight Time is the amount of time that occurs between the takeoff and landing phases of a jump.',
  Stiffness: `Stiffness represents a quantitative measure of a body’s elastic properties and is a calculated by dividing the Ground Reaction Force (GRF) applied vertically to the athlete by the peak negative vertical displacement of the athlete.
    \nThis metric may also be referred to as “CMJ Stiffness”.`,
  'Positive Impulse':
    'Positive Impulse is any force applied by an athlete during the takeoff/propoulsion and subsequent landing/braking phases.',
  'Braking RFD (Rate of Force Development)': `Braking Rate of Force Development (RFD) refers to the timespan in which vertical ground reaction force is applied during the braking phase.
    \nThis metric may also be referred to as "Eccentric Braking RFD".`,
  'Countermovement Depth':
    'Countermovement Depth is the peak negative vertical displacement that occurs from the contact (zero) position. Simply put, this refers to the depth achieved by an athlete during the initial downward movement of a jump.',
  'Peak Relative Power': `Peak Relative Power refers to the maximal amount of power generated per unit of bodyweight or mass during the propulsive portion of a jump.
    \nThis metric may also be referred to as "Peak Relative Propulsive Power" and "Peak Power / BM".`,
  'Peak Landing Force':
    'Peak Landing Force is the maximal vertical ground reaction force exerted on the athlete during the landing phase of a jump.',
  'Braking Impulse': `Braking Impulse refers to the total change in momentum of an athlete due to braking. In the context of a jump, this refers to the vertical forces exerted during the descent or eccentric phase of a jump.
    \nThis metric may also be referred to as "Eccentric Braking Impulse".`,
  'L|R Braking Impulse': `Braking Impulse (L/R) refers to the asymmetry between the left and right total change in momentum during the descent or eccentric portion of a jump. A negative value (-) means the athlete demonstrates more displacement towards their left side, while a positive value (+) means the athlete demonstrates more displacement towards the right side.
    \nThis metric may also be referred to as "L/R Braking Impulse Index" and "Eccentric Braking Impulse (Asymmetry)".`,
  'L|R Landing Impulse': `Landing Impulse (L/R) refers to the asymmetry between the left and right total change in momentum upon landing. A negative value (-) means the athlete demonstrates more displacement towards their left side, while a positive value (+) means the athlete demonstrates more displacement towards the right side.
    \nThis metric may also be referred to as "L/R Landing Impulse Index" and "Landing Impulse (Asymmetry)".`,
  'L|R Landing RFD': `Landing RFD (L/R) refers to the timespan in which vertical ground reaction force is applied as it relates to each limb during the landing phase. A negative value (-) means the athlete demonstrates more displacement towards their left side, while a positive value (+) means the athlete demonstrates more displacement towards the right side.
    \nThis metric may also be referred to as "L/R Avg. Landing Force" and "Landing RFD Asymmetry".`,
  'L|R Concentric Impulse': `Concentric Impulse (L/R) refers to the displacement between left and right limbs during the propulsive portion of a jump. A negative value (-) means the athlete demonstrates more displacement towards their left side, while a positive value (+) means the athlete demonstrates more displacement towards the right side.
    \nThis metric may also be referred to as “L/R Propulsive Impulse Index” and “Concentric Impulse Asymmetry”.`,
  'Left - Average Propulsive Force':
    'The average left vertical ground reaction force applied to the athlete during the propulsion phase.',
  'Right - Average Propulsive Force':
    'The average right vertical ground reaction force applied to the athlete during the propulsion phase.',
  'Left - Average Landing Force':
    'The average left vertical ground reaction force applied to the athlete during the landing phase.',
  'Right - Average Landing Force':
    'The average right vertical ground reaction force applied to the athlete during the landing phase.',
};
