/* eslint-disable max-len */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import IcomoonReact from 'icomoon-react';
import PropTypes from 'prop-types';
import TableToExcel from '@linways/table-to-excel';
import styled from '@emotion/styled';
import iconSet from '../../../../shared/images/teambuildr-selection.json';
import Text from '../../../../shared/components/Text/Text';
import { setShowAllForDownload } from '../../ducks/loadMonitoringDashboardActions';

const DownloadReportButton = ({ textColor, backgroundColor, chartName }) => {
  const dispatch = useDispatch();
  const currentReportType = useSelector(
    (state) => state.loadMonitoringDashboard.data.currentReportType,
  );
  // const isChartShowingAllData = useSelector((state) => state.loadMonitoringDashboard.data.isChartShowingAllData);
  // const showAllForDownload = useSelector((state) => state.loadMonitoringDashboard.data.showAllForDownload);

  const exportFile = () => {
    dispatch(setShowAllForDownload(true));
    const tb = document.getElementById('reportTable');

    if (currentReportType === 'singleDay') {
      setTimeout(() => {
        TableToExcel.convert(tb, {
          name: `${chartName}.xlsx`,
          sheet: {
            name: 'Sheet 1',
          },
        });
      }, 500); // wait one half of a second to allow the athletes to render
    } else {
      TableToExcel.convert(tb, {
        name: `${chartName}.xlsx`,
        sheet: {
          name: 'Sheet 1',
        },
      });
    }
  };

  const Wrapper = styled('div')`
    display: flex;
    cursor: pointer;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px 9px 12px;
    height: 28px;
    width: 160px;
    min-width: 160px;
    background: ${(props) => props.backgroundColor && props.backgroundColor};
    box-shadow: 0px 0px 1px rgba(48, 49, 51, 0.05),
      0px 4px 8px rgba(48, 49, 51, 0.1);
    border-radius: 12px;
  `;

  return (
    <Wrapper
      onClick={exportFile}
      textColor={textColor}
      backgroundColor={backgroundColor}
    >
      <IcomoonReact
        iconSet={iconSet}
        size={12}
        icon='download'
        color={textColor}
      />
      <Text color={textColor} fontSize={11} fontWeight={700}>
        Download Chart Data
      </Text>
    </Wrapper>
  );
};

DownloadReportButton.propTypes = {
  textColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  chartName: PropTypes.string,
};

DownloadReportButton.defaultProps = {
  textColor: '#ffffff',
  backgroundColor: '#444444',
  chartName: 'Questionnaire Report.xlsx',
};

export default DownloadReportButton;
