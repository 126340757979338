/* eslint-disable react/prop-types */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useRef } from 'react';
import { toast } from 'react-toastify';
import styled from '@emotion/styled';
import ResizeObserver from 'react-resize-observer';
import { useSelector, useDispatch } from 'react-redux';
import { animated, useTransition, useSpring } from 'react-spring';
import * as Yup from 'yup';
import IcomoonReact from 'icomoon-react';
import moment from 'moment';
import { useTheme } from 'emotion-theming';
import { Form } from 'formik';

import iconSet from '../../../../shared/images/teambuildr-selection.json';
import Text from '../../../../shared/_TBComponents/Text';
import Button from '../../../../shared/_TBComponents/Button';
import FormHandler from '../../../../shared/components/FormHandler/FormHandler';
import {
  fetchQuestionnairesForAccount, fetchQuestionsForAccount, fetchQuestionnaireReportSingleDay, fetchQuestionnaireReportMultiDay,
} from '../../ducks/reportingActions';
import Spinner from '../../../../shared/_TBComponents/Spinner';
import QuestionnaireModalFormSlideOne from './QuestionnaireModalFormSlideOne';
import QuestionnaireModalFormSlideTwo from './QuestionnaireModalFormSlideTwo';
import { TransitionGlobal } from '../../../../shared/GlobalStyles';
import { reportInteractionTracker } from '../../../../shared/utils/amplitudeHelper';

const SlideContainer = styled('div')`
  display: block;
  height: auto;
  height: ${(props) => `${props.height}px`};
  position: relative;
  width: 100%;
  min-height: 184px;
  transition: ${TransitionGlobal};
  z-index: 1000;
`;

const SliderNav = styled('div')`
  display: flex;
  justify-content: space-between;
  align-self: center;
  width: 30px;
  margin: 0 auto;
  margin-top: 15px;
  margin-bottom: 20px;
  z-index: 10;
  position: relative;
`;

const NavCircle = styled('button')`
  height: 10px;
  width: 10px;
  display: block;
  border: 1px solid #979797;
  border-radius: 100%;
  cursor: pointer;

  &.active {
    background-color: #d8d8d8;
    border: 1px solid #d8d8d8;
    cursor: default;
  }
`;

const SpinnerContainer = styled('div')`
  display: flex;
  height: 100%;
  flex: 1;
  position: absolute;
  top: 0;
  justify-content: center;
  width: 100%;
  z-index: 0;
  left: 0;
  margin-top: 40px;
`;

const FieldError = styled('div')`
  margin-bottom: 15px;
  font-size: 14px;
  color: #ff6600;
`;

const NavigationWrapper = styled('div')`
  display: flex;
  width: 100%;
  margin-bottom: -20px;
  z-index: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  padding: 20px;
  justify-content: ${(props) => (props.spaceBetween ? 'space-between' : 'end')};

  p {
    opacity: .5;
    :hover {
      opacity: 1;
    }
  }
`;

const QuestionnaireReportSchema = Yup.object().shape({
  arrayOfQuestions: Yup.array().min(1, 'Please select at least one question').required('Required'),
  reportType: Yup.string(),
  landedOnSlide2: Yup.boolean().oneOf([true], 'This field must be true'),
  startDate: Yup.object().when('reportType', {
    is: 'multiDay',
    then: Yup.object().shape({
      queryFormat: Yup.string().required('Please Select a Start Date'),
    }),
  }).nullable(),
  endDate: Yup.object().when('reportType', {
    is: 'multiDay',
    then: Yup.object().shape({
      queryFormat: Yup.string().required('Please Select an End Date'),
    }),
  }).nullable(),
  selectedDate: Yup.object().when('singleDaySelect', {
    is: true,
    then: Yup.object().shape({
      queryFormat: Yup.string().required('Please Select a Date'),
    }),
  }).nullable(),
});

const defaultSlideStyle = { position: 'absolute', width: '100%' };

const QuestionnaireReportForm = ({
  closeModal,
}) => {
  const [activeSlide, setActiveSlide] = useState(0);
  const [slideDimensions, setSlideDimensions] = useState({
    height: 0,
    width: 0,
  });
  const slideRef = useRef();
  const theme = useTheme();

  const questionnairesForAccount = useSelector((state) => state.reporting.data.questionnairesForAccount);
  const allQuestionsAssociatedWithAccount = useSelector((state) => state.reporting.data.questionsForAccount);
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const sidebarFilter = useSelector((state) => state.reporting.data.sidebarFilter);
  const questionnaireReportError = useSelector((state) => state.reporting.data.questionnaireReportError);
  const questionnaireReportForm = useSelector((state) => state.reporting.data.questionnaireReportForm);
  const isLoadingQuestionnaireReportSingleDay = useSelector((state) => state.reporting.ui.isLoadingQuestionnaireReportSingleDay);
  const isLoadingQuestionnaireReportMultiday = useSelector((state) => state.reporting.ui.isLoadingQuestionnaireReportMultiday);
  const { accountCode } = currentUser;

  const dispatch = useDispatch();

  useEffect(() => {
    /** on form load, fetch questionnaires associated with account
     * and fetch questions associated with account
     */
    dispatch(fetchQuestionnairesForAccount(accountCode));
    dispatch(fetchQuestionsForAccount(accountCode));
  }, []);

  const fetchReport = (values) => {
    /** fetch questionnaire report for single day or for multiday depending on info passed */
    if (sidebarFilter.filterIds.length) {
      if (values.reportType === 'multiDay') {
        dispatch(fetchQuestionnaireReportMultiDay(accountCode, sidebarFilter, values));
      } else {
        dispatch(fetchQuestionnaireReportSingleDay(accountCode, sidebarFilter, values));
      }
    } else {
      toast.error('You must select filters before continuing');

      // Amplitude tracker.
      reportInteractionTracker('Report Error', 'Questionnaire', 'Reporting Filter has not been selected.');
    }
  };

  const formAnimation = useSpring({
    opacity: isLoadingQuestionnaireReportSingleDay || isLoadingQuestionnaireReportMultiday ? 0 : 1,
    zIndex: 1,
  });

  const spinnerAnimation = useSpring({ opacity: isLoadingQuestionnaireReportSingleDay || isLoadingQuestionnaireReportMultiday ? 1 : 0, zIndex: 0 });

  const slideTransition = useTransition(activeSlide, null, {
    from: { opacity: 0, transform: activeSlide === 1 ? 'translate3d(25%, 0, 0)' : 'translate3d(-25%, 0, 0)' },
    enter: { opacity: 1, transform: 'translate3d(0, 0, 0)' },
    leave: { opacity: 0, transform: activeSlide === 1 ? 'translate3d(-25%, 0, 0)' : 'translate3d(25%, 0, 0)' },
  });

  const currentDate = new Date();

  return (
    <>
      <FormHandler
        initialValues={
          Object.keys(questionnaireReportForm).length !== 0 ? (
            questionnaireReportForm
          ) : (
            {
              reportType: 'singleDay',
              selectionType: 'selectAllQuestions',
              landedOnSlide2: false,
              singleDaySelect: false,
              selectedDate: {
                date: null,
                queryFormat: '',
              },
              currentDate: {
                date: currentDate,
                queryFormat: moment(currentDate).format('YYYY-MM-DD'),
              },
              startDate: {
                date: null,
                queryFormat: '',
              },
              endDate: {
                date: null,
                queryFormat: '',
              },
              arrayOfQuestions: [],
              questionIds: [{ title: '', id: '' }],
              questionnaire: null,
              questionnaireIds: [],
              showRangeAverages: true,
              showIncompleteData: false,
              showTotal: true,
            }
          )
        }
        validationSchema={QuestionnaireReportSchema}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            setSubmitting(false);
            fetchReport(values);
          });
        }}
      >
        {(formProps) => (
          <>
            <NavigationWrapper spaceBetween={activeSlide === 1}>
              {activeSlide === 1 && (
                <Text
                  onClick={() => {
                    setActiveSlide(0);
                    formProps.setErrors({});
                    formProps.setTouched({});
                  }}
                  styledTextProps={{ lineHeight: 1 }}
                >
                  <IcomoonReact
                    iconSet={iconSet}
                    size={15}
                    icon='left-arrow'
                  />
                </Text>
              )}
              <Text onClick={closeModal} styledTextProps={{ lineHeight: 1 }}>
                <IcomoonReact
                  iconSet={iconSet}
                  size={13}
                  icon='remove'
                />
              </Text>
            </NavigationWrapper>
            <Form
              style={{ width: '100%' }}
            >
              <animated.div style={formAnimation}>
                {questionnaireReportError && (
                <FieldError className='text-center'>
                  {questionnaireReportError.message === 'should have required property \'filterIds\''
                    ? 'Please select athletes, groups, or calendars from the filter.'
                    : questionnaireReportError.message === 'should be integer'
                      ? 'Please select at least one exercise.'
                      : questionnaireReportError.message}
                </FieldError>
                )}
                <SlideContainer
                  height={slideDimensions.height}
                >
                  {/* Slider transition that mounts the active slide
                      based on the state object, activeSlide. */}
                  {slideTransition.map(({ item, props }) => (
                    item === 0 ? (
                      <animated.div ref={slideRef} style={{ ...defaultSlideStyle, ...props }}>
                        <ResizeObserver
                          onResize={(rect) => {
                            setSlideDimensions(rect);
                          }}
                        />
                        <QuestionnaireModalFormSlideOne
                          formProps={formProps}
                          questions={allQuestionsAssociatedWithAccount}
                          questionnaires={questionnairesForAccount}
                        />
                      </animated.div>
                    ) : (
                      <animated.div ref={slideRef} style={{ ...defaultSlideStyle, ...props }}>
                        <ResizeObserver
                          onResize={(rect) => {
                            setSlideDimensions(rect);
                          }}
                        />
                        <QuestionnaireModalFormSlideTwo
                          formProps={formProps}
                        />
                      </animated.div>
                    )))}
                </SlideContainer>
                <SliderNav>
                  <NavCircle
                    className={activeSlide === 0 ? 'active' : ''}
                    type='button'
                    onClick={() => {
                      setActiveSlide(0);
                      formProps.setErrors({});
                      formProps.setTouched({});
                    }}
                  />
                  <NavCircle
                    type='submit'
                    className={activeSlide === 1 ? 'active' : ''}
                    onClick={() => {
                      formProps.setFieldValue('landedOnSlide2', false);
                      formProps.validateForm().then(() => {
                        if (formProps.values.arrayOfQuestions.length) {
                          setActiveSlide(1);
                        }
                      });
                    }}
                  />
                </SliderNav>
              </animated.div>
              <animated.div style={spinnerAnimation}>
                <SpinnerContainer>
                  <Spinner />
                </SpinnerContainer>
              </animated.div>
              {activeSlide !== 0 && (
                <Button
                  bottom
                  bgColor={theme.colors.green}
                  fontSize='14px'
                  fullWidth
                  large
                  name='Run Report'
                  noBorder
                  onClick={() => {
                    formProps.setFieldValue('landedOnSlide2', true);
                  }}
                  square
                  styledWrapperProps={{
                    bottom: 0,
                    left: 0,
                    position: 'absolute',
                  }}
                  txtColor='#FFFFFF'
                  type='submit'
                  upperCase
                />
              )}
              {activeSlide === 0 && (
                <Button
                  bottom
                  bgColor={theme.colors.green}
                  fontSize='14px'
                  fullWidth
                  large
                  name='next'
                  noBorder
                  onClick={() => {
                    formProps.validateForm().then(() => {
                      if (formProps.values.arrayOfQuestions.length) {
                        setActiveSlide(1);
                      }
                    });
                  }}
                  square
                  styledWrapperProps={{
                    bottom: 0,
                    left: 0,
                    position: 'absolute',
                  }}
                  txtColor='#FFFFFF'
                  type='submit'
                  upperCase
                />
              )}
            </Form>
          </>
        )}
      </FormHandler>
    </>
  );
};

export default QuestionnaireReportForm;
