/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';
import moment from 'moment';
import ResizeObserver from 'react-resize-observer';
import PropTypes from 'prop-types';

import useMaxReportModal from '../hooks/useMaxReportModal';
import HeaderText from '../../../../shared/_TBComponents/HeaderText';
import { BoxShadow } from '../../../../shared/GlobalStyles';
import ReportingModal from '../ReportingModal';
import ActiveReportSidebar from '../ActiveReportSidebar';
import QuestionnaireGrid from './QuestionnaireGrid';
import AveragesAndDonutChartComponent from './AveragesAndDonutChartComponent';
import MultiDayGridWithSubRows from './MultiDayGridWithSubRows';
import Text from '../../../../shared/_TBComponents/Text';

const MainContainer = styled('main')`
  flex: ${(props) => (props.isReportOpen ? 50 : 10)};
  display: flex;
  height: 100vh;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
  background: ${(props) => (props.isReportOpen ? 'white' : '')};
  padding: 0px 15px;
  border-left: solid #f5f5f5 1px;
  max-width: 100vw;

  @media screen and (max-width: 600px) {
    padding: 0px 0px;
  }
  
  @media screen and (max-width: 540px) {
    width: 100%;
    padding: 0px 0px;
    margin: 0px;
  }
`;

const HeaderRow = styled('div')`
  display: flex;
  margin-top: 30px;
  margin-bottom: 40px;
  align-items: self-end;
  gap: 15px;
  flex-wrap: wrap;

  h1 {
    text-transform: capitalize;
  }

  @media screen and (max-height: 920px) {
    margin-bottom: 20px;
    margin-top: 30px;
  }
  
  @media screen and (max-width: 768px) {
    display: flex;
    justify-content: center;
  }
`;
const RowWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: fit-content;
  max-width: 100%;
`;

const BodyRow = styled('div')`
  display: flex;
  flex-direction: row;
  margin-top: 50px;
  margin-bottom: 150px;
  height: max-content;
  padding: 0px 15px;
  overflow-x: ${(props) => (props.containerWidth < 485 ? 'scroll' : 'hidden')};
`;

const MobileActiveMenu = styled('div')`
  position: fixed;
  z-index: 100;
  height: 45px;
  bottom: 30px;
  left: 50%;
  margin-left: -100px;
  background-color: white;
  border-radius: 30px;
  display: none;
  box-shadow: ${BoxShadow};
  
  @media screen and (max-width: 767px) {
    display: block;
  }
`;

/** This is the actual page that the modal opens over, and where the user is taken after they
 * make their modal selections
 */
const QuestionnaireReport = ({ handlePrint, printRef }) => {
  const {
    handleSetIsModalOpen,
  } = useMaxReportModal();

  /** isReportOpen referes to the state when the modal has closed and there is data
   * on the page reflecting the selections made in the modals
   */
  const isReportOpen = useSelector((state) => state.reporting.ui.isReportOpen);

  /** setting the active modal is how the modal handler knows which content to
   * put in the modal
   */
  const activeModal = useSelector((state) => state.reporting.ui.activeModal);
  const reportTypeSentToForm = useSelector((state) => state.reporting.data?.questionnaireReport?.values?.reportType);
  const questionnaireReportForm = useSelector((state) => state.reporting.data.questionnaireReportForm);

  /** once the user clicks questionnaire from the reporting grid
   * they will be brought to this page, and on page load we want the
   * filter modal to open, which is why we implement this useEffect.
   */
  useEffect(() => {
    handleSetIsModalOpen(true, 'questionnaire');
  }, []);

  const [containerWidth, setContainerWidth] = useState({});

  return (
    <MainContainer
    /** There will be another modal for downloading the report triggerd by the icon click in the
     * mobile side bar once the user is on the report page viewing the selected data
     */
      isReportOpen={isReportOpen || activeModal === 'downloadQuestionnaireReport'}
      containerWidth={containerWidth}
    >
      <ResizeObserver
        onResize={(rect) => {
          setContainerWidth(rect.width);
        }}
      />
      <ReportingModal />
      {isReportOpen && (
        <div ref={printRef}>
          <HeaderRow>
            <HeaderText
              fontWeight={300}
              letterSpacing='normal'
            >
              Questionnaire Report
            </HeaderText>
            {questionnaireReportForm.reportType === 'singleDay' && questionnaireReportForm.singleDaySelect && (
              <Text>{moment(questionnaireReportForm.selectedDate.date).format('MMMM Do, YYYY')}</Text>
            )}
            {questionnaireReportForm.reportType === 'singleDay' && !questionnaireReportForm.singleDaySelect && (
              <Text>{moment(questionnaireReportForm.currentDate.date).format('MMMM Do, YYYY')}</Text>
            )}
            {questionnaireReportForm.reportType === 'multiDay' && (
              <Text>
                {moment(questionnaireReportForm.startDate.date).format('MMMM Do, YYYY')}
                {' '}
                -
                {' '}
                {moment(questionnaireReportForm.endDate.date).format('MMMM Do, YYYY')}
              </Text>
            )}
          </HeaderRow>
          <RowWrapper>
            {reportTypeSentToForm !== 'multiDay' && (
            <AveragesAndDonutChartComponent />
            )}
            <BodyRow containerWidth={containerWidth}>
              {reportTypeSentToForm === 'multiDay' ? (
                <MultiDayGridWithSubRows containerWidth={containerWidth} />
              ) : (
                <QuestionnaireGrid containerWidth={containerWidth} />
              )}
            </BodyRow>
          </RowWrapper>
          <MobileActiveMenu>
            <ActiveReportSidebar download edit handlePrint={handlePrint} print />
          </MobileActiveMenu>
        </div>
      )}
    </MainContainer>
  );
};

QuestionnaireReport.propTypes = {
  printRef: PropTypes.shape({
    current: PropTypes.oneOfType([
      PropTypes.instanceOf(Element),
      PropTypes.oneOf([null]),
    ]),
  }),
  handlePrint: PropTypes.func,
};

QuestionnaireReport.defaultProps = {
  printRef: null,
  handlePrint: () => {},
};

export default QuestionnaireReport;
