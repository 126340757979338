/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React from 'react';
import IcomoonReact from 'icomoon-react';
import styled from '@emotion/styled';
import iconSet from '../../../../shared/images/teambuildr-selection.json';
import Text from '../../../../shared/components/Text/Text';

const WarningComponent = ({ containerWidth, message }) => {
  const Wrapper = styled('div')`
    display: flex;
    flex-direction: row;
    box-shadow: 0px 0px 1px rgba(48, 49, 51, 0.05),
      0px 8px 16px rgba(48, 49, 51, 0.1);
    border-radius: 24px;
    width: ${(props) => props.containerWidth < 575 && '100%'};
    max-width: ${(props) => props.containerWidth < 1035 && props.containerWidth > 575 && '240px'};
    padding: ${(props) => (props.containerWidth < 301
    ? '15px'
    : props.containerWidth > 301 && props.containerWidth < 1035
      ? '25px'
      : '10px 25px')};
    align-items: center;
    gap: ${(props) => (props.containerWidth < 1035 ? '15px' : '15px')};
    flex-wrap: ${(props) => props.containerWidth < 1035 && 'wrap'};
    flex-grow: 1;
    background-image: linear-gradient(rgb(255, 102, 0), rgb(252, 96, 118));
    max-width: 565px;
    justify-content: center;
  `;

  const TextContainer = styled('span')`
    display: flex;
    justify-content: center;
    align-items: center;
  `;

  return (
    <Wrapper containerWidth={containerWidth}>
      <IcomoonReact iconSet={iconSet} size={40} icon='warning' color='white' />
      <TextContainer>
        <Text fontSize={12} fontWeight={700} color='white'>
          {message}
        </Text>
      </TextContainer>
    </Wrapper>
  );
};

export default WarningComponent;
