/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import Helmet from 'react-helmet';
import { batch, useDispatch, useSelector } from 'react-redux';
import IcomoonReact from 'icomoon-react';
import { Prompt } from 'react-router-dom';
import { useTheme } from 'emotion-theming';

import { set } from 'lodash';
import iconSet from '../../shared/images/teambuildr-selection.json';
import HeaderText from '../../shared/components/HeaderText/HeaderText';
import SharedTemplatesGrid from '../sports-science/components/presentational/sharedTemplateComponents/SharedTemplatesGrid';
import SharedTemplateTriOptionMenuButton from '../sports-science/components/presentational/sharedTemplateComponents/SharedTemplateTriOptionMenuButton';
import PerformanceOverview from './components/presentational/PerformanceOverview';
import GroupAnalysis from './components/presentational/GroupAnalysis';
import NewFPDashForm from './NewFPDashForm';
import SharedTemplateAboutModal from '../sports-science/components/presentational/sharedTemplateComponents/SharedTemplateAboutModal';
import SharedTemplateDeleteModal from '../sports-science/components/presentational/sharedTemplateComponents/SharedTemplateDeleteModal';
import SharedTemplateDuplicateModal from '../sports-science/components/presentational/sharedTemplateComponents/SharedTemplateDuplicateModal';
import SharedTemplateEditModal from '../sports-science/components/presentational/sharedTemplateComponents/SharedTemplateEditModal';
import SharedTemplateSaveModal from '../sports-science/components/presentational/sharedTemplateComponents/SharedTemplateSaveModal';
import SharedTemplateShareModal from '../sports-science/components/presentational/sharedTemplateComponents/SharedTemplateShareModal';
import SharedTemplateTriOptionModal from '../sports-science/components/presentational/sharedTemplateComponents/SharedTemplateTriOptionModal';
import MetricInfoModal from './components/presentational/MetricInfoModal';
import LegendInfoModal from './components/presentational/LegendInfoModal';
import { dashboardTracker } from '../../shared/utils/amplitudeHelper';

import {
  fetchGroups,
  fetchFPTemplates,
  setIsDashboardDisplayed,
  setAboutFPDashModal,
  setDeleteFPDashboardModal,
  setDuplicateFPDashModal,
  setEditFPDashModal,
  setIsNewFPDash,
  setUnsavedFPDashModal,
  setShareFPDashModal,
  setNextLocation,
  setForcePlateView,
  setActiveFPTemplate,
  setGroups,
  fetchGroupAnalysis,
  fetchPerformanceOverview,
  fetchMetricCardData,
  setFlaggedAthletesView,
  setGroupFilterRadio,
  clearActiveFPDashboard,
  setGroup,
  setMetricFiltersGroup,
  setMetricFiltersPerformance,
  setKeyPerformanceMetrics,
  clearFPDashModals,
} from './ducks/forcePlateDashboardActions';

const Container = styled('div')`
  width: 100%;
`;

const PageWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const DashboardHeader = styled('div')`
  display: flex;
  width: 100%;
  background-color: ${(props) => props.bgColor};
  padding-top: 30px;
  padding-left: 20px;
  justify-content: space-between;
  align-items: flex-end;
`;

const MenuContainer = styled('div')`
  display: flex;
`;

const MenuItem = styled('button')`
  border: none;
  margin-left: 10px;
  margin-right: 12px;
  font-family: "Nunito Sans";
  font-weight: 400;
  font-size: 16px;
  border-bottom: ${(props) => (props.isActive ? '3px solid #1787FB!important' : '3px solid white')};
  color: ${(props) => (!props.isActive ? '#9E9E9E' : '#444444')};
`;

const SearchContainer = styled('input')`
  display: flex;
  height: 40px;
  border: none;
  background: none;
  padding-left: 5px;
`;

const SearchAndIcon = styled('div')`
  display: flex;
  align-items: center;
  border: ${(props) => (props.isFocused ? '1px solid   #5F8BDC' : '1px solid #EEEEEE')};
  border-radius: 50px;
  background: white;
  transition: max-width 0.5s ease;
  max-width: ${(props) => (props.isFocused ? '250px' : '40px')};
  overflow: hidden;
`;

const IconsAndSearch = styled('div')`
  display: flex;
  align-items: center;
`;

const HeaderWidthContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const SearchIconContainer = styled('div')`
  padding-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 5px;
`;

const HeaderTextAndArrow = styled('div')`
  display: flex;
  align-items: center;
  padding-bottom: 8px;
`;

const BackButtonContainer = styled('div')`
  cursor: pointer;
`;

const selectKeyMetricOptions = [
  {
    id: 1,
    label: 'Default',
  },
  {
    id: 2,
    label: 'Custom',
  },
];

const ForcePlateDashboard = () => {
  const dispatch = useDispatch();
  const pageRef = useRef(null);
  const theme = useTheme();

  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const isDashboardDisplayed = useSelector(
    (state) => state.forcePlateDashboard.ui.isDashboardDisplayed,
  );
  const nextLocation = useSelector(
    (state) => state.forcePlateDashboard.data.nextLocation,
  );
  const forcePlateView = useSelector(
    (state) => state.forcePlateDashboard.ui.forcePlateView,
  );
  const activeTemplate = useSelector(
    (state) => state.forcePlateDashboard.data.activeTemplate,
  );
  const isNewDash = useSelector(
    (state) => state.forcePlateDashboard.ui.isNewDash,
  );
  const forcePlateTemplates = useSelector(
    (state) => state.forcePlateDashboard.data.templates,
  );
  const group = useSelector((state) => state.forcePlateDashboard.data.group);
  const metricFiltersGroup = useSelector(
    (state) => state.forcePlateDashboard.data.metricFiltersGroup,
  );
  const metricFiltersPerformance = useSelector(
    (state) => state.forcePlateDashboard.data.metricFiltersPerformance,
  );
  const keyPerformanceMetrics = useSelector(
    (state) => state.forcePlateDashboard.data.keyPerformanceMetrics,
  );
  const startDateData = useSelector(
    (state) => state.forcePlateDashboard.data.startDateData,
  );
  const endDateData = useSelector(
    (state) => state.forcePlateDashboard.data.endDateData,
  );
  const aboutModalShowing = useSelector(
    (state) => state.forcePlateDashboard.ui.aboutModalShowing,
  );
  const deleteModalShowing = useSelector(
    (state) => state.forcePlateDashboard.ui.deleteModalShowing,
  );
  const duplicateModalShowing = useSelector(
    (state) => state.forcePlateDashboard.ui.duplicateModalShowing,
  );
  const editModalShowing = useSelector(
    (state) => state.forcePlateDashboard.ui.editModalShowing,
  );
  const saveModalShowing = useSelector(
    (state) => state.forcePlateDashboard.ui.saveModalShowing,
  );
  const shareModalShowing = useSelector(
    (state) => state.forcePlateDashboard.ui.shareModalShowing,
  );
  const triOptionModalShowing = useSelector(
    (state) => state.forcePlateDashboard.ui.triOptionModalShowing,
  );
  const isLegendInfoModalShowing = useSelector(
    (state) => state.forcePlateDashboard.ui.isLegendInfoModalShowing,
  );
  const isMetricInfoModalShowing = useSelector(
    (state) => state.forcePlateDashboard.ui.isMetricInfoModalShowing,
  );

  const [searchTerm, setSearchTerm] = useState('');
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  // Form Values for New Dashboard declared here for use in handleReturnClick function
  const [selectedChartMetrics, setSelectedChartMetrics] = useState([]);
  const [keyMetricSelection, setKeyMetricSelection] = useState(
    selectKeyMetricOptions[0],
  );
  /** Default KPI metrics used in performance overview
   *Selected key metrics also set group analysis table metrics on load of dashboard */
  const [selectedKeyMetrics, setSelectedKeyMetrics] = useState([
    'Jump Height',
    'Takeoff Velocity',
    'Peak Power',
    'L|R Concentric Impulse',
    'mRSI',
  ]);

  const handleMenuClick = (view) => {
    if (view !== forcePlateView) {
      if (view === 'group' && group) {
        dispatch(
          fetchGroupAnalysis(
            currentUser.accountCode,
            startDateData,
            endDateData,
            group.id,
            metricFiltersGroup,
          ),
        );
      } else if (view === 'performance' && group) {
        batch(() => {
          dispatch(
            fetchPerformanceOverview(
              currentUser.accountCode,
              startDateData,
              endDateData,
              group.id,
              metricFiltersPerformance,
            ),
          );
          dispatch(
            fetchMetricCardData(
              currentUser.accountCode,
              startDateData,
              endDateData,
              group.id,
              keyPerformanceMetrics,
            ),
          );
        });
      }
      batch(() => {
        dispatch(setForcePlateView(view));
        dispatch(setFlaggedAthletesView(false));
        dispatch(setGroupFilterRadio('all'));
      });
      // Amplitude tracking
      dashboardTracker(
        'Force Plate Dashboard',
        view === 'group' ? 'Group Analysis' : 'Performance Overview',
        'Menu item clicked',
      );
    }
  };

  useEffect(() => {
    dispatch(setIsNewFPDash(false));
    dispatch(setIsDashboardDisplayed(false));
    dispatch(clearFPDashModals());
  }, []);

  useEffect(() => {
    if (Object.keys(currentUser).length) {
      dispatch(fetchFPTemplates(currentUser.accountCode));
    }
  }, [currentUser]);

  useEffect(() => {
    if (!isDashboardDisplayed && currentUser) {
      dispatch(fetchGroups(currentUser.accountCode));
    }
  }, [isDashboardDisplayed, currentUser]);

  // Handle scrolling back to top for performance overview screen
  const scrollTop = () => {
    setTimeout(
      () => pageRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      }),
      250,
    );
  };

  useEffect(() => {
    if (isDashboardDisplayed) {
      scrollTop();
    }
  }, [isDashboardDisplayed]);

  const unsavedDash = isDashboardDisplayed && !isNewDash && !activeTemplate;

  // Handles if a user tries to leave the site, or navigate to PHP page, with an unsaved dashboard
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();

      // Note, custom messages do not work for Chrome
      const customMessage = `This dashboard has not been saved, are you sure you want to exit without saving?
      Exiting now will discard any unsaved progress. If you have made any updates,
      close this modal to review and save your changes.`;

      return customMessage;
    };

    if (unsavedDash) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    }

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [unsavedDash]);

  // Handles if a user tries to navigate to another React page with an unsaved dashboard
  const handleNavigation = (location) => {
    if (unsavedDash && !nextLocation) {
      batch(() => {
        dispatch(setNextLocation(location.pathname));
        dispatch(setUnsavedFPDashModal(true));
      });
      setSelectedChartMetrics([]);
      setKeyMetricSelection(selectKeyMetricOptions[0]);
      setSelectedKeyMetrics([
        'Jump Height',
        'Takeoff Velocity',
        'Peak Power',
        'L|R Concentric Impulse',
        'mRSI',
      ]);
      return false; // Prevent navigation
    }
    return true; // Allow navigation
  };

  const handleReturnClick = () => {
    if (isNewDash) {
      // When viewing new dashboard form; ensure form state reset
      batch(() => {
        dispatch(setIsNewFPDash(false));
        dispatch(setIsDashboardDisplayed(false));
      });
      setSelectedChartMetrics([]);
      setKeyMetricSelection(selectKeyMetricOptions[0]);
      setSelectedKeyMetrics([
        'Jump Height',
        'Takeoff Velocity',
        'Peak Power',
        'L|R Concentric Impulse',
        'mRSI',
      ]);
      // KPI tracking
      dashboardTracker(
        'Force Plate Dashboard',
        'Back Arrow',
        'Return to main screen clicked from form',
      );
    } else if (unsavedDash) {
      // When viewing unsaved new dashboard
      dispatch(setIsNewFPDash(true));
      dispatch(setIsDashboardDisplayed(false));
      // KPI tracking
      dashboardTracker(
        'Force Plate Dashboard',
        'Back Arrow',
        'Return to form clicked from unsaved dashboard',
      );
    } else {
      // When viewing saved template
      batch(() => {
        dispatch(clearActiveFPDashboard());
        dispatch(setIsDashboardDisplayed(!isDashboardDisplayed));
      });
      // KPI tracking
      dashboardTracker(
        'Force Plate Dashboard',
        'Main Screen',
        'Return to main screen clicked from saved dashboard template',
      );
    }
  };

  const handleCreateNewDashboard = () => {
    // KPI tracking
    dashboardTracker(
      'Force Plate Dashboard',
      'Main Screen',
      'Create new dashboard clicked',
    );
    batch(() => {
      dispatch(clearActiveFPDashboard());
      dispatch(setGroup(null));
      dispatch(setIsNewFPDash(true));
    });
  };

  /** Only sending metric names to BE; get duplicates due to
   * some metrics having multiple DB entries for different unit values */
  const uniqueFilters = (filterArray) => {
    if (!filterArray) return [];
    const uniqueArr = [];
    filterArray.forEach((filterObj) => {
      if (!uniqueArr.some((obj) => obj.name === filterObj.name)) uniqueArr.push(filterObj);
    });
    return uniqueArr;
  };

  const handleTemplateView = (template) => {
    // KPI tracking
    dashboardTracker(
      'Force Plate Dashboard',
      'Main Screen',
      'Saved dashboard opened',
    );
    const isOwner = currentUser?.id === template?.createdBy;
    const templateFilterMetrics = uniqueFilters(
      template.chartForcePlateMetrics,
    ).map((metric) => metric.name.replace(/\s/g, ''));
    const templateKeyMetrics = uniqueFilters(template.kpiForcePlateMetrics).map(
      (metric) => metric.name,
    );
    const templateKeyMetricsFormatted = templateKeyMetrics.map((metric) => metric.replace(/\s/g, ''));
    batch(() => {
      dispatch(setActiveFPTemplate(template));
      dispatch(setGroup(null));
      /** Dictates template group picker items
       * If group access is private, template owner will have
       * access to all their reportable groups; non-owners shouldn't have any access
       * Otherwise, groups will be intersection of template groups
       * and user's reportable group access */
      if (template.groups.length || !isOwner) {
        dispatch(setGroups(template.groups));
      }
      dispatch(setMetricFiltersGroup([...templateKeyMetricsFormatted]));
      dispatch(setMetricFiltersPerformance([...templateFilterMetrics]));
      dispatch(setKeyPerformanceMetrics([...templateKeyMetrics]));
      dispatch(setIsDashboardDisplayed(true));
    });
  };

  const templateActions = {
    handleCreateNewDashboard,
    handleTemplateView,
    setDeleteModal: setDeleteFPDashboardModal,
    setAboutModal: setAboutFPDashModal,
    setEditModal: setEditFPDashModal,
    setDuplicateModal: setDuplicateFPDashModal,
    setShareModal: setShareFPDashModal,
    clearActiveTemplate: clearActiveFPDashboard,
  };

  return (
    <Container
      onClick={(e) => {
        if (
          e.target.id !== 'search-container'
          && e.target.id !== 'search-icon-container'
          && e.target.id !== 'search-container-input'
          && e.target.id !== 'mag-icon'
          && isSearchFocused
          && searchTerm.length === 0
        ) {
          setIsSearchFocused(false);
        }
      }}
    >
      {aboutModalShowing && (
        <SharedTemplateAboutModal dashboard='Force Plate' />
      )}
      {deleteModalShowing && (
        <SharedTemplateDeleteModal dashboard='Force Plate' />
      )}
      {duplicateModalShowing && (
        <SharedTemplateDuplicateModal dashboard='Force Plate' />
      )}
      {editModalShowing && <SharedTemplateEditModal dashboard='Force Plate' />}
      {saveModalShowing && <SharedTemplateSaveModal dashboard='Force Plate' />}
      {shareModalShowing && (
        <SharedTemplateShareModal dashboard='Force Plate' />
      )}
      {triOptionModalShowing && (
        <SharedTemplateTriOptionModal dashboard='Force Plate' />
      )}
      {isMetricInfoModalShowing && <MetricInfoModal />}
      {isLegendInfoModalShowing && <LegendInfoModal />}
      <Helmet>
        <title>Force Plate Dashboard | TeamBuildr</title>
      </Helmet>
      <PageWrapper ref={pageRef}>
        <DashboardHeader bgColor={theme.colors.background[0]}>
          <HeaderWidthContainer>
            <HeaderTextAndArrow>
              {isDashboardDisplayed || isNewDash ? (
                <BackButtonContainer>
                  <IcomoonReact
                    iconSet={iconSet}
                    size={20}
                    icon='left-arrow'
                    onClick={handleReturnClick}
                  />
                </BackButtonContainer>
              ) : null}
              <HeaderText
                fontWeight='300'
                letterSpacing='normal'
                style={{
                  marginLeft: 10,
                }}
              >
                Force Plate Dashboard
              </HeaderText>
            </HeaderTextAndArrow>
            {!isDashboardDisplayed && !isNewDash && (
              <MenuContainer>
                <IconsAndSearch>
                  <MenuItem>
                    <SearchAndIcon
                      id='search-container'
                      onClick={() => setIsSearchFocused(true)}
                      isFocused={isSearchFocused}
                    >
                      <SearchIconContainer id='search-icon-container'>
                        <IcomoonReact
                          iconSet={iconSet}
                          size={20}
                          icon='magnifying-glass-alt'
                          id='mag-icon'
                        />
                      </SearchIconContainer>
                      <SearchContainer
                        placeholder='Search'
                        id='search-container-input'
                        value={searchTerm}
                        onFocus={() => setIsSearchFocused(true)}
                        onChange={(inputValue) => {
                          setSearchTerm(inputValue.target.value);
                        }}
                      />
                    </SearchAndIcon>
                  </MenuItem>
                </IconsAndSearch>
              </MenuContainer>
            )}
            {!isNewDash && isDashboardDisplayed && (
              <MenuContainer>
                <MenuItem
                  isActive={forcePlateView === 'performance'}
                  onClick={() => handleMenuClick('performance')}
                >
                  Performance Overview
                </MenuItem>
                <MenuItem
                  isActive={forcePlateView === 'group'}
                  onClick={() => handleMenuClick('group')}
                >
                  Group Analysis
                </MenuItem>
              </MenuContainer>
            )}
          </HeaderWidthContainer>
        </DashboardHeader>
        {!isDashboardDisplayed && !isNewDash && (
          <SharedTemplatesGrid
            searchTerm={searchTerm}
            dashboard='Force Plate'
            templates={forcePlateTemplates}
            actions={templateActions}
          />
        )}
        {isDashboardDisplayed && forcePlateView === 'performance' && (
          <PerformanceOverview />
        )}
        {isDashboardDisplayed && forcePlateView === 'group' && (
          <GroupAnalysis />
        )}
        {!isDashboardDisplayed && isNewDash && (
          <NewFPDashForm
            selectedChartMetrics={selectedChartMetrics}
            setSelectedChartMetrics={setSelectedChartMetrics}
            selectKeyMetricOptions={selectKeyMetricOptions}
            keyMetricSelection={keyMetricSelection}
            setKeyMetricSelection={setKeyMetricSelection}
            selectedKeyMetrics={selectedKeyMetrics}
            setSelectedKeyMetrics={setSelectedKeyMetrics}
          />
        )}
        {isDashboardDisplayed && (
          <SharedTemplateTriOptionMenuButton dashboard='Force Plate' />
        )}
      </PageWrapper>
      <Prompt when={unsavedDash} message={handleNavigation} />
    </Container>
  );
};

export default ForcePlateDashboard;
