import React, {
  useMemo, useCallback,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from 'react-router-dom';
import Checkbox from '@material-ui/core/Checkbox';

import SubText from '../../../../shared/components/SubText/SubText';
import Button from '../../../../shared/components/Button/Button';
import Table from '../../../../shared/components/Table/Table';
import iconSet from '../../../../shared/images/teambuildr-selection.json';
import useWindowSize from '../../../../shared/hooks/useWindowSize';
import ChildTableWrapper from './ChildTableWrapper';

import {
  setActiveReduxModal,
  setCurrentRowRedux,
  setSingleActionRedux,
  setECDictionaryRedux,
  setSelectedChildRowsRedux,
} from '../../ducks/calendarsActions';

const DropDownMenuWrapper = styled('div')`
  width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20%;
  color: #333333;
  transition: none;
  .dropdown-item {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    transition: none;
    span {
      color: #999999;
    }
    .drop-icon-text {
      margin-left: 10px;
      padding-top: 2px;
    }
    :hover {
      background: #00b371;
      color: white;
      span {
        color: white;
      }
      svg {
        fill: white;
      }
    }
  }
  .dropdown-menu {
    padding: 0px;
  }
  .dropdown-divider {
    margin: 0px;
  }
`;

const UserCount = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  cursor: pointer;
  :hover {
    /* background: grey; */
    /* background-color: rgba(255, 0, 0, 0.6); */
  }
`;

/**
   * this is a custom dropdown toggle for the dropdown menu
   * it causes the toggle to appear as the 3 dots instead of the defualt
   * graphic, which is some kind of button I believe
   */
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  // eslint-disable-next-line jsx-a11y/anchor-is-valid
  <Link
    href=''
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    <IcomoonReact
      iconSet={iconSet}
      size={25}
      icon='dots'
      color='grey'
    />
  </Link>
));

const calendarUsersUrl = (calId) => `${process.env.PHP_APP_URL}/manage_users?calId=${calId}`;

const CustomTable = ({
  displayCalendars,
  clearRowToggle,
  isArchivedShowing,
  setSelectedRows,
  activeSearchString,
  childrenWithGenParents,
  defaultTablePage,
  defaultPageResetter,
  rowsPerPage,
  setRowsPerPage,
  setDefaultTablePage,
}) => {
  const expandCollapseDictionary = useSelector(
    (state) => state.calendars.data.expandCollapseDictionary,
  );
  const selectedChildRows = useSelector(
    (state) => state.calendars.data.selectedChildRows,
  );
  const selectedRows = useSelector(
    (state) => state.calendars.data.selectedRows,
  );
  const currentUser = useSelector((state) => state.auth.data.currentUser);

  const dispatch = useDispatch();

  const setCurrentRow = (rowSelection) => {
    dispatch(setCurrentRowRedux(rowSelection));
  };

  const setActiveModal = (activeModalString) => {
    dispatch(setActiveReduxModal(activeModalString));
  };

  const setSingleAction = (bool) => {
    dispatch(setSingleActionRedux(bool));
  };

  const setExpandCollapseDictionary = (dict) => {
    dispatch(setECDictionaryRedux(dict));
  };

  const setSelectedChildRows = (rowSelection) => {
    dispatch(setSelectedChildRowsRedux(rowSelection));
  };

  const windowWidth = useWindowSize().width;

  /**
   * this function keeps track of which rows are selected, and reduces the selection
   * to an array of calendar IDs which can then be passed to archive or delete functions
   */
  const handleSelectedRowChange = useCallback((selections) => {
    const oldDictionary = selectedRows;
    const oldChildDictionary = selectedChildRows;
    const newDictionary = {};
    const newChildDictionary = {};
    const selectionsDictionary = {};
    selections.selectedRows.forEach((selection) => {
      selectionsDictionary[selection.id] = selection;
      if (selection.newChildCalendars === undefined) {
        newChildDictionary[selection.id] = selection;
        newChildDictionary[selection.id].monkey = true;
      } else {
        newDictionary[selection.id] = selection;
      }
      if (!oldDictionary[selection.id]) {
        if (selection.newChildCalendars && selection.newChildCalendars.length) {
          selection.newChildCalendars.forEach((childCalendar) => {
            newChildDictionary[childCalendar.id] = childCalendar;
          });
        }
      }
    });
    const oldChildDictionaryKeys = Object.keys(oldChildDictionary);
    const newChildDictionaryKeys = Object.keys(newChildDictionary);
    Object.keys(oldChildDictionary).forEach((childCalId) => {
      if (!newChildDictionaryKeys.includes(childCalId)) {
        newChildDictionary[childCalId] = oldChildDictionary[childCalId];
      }
    });
    const newDictionaryKeys = Object.keys(newDictionary);
    const oldDictionaryKeys = Object.keys(oldDictionary);
    Object.keys(newChildDictionary).forEach((childCalId) => {
      const { parentId } = newChildDictionary[childCalId];
      const negativeParentId = -parentId;
      const parentIdString = parentId.toString();
      const negativeParentIdString = negativeParentId.toString();
      if ((!newDictionaryKeys.includes(parentIdString)
      && !newDictionaryKeys.includes(negativeParentIdString))
        && (oldDictionaryKeys.includes(parentIdString)
        || oldDictionaryKeys.includes(negativeParentIdString))) {
        delete newChildDictionary[childCalId];
      }
    });
    if (activeSearchString) {
      oldChildDictionaryKeys.forEach((childKey) => {
        if (!selectionsDictionary[childKey]
          && (newChildDictionary[childKey] && newChildDictionary[childKey].monkey)) {
          delete newChildDictionary[childKey];
        }
      });
    }
    setSelectedChildRows(newChildDictionary);
    setSelectedRows(newDictionary);
  });

  /**
   * cells for the table:
   * orientation arrow, icon, name, user count, created date, workout visability, dropdown toggle
   */
  const columns = useMemo(() => [
    {
      cell: (row) => {
        if (!row.parentId) {
          return (
            <IcomoonReact
              iconSet={iconSet}
              size={20}
              icon='calendar'
            />
          );
        }
        if (row.parentId) {
          return (
            <IcomoonReact
              iconSet={iconSet}
              size={20}
              icon='sub-arrow'
            />
          );
        }
        return null;
      },
      width: '65px',
      omit: windowWidth <= 768,
    },
    {
      cell: (row) => {
        if (row.parentName && activeSearchString.length) {
          return `${row.parentName}/${row.name}`;
        }
        return row.name;
      },
      name: 'Name',
      selector: 'name',
      sortable: true,
      sortFunction: (a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }), // case insensitive sort
      style: {
        'font-weight': 'bold',
      },
    },
    {
      name: 'User Count',
      cell: (row) => <UserCount onClick={() => window.open(calendarUsersUrl(row.id), '_blank')}>{row.userCount}</UserCount>,
      selector: 'userCount',
      sortable: true,
      style: {
        'text-align': 'center',
        'margin-right': '10px',
      },
      center: true,
      omit: windowWidth <= 768,
    },
    {
      name: 'Visibility',
      selector: 'workoutVisibilityDescription',
      sortable: true,
      center: true,
      style: {
        'text-align': 'center',
        'margin-right': '10px',
      },
      omit: windowWidth <= 768,
    },
    {
      name: 'Date Created',
      selector: ((row) => (row.createdAt ? row.createdAt : '-')),
      sortable: true,
      sortFunction: (a, b) => {
        // if either date is null, treat as oldest date
        if (a.createdAt === null) return -1;
        if (b.createdAt === null) return 1;
        return new Date(a.createdAt) - new Date(b.createdAt);
      },
      style: {
        'text-align': 'center',
        'margin-right': '25px',
      },
      center: true,
      omit: windowWidth <= 768,
    },
    {
      cell: (row) => {
        // only shows dropdown toggle if the row isn't a generic calendar
        if (row.id && !row.generic) {
          /**
           * defines the dropdown with the correct options
           * (only shows update option if calendar is active)
           * swaps archive for unarchive if in archived view
           */
          return (
            <DropDownMenuWrapper>
              <Dropdown
                bsPrefix='trick-fix'
              >
                <Dropdown.Toggle as={CustomToggle} />
                <Dropdown.Menu>
                  {!isArchivedShowing ? (
                    <Dropdown.Item
                      className='dropdown-item'
                      onClick={() => {
                        setCurrentRow(row);
                        setActiveModal('update_cal_modal');
                      }}
                    >
                      <IcomoonReact
                        iconSet={iconSet}
                        size={12}
                        icon='pencil'
                      />
                      <SubText
                        className='drop-icon-text'
                        fontSize={14}
                      >
                        Update
                      </SubText>
                    </Dropdown.Item>
                  ) : null}
                  {!isArchivedShowing ? <Dropdown.Divider /> : null}
                  {!isArchivedShowing || (isArchivedShowing && !row.isParentArchived) ? (
                    <Dropdown.Item onClick={() => {
                      const object = {};
                      const rowId = row.id;
                      object[rowId] = row;
                      setSelectedRows(object);
                      setSingleAction(true);
                      setActiveModal('archive_cals_modal');
                    }}
                    >
                      {isArchivedShowing ? (
                        <>
                          <IcomoonReact
                            iconSet={iconSet}
                            size={12}
                            icon='visible'
                          />
                          <SubText
                            className='drop-icon-text'
                            fontSize={14}
                          >
                            Unarchive
                          </SubText>
                        </>
                      ) : (
                        <>
                          <IcomoonReact
                            iconSet={iconSet}
                            size={12}
                            icon='invisible'
                          />
                          <SubText
                            className='drop-icon-text'
                            fontSize={14}
                          >
                            Archive
                          </SubText>
                        </>
                      )}
                    </Dropdown.Item>
                  ) : null}
                  <Dropdown.Divider />
                  <Dropdown.Item onClick={() => {
                    const object = {};
                    const rowId = row.id;
                    object[rowId] = row;
                    setSelectedRows(object);
                    setSingleAction(true);
                    setActiveModal('delete_cals_modal');
                  }}
                  >
                    <IcomoonReact
                      iconSet={iconSet}
                      size={12}
                      icon='trashcan'
                    />
                    <SubText
                      className='drop-icon-text'
                      fontSize={14}
                    >
                      Delete
                    </SubText>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </DropDownMenuWrapper>
          );
        }
        return null;
      },
      right: true,
      width: '75px',
    },
  ]);

  // this is a custom component that displays when displayCalendars has no length
  const customNoRecordComponent = () => {
    const TextBox = styled('div')`
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      margin-top: 20px;
      font-size: 20px;
    `;
    const TextAndIcon = styled('div')`
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      margin-top: 100px;
      .alt-create-cal-button {
        margin-top: 30px;
        :hover {
          background: #00b371;
          border-color: #00b371;
        }
      }
    `;
    return (
      <TextAndIcon>
        <IcomoonReact
          iconSet={iconSet}
          size={100}
          icon='calendar'
        />
        <TextBox>
          No calendars available
        </TextBox>
        {!isArchivedShowing
        && (currentUser.calAccess === null
          || (currentUser.calAccess && currentUser.calAccess.length)) ? (
            <Button
              className='alt-create-cal-button'
              onClick={() => {
                setActiveModal('create_cal_modal');
              }}
              cta='Add Calendar'
            />
          ) : null}
      </TextAndIcon>
    );
  };

  /**
   * this array is passed to the react data table component, and styles the rows
   * differently depending on whether they meet certain criteria.  currently, I'm giving
   * child calendars sharp edges and clumping them together under the parent calendar
   */
  const conditionalRowStyles = [
    {
      when: (row) => row,
      style: {
        paddingLeft: '10px',
      },
    },
  ];

  /**
   * custom styling for the entire table -
   * overFloxX hidden prevents the elements from being pushed to the
   * right when the dropdown appears, causing a scrollbar to appear
   * at the bottom of the table
   * padding bottom adds room for the dropdown menu when there's only a single
   * calendar in the table
   */
  const customTableStyle = {
    overflowX: 'hidden',
    paddingBottom: '130px',
  };

  return (
    <Table
      columns={columns}
      data={displayCalendars}
      selectableRows={!!currentUser.admin}
      // selectableRowDisabled={(row) => row.generic || row.id === null}
      highlightOnHover
      pointerOnHover
      onRowClicked={(row) => {
        if (!isArchivedShowing) {
          setCurrentRow(row);
          setActiveModal('update_cal_modal');
        }
      }}
      pagination
      paginationRowsPerPageOptions={[10, 20]}
      paginationPerPage={rowsPerPage}
      paginationDefaultPage={defaultTablePage}
      paginationResetDefaultPage={defaultPageResetter}
      onChangePage={(a, b) => setDefaultTablePage(a)}
      selectableRowSelected={(row) => (
        row.id ? Object.keys(selectedRows).includes(row.id.toString()) : null
      )}
      onChangeRowsPerPage={(a, b) => setRowsPerPage(a)}
      selectableRowsVisibleOnly
      noHeader
      onSelectedRowsChange={(rows) => handleSelectedRowChange(rows)}
      clearSelectedRows={clearRowToggle}
      conditionalRowStyles={conditionalRowStyles}
      style={customTableStyle}
      noDataComponent={customNoRecordComponent()}
      expandableRows={(row) => row.newChildCalendars.length}
      expandableRowDisabled={
        (row) => (!row.newChildCalendars
          || row.newChildCalendars.length === 0)
          || activeSearchString
      }
      onRowExpandToggled={(toggleState, row) => {
        const newExpandCollapseDictionary = expandCollapseDictionary;
        newExpandCollapseDictionary[row.id] = toggleState;
        setExpandCollapseDictionary(newExpandCollapseDictionary);
      }}
      expandableRowExpanded={
        (row) => {
          const rowId = row.id;
          return expandCollapseDictionary[rowId];
        }
      }
      selectableRowsComponent={Checkbox}
      selectableRowsComponentProps={{ color: 'primary', size: 'small' }}
      expandableRowsComponent={(
        <ChildTableWrapper
          activeSearchString={activeSearchString}
          isArchivedShowing={isArchivedShowing}
          setCurrentRow={setCurrentRow}
          setActiveModal={setActiveModal}
          childrenWithGenParents={childrenWithGenParents}
        />
      )}
    />
  );
};

CustomTable.propTypes = {
  displayCalendars: PropTypes.instanceOf(Array).isRequired,
  clearRowToggle: PropTypes.bool.isRequired,
  isArchivedShowing: PropTypes.bool.isRequired,
  setSelectedRows: PropTypes.func.isRequired,
  activeSearchString: PropTypes.string.isRequired,
  childrenWithGenParents: PropTypes.instanceOf(Array).isRequired,
};

export default CustomTable;
