/* eslint-disable no-tabs */
import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const SharedModalOverlay = styled('div')`
  height: 100vh;
  width: 100vw;
  position: absolute;
  background-color: rgba(216, 216, 216, 0.8);
  z-index: 1002;
  pointer-events: ${(props) => (props.isActive ? 'auto' : 'none')};
  transition: opacity 0.3s ease;
  opacity: ${(props) => (props.isActive ? 0.5 : 0)};
  margin-top: ${(props) => (props.offset ? '-31px' : '0')};
  margin-left: ${(props) => (props.offset ? '-31px' : '0')};
`;

const SharedTemplateModalContainer = ({
  isModalShowing,
  onClose,
}) => (
  <SharedModalOverlay
    isActive={isModalShowing}
    onClick={() => onClose()}
  />
);

SharedTemplateModalContainer.propTypes = {
  isModalShowing: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default SharedTemplateModalContainer;
