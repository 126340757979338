/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import Text from '../../../../shared/_TBComponents/Text';

const AveragesCard = ({ textToDisplay, value, containerWidth }) => {
  const CardWrapper = styled('div')`
    min-height: 158px;
    max-height: 160px;
    width: ${(props) => (props.containerWidth <= 930 ? '160px' : '191px')};
    min-width: 160px;
    background: #FFFFFF;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    flex-grow: ${(props) => (props.containerWidth <= 820 && '1')};
  `;

  const TextContainer = styled('div')`
    background: #424242;
    border-radius: 20px 20px 0px 0px;
    padding: 5px;
    padding-top: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  `;

  const ValueContainer = styled('div')`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
  `;

  return (
    <CardWrapper containerWidth={containerWidth}>
      <TextContainer>
        <Text styledTextProps={{ color: 'white', fontSize: '12px', fontWeight: 700 }}>{textToDisplay}</Text>
      </TextContainer>
      {value && value !== '-' ? (
        <ValueContainer>
          <Text styledTextProps={{ fontSize: '40px', fontWeight: 900 }}>{value}</Text>
        </ValueContainer>
      ) : (
        <ValueContainer style={{ flexDirection: 'column' }}>
          <Text styledTextProps={{ fontSize: '14px', fontWeight: 700 }}>No Status</Text>
          <Text styledTextProps={{ color: '#D9D9D9', fontWeight: 900 }}>---</Text>
        </ValueContainer>
      )}
    </CardWrapper>
  );
};

AveragesCard.propTypes = {
  textToDisplay: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
};

export default AveragesCard;
