import update from 'immutability-helper';

import {
  FETCH_REPORTABLE_GROUPS_LM_DASH_FULFILLED,
  FETCH_REPORTABLE_GROUPS_LM_DASH_REJECTED,
  FETCH_QUESTIONNAIRES_FOR_ACCOUNT_LM_DASH_FULFILLED,
  FETCH_QUESTIONNAIRES_FOR_ACCOUNT_LM_DASH_REJECTED,
  FETCH_QUESTIONS_FOR_ACCOUNT_LM_DASH_FULFILLED,
  FETCH_QUESTIONS_FOR_ACCOUNT_LM_DASH_REJECTED,
  FETCH_QUESTIONNAIRE_REPORT_SINGLE_DAY_LM_DASH_FULFILLED,
  FETCH_QUESTIONNAIRE_REPORT_SINGLE_DAY_LM_DASH_REJECTED,
  FETCH_QUESTIONNAIRE_REPORT_MULTIDAY_LM_DASH_FULFILLED,
  FETCH_QUESTIONNAIRE_REPORT_MULTIDAY_LM_DASH_REJECTED,
  SET_CURRENT_QUESTION_IN_DONUT_CHART_QUESTIONNAIRE_PICKER,
  SET_CURRENT_DATE_IN_DASH_DATE_PICKER,
  SET_CURRENT_GROUP_IN_GROUP_PICKER,
  SET_CURRENT_ATHLETE_IN_ATHLETE_PICKER,
  SET_ATHLETES_IN_CLICKED_ZONE,
  SET_CURRENT_CHECKED_BOXES,
  FETCH_LOAD_MONITORING_LINE_CHART_FULFILLED,
  FETCH_LOAD_MONITORING_LINE_CHART_REJECTED,
  SET_SHOW_ALL_FOR_DOWNLOAD,
  SET_IS_CHART_SHOWING_ALL_DATA,
  FETCH_LM_TEMPLATES_START,
  FETCH_LM_TEMPLATES_FULFILLED,
  FETCH_LM_TEMPLATES_REJECTED,
  SET_ACTIVE_DASH,
  CLEAR_ACTIVE_LM_DASH,
  CREATE_LM_TEMPLATE_START,
  CREATE_LM_TEMPLATE_FULFILLED,
  CREATE_LM_TEMPLATE_REJECTED,
  SET_EDIT_LM_DASH_MODAL,
  EDIT_LM_TEMPLATE_START,
  EDIT_LM_TEMPLATE_FULFILLED,
  EDIT_LM_TEMPLATE_REJECTED,
  SET_DELETE_LM_TEMPLATE_MODAL,
  DELETE_LM_TEMPLATE_START,
  DELETE_LM_TEMPLATE_FULFILLED,
  DELETE_LM_TEMPLATE_REJECTED,
  SET_SHARE_LM_DASH_MODAL,
  FETCH_SHARE_LM_DASH_LINK_START,
  FETCH_SHARE_LM_DASH_LINK_FULFILLED,
  FETCH_SHARE_LM_DASH_LINK_REJECTED,
  CLEAR_SHARE_LM_DASH_LINK,
  FETCH_PUBLIC_LM_QUESTIONNAIRE_REPORT_MULTIDAY_FULFILLED,
  FETCH_PUBLIC_LM_QUESTIONNAIRE_REPORT_MULTIDAY_REJECTED,
  FETCH_PUBLIC_LM_QUESTIONNAIRE_REPORT_SINGLE_DAY_FULFILLED,
  FETCH_PUBLIC_LM_QUESTIONNAIRE_REPORT_SINGLE_DAY_REJECTED,
  FETCH_PUBLIC_LM_LINE_CHART_FULFILLED,
  FETCH_PUBLIC_LM_LINE_CHART_REJECTED,
  SET_DUPLICATE_LM_DASH_MODAL,
  DUPLICATE_LM_DASH_START,
  DUPLICATE_LM_DASH_FULFILLED,
  DUPLICATE_LM_DASH_REJECTED,
  SET_ABOUT_LM_DASH_MODAL,
  SET_NEXT_LOCATION,
  SET_CHART_DAY_RANGE,
  SET_GROUPS,
  CLEAR_LM_DASH_DATA,
  CLEAR_LM_DASH_MODALS,
} from './actionTypes';

const initialState = {
  /** questionnaire state start */
  reportableGroups: [],
  questionnairesForAccount: [],
  questionsForAccount: [],
  questionnairesForAccountError: {},
  questionsForAccountError: {},
  questionnaireReportData: {},
  questionnaireReportHasData: false,
  questionnaireReportError: {},
  loadMonitoringFormValues: {},
  currentQuestionInDonutChartQuestionnairePicker: {},
  currentDateInDashDatePicker: {},
  currentGroupInGroupPicker: {},
  currentAthleteInAthletePicker: {},
  currentReportType: '',
  currentCheckedBoxes: [],
  athletesAssociatedWithCurrentGroup: [],
  athletesInClickedZone: [],
  loadMonitoringLineChartData: [],
  lineChartHasData: false,
  /** questionnaire state end */
  loadMonitoringLineChartDataError: {},
  showAllForDownload: false,
  isChartShowingAllData: null,
  templates: [],
  templatesError: null,
  activeTemplate: null,
  createTemplateError: null,
  editTemplate: {},
  editError: null,
  deleteTemplate: {},
  deleteError: null,
  shareTemplate: {},
  shareDashLink: null,
  shareDashLinkError: null,
  duplicateTemplate: {},
  duplicateError: null,
  aboutTemplate: null,
  nextLocation: null,
  dayRange: 7, // controls 7-Day/28-Day toggle for charts
  // PUBLIC DATA USED FOR VIEW-ONLY DASHBOARD
  dashBGColor: null,
  dashCreator: null,
  dashDescription: null,
  dashLogo: null,
  dashTitle: null,
  readOnlyQuestionnaire: '',
  readOnlyQuestions: [],
  readOnlyShowAverages: true,
  readOnlyShowTotals: true,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_REPORTABLE_GROUPS_LM_DASH_FULFILLED: {
      return {
        ...state,
        // reportableGroups: [],
        reportableGroups: action.payload,
      };
    }
    case FETCH_REPORTABLE_GROUPS_LM_DASH_REJECTED: {
      return {
        ...state,
        reportableGroups: [],
      };
    }
    case FETCH_QUESTIONNAIRES_FOR_ACCOUNT_LM_DASH_FULFILLED: {
      return {
        ...state,
        questionnairesForAccount: action.payload,
        questionnairesForAccountError: {},
      };
    }
    case FETCH_QUESTIONNAIRES_FOR_ACCOUNT_LM_DASH_REJECTED: {
      return {
        ...state,
        questionnairesForAccountError: action.payload,
      };
    }
    case FETCH_QUESTIONS_FOR_ACCOUNT_LM_DASH_FULFILLED: {
      return {
        ...state,
        questionsForAccount: action.payload,
        questionsForAccountError: {},
      };
    }
    case FETCH_QUESTIONS_FOR_ACCOUNT_LM_DASH_REJECTED: {
      return {
        ...state,
        questionsForAccountError: action.payload,
      };
    }
    case FETCH_LOAD_MONITORING_LINE_CHART_FULFILLED: {
      return {
        ...state,
        loadMonitoringLineChartData: action.payload.data,
        lineChartHasData: action.payload.hasData,
        loadMonitoringLineChartDataError: {},
      };
    }
    case FETCH_LOAD_MONITORING_LINE_CHART_REJECTED: {
      return {
        ...state,
        loadMonitoringLineChartData: [],
        lineChartHasData: false,
        loadMonitoringLineChartDataError: action.payload.response?.headers?.message || 'An error has occurred',
      };
    }
    case FETCH_QUESTIONNAIRE_REPORT_SINGLE_DAY_LM_DASH_FULFILLED: {
      const { dataRows } = action.payload;
      return {
        ...state,
        /** questionnaire report reflects the return values sent by the api endpoint */
        questionnaireReportData: action.payload,
        questionnaireReportHasData: action.payload.hasData,
        /** questionnaire report form reflects the values sent in the form
         * we can use this for initial values in formik
         */
        loadMonitoringFormValues: action.payload?.values,
        questionnaireReportError: {},
        currentReportType: 'singleDay',
        athletesAssociatedWithCurrentGroup: dataRows,
      };
    }
    case FETCH_QUESTIONNAIRE_REPORT_SINGLE_DAY_LM_DASH_REJECTED: {
      return {
        ...state,
        questionnaireReportData: [],
        questionnaireReportHasData: false,
        athletesAssociatedWithCurrentGroup: [],
        questionnaireReportError: action.payload.response.headers,
        currentReportType: 'singleDay',
      };
    }
    case FETCH_QUESTIONNAIRE_REPORT_MULTIDAY_LM_DASH_FULFILLED: {
      return {
        ...state,
        /** questionnaire report reflects the return values sent by the api endpoint */
        questionnaireReportData: action.payload,
        questionnaireReportHasData: action.payload.hasData,
        /** questionnaire report form reflects the values sent in the form
         * we can use this for initial values in formik
         */
        loadMonitoringFormValues: action.payload.values,
        questionnaireReportError: {},
        currentReportType: 'multiDay',
      };
    }
    case FETCH_QUESTIONNAIRE_REPORT_MULTIDAY_LM_DASH_REJECTED: {
      return {
        ...state,
        questionnaireReportData: [],
        questionnaireReportHasData: false,
        questionnaireReportError: action.payload.response?.headers?.message || 'An error has occurred',
        currentReportType: 'multiDay',
      };
    }
    case SET_CURRENT_QUESTION_IN_DONUT_CHART_QUESTIONNAIRE_PICKER: {
      return {
        ...state,
        currentQuestionInDonutChartQuestionnairePicker: action.payload,
      };
    }
    case SET_CURRENT_DATE_IN_DASH_DATE_PICKER: {
      return {
        ...state,
        currentDateInDashDatePicker: action.payload,
      };
    }
    case SET_CURRENT_GROUP_IN_GROUP_PICKER: {
      return {
        ...state,
        currentGroupInGroupPicker: action.payload,
      };
    }
    case SET_CURRENT_ATHLETE_IN_ATHLETE_PICKER: {
      return {
        ...state,
        currentAthleteInAthletePicker: action.payload,
      };
    }
    case SET_ATHLETES_IN_CLICKED_ZONE: {
      return {
        ...state,
        athletesInClickedZone: action.payload,
      };
    }
    case SET_CURRENT_CHECKED_BOXES: {
      return {
        ...state,
        currentCheckedBoxes: action.payload,
      };
    }
    case SET_SHOW_ALL_FOR_DOWNLOAD: {
      return {
        ...state,
        showAllForDownload: action.payload,
      };
    }
    case SET_IS_CHART_SHOWING_ALL_DATA: {
      return {
        ...state,
        isChartShowingAllData: action.payload,
      };
    }
    case FETCH_LM_TEMPLATES_START: {
      return {
        ...state,
        templatesError: null,
      };
    }
    case FETCH_LM_TEMPLATES_FULFILLED: {
      return {
        ...state,
        templates: action.payload,
        templatesError: null,
      };
    }
    case FETCH_LM_TEMPLATES_REJECTED: {
      return {
        ...state,
        templates: [],
        templatesError: action.payload,
      };
    }
    case SET_ACTIVE_DASH: {
      return {
        ...state,
        activeTemplate: action.payload,
      };
    }
    case CLEAR_ACTIVE_LM_DASH: {
      return {
        ...state,
        activeTemplate: null,
        // CHECK WHAT OTHER VALUES NEED TO BE RESET
      };
    }
    case CREATE_LM_TEMPLATE_START: {
      return {
        ...state,
        createTemplateError: action.payload,
      };
    }
    case CREATE_LM_TEMPLATE_FULFILLED: {
      return {
        ...state,
        activeTemplate: action.payload,
        createTemplateError: null,
        templates: [action.payload, ...state.templates],
      };
    }
    case CREATE_LM_TEMPLATE_REJECTED: {
      return {
        ...state,
        createTemplateError: action.payload,
      };
    }
    case SET_EDIT_LM_DASH_MODAL: {
      return {
        ...state,
        editTemplate: action.payload.template,
      };
    }
    case EDIT_LM_TEMPLATE_START: {
      return {
        ...state,
        editError: null,
      };
    }
    case EDIT_LM_TEMPLATE_FULFILLED: {
      return update(state, {
        templates: {
          [action.payload.index]: {
            $set: action.payload.data,
          },
        },
        editTemplate: {
          $set: {},
        },
        editError: {
          $set: null,
        },
      });
    }
    case EDIT_LM_TEMPLATE_REJECTED: {
      return {
        ...state,
        editError: action.payload,
      };
    }
    case SET_DELETE_LM_TEMPLATE_MODAL: {
      return {
        ...state,
        deleteTemplate: action.payload.template,
      };
    }
    case DELETE_LM_TEMPLATE_START: {
      return {
        ...state,
        deleteError: null,
      };
    }
    case DELETE_LM_TEMPLATE_FULFILLED: {
      return update(state, {
        templates: {
          $splice: [[action.payload.index, 1]],
        },
        deleteTemplate: {
          $set: {},
        },
      });
    }
    case DELETE_LM_TEMPLATE_REJECTED: {
      return {
        ...state,
        deleteError: action.payload,
      };
    }
    case SET_SHARE_LM_DASH_MODAL: {
      return {
        ...state,
        shareTemplate: action.payload.template,
      };
    }
    case FETCH_SHARE_LM_DASH_LINK_START: {
      return {
        ...state,
        shareDashLinkError: null,
      };
    }
    case FETCH_SHARE_LM_DASH_LINK_FULFILLED: {
      return {
        ...state,
        shareDashLink: action.payload,
        shareDashLinkError: null,
      };
    }
    case FETCH_SHARE_LM_DASH_LINK_REJECTED: {
      return {
        ...state,
        shareDashLink: null,
        shareDashLinkError: action.payload,
      };
    }
    case CLEAR_SHARE_LM_DASH_LINK: {
      return {
        ...state,
        shareDashLink: null,
        shareDashLinkError: null,
      };
    }
    case FETCH_PUBLIC_LM_QUESTIONNAIRE_REPORT_MULTIDAY_FULFILLED: {
      const { dashboardInfo } = action.payload;
      return {
        ...state,
        dashBGColor: dashboardInfo.account?.primaryColor,
        dashCreator: dashboardInfo.creator,
        dashDescription: dashboardInfo.dashboardDescription,
        dashLogo: dashboardInfo.account?.logo,
        dashTitle: dashboardInfo.dashboardTitle,
        readOnlyQuestionnaire: dashboardInfo.questionnaire,
        readOnlyQuestions: dashboardInfo.questions,
        readOnlyShowAverages: dashboardInfo.showAverages,
        readOnlyShowTotals: dashboardInfo.showTotals,
        dayRange: dashboardInfo.daysToGoBack,
        currentDateInDashDatePicker: {
          date: dashboardInfo.endDate,
        },
        currentGroupInGroupPicker: {
          ...dashboardInfo.group,
          displayText: dashboardInfo.group.name,
        },
        /** questionnaire report reflects the return values sent by the api endpoint */
        questionnaireReportData: action.payload,
        questionnaireReportHasData: action.payload.hasData,
        /** questionnaire report form reflects the values sent in the form
         * we can use this for initial values in formik
         */
        // loadMonitoringFormValues: action.payload.values,
        questionnaireReportError: {},
        currentReportType: 'multiDay',
      };
    }
    case FETCH_PUBLIC_LM_QUESTIONNAIRE_REPORT_MULTIDAY_REJECTED: {
      return {
        ...state,
        questionnaireReportData: [],
        questionnaireReportHasData: false,
        questionnaireReportError: action.payload.response?.headers?.message || 'An error has occurred',
        currentReportType: 'multiDay',
      };
    }
    case FETCH_PUBLIC_LM_QUESTIONNAIRE_REPORT_SINGLE_DAY_FULFILLED: {
      const {
        account,
        dashboardDescription,
        dashboardTitle,
        creator,
        group,
        questionnaire,
        questions,
        showAverages,
        showTotals,
        daysToGoBack,
        endDate,
      } = action.payload.dashboardInfo;
      const { dataRows } = action.payload;
      return {
        ...state,
        dashBGColor: account?.primaryColor,
        dashCreator: creator,
        dashDescription: dashboardDescription,
        dashLogo: account?.logo,
        dashTitle: dashboardTitle,
        readOnlyQuestionnaire: questionnaire,
        readOnlyQuestions: questions,
        readOnlyShowAverages: showAverages,
        readOnlyShowTotals: showTotals,
        dayRange: daysToGoBack,
        currentDateInDashDatePicker: {
          date: endDate,
        },
        currentGroupInGroupPicker: {
          ...group,
          displayText: group.name,
        },
        currentAthleteInAthletePicker: {
          ...group,
          displayText: group.name,
          isAthlete: false,
        },
        /** questionnaire report reflects the return values sent by the api endpoint */
        questionnaireReportData: action.payload,
        questionnaireReportHasData: action.payload.hasData,
        /** questionnaire report form reflects the values sent in the form
         * we can use this for initial values in formik
         */
        questionnaireReportError: {},
        currentReportType: 'singleDay',
        athletesAssociatedWithCurrentGroup: dataRows,
      };
    }
    case FETCH_PUBLIC_LM_QUESTIONNAIRE_REPORT_SINGLE_DAY_REJECTED: {
      return {
        ...state,
        questionnaireReportData: [],
        questionnaireReportError: action.payload.response?.headers?.message || 'An error has occurred',
        currentReportType: 'singleDay',
      };
    }
    case FETCH_PUBLIC_LM_LINE_CHART_FULFILLED: {
      const { data, hasData } = action.payload;
      return {
        ...state,
        dashBGColor: data.account?.primaryColor,
        dashCreator: data.creator,
        dashDescription: data.dashboardDescription,
        dashLogo: data.account?.logo,
        dashTitle: data.dashboardTitle,
        readOnlyQuestionnaire: data.questionnaire,
        readOnlyQuestions: data.questions,
        readOnlyShowAverages: data.showAverages,
        readOnlyShowTotals: data.showTotals,
        dayRange: data.daysToGoBack,
        currentDateInDashDatePicker: {
          date: data.endDate,
        },
        currentGroupInGroupPicker: {
          ...data.group,
          displayText: data.group.name,
        },
        loadMonitoringLineChartData: data.dashboardData,
        lineChartHasData: hasData,
        loadMonitoringLineChartDataError: {},
      };
    }
    case FETCH_PUBLIC_LM_LINE_CHART_REJECTED: {
      return {
        ...state,
        loadMonitoringLineChartData: [],
        lineChartHasData: false,
        loadMonitoringLineChartDataError: action.payload.response?.headers?.message || 'An error has occurred',
      };
    }
    case SET_DUPLICATE_LM_DASH_MODAL: {
      return {
        ...state,
        duplicateTemplate: action.payload.template,
      };
    }
    case DUPLICATE_LM_DASH_START: {
      return {
        ...state,
        duplicateError: null,
      };
    }
    case DUPLICATE_LM_DASH_FULFILLED: {
      return update(state, {
        templates: {
          $unshift: [action.payload],
        },
      });
    }
    case DUPLICATE_LM_DASH_REJECTED: {
      return {
        ...state,
        duplicateError: action.payload,
      };
    }
    case SET_ABOUT_LM_DASH_MODAL: {
      return {
        ...state,
        aboutTemplate: action.payload.template,
      };
    }
    case SET_NEXT_LOCATION: {
      return {
        ...state,
        nextLocation: action.payload,
      };
    }
    case SET_CHART_DAY_RANGE: {
      return {
        ...state,
        dayRange: action.payload,
      };
    }
    case SET_GROUPS: {
      return {
        ...state,
        reportableGroups: action.payload,
      };
    }
    case CLEAR_LM_DASH_DATA: {
      return {
        ...state,
        questionnaireReportData: [],
        questionnaireReportHasData: false,
        questionnaireReportError: {},
        loadMonitoringFormValues: {},
        currentQuestionInDonutChartQuestionnairePicker: {},
        currentDateInDashDatePicker: {},
        currentGroupInGroupPicker: {},
        currentAthleteInAthletePicker: {},
        currentReportType: '',
        currentCheckedBoxes: [],
        athletesAssociatedWithCurrentGroup: [],
        athletesInClickedZone: [],
        loadMonitoringLineChartData: [],
        lineChartHasData: false,
        loadMonitoringLineChartDataError: {},
        showAllForDownload: false,
        isChartShowingAllData: null,
        dayRange: 7,
        dashBGColor: null,
        dashCreator: null,
        dashDescription: null,
        dashLogo: null,
        dashTitle: null,
        readOnlyQuestionnaire: '',
        readOnlyQuestions: [],
        readOnlyShowAverages: true,
        readOnlyShowTotals: true,
      };
    }
    case CLEAR_LM_DASH_MODALS: {
      return {
        ...state,
        aboutTemplate: null,
        deleteTemplate: {},
        duplicateTemplate: {},
        editTemplate: {},
        shareTemplate: {},
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
