/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { batch, useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import TableToExcel from '@linways/table-to-excel';

import { BoxShadow } from '../../../../shared/GlobalStyles';
import Button from '../../../../shared/components/Button/Button';
import GroupFilter from './GroupFilter';
import ForcePlateDatePicker from './ForcePlateDatePicker';
import {
  fetchGroupAnalysis,
  setDayRange,
  setEndDateCal,
  setEndDateData,
  setFlaggedAthletes,
  setFlaggedAthletesGroup,
  setFlaggedAthletesView,
  setGroupFilterRadio,
  setGroup,
  setSelectedAthleteGroup,
  setShowFlaggedAthletesModalGroup,
  setStartDateCal,
  setStartDateData,
  setSelectedAthletePerformance,
} from '../../ducks/forcePlateDashboardActions';
import GroupAnalysisTableNoData from './GroupAnalysisTableNoData';
import GroupAnalysisTable from './GroupAnalysisTable';
import ForcePlateDropDown from './ForcePlateDropDown';
import FlaggedAthletesModal from './FlaggedAthletesModal';
import Spinner from '../../../../shared/components/Spinner/Spinner';
import { dashboardTracker } from '../../../../shared/utils/amplitudeHelper';

const DashboardContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin: 15px;
  background-color: #FFFFFF;
  border-radius: 10px;
  padding: 16px;
  box-shadow: ${BoxShadow};
  height: 100%;
  position: relative;
`;

const OptionsHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 10px;
`;

const MenuOptionsContainer = styled('div')`
  display: flex;
  flex-wrap: wrap;
`;

const DateOptionsContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;

  .downloadBtn {
    border: 1px solid #808080;
    border-radius: 50%;
    height: 35px;
    width: 35px;
    padding: 3px;
    margin-left: 10px;
  }
`;

const DaySelector = styled('div')`
  display: flex;
  padding: 8px;
  border: solid 1px #979797;
  border-radius: 4px;
  margin-right: 10px;
`;

const DayOption = styled('button')`
  border: none;
  font-family: 'Nunito Sans';
  font-size: 16px;
  color: ${(props) => (!props.isActive ? '#9E9E9E' : null)};
`;

const DaySpacer = styled('span')`
  color: #9E9E9E;
  font-family: 'Nunito Sans';
  font-size: 16px;
  margin: 0px 5px;
`;

const ColorNote = styled('div')`
  color: #444444;
  font-family: 'Nunito Sans';
  font-size: 14px;
  font-weight: normal;
`;

const NoDataModal = styled('div')`
  position: absolute;
  text-align: center;
  top: 50%;
  left: 50%;
  margin: -45px 0px 0px -182px;
  height: 119px;
  width: 400px;
  background-color: #FFFFFF;
  border-radius: 8px;
  box-shadow: ${BoxShadow};
  font-family: 'Nunito Sans';
  font-size: 19px;
  color: #424242;
  padding: 15px;
`;

const ModalTitle = styled('div')`
  font-weight: bold;
`;

const ModalMessage = styled('div')`
  font-weight: 300;
`;

const GroupAnalysis = () => {
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const dayRange = useSelector((state) => state.forcePlateDashboard.data.dayRange);
  const endDateCal = useSelector((state) => state.forcePlateDashboard.data.endDateCal);
  const endDateData = useSelector((state) => state.forcePlateDashboard.data.endDateData);
  const groupAnalysisAthleteData = useSelector((
    state,
  ) => state.forcePlateDashboard.data.groupAnalysisAthleteData);
  const groupAnalysisAverageData = useSelector((
    state,
  ) => state.forcePlateDashboard.data.groupAnalysisAverageData);
  const flaggedAthletesData = useSelector((
    state,
  ) => state.forcePlateDashboard.data.flaggedAthletesData);
  const flaggedAthletesDataGroup = useSelector((
    state,
  ) => state.forcePlateDashboard.data.flaggedAthletesDataGroup);
  const group = useSelector((state) => state.forcePlateDashboard.data.group);
  const groups = useSelector((state) => state.forcePlateDashboard.data.groups);
  const isFlaggedAthletesModalShowingGroup = useSelector((
    state,
  ) => state.forcePlateDashboard.ui.isFlaggedAthletesModalShowingGroup);
  const isFlaggedAthletesView = useSelector((
    state,
  ) => state.forcePlateDashboard.ui.isFlaggedAthletesView);
  const isGroupAnalysisLoading = useSelector((
    state,
  ) => state.forcePlateDashboard.ui.isGroupAnalysisLoading);
  const metricFiltersGroup = useSelector((
    state,
  ) => state.forcePlateDashboard.data.metricFiltersGroup);
  const selectedAthleteGroup = useSelector((
    state,
  ) => state.forcePlateDashboard.data.selectedAthleteGroup);
  const startDateCal = useSelector((
    state,
  ) => state.forcePlateDashboard.data.startDateCal);
  const startDateData = useSelector((
    state,
  ) => state.forcePlateDashboard.data.startDateData);

  const hasData = groupAnalysisAthleteData?.length > 0;
  const hasFlaggedData = isFlaggedAthletesView && flaggedAthletesDataGroup?.length > 0;

  const athletes = hasData && groupAnalysisAthleteData.map((obj) => ({
    fullName: obj.fullName,
    userId: obj.userId,
  }));
  athletes && athletes.unshift({ fullName: 'View All', userId: 0 });

  const selectedAthleteData = (selectedAthleteGroup && hasData)
    && groupAnalysisAthleteData.find((obj) => obj.fullName === selectedAthleteGroup.fullName);

  const dispatch = useDispatch();

  const setSelectedGroup = (option) => {
    // Amplitude tracking
    dashboardTracker('Force Plate Dashboard', 'Group Analysis - Group Picker', 'Group selected');
    batch(() => {
      dispatch(setGroup(option));
      dispatch(setSelectedAthleteGroup(null, false));
      dispatch(setSelectedAthletePerformance(null, false));
      dispatch(
        fetchGroupAnalysis(
          currentUser.accountCode,
          startDateData,
          endDateData,
          option.id,
          metricFiltersGroup,
        ),
      );
    });
  };

  const setAthlete = (option) => {
    if (option.fullName === 'View All') {
      // Amplitude tracking
      dashboardTracker('Force Plate Dashboard', 'Group Analysis - Athlete Picker', 'Athlete selection reset');
      dispatch(setSelectedAthleteGroup({ fullName: 'Viewing All' }, false));
    } else {
      // Amplitude tracking
      dashboardTracker('Force Plate Dashboard', 'Group Analysis - Athlete Picker', 'Athlete selected');
      dispatch(setSelectedAthleteGroup(option, true));
    }
  };

  const setStartDate = (date) => {
    // Amplitude tracking
    dashboardTracker('Force Plate Dashboard', 'Group Analysis - Date Picker', 'Start date selected');
    const dateDiff = moment(endDateData).diff(moment(date), 'days');
    batch(() => {
      dispatch(setDayRange(dateDiff));
      dispatch(setStartDateData(moment(date).format('YYYY-MM-DD')));
      dispatch(setStartDateCal(moment(date)));
      dispatch(
        fetchGroupAnalysis(
          currentUser.accountCode,
          moment(date).format('YYYY-MM-DD'),
          endDateData,
          group.id,
          metricFiltersGroup,
        ),
      );
    });
  };

  const setEndDate = (date) => {
    // Amplitude tracking
    dashboardTracker('Force Plate Dashboard', 'Group Analysis - Date Picker', 'End date selected');
    const dateDiff = moment(moment(date)).diff(startDateData, 'days');
    batch(() => {
      dispatch(setDayRange(dateDiff));
      dispatch(setEndDateData(moment(date).format('YYYY-MM-DD')));
      dispatch(setEndDateCal(moment(date)));
      dispatch(
        fetchGroupAnalysis(
          currentUser.accountCode,
          startDateData,
          moment(date).format('YYYY-MM-DD'),
          group.id,
          metricFiltersGroup,
        ),
      );
    });
  };

  const handleDayClick = (days) => {
    // Amplitude tracking
    dashboardTracker('Force Plate Dashboard', 'Group Analysis - Day Range Picker', `Range of ${days} days selected`);
    batch(() => {
      dispatch(setDayRange(days));
      dispatch(setStartDateCal(moment().subtract(days - 1, 'days')));
      dispatch(setStartDateData(moment().subtract(days - 1, 'days').format('YYYY-MM-DD')));
      dispatch(setEndDateCal(moment()));
      dispatch(setEndDateData(moment().format('YYYY-MM-DD')));
      dispatch(
        fetchGroupAnalysis(
          currentUser.accountCode,
          moment().subtract(days - 1, 'days').format('YYYY-MM-DD'),
          endDateData,
          group.id,
          metricFiltersGroup,
        ),
      );
    });
  };

  const handleDownload = () => {
    // Amplitude tracking
    dashboardTracker('Force Plate Dashboard', 'Group Analysis - Download', 'Table downloaded');
    const tb = document.getElementById('reportTable');
    TableToExcel.convert(tb, {
      name: 'Group_Analysis.xlsx',
      sheet: {
        name: 'Sheet 1',
      },
    });
  };

  useEffect(() => {
    if (flaggedAthletesDataGroup?.length > 0 && !isFlaggedAthletesView) {
      dispatch(setShowFlaggedAthletesModalGroup(true));
    } else {
      dispatch(setShowFlaggedAthletesModalGroup(false));
    }
  }, [flaggedAthletesDataGroup]);

  const handleDismiss = () => {
    // Amplitude tracking
    dashboardTracker('Force Plate Dashboard', 'Group Analysis - Flagged Athletes', 'Flagged athletes dismissed');
    batch(() => {
      dispatch(setShowFlaggedAthletesModalGroup(false));
      dispatch(setFlaggedAthletesGroup([]));
    });
  };

  const handleReview = () => {
    // Amplitude tracking
    dashboardTracker('Force Plate Dashboard', 'Group Analysis - Flagged Athletes', 'Review flagged athletes clicked');
    // Change filter to 'alerts only' and display the flaggedAthletesData in the table
    // All other data should already match since we are not swapping screens
    batch(() => {
      dispatch(setSelectedAthleteGroup(null, false));
      dispatch(setFlaggedAthletes(flaggedAthletesDataGroup));
      dispatch(setGroupFilterRadio('alerts'));
      dispatch(setShowFlaggedAthletesModalGroup(false));
      dispatch(setFlaggedAthletesView(true));
    });
  };

  const [columnHeaderHeight, setColumnHeaderHeight] = useState(null);
  const containerWidth = 1000;

  return (
    <>
      <DashboardContainer>
        <OptionsHeader>
          <MenuOptionsContainer>
            <ForcePlateDropDown
              list={groups}
              label='name'
              selectItemFunction={setSelectedGroup}
              headerWidthPx={175}
              placeholder='Select a Group'
              defaultOption={group || {}}
              readOnly
            />
            {athletes && (
            <ForcePlateDropDown
              list={athletes}
              label='fullName'
              selectItemFunction={setAthlete}
              headerWidthPx={175}
              placeholder='Select an Athlete'
              defaultOption={selectedAthleteGroup || {}}
            />
            )}
            <GroupFilter />
          </MenuOptionsContainer>
          <DateOptionsContainer>
            <DaySelector>
              <DayOption
                isActive={dayRange === 7}
                onClick={() => handleDayClick(7)}
              >
                7D
              </DayOption>
              <DaySpacer> | </DaySpacer>
              <DayOption
                isActive={dayRange === 14}
                onClick={() => handleDayClick(14)}
              >
                14D
              </DayOption>
              <DaySpacer> | </DaySpacer>
              <DayOption
                isActive={dayRange === 28}
                onClick={() => handleDayClick(28)}
              >
                28D
              </DayOption>
            </DaySelector>
            <ForcePlateDatePicker
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              startDate={startDateCal.toDate()}
              endDate={endDateCal.toDate()}
            />
            <Button
              className='downloadBtn'
              disabled={!hasData}
              iconOnly
              icon='download'
              onClick={handleDownload}
            />
          </DateOptionsContainer>
        </OptionsHeader>
        <ColorNote>
          <span style={{ fontWeight: 'bold' }}>NOTE: </span>
          The column background color is determined by the amount of deviation from the average.
          The deviation column will be red if the deviation is less than 1 and green
          if greater than 1.
        </ColorNote>
        {isGroupAnalysisLoading ? (
          <Spinner />
        ) : !isFlaggedAthletesView && !hasData ? (
          <>
            <GroupAnalysisTableNoData
              setColumnHeaderHeight={setColumnHeaderHeight}
              containerWidth={containerWidth}
              dataRows={['', '', '', '', '']}
              rowIsScrollable
            />
            <NoDataModal>
              <ModalTitle>
                {!group && !hasData ? 'Select a Group' : 'No Data Available'}
              </ModalTitle>
              <ModalMessage>
                {!group && !hasData
                  ? 'Select a group using the dropdown above to get started'
                  : 'There is no data available, please adjust your selection above'}
              </ModalMessage>
            </NoDataModal>
          </>
        ) : isFlaggedAthletesView && flaggedAthletesData?.length <= 0 ? (
          <>
            <GroupAnalysisTableNoData
              setColumnHeaderHeight={setColumnHeaderHeight}
              containerWidth={containerWidth}
              dataRows={['', '', '', '', '']}
              rowIsScrollable
            />
            <NoDataModal>
              <ModalTitle>
                {!group && !hasFlaggedData ? 'Select a Group' : 'No Data Available'}
              </ModalTitle>
              <ModalMessage>
                {!group && !hasFlaggedData
                  ? 'Select a group using the dropdown above to get started'
                  : 'There is no data available, please adjust your selection above'}
              </ModalMessage>
            </NoDataModal>
          </>
        ) : (
          <>
            {isFlaggedAthletesView ? (
              <GroupAnalysisTable
                setColumnHeaderHeight={setColumnHeaderHeight}
                containerWidth={containerWidth}
                dataRows={flaggedAthletesData}
                groupAverageData={groupAnalysisAverageData}
                rowIsScrollable
              />
            ) : selectedAthleteData ? (
              <GroupAnalysisTable
                setColumnHeaderHeight={setColumnHeaderHeight}
                containerWidth={containerWidth}
                dataRows={[selectedAthleteData]}
                groupAverageData={groupAnalysisAverageData}
                rowIsScrollable
              />
            ) : (
              <GroupAnalysisTable
                setColumnHeaderHeight={setColumnHeaderHeight}
                containerWidth={containerWidth}
                dataRows={groupAnalysisAthleteData}
                groupAverageData={groupAnalysisAverageData}
                rowIsScrollable
              />
            )}
          </>
        )}
        {!isFlaggedAthletesView && (
          <FlaggedAthletesModal
            active={isFlaggedAthletesModalShowingGroup}
            handleDismiss={handleDismiss}
            handleReview={handleReview}
            isPerformance={false}
            numOfAthletes={flaggedAthletesDataGroup?.length}
          />
        )}
      </DashboardContainer>
    </>
  );
};

export default GroupAnalysis;
