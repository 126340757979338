/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import Helmet from 'react-helmet';
import { batch, useDispatch, useSelector } from 'react-redux';

import HeaderText from '../../shared/components/HeaderText/HeaderText';
import CompletionOverview from './components/presentational/CompletionOverview';
import GroupAnalysis from './components/presentational/GroupAnalysis';
import {
  setHabitTrackingView, setOnboardingModalOpen, fetchViewedFeatures, setViewedFeature,
} from './ducks/habitTrackingDashboardActions';
import { dashboardTracker, onboardingTracker } from '../../shared/utils/amplitudeHelper';
import {
  fetchHabitsForAccount,
  fetchReportableGroups,
  fetchAthletes,
  fetchCategoriesForAccount,
} from '../habit-tracking/ducks/habitTrackingActions';
import HabitTracking from '../habit-tracking/HabitTracking';
import OnboardingModal from '../../shared/_TBComponents/OnboardingModal';
import { FEATURES, hasViewedFeature, createViewedFeaturePayload } from '../../shared/utils/onboardingStatus';
import onboardingImageOne from '../../shared/images/ht-onboarding-one.png';
import onboardingImageTwo from '../../shared/images/ht-onboarding-two.png';
import onboardingImageThree from '../../shared/images/ht-onboarding-three.png';
import onboardingImageFour from '../../shared/images/ht-onboarding-four.png';
import { TransitionGlobal } from '../../shared/GlobalStyles';
import Icon from '../../shared/_TBComponents/Icon';

const Container = styled('div')`
  width: 100%;
`;

const PageWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: 1450px;
`;

const DashboardHeader = styled('div')`
  display: flex;
  width: 100%;
  background-color: #ffffff;
  padding-top: 30px;
  padding-left: 20px;
  justify-content: space-between;

  h1 {
    padding-bottom: 8px;
  }
`;

const MenuContainer = styled('div')`
  display: flex;
`;

const MenuItem = styled('button')`
  border: none;
  margin-left: 10px;
  margin-right: 12px;
  font-family: 'Nunito Sans';
  font-weight: 400;
  font-size: 16px;
  border-bottom: ${(props) => (props.isActive ? '3px solid #1787FB!important' : '3px solid white')};
  color: ${(props) => (!props.isActive ? '#9E9E9E' : '#444444')};
`;

const InfoWrapper = styled('div')`
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 20px;
  border: 2px solid #9E9E9E;
  margin-left: 10px;
  margin-bottom: 8px;
  cursor: ${(props) => (props.disabled ? 'cursor' : 'pointer')};
    :hover {
      opacity: ${(props) => (props.disabled ? '1' : '.7')};
    }
  transition: ${TransitionGlobal};
  @media only screen and (max-width: 601px) {
    display: none;
  }
`;

const HeaderContainer = styled('div')`
  display: flex;
  align-items: center;
`;

const HabitTrackingDashboard = () => {
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const habitsForAccount = useSelector((state) => state.habitTracking.data.habits);
  const accountCode = currentUser?.accountCode;
  const categoriesForAccount = useSelector((state) => state.habitTracking.data.categories);
  const habitTrackingView = useSelector(
    (state) => state.habitTrackingDashboard.ui.habitTrackingView,
  );
  const isOnboardingModalOpen = useSelector(
    (state) => state.habitTrackingDashboard.ui.isOnboardingModalOpen,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    fetchViewedFeatures()
      .then((data) => {
        const isFeatureViewed = hasViewedFeature(data, FEATURES.CONSISTENCY_COACH_DASHBOARD);
        if (!isFeatureViewed) {
          dispatch(setOnboardingModalOpen(true));
          // Amplitude tracking
          onboardingTracker('Modal Opened', 'Consistency Coach', 'Onboarding modal opened on user\'s first view of dashboard');
        }
      })
      .catch((err) => {
        console.error('Failed to load viewed features:', err);
      });
  }, []);

  // Function to handle menu clicks and switch views
  const handleMenuClick = (view) => {
    if (view !== habitTrackingView) {
      if (view === 'management') {
        dispatch(fetchHabitsForAccount(accountCode));
        dispatch(fetchReportableGroups(accountCode));
        dispatch(fetchAthletes(accountCode));

        // Fetch habits and categories if not already loaded
        if (!habitsForAccount?.length) {
          dispatch(fetchHabitsForAccount(accountCode));
        }
        if (!categoriesForAccount?.length) {
          dispatch(fetchCategoriesForAccount(accountCode));
        }
      }

      // Set the selected habit tracking view
      dispatch(setHabitTrackingView(view));

      // Amplitude tracking
      dashboardTracker(
        'Consistency Coach',
        view === 'group' ? 'Group Analysis' : view === 'management' ? 'Management Overview' : 'Performance Overview',
        'Menu item clicked',
      );
    }
  };

  const content = [
    {
      image: onboardingImageOne,
      title: 'Create & Categorize Habits',
      description: 'To begin habit tracking inside TeamBuildr, start out by creating habits inside the web app so they appear for your athletes. Habits need a category assigned in order to view the collected data in Group & Individual Analysis.',
      gradientType: 'primary',
    },
    {
      image: onboardingImageTwo,
      title: 'Completion Overview',
      description: 'In the Completion Overview tab, select a group from the dropdown menu in the top left corner to view the collected data, and choose a week range in the top right. It is possible to go back at a max of 12 weeks from a selected day. The date selector will automatically detect the week range you select. Collected data starts on Monday and ends on Sunday.',
      gradientType: 'primary',

    },
    {
      image: onboardingImageThree,
      title: 'Group Analysis',
      description: 'The Group Analysis tab allows you to view a table of athletes and their assigned habit data, either individually or as a full group. You can select up to 10 habits in this view by filtering them, the filter can be found to the right of the selected group or athlete. In this view, you are also able to dynamically set thresholds for each habit. Thresholds will be removed if the browser is closed or you move out of habit tracking inside the same browser window.',
      gradientType: 'primary',

    },
    {
      image: onboardingImageFour,
      title: 'Data Visibility',
      description: 'Data will not appear for habits that do not have a category assigned to it. If you delete a category, all associated habits will still be there, but they will need to be assigned a new category in order to view it in the Group and Individual analysis tabs.',
      gradientType: 'primary',
    },
  ];

  const handleClose = () => {
    const viewedFeaturePayload = createViewedFeaturePayload(
      accountCode,
      currentUser.id,
      FEATURES.CONSISTENCY_COACH_DASHBOARD,
    );
    batch(() => {
      dispatch(setOnboardingModalOpen(false));
      dispatch(setViewedFeature(viewedFeaturePayload));
    });
  };

  const handleOpenOnboarding = () => {
    dispatch(setOnboardingModalOpen(true));
  };

  return (
    <Container>
      <Helmet>
        <title>Consistency Coach | TeamBuildr</title>
      </Helmet>
      <PageWrapper>
        <DashboardHeader>
          <HeaderContainer>
            <HeaderText
              fontWeight='300'
              letterSpacing='normal'
            >
              Consistency Coach
            </HeaderText>
            <InfoWrapper onClick={handleOpenOnboarding}>
              <Icon
                icon='info'
                size='12px'
                styledProps={{
                  display: 'flex',
                }}
              />
            </InfoWrapper>
          </HeaderContainer>
          <MenuContainer>
            <MenuItem
              isActive={habitTrackingView === 'management'}
              onClick={() => handleMenuClick('management')}
            >
              Habits Management
            </MenuItem>

            <MenuItem
              isActive={habitTrackingView === 'performance'}
              onClick={() => handleMenuClick('performance')}
            >
              Completion Overview
            </MenuItem>

            <MenuItem
              isActive={habitTrackingView === 'group'}
              onClick={() => handleMenuClick('group')}
            >
              Group Analysis
            </MenuItem>
          </MenuContainer>
        </DashboardHeader>

        {habitTrackingView === 'management' && (
          <HabitTracking />
        )}
        {habitTrackingView === 'performance' && (
          <CompletionOverview />
        )}
        {habitTrackingView === 'group' && (
          <GroupAnalysis />
        )}
      </PageWrapper>
      <OnboardingModal
        isActive={isOnboardingModalOpen}
        content={content}
        maxHeight={null}
        minHeight={null}
        dashName='Consistency Coach'
        onClose={handleClose}
        onPrimaryAction={handleClose}
        onSecondaryAction={handleClose}
        badgeText='GETTING STARTED'
      />
    </Container>
  );
};

export default HabitTrackingDashboard;
