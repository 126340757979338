import React, { useEffect } from 'react';
import { useDispatch, useSelector, batch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { push } from 'connected-react-router';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { openPopupWidget } from 'react-calendly';
import { BoxShadow } from '../../../../shared/GlobalStyles';
import {
  setIsSideBarExpandedGlobal, setSelectedTab, clearGymStudioError, setActiveGymStudioModal,
} from '../../ducks/gymStudioActions';
import Icon from '../../../../shared/_TBComponents/Icon';
import Text from '../../../../shared/_TBComponents/Text';
import { featureClickTracker, moduleTracker } from '../../../../shared/utils/amplitudeHelper';

const SideBarDiv = styled('div')`
  transition: all 0.3s ease;
  margin-top: 49px;
  @media screen and (max-width: 714px) {
    width: ${(props) => (props.isSideBarActive ? '100%' : '0%')} ;
  }
  overflow: hidden;
  height: 100%;
  z-index: 1;
  width: ${(props) => (props.isExpanded ? '300px' : '96px')};
`;

const SideBarHeader = styled('div')`
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 25px;
  display: flex;
  align-items: center;
  padding: ${(props) => (props.isExpanded ? '15px' : '25px')};
  margin-top: 20px;
  justify-content: ${(props) => (props.isExpanded ? 'space-between' : 'flex-end')};
  @media screen and (max-width: 767px) {
    justify-content: space-between;
  }
`;

const HeaderText = styled('div')`
  width: auto;
  transition: all 0.3s ease;
  font-size: ${(props) => (!props.isExpanded ? '14px' : '16px')};
  margin-right: ${(props) => (!props.isExpanded && '5px')};
  margin-top: ${(props) => (!props.isExpanded && '5px')};
  @media screen and (max-width: 767px) {
    opacity: 1;
    font-size: ${(props) => (!props.isExpanded ? '16px' : '24px')};
  }
`;

const MenuTab = styled('div')`
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: ${(props) => (props.isExpanded ? '14px' : '0px')};
  gap: ${(props) => (props.isExpanded ? '14px' : '0px')};
  height: ${(props) => (props.isExpanded ? '120px' : '80px')};
  width: ${(props) => (props.isExpanded ? '100%' : '80px')};  
  background: ${(props) => {
    if (!props.isStudioConnected && props.tabKey === 'quick-statistics') {
      return props.theme.colors.gymStudio;
    }
    return props.activeTab ? props.theme.colors.activeTabBackground : 'transparent';
  }};
  border-radius: 8px;
  color: ${(props) => {
    if (!props.isStudioConnected && props.tabKey === 'quick-statistics') {
      return 'white';
    }
    return props.activeTab ? props.theme.colors.activeTabText : '#9E9E9E';
  }};
  position: relative;
  border: ${(props) => (props.activeTab ? `1px solid ${props.theme.colors.gymStudio}` : 'none')};
  box-shadow: ${BoxShadow};
  transition: all 0.3s ease;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`;

const TabTitle = styled('div')`
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => {
    if (!props.isStudioConnected && props.tabKey === 'quick-statistics') {
      return 'white';
    }
    if (props.tabKey === 'quick-statistics') {
      return props.theme.colors.text;
    }
    return props.unlocked ? props.theme.colors.text : 'white';
  }};
  transition: all 0.3s ease;
  display: ${(props) => (props.isExpanded ? 'flex' : 'none')};
`;

const TabDescription = styled('div')`
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-top: 5px;
  color: ${(props) => {
    if (!props.isStudioConnected && props.tabKey === 'quick-statistics') {
      return 'white';
    }
    if (props.tabKey === 'quick-statistics') {
      return props.theme.colors.text;
    }
    return props.unlocked ? props.theme.colors.text : 'white';
  }};
  display: ${(props) => (props.isExpanded ? 'flex' : 'none')};
`;

const TitleAndDescription = styled('div')`
  display: flex;
  flex-direction: column;
`;

const IconContainer = styled('div')`
  transition: all 0.3s ease;
  color: ${(props) => {
    if (!props.isStudioConnected && props.tabKey === 'quick-statistics') {
      return 'white';
    }
    if (props.tabKey === 'quick-statistics') {
      return props.theme.colors.text;
    }
    return props.unlocked ? props.theme.colors.text : 'white';
  }};
`;

const IconWrapper = styled('div')`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: ${(props) => (props.isTransparent ? 'transparent' : props.theme.colors.text)};
  border-radius: 50%;
  width: 20px;
  height: 20px;
`;

const ModalButtons = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  bottom: 50px;
  padding: ${(props) => (props.isSideBarExpanded ? '15px' : '5px')};
`;

const ModalButton = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${(props) => (props.isExpanded ? '10000px' : '8px')};
  height: 40px;
  width: ${(props) => (props.isExpanded ? '100%' : '70%')};
  margin-bottom: 5px;
  padding-bottom: ${(props) => (!props.isExpanded && props.isCalendar ? '4px' : '0px')};
  gap: 8px;
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: ${(props) => (props.isExpanded ? '12px' : '10px')};
  cursor: pointer;
  border: 3px solid ${(props) => props.theme.colors.text};;
  color: ${(props) => (props.isTransparent ? props.theme.colors.text : 'white')};
  background-color: ${(props) => (props.isTransparent ? 'transparent' : props.theme.colors.text)};

  &:hover {
    opacity: 0.7;
  }
`;

const SpacingWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  background: white;
`;

const LockContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${(props) => (props.isExpanded ? '45px' : '20px')};
  width: ${(props) => (props.isExpanded ? '45px' : '20px')};
  background: #444444;
  position: absolute;
  right: ${(props) => (props.isExpanded ? '-10px' : '-5px')};
  top: -10px;
  border-radius: 50%;
  cursor: pointer;
  opacity: ${(props) => (props.isActive ? 1 : 0)};
  transition: opacity 0.3s ease;
`;

const MenuAndButtons = styled('div')`
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 125px;
  overflow: auto;
  padding-bottom: 50px;
  flex: 1 auto;
  @media screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: ${(props) => (props.isExpanded ? 'hidden' : 'clip')};
  overflow-y: auto;
`;

const ChevronContainer = styled('div')`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const Chevron = styled('div')`
  transform: ${(props) => (props.isRotated ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: transform 0.3s ease;
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
`;

const SideBar = ({
  isSideBarActive,
  setIsSideBarActive,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const isStudioConnected = useSelector((state) => state.gymStudio.data.isStudioConnected);
  const isSideBarExpanded = useSelector((state) => state.gymStudio.ui.isSideBarExpanded);
  const selectedTab = useSelector((state) => state.gymStudio.ui.selectedTab);

  // Update selected tab based on location
  useEffect(() => {
    if (location.pathname.includes('/gym-overview')) {
      dispatch(setSelectedTab('gym-overview'));
    } else if (location.pathname.includes('/quick-statistics')) {
      dispatch(setSelectedTab('quick-statistics'));
    }
  }, [location.pathname, dispatch]);

  const handleTabClick = (tab, path) => {
    batch(() => {
      dispatch(clearGymStudioError());
      dispatch(setActiveGymStudioModal(''));
      dispatch(setSelectedTab(tab));
      dispatch(push(path));
    });
    setIsSideBarActive(false);
  };

  return (
    <SideBarDiv isSideBarActive={isSideBarActive} isExpanded={isSideBarExpanded}>
      <SpacingWrapper>
        <SideBarHeader isExpanded={isSideBarExpanded}>
          {!isSideBarExpanded ? (
            <div style={{
              width: 18,
            }}
            />
          ) : null}
          <HeaderText isExpanded={isSideBarExpanded}>
            {isSideBarExpanded ? 'GymStudio' : 'GS'}
          </HeaderText>
          <ChevronContainer onClick={() => {
            dispatch(setIsSideBarExpandedGlobal(!isSideBarExpanded));
          }}
          >
            <Chevron isRotated={isSideBarExpanded}>
              <Icon
                size={isSideBarExpanded ? 18 : 12}
                icon='altrightarrow'
                color='black'
              />
            </Chevron>
          </ChevronContainer>
        </SideBarHeader>
        <MenuAndButtons>
          <MenuTab
            isExpanded={isSideBarExpanded}
            unlocked
            activeTab={selectedTab === 'gym-overview'}
            onClick={() => {
              handleTabClick('gym-overview', '/gymstudio/gym-overview');

              // Amplitude tracking
              moduleTracker('GymStudio - Gym Overview', !isStudioConnected);
            }}
          >
            <IconContainer isExpanded={isSideBarExpanded} unlocked>
              <Icon
                size={40}
                icon='gs-layout'
              />
            </IconContainer>
            <TitleAndDescription isExpanded={isSideBarExpanded}>
              <TabTitle isExpanded={isSideBarExpanded} unlocked>Gym Overview</TabTitle>
              <TabDescription isExpanded={isSideBarExpanded} unlocked>
                View integration details, user information, and payment insights.
              </TabDescription>
            </TitleAndDescription>
          </MenuTab>
          <MenuTab
            isExpanded={isSideBarExpanded}
            unlocked
            isStudioConnected={isStudioConnected}
            tabKey='quick-statistics'
            activeTab={selectedTab === 'quick-statistics'}
            onClick={() => {
              handleTabClick('quick-statistics', '/gymstudio/quick-statistics');

              // Amplitude tracking
              moduleTracker('GymStudio - Quick Statistics', !isStudioConnected);
            }}
          >
            <IconContainer isExpanded={isSideBarExpanded} isStudioConnected={isStudioConnected} tabKey='quick-statistics'>
              <Icon
                size={40}
                icon='gs-chart'
              />
            </IconContainer>
            <LockContainer
              isActive={!isStudioConnected}
              isExpanded={isSideBarExpanded}
            >
              <Icon
                size={isSideBarExpanded ? 25 : 12}
                icon='lock'
                color='white'
              />
            </LockContainer>
            <TitleAndDescription isExpanded={isSideBarExpanded}>
              <TabTitle isExpanded={isSideBarExpanded} isStudioConnected={isStudioConnected} tabKey='quick-statistics'>
                Quick Statistics
              </TabTitle>
              <TabDescription isExpanded={isSideBarExpanded} isStudioConnected={isStudioConnected} tabKey='quick-statistics'>
                View class schedules, member bookings, and cancellations statistics.
              </TabDescription>
            </TitleAndDescription>
          </MenuTab>
        </MenuAndButtons>
        <ModalButtons isSideBarExpanded={isSideBarExpanded}>
          <ModalButton
            isExpanded={isSideBarExpanded}
            isCalendar
            onClick={() => {
              openPopupWidget({
                url: 'https://calendly.com/gymstudio-app/gymstudio-30-minute-demo?back=1&month=2024-11',
              });

              // Amplitude tracking
              featureClickTracker('GymStudio', 'GymStudio - Book a Demo', 'GymStudio - User clicked to book a demo');
            }}
          >
            <IconWrapper isTransparent>
              <Icon
                size={isSideBarExpanded ? 16 : 24}
                icon='calendar'
                color='white'
              />
            </IconWrapper>
            {isSideBarExpanded ? <Text styledTextProps={{ fontSize: '12px', color: 'white' }}>BOOK A DEMO</Text> : null}
          </ModalButton>
          {/* <ModalButton
            isExpanded={isSideBarExpanded}
            isTransparent
            onClick={() => {
              console.log('Learn more');
            }}
          >
            {isSideBarExpanded ? (
              <IconWrapper>
                <Icon
                  size={12}
                  icon='question-mark'
                  color='white'
                />
              </IconWrapper>
            ) : (
              <Icon
                size={24}
                icon='question-mark'
                color='black'
              />
            )}
            {isSideBarExpanded ?
              <Text styledTextProps={{ fontSize: '12px' }}>
                LEARN MORE
              </Text> : null}
          </ModalButton> */}
        </ModalButtons>
      </SpacingWrapper>
    </SideBarDiv>
  );
};

SideBar.propTypes = {
  isSideBarActive: PropTypes.bool.isRequired,
  setIsSideBarActive: PropTypes.func.isRequired,
};

export default SideBar;
