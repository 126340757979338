export const FETCH_REPORTABLE_GROUPS_LM_DASH_START = 'FETCH_REPORTABLE_GROUPS_LM_DASH_START';
export const FETCH_REPORTABLE_GROUPS_LM_DASH_FULFILLED = 'FETCH_REPORTABLE_GROUPS_LM_DASH_FULFILLED';
export const FETCH_REPORTABLE_GROUPS_LM_DASH_REJECTED = 'FETCH_REPORTABLE_GROUPS_LM_DASH_REJECTED';

export const FETCH_QUESTIONNAIRES_FOR_ACCOUNT_LM_DASH_START = 'FETCH_QUESTIONNAIRES_FOR_ACCOUNT_LM_DASH_START';
export const FETCH_QUESTIONNAIRES_FOR_ACCOUNT_LM_DASH_FULFILLED = 'FETCH_QUESTIONNAIRES_FOR_ACCOUNT_LM_DASH_FULFILLED';
export const FETCH_QUESTIONNAIRES_FOR_ACCOUNT_LM_DASH_REJECTED = 'FETCH_QUESTIONNAIRES_FOR_ACCOUNT_LM_DASH_REJECTED';

export const FETCH_QUESTIONS_FOR_ACCOUNT_LM_DASH_START = 'FETCH_QUESTIONS_FOR_ACCOUNT_LM_DASH_START';
export const FETCH_QUESTIONS_FOR_ACCOUNT_LM_DASH_FULFILLED = 'FETCH_QUESTIONS_FOR_ACCOUNT_LM_DASH_FULFILLED';
export const FETCH_QUESTIONS_FOR_ACCOUNT_LM_DASH_REJECTED = 'FETCH_QUESTIONS_FOR_ACCOUNT_LM_DASH_REJECTED';

export const FETCH_QUESTIONNAIRE_REPORT_SINGLE_DAY_LM_DASH_START = 'FETCH_QUESTIONNAIRE_REPORT_SINGLE_DAY_LM_DASH_START';
export const FETCH_QUESTIONNAIRE_REPORT_SINGLE_DAY_LM_DASH_FULFILLED = 'FETCH_QUESTIONNAIRE_REPORT_SINGLE_DAY_LM_DASH_FULFILLED';
export const FETCH_QUESTIONNAIRE_REPORT_SINGLE_DAY_LM_DASH_REJECTED = 'FETCH_QUESTIONNAIRE_REPORT_SINGLE_DAY_LM_DASH_REJECTED';

export const FETCH_QUESTIONNAIRE_REPORT_MULTIDAY_LM_DASH_START = 'FETCH_QUESTIONNAIRE_REPORT_MULTIDAY_LM_DASH_START';
export const FETCH_QUESTIONNAIRE_REPORT_MULTIDAY_LM_DASH_FULFILLED = 'FETCH_QUESTIONNAIRE_REPORT_MULTIDAY_LM_DASH_FULFILLED';
export const FETCH_QUESTIONNAIRE_REPORT_MULTIDAY_LM_DASH_REJECTED = 'FETCH_QUESTIONNAIRE_REPORT_MULTIDAY_LM_DASH_REJECTED';

export const FETCH_LOAD_MONITORING_LINE_CHART_START = 'FETCH_LOAD_MONITORING_LINE_CHART_START';
export const FETCH_LOAD_MONITORING_LINE_CHART_FULFILLED = 'FETCH_LOAD_MONITORING_LINE_CHART_FULFILLED';
export const FETCH_LOAD_MONITORING_LINE_CHART_REJECTED = 'FETCH_LOAD_MONITORING_LINE_CHART_REJECTED';

export const SET_CURRENT_QUESTION_IN_DONUT_CHART_QUESTIONNAIRE_PICKER = 'SET_CURRENT_QUESTION_IN_DONUT_CHART_QUESTIONNAIRE_PICKER';
export const SET_CURRENT_DATE_IN_DASH_DATE_PICKER = 'SET_CURRENT_DATE_IN_DASH_DATE_PICKER';
export const SET_CURRENT_GROUP_IN_GROUP_PICKER = 'SET_CURRENT_GROUP_IN_GROUP_PICKER';
export const SET_CURRENT_ATHLETE_IN_ATHLETE_PICKER = 'SET_CURRENT_ATHLETE_IN_ATHLETE_PICKER';
export const SET_ATHLETES_IN_CLICKED_ZONE = 'SET_ATHLETES_IN_CLICKED_ZONE ';
export const SET_CURRENT_CHECKED_BOXES = 'SET_CURRENT_CHECKED_BOXES';
export const SET_SHOW_ALL_FOR_DOWNLOAD = 'SET_SHOW_ALL_FOR_DOWNLOAD';
export const SET_IS_CHART_SHOWING_ALL_DATA = 'SET_IS_CHART_SHOWING_ALL_DATA';

export const SET_NEW_LM_DASH = 'SET_NEW_LM_DASH';
export const FETCH_LM_TEMPLATES_START = 'FETCH_LM_TEMPLATES_START';
export const FETCH_LM_TEMPLATES_FULFILLED = 'FETCH_LM_TEMPLATES_FULFILLED';
export const FETCH_LM_TEMPLATES_REJECTED = 'FETCH_LM_TEMPLATES_REJECTED';
export const SET_ACTIVE_DASH = 'SET_ACTIVE_DASH';
export const CLEAR_ACTIVE_LM_DASH = 'CLEAR_ACTIVE_LM_DASH';

export const SET_SAVE_LM_TEMPLATE_MODAL = 'SET_SAVE_LM_DASH_MODAL';
export const CREATE_LM_TEMPLATE_START = 'CREATE_LM_TEMPLATE_START';
export const CREATE_LM_TEMPLATE_FULFILLED = 'CREATE_LM_TEMPLATE_FULFILLED';
export const CREATE_LM_TEMPLATE_REJECTED = 'CREATE_LM_TEMPLATE_REJECTED';
export const SET_IS_UNSAVED_LM_DASH_MODAL = 'SET_IS_UNSAVED_LM_DASH_MODAL';

export const SET_EDIT_LM_DASH_MODAL = 'SET_EDIT_LM_DASH_MODAL';
export const EDIT_LM_TEMPLATE_START = 'EDIT_LM_TEMPLATE_START';
export const EDIT_LM_TEMPLATE_FULFILLED = 'EDIT_LM_TEMPLATE_FULFILLED';
export const EDIT_LM_TEMPLATE_REJECTED = 'EDIT_LM_TEMPLATE_REJECTED';

export const SET_DELETE_LM_TEMPLATE_MODAL = 'SET_DELETE_LM_TEMPLATE_MODAL';
export const DELETE_LM_TEMPLATE_START = 'DELETE_LM_TEMPLATE_START';
export const DELETE_LM_TEMPLATE_FULFILLED = 'DELETE_LM_TEMPLATE_FULFILLED';
export const DELETE_LM_TEMPLATE_REJECTED = 'DELETE_LM_TEMPLATE_REJECTED';

export const FETCH_LM_DASH_DATA_START = 'FETCH_LM_DASH_DATA_START';
export const FETCH_LM_DASH_DATA_FULFILLED = 'FETCH_LM_DASH_DATA_FULFILLED';
export const FETCH_LM_DASH_DATA_REJECTED = 'FETCH_LM_DASH_DATA_REJECTED';

export const SET_SHARE_LM_DASH_MODAL = 'SET_SHARE_LM_DASH_MODAL';
export const FETCH_SHARE_LM_DASH_LINK_START = 'FETCH_SHARE_LM_DASH_LINK_START';
export const FETCH_SHARE_LM_DASH_LINK_FULFILLED = 'FETCH_SHARE_LM_DASH_LINK_FULFILLED';
export const FETCH_SHARE_LM_DASH_LINK_REJECTED = 'FETCH_SHARE_LM_DASH_LINK_REJECTED';
export const CLEAR_SHARE_LM_DASH_LINK = 'CLEAR_SHARE_LM_DASH_LINK';

export const FETCH_PUBLIC_LM_QUESTIONNAIRE_REPORT_MULTIDAY_START = 'FETCH_PUBLIC_LM_QUESTIONNAIRE_REPORT_MULTIDAY_START';
export const FETCH_PUBLIC_LM_QUESTIONNAIRE_REPORT_MULTIDAY_FULFILLED = 'FETCH_PUBLIC_LM_QUESTIONNAIRE_REPORT_MULTIDAY_FULFILLED';
export const FETCH_PUBLIC_LM_QUESTIONNAIRE_REPORT_MULTIDAY_REJECTED = 'FETCH_PUBLIC_LM_QUESTIONNAIRE_REPORT_MULTIDAY_REJECTED';

export const FETCH_PUBLIC_LM_QUESTIONNAIRE_REPORT_SINGLE_DAY_START = 'FETCH_PUBLIC_LM_QUESTIONNAIRE_REPORT_SINGLE_DAY_START';
export const FETCH_PUBLIC_LM_QUESTIONNAIRE_REPORT_SINGLE_DAY_FULFILLED = 'FETCH_PUBLIC_LM_QUESTIONNAIRE_REPORT_SINGLE_DAY_FULFILLED';
export const FETCH_PUBLIC_LM_QUESTIONNAIRE_REPORT_SINGLE_DAY_REJECTED = 'FETCH_PUBLIC_LM_QUESTIONNAIRE_REPORT_SINGLE_DAY_REJECTED';

export const FETCH_PUBLIC_LM_LINE_CHART_START = 'FETCH_PUBLIC_LM_LINE_CHART_START';
export const FETCH_PUBLIC_LM_LINE_CHART_FULFILLED = 'FETCH_PUBLIC_LM_LINE_CHART_FULFILLED';
export const FETCH_PUBLIC_LM_LINE_CHART_REJECTED = 'FETCH_PUBLIC_LM_LINE_CHART_REJECTED';

export const SET_DUPLICATE_LM_DASH_MODAL = 'SET_DUPLICATE_LM_DASH_MODAL';
export const DUPLICATE_LM_DASH_START = 'DUPLICATE_LM_DASH_START';
export const DUPLICATE_LM_DASH_FULFILLED = 'DUPLICATE_LM_DASH_FULFILLED';
export const DUPLICATE_LM_DASH_REJECTED = 'DUPLICATE_LM_DASH_REJECTED';

export const SET_LM_TRI_OPTION_MODAL = 'SET_LM_TRI_OPTION_MODAL';
export const SET_ABOUT_LM_DASH_MODAL = 'SET_LM_ABOUT_DASH_MODAL';
export const SET_NEXT_LOCATION = 'SET_NEXT_LOCATION';
export const CLEAR_LM_DASH_DATA = 'CLEAR_LM_DASH_DATA';

export const SET_CHART_DAY_RANGE = 'SET_CHART_DAY_RANGE';
export const SET_IS_DASHBOARD_DISPLAYED = 'SET_IS_DASHBOARD_DISPLAYED';

export const SET_GROUPS = 'SET_GROUPS';
export const CLEAR_LM_DASH_MODALS = 'CLEAR_LM_DASH_MODALS';
