/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';
import moment from 'moment';
import ResizeObserver from 'react-resize-observer';
import PropTypes from 'prop-types';

import useMaxReportModal from '../hooks/useMaxReportModal';
import HeaderText from '../../../../shared/_TBComponents/HeaderText';
import { BoxShadow } from '../../../../shared/GlobalStyles';
import ReportingModal from '../ReportingModal';
import ActiveReportSidebar from '../ActiveReportSidebar';
import Text from '../../../../shared/_TBComponents/Text';
import CompletionReportTable from './CompletionReportTable';

const MainContainer = styled('main')`
  flex: ${(props) => (props.isReportOpen ? 50 : 10)};
  display: flex;
  height: 100vh;
  flex-direction: column;
  overflow: auto;
  background: ${(props) => (props.isReportOpen ? 'white' : '')};
  padding: 0px 15px;
  border-left: solid #f5f5f5 1px;
  max-width: 100vw;
`;

const HeaderRow = styled('div')`
  display: flex;
  margin-top: 30px;
  margin-bottom: 40px;
  align-items: self-end;
  gap: 15px;
  flex-wrap: wrap;

  h1 {
    text-transform: capitalize;
  }

  @media screen and (max-height: 920px) {
    margin-bottom: 20px;
    margin-top: 30px;
  }
  
  @media screen and (max-width: 768px) {
    display: flex;
    justify-content: center;
  }
`;

const TypeAndDate = styled('div')`
  display: flex;
  gap: 10px;
`;

const RowWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: fit-content;
  max-width: 100%;
`;

const MobileActiveMenu = styled('div')`
  position: fixed;
  z-index: 100;
  height: 45px;
  bottom: 30px;
  left: 50%;
  margin-left: -70px;
  background-color: white;
  border-radius: 30px;
  display: none;
  box-shadow: ${BoxShadow};
  @media screen and (max-width: 767px) {
    display: block;
  }
`;

const CompletionReport = ({ handlePrint, printRef }) => {
  const {
    handleSetIsModalOpen,
  } = useMaxReportModal();

  const activeModal = useSelector((state) => state.reporting.ui.activeModal);
  const completionReport = useSelector((state) => state.reporting.data.completionReport);
  const completionReportAvg = useSelector((state) => state.reporting.data.completionReportAvg);
  const completionReportForm = useSelector((state) => state.reporting.data.completionReportForm);
  const isReportOpen = useSelector((state) => state.reporting.ui.isReportOpen);

  useEffect(() => {
    handleSetIsModalOpen(true, 'completion');
  }, []);

  const [containerWidth, setContainerWidth] = useState({});
  const [columnHeaderHeight, setColumnHeaderHeight] = useState(null);

  return (
    <MainContainer
      isReportOpen={isReportOpen || activeModal === 'downloadCompletionReport'}
      containerWidth={containerWidth}
    >
      <ResizeObserver
        onResize={(rect) => {
          setContainerWidth(rect.width);
        }}
      />
      <ReportingModal />
      {isReportOpen && (
        <div ref={printRef}>
          <HeaderRow>
            <HeaderText
              fontWeight={300}
              letterSpacing='normal'
            >
              Completion Report
            </HeaderText>
            <TypeAndDate>
              <Text>
                {completionReportForm.exerType === 1 ? 'All Exercise Types'
                  : completionReportForm.exerType === 2 ? 'Lifts'
                    : completionReportForm.exerType === 3 ? 'SAQ+C'
                      : completionReportForm.exerType === 4 ? 'Circuits'
                        : 'Questionnaires'}
              </Text>
              {completionReportForm.rangeType === 1 && (
              <Text>
                {moment().format('MMMM Do, YYYY')}
              </Text>
              )}
              {completionReportForm.rangeType === 2 && (
              <Text>
                {moment().subtract(30, 'd').format('MMMM Do, YYYY')}
                {' '}
                -
                {' '}
                {moment().format('MMMM Do, YYYY')}
              </Text>
              )}
              {completionReportForm.rangeType === 3 && (
              <Text>
                {moment(completionReportForm.startDate.date).format('MMMM Do, YYYY')}
                {' '}
                -
                {' '}
                {moment(completionReportForm.endDate.date).format('MMMM Do, YYYY')}
              </Text>
              )}
            </TypeAndDate>
          </HeaderRow>
          <RowWrapper>
            <CompletionReportTable
              averageData={completionReportAvg}
              containerWidth={1000}
              dataRows={completionReport}
              rowIsScrollable
              setColumnHeaderHeight={setColumnHeaderHeight}
            />
          </RowWrapper>
          <MobileActiveMenu>
            <ActiveReportSidebar download edit handlePrint={handlePrint} print />
          </MobileActiveMenu>
        </div>
      )}
    </MainContainer>
  );
};

CompletionReport.propTypes = {
  printRef: PropTypes.shape({
    current: PropTypes.oneOfType([
      PropTypes.instanceOf(Element),
      PropTypes.oneOf([null]),
    ]),
  }),
  handlePrint: PropTypes.func,
};

CompletionReport.defaultProps = {
  printRef: null,
  handlePrint: () => {},
};

export default CompletionReport;
