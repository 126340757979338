/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import Shimmer from 'react-shimmer-effect';
import { Formik } from 'formik';
import Text from '../../../../shared/components/Text/Text';
import { setCurrentCheckedBoxes } from '../../ducks/loadMonitoringDashboardActions';
import Checkbox from './StyledCheckBox';
import TBTooltip from '../../../../shared/components/TBTooltip/TBTooltip';
import generateUniqueId from '../../../../shared/utils/generateUniqueId';
import { dummyData } from './dummyData';

const Container = styled('div')`
  background: white;
  border-radius: 15px;
  width: 100%;
  min-width: 180px;
  height: ${(props) => (props.containerWidth > 767 ? 'auto' : 'fit-content')};
  max-width: ${(props) => (props.containerWidth < 768 ? '100%' : '180px')};
  box-shadow: 0px 0px 1px rgba(48, 49, 51, 0.05),
    0px 8px 16px rgba(48, 49, 51, 0.1);
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  filter: ${(props) => (props.isBlurred ? 'blur(1.2px)' : 'null')};

  .last-item {
    border-bottom: none;
  }
`;

const Form = styled('form')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  font-size: 11px;
  font-weight: 600;

  label {
    margin-bottom: 0px;
    display: flex;
    height: 100%;
  }
`;

const InputContainerBox = styled('div')`
  border: solid 0.5px #e0e0e0;
  padding: 5px;
  display: flex;
  border-left: none;
  border-top: none;
  min-width: 40px;
  justify-content: center;
  align-items: center;
  box-shadow: 4px 0px 8px rgba(48, 49, 51, 0.1);
`;

const TextContainerBox = styled('div')`
  border: solid 0.5px #e0e0e0;
  padding: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  border-top: none;
  border-right: none;
  .last-item {
    border-bottom: none;
  }
`;

const CheckboxFilterComponent = ({
  containerWidth,
  renderTooltip,
  lineChartData,
}) => {
  const dispatch = useDispatch();
  const groupAveragesData = lineChartData.length ? lineChartData : dummyData;
  const isDataLoading = useSelector(
    (state) => state.loadMonitoringDashboard.ui.isLoadingLineChartData,
  );

  const formattedInitialValues = groupAveragesData?.length
    && groupAveragesData[0].values.map((obj) => ({
      ...obj,
      checkboxValue: true,
    }));

  const questionArrayLength = groupAveragesData?.length && groupAveragesData[0].values.length;

  const placeholderQuestionBoxesArray = questionArrayLength < 10
    ? Array(9 - questionArrayLength)
      .fill()
      .map((_, index) => index + 1)
    : [];

  React.useEffect(() => {
    /** on page load set formatted initial values in checkbox global state */
    if (formattedInitialValues) {
      dispatch(setCurrentCheckedBoxes(formattedInitialValues));
    }
  }, [lineChartData]);

  const handleCheckboxChange = (option, values, setValues) => {
    const updatedOptions = values.checkboxValues.map((originalValueObject) => {
      if (originalValueObject === option) {
        return { ...originalValueObject, checkboxValue: !option.checkboxValue };
      }
      return originalValueObject;
    });

    setValues({ checkboxValues: updatedOptions });
    dispatch(setCurrentCheckedBoxes(updatedOptions));
  };

  const webkitStyles = {
    display: '-webkit-box',
    webkitLineClamp: 2,
    webkitBoxOrient: 'vertical',
    overflow: 'hidden',
  };

  return (
    <>
      {isDataLoading ? (
        <Shimmer>
          <Container
            containerWidth={containerWidth}
            style={{ minHeight: '200px' }}
          />
        </Shimmer>
      ) : (
        <>
          <Formik initialValues={{ checkboxValues: formattedInitialValues }}>
            {({ values, setValues }) => (
              <Container
                containerWidth={containerWidth}
                isBlurred={!lineChartData?.length}
              >
                <Form>
                  {values.checkboxValues?.map((option, index) => (
                    <label key={option.metricId}>
                      <InputContainerBox
                        className={
                          index === values.checkboxValues.length - 1
                          && containerWidth <= 768
                            ? 'last-item'
                            : ''
                        }
                      >
                        <Checkbox
                          disabled={!lineChartData?.length}
                          checked={option.checkboxValue}
                          onChange={() => handleCheckboxChange(option, values, setValues)}
                        />
                      </InputContainerBox>
                      <TextContainerBox
                        className={
                          index === values.checkboxValues.length - 1
                          && containerWidth <= 768
                            ? 'last-item'
                            : ''
                        }
                      >
                        {renderTooltip ? (
                          <TBTooltip
                            textToDisplay={option.metricTitle}
                            textToHover={option.metricTitle}
                            textContainerPadding='0px'
                            textContainerMaxWidth='120px'
                            fontSize='13px'
                          />
                        ) : (
                          <Text
                            fontSize='13px'
                            fontWeight={600}
                            style={webkitStyles}
                          >
                            {option.metricTitle}
                          </Text>
                        )}
                      </TextContainerBox>
                    </label>
                  ))}
                  {placeholderQuestionBoxesArray?.length && containerWidth > 768
                    ? placeholderQuestionBoxesArray.map(
                      (placeholderObject, index) => (
                        <label key={generateUniqueId()}>
                          <InputContainerBox
                            className={
                                index
                                === placeholderQuestionBoxesArray.length - 1
                                  ? 'last-item'
                                  : ''
                              }
                            style={{ opacity: 0.5 }}
                          >
                            <Checkbox checked={false} disabled />
                          </InputContainerBox>
                          <TextContainerBox
                            className={
                                index
                                === placeholderQuestionBoxesArray.length - 1
                                  ? 'last-item'
                                  : ''
                              }
                          >
                            <Text
                              fontSize='13px'
                              fontWeight={600}
                              style={webkitStyles}
                              opacity={0}
                            >
                              placeholder Text
                            </Text>
                          </TextContainerBox>
                        </label>
                      ),
                    )
                    : null}
                </Form>
              </Container>
            )}
          </Formik>
        </>
      )}
    </>
  );
};

export default CheckboxFilterComponent;
