/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import { toast } from 'react-toastify';
import {
  FETCH_REPORTABLE_GROUPS_LM_DASH_START,
  FETCH_REPORTABLE_GROUPS_LM_DASH_FULFILLED,
  FETCH_REPORTABLE_GROUPS_LM_DASH_REJECTED,
  SET_CURRENT_QUESTION_IN_DONUT_CHART_QUESTIONNAIRE_PICKER,
  SET_CURRENT_DATE_IN_DASH_DATE_PICKER,
  SET_CURRENT_GROUP_IN_GROUP_PICKER,
  SET_CURRENT_ATHLETE_IN_ATHLETE_PICKER,
  SET_ATHLETES_IN_CLICKED_ZONE,
  SET_CURRENT_CHECKED_BOXES,
  FETCH_QUESTIONNAIRES_FOR_ACCOUNT_LM_DASH_START,
  FETCH_QUESTIONNAIRES_FOR_ACCOUNT_LM_DASH_FULFILLED,
  FETCH_QUESTIONNAIRES_FOR_ACCOUNT_LM_DASH_REJECTED,
  FETCH_QUESTIONS_FOR_ACCOUNT_LM_DASH_START,
  FETCH_QUESTIONS_FOR_ACCOUNT_LM_DASH_FULFILLED,
  FETCH_QUESTIONS_FOR_ACCOUNT_LM_DASH_REJECTED,
  FETCH_QUESTIONNAIRE_REPORT_SINGLE_DAY_LM_DASH_START,
  FETCH_QUESTIONNAIRE_REPORT_SINGLE_DAY_LM_DASH_FULFILLED,
  FETCH_QUESTIONNAIRE_REPORT_SINGLE_DAY_LM_DASH_REJECTED,
  FETCH_QUESTIONNAIRE_REPORT_MULTIDAY_LM_DASH_START,
  FETCH_QUESTIONNAIRE_REPORT_MULTIDAY_LM_DASH_FULFILLED,
  FETCH_QUESTIONNAIRE_REPORT_MULTIDAY_LM_DASH_REJECTED,
  FETCH_LOAD_MONITORING_LINE_CHART_START,
  FETCH_LOAD_MONITORING_LINE_CHART_FULFILLED,
  FETCH_LOAD_MONITORING_LINE_CHART_REJECTED,
  SET_SHOW_ALL_FOR_DOWNLOAD,
  SET_IS_CHART_SHOWING_ALL_DATA,
  SET_NEW_LM_DASH,
  FETCH_LM_TEMPLATES_START,
  FETCH_LM_TEMPLATES_FULFILLED,
  FETCH_LM_TEMPLATES_REJECTED,
  SET_ACTIVE_DASH,
  CLEAR_ACTIVE_LM_DASH,
  SET_SAVE_LM_TEMPLATE_MODAL,
  CREATE_LM_TEMPLATE_START,
  CREATE_LM_TEMPLATE_FULFILLED,
  CREATE_LM_TEMPLATE_REJECTED,
  SET_EDIT_LM_DASH_MODAL,
  EDIT_LM_TEMPLATE_START,
  EDIT_LM_TEMPLATE_FULFILLED,
  EDIT_LM_TEMPLATE_REJECTED,
  SET_DELETE_LM_TEMPLATE_MODAL,
  DELETE_LM_TEMPLATE_START,
  DELETE_LM_TEMPLATE_FULFILLED,
  DELETE_LM_TEMPLATE_REJECTED,
  SET_SHARE_LM_DASH_MODAL,
  FETCH_SHARE_LM_DASH_LINK_START,
  FETCH_SHARE_LM_DASH_LINK_FULFILLED,
  FETCH_SHARE_LM_DASH_LINK_REJECTED,
  CLEAR_SHARE_LM_DASH_LINK,
  FETCH_PUBLIC_LM_QUESTIONNAIRE_REPORT_MULTIDAY_START,
  FETCH_PUBLIC_LM_QUESTIONNAIRE_REPORT_MULTIDAY_FULFILLED,
  FETCH_PUBLIC_LM_QUESTIONNAIRE_REPORT_MULTIDAY_REJECTED,
  FETCH_PUBLIC_LM_QUESTIONNAIRE_REPORT_SINGLE_DAY_START,
  FETCH_PUBLIC_LM_QUESTIONNAIRE_REPORT_SINGLE_DAY_FULFILLED,
  FETCH_PUBLIC_LM_QUESTIONNAIRE_REPORT_SINGLE_DAY_REJECTED,
  FETCH_PUBLIC_LM_LINE_CHART_START,
  FETCH_PUBLIC_LM_LINE_CHART_FULFILLED,
  FETCH_PUBLIC_LM_LINE_CHART_REJECTED,
  SET_DUPLICATE_LM_DASH_MODAL,
  DUPLICATE_LM_DASH_START,
  DUPLICATE_LM_DASH_FULFILLED,
  DUPLICATE_LM_DASH_REJECTED,
  SET_LM_TRI_OPTION_MODAL,
  SET_ABOUT_LM_DASH_MODAL,
  SET_IS_UNSAVED_LM_DASH_MODAL,
  SET_NEXT_LOCATION,
  CLEAR_LM_DASH_DATA,
  SET_CHART_DAY_RANGE,
  SET_IS_DASHBOARD_DISPLAYED,
  SET_GROUPS,
  CLEAR_LM_DASH_MODALS,
} from './actionTypes';

import {
  axiosAuthed,
  axiosUnauthed,
} from '../../../shared/utils/setCommonHeaders';
import { authProvider } from '../../login/ducks/loginActions';

authProvider();

export const fetchReportableGroups = (accountCode) => (dispatch) => {
  dispatch({ type: FETCH_REPORTABLE_GROUPS_LM_DASH_START });
  axiosAuthed
    .get(`/accounts/${accountCode}/groups?reporting=1`)
    .then((response) => {
      dispatch({
        type: FETCH_REPORTABLE_GROUPS_LM_DASH_FULFILLED,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: FETCH_REPORTABLE_GROUPS_LM_DASH_REJECTED,
        payload: err,
      });
    });
};

export const fetchQuestionnairesForAccount = (accountCode) => (dispatch) => {
  dispatch({ type: FETCH_QUESTIONNAIRES_FOR_ACCOUNT_LM_DASH_START });
  axiosAuthed
    .get(`/accounts/${accountCode}/questionnaires`)
    .then((response) => {
      dispatch({
        type: FETCH_QUESTIONNAIRES_FOR_ACCOUNT_LM_DASH_FULFILLED,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: FETCH_QUESTIONNAIRES_FOR_ACCOUNT_LM_DASH_REJECTED,
        payload: err,
      });
    });
};

export const fetchQuestionsForAccount = (accountCode) => (dispatch) => {
  dispatch({ type: FETCH_QUESTIONS_FOR_ACCOUNT_LM_DASH_START });
  axiosAuthed
    .get(`/accounts/${accountCode}/questions`)
    .then((response) => {
      dispatch({
        type: FETCH_QUESTIONS_FOR_ACCOUNT_LM_DASH_FULFILLED,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: FETCH_QUESTIONS_FOR_ACCOUNT_LM_DASH_REJECTED,
        payload: err,
      });
    });
};

export const fetchLoadMonitoringLineChartData = (
  accountCode,
  filterType,
  filterIds,
  lineChartValues,
) => (dispatch) => {
  const {
    date, questionnaire, arrayOfQuestions, dayRange,
  } = lineChartValues;

  const questionnaireId = questionnaire?.id;
  const questionnaireQuery = questionnaireId
    ? `&questionnaire=${questionnaireId}`
    : '';

  const arrayOfQuestionIds = arrayOfQuestions?.map((question) => question.id);
  const questionsQuery = questionnaireId
    ? ''
    : `&questions=${arrayOfQuestionIds}`;

  const filterQuery = `?filterType=${filterType}`;
  const filterIdsQuery = `${filterIds
    .map((id) => `&filterIds=${id}`)
    .join('')}`;
  const dateQuery = `&date=${date.queryFormat}`;
  const dayRangeQuery = `&daysToGoBack=${dayRange}`;

  dispatch({ type: FETCH_LOAD_MONITORING_LINE_CHART_START });
  axiosAuthed
    .get(
      `/accounts/${accountCode}/reports/load-monitor/create/line${filterQuery}${filterIdsQuery}${dateQuery}${questionnaireQuery}${questionsQuery}${dayRangeQuery}`,
    )
    .then((response) => {
      const hasData = response.data.some((dateObj) => dateObj.values.some((valuesObj) => valuesObj.value !== 0));
      dispatch({
        type: FETCH_LOAD_MONITORING_LINE_CHART_FULFILLED,
        payload: {
          data: response.data,
          hasData,
        },
      });
    })
    .catch((err) => {
      dispatch({
        type: FETCH_LOAD_MONITORING_LINE_CHART_REJECTED,
        payload: err,
      });
    });
};

export const fetchQuestionnaireReportSingleDay = (
  accountCode,
  filterType,
  filterIds,
  values,
  actionType,
) => (dispatch, getState) => {
  const {
    startDate,
    date,
    selectionType,
    questionnaire,
    arrayOfQuestions,
    showRangeAverages,
    showTotal,
  } = values;

  const questionnaireId = questionnaire?.id;
  const questionnaireQuery = questionnaireId
    ? `&questionnaire=${questionnaireId}`
    : '';

  const arrayOfQuestionIds = arrayOfQuestions.map((question) => question.id);
  const questionsQuery = questionnaireId
    ? ''
    : `&questions=${arrayOfQuestionIds}`;

  const accountString = `/accounts/${accountCode}`;
  const reportQuery = '/reports/load-monitor/create/table/group';
  const filterQuery = `?filterType=${filterType}`;
  const filterIdsQuery = `?group=${filterIds}`;
  const dateQuery = `&date=${date.queryFormat}`;
  const averageQuery = `&showAverages=${!!showRangeAverages}`;
  const totalQuery = `&showTotals=${!!showTotal}`;

  dispatch({ type: FETCH_QUESTIONNAIRE_REPORT_SINGLE_DAY_LM_DASH_START });
  return axiosAuthed
    .get(
      `${accountString}${reportQuery}${filterIdsQuery}${dateQuery}${questionnaireQuery}${questionsQuery}${averageQuery}${totalQuery}`,
    )
    .then((response) => {
      const apiResponseData = response.data;

      const dataRows = apiResponseData.filter(
        (obj) => obj.name !== 'Team Average',
      );

      const hasData = dataRows.some((athleteObj) => athleteObj.questions.some(
        (questionObj) => questionObj.response.value !== '-'
            || questionObj.weekAverage.value !== '-'
            || questionObj.monthAverage.value !== '-',
      ));

      /** array to loop through for the footer accessors to display team averages info */
      const teamAverageTotals = apiResponseData.filter(
        (obj) => obj.name === 'Team Average',
      )[0];

      const arrayOfAllQuestions = [];
      const getArrayOfAllQuestions = () => dataRows.map((athlete) => arrayOfAllQuestions.push(...athlete.questions));
      getArrayOfAllQuestions();

      const groupByCategory = arrayOfAllQuestions.reduce((group, question) => {
        const { id } = question;
        // eslint-disable-next-line no-param-reassign
        group[id] = group[id] ?? [];
        group[id].push(question);
        return group;
      }, {});

      const arrayOfGroupArrays = Object.values(groupByCategory);

      const donutChartData = arrayOfGroupArrays.map((groupArray) => ({
        title: groupArray[0].title,
        id: groupArray[0].id,
        groupQuestionResponsesFromEachAthlete: groupArray,
        red: groupArray.filter(
          (question) => question.response.backgroundColor === '#FF3737',
        ),
        yellow: groupArray.filter(
          (question) => question.response.backgroundColor === '#FFCB47',
        ),
        green: groupArray.filter(
          (question) => question.response.backgroundColor === '#0DCC8A',
        ),
        noColor: groupArray.filter(
          (question) => question.response.backgroundColor !== '#0DCC8A'
            || '#FFCB47'
            || '#FF3737',
        ),
      }));

      const totalScoresDonutChartDataRed = dataRows.filter(
        (athleteObj) => athleteObj.totalScores.value.backgroundColor === '#FF3737',
      );
      const totalScoresDonutChartDataGreen = dataRows.filter(
        (athleteObj) => athleteObj.totalScores.value.backgroundColor === '#0DCC8A',
      );
      const totalScoresDonutChartDataYellow = dataRows.filter(
        (athleteObj) => athleteObj.totalScores.value.backgroundColor === '#FFCB47',
      );

      dispatch({
        type: FETCH_QUESTIONNAIRE_REPORT_SINGLE_DAY_LM_DASH_FULFILLED,
        payload: {
          data: apiResponseData,
          dataRows,
          hasData,
          teamAverageTotals,
          values,
          arrayOfAllQuestions,
          donutChartData,
          totalScoresDonutChartDataGreen,
          totalScoresDonutChartDataRed,
          totalScoresDonutChartDataYellow,
        },
      });
    })
    .catch((err) => {
      dispatch({
        type: FETCH_QUESTIONNAIRE_REPORT_SINGLE_DAY_LM_DASH_REJECTED,
        payload: err,
      });
    });
};

export const fetchQuestionnaireReportMultiDay = (
  accountCode,
  filterType,
  filterIds,
  values,
  actionType,
) => (dispatch, getState) => {
  const {
    startDate,
    date,
    selectionType,
    questionnaire,
    arrayOfQuestions,
    showRangeAverages,
    showTotal,
    dayRange,
  } = values;

  const questionnaireId = questionnaire?.id;
  const questionnaireQuery = questionnaireId
    ? `&questionnaire=${questionnaireId}`
    : '';

  const arrayOfQuestionIds = arrayOfQuestions.map((question) => question.id);
  const questionsQuery = questionnaireId
    ? ''
    : `&questions=${arrayOfQuestionIds}`;

  const accountString = `/accounts/${accountCode}`;
  const reportQuery = '/reports/load-monitor/create/table/athlete';
  const filterIdsQuery = `?athlete=${filterIds}`;
  const dateQuery = `&date=${date.queryFormat}`;
  const daysToGoBackQuery = `&daysToGoBack=${dayRange}`;
  const averageQuery = `${
    showRangeAverages ? '&showAverages=true' : '&showAverages=false'
  }`;
  const totalQuery = `${showTotal ? '&showTotals=true' : '&showTotals=false'}`;

  dispatch({ type: FETCH_QUESTIONNAIRE_REPORT_MULTIDAY_LM_DASH_START });
  return axiosAuthed
    .get(
      `${accountString}${reportQuery}${filterIdsQuery}${dateQuery}${daysToGoBackQuery}${questionnaireQuery}${questionsQuery}${averageQuery}${totalQuery}`,
    )
    .then((apiResponse) => {
      const apiResponseData = apiResponse.data;

      const hasData = apiResponseData[0].responses.some((responseObj) => responseObj.questions.some(
        (questionObj) => questionObj.response.value !== '-',
      ));

      const formatData = () => {
        /** to get total score for each athlete object we need to map through
         * the total scores array and return score date object that matches the date of
         * the question at index [i];
         */

        const totalScoreArraysForEachAthlete = apiResponseData.map(
          (athleteObject, i) => athleteObject.totals.scores,
        );

        const responsesArraysForEachAthlete = apiResponseData.map(
          (athleteObject, i) => athleteObject.responses,
        );

        const averagesArraysForEachAthlete = apiResponseData.map(
          (athleteObject) => athleteObject.averages,
        );

        const getResponseArray = (athleteObject, athleteIndex) => {
          const getNewQuestionsArray = (response) => {
            const questionsArrayForResponse = response.questions;

            const newQuestionsArrayForResponse = questionsArrayForResponse.map(
              (questionsObject, Qindex) => ({
                id: questionsObject.id,
                title: questionsObject.title,
                abbr: questionsObject.abbr,
                value: questionsObject.response.value,
                backgroundColor: questionsObject.response.backgroundColor,
                textColor: questionsObject.response.textColor,
                average: averagesArraysForEachAthlete[athleteIndex].filter(
                  (averagesObj, averagesObjectIndex) => averagesObjectIndex === Qindex,
                )[0].response.value,
                averageTextColor: averagesArraysForEachAthlete[
                  athleteIndex
                ].filter(
                  (averagesObj, averagesObjectIndex) => averagesObjectIndex === Qindex,
                )[0].response.textColor,
              }),
            );
            return newQuestionsArrayForResponse;
          };

          const newResponseArray = responsesArraysForEachAthlete[
            athleteIndex
          ].map((response, resonseIndex) => ({
            date: response.date,
            //* * map through questions, averages indexes should map with question indexes */
            questions: getNewQuestionsArray(response),
            totalScore: totalScoreArraysForEachAthlete[athleteIndex].filter(
              (scoreObject) => scoreObject.date === response.date,
            )[0].value,
            totalScoreBackgroundColor: totalScoreArraysForEachAthlete[
              athleteIndex
            ].filter((scoreObject) => scoreObject.date === response.date)[0]
              .backgroundColor,
            totalScoreTextColor: totalScoreArraysForEachAthlete[
              athleteIndex
            ].filter((scoreObject) => scoreObject.date === response.date)[0]
              .textColor,
            totalScoreAverage: athleteObject.totals.average.value,
            totalScoreAverageTextColor: athleteObject.totals.average.textColor,
          }));

          return newResponseArray;
        };
        const formattedData = apiResponseData.map(
          (athleteObject, athleteIndex) => ({
            name: athleteObject.name,
            id: athleteObject.id,
            responses: getResponseArray(athleteObject, athleteIndex),
          }),
        );
        return formattedData;
      };

      const formattedDataToPassToTable = formatData();

      dispatch({
        type: FETCH_QUESTIONNAIRE_REPORT_MULTIDAY_LM_DASH_FULFILLED,
        payload: {
          data: apiResponseData,
          dataRows: formattedDataToPassToTable,
          hasData,
          values,
        },
      });
    })
    .catch((err) => {
      dispatch({
        type: FETCH_QUESTIONNAIRE_REPORT_MULTIDAY_LM_DASH_REJECTED,
        payload: err,
      });
    });
};
export const setCurrentQuestionInQuestionnairePicker = (value) => ({
  type: SET_CURRENT_QUESTION_IN_DONUT_CHART_QUESTIONNAIRE_PICKER,
  payload: value,
});

export const setCurrentDateInDashDatePicker = (value) => ({
  type: SET_CURRENT_DATE_IN_DASH_DATE_PICKER,
  payload: value,
});

export const setCurrentGroupInGroupPicker = (value) => ({
  type: SET_CURRENT_GROUP_IN_GROUP_PICKER,
  payload: value,
});

export const setCurrentAthleteInAthletePicker = (value) => ({
  type: SET_CURRENT_ATHLETE_IN_ATHLETE_PICKER,
  payload: value,
});

export const setAthletesInClickedZone = (athletes) => ({
  type: SET_ATHLETES_IN_CLICKED_ZONE,
  payload: athletes,
});
export const setCurrentCheckedBoxes = (checkedBoxesObject) => ({
  /** checked boxes object needs to include the question name and question/metric id */
  type: SET_CURRENT_CHECKED_BOXES,
  payload: checkedBoxesObject,
});

export const setShowAllForDownload = (bool) => ({
  type: SET_SHOW_ALL_FOR_DOWNLOAD,
  payload: bool,
});

export const setIsChartShowingAllData = (bool) => ({
  type: SET_IS_CHART_SHOWING_ALL_DATA,
  payload: bool,
});

export const setIsNewLMDash = (bool) => ({
  type: SET_NEW_LM_DASH,
  payload: bool,
});

export const fetchLMTemplates = (accountCode) => (dispatch) => {
  dispatch({ type: FETCH_LM_TEMPLATES_START });
  axiosAuthed
    .get(`/accounts/${accountCode}/reports/load-monitor/templates`)
    .then((response) => {
      dispatch({
        type: FETCH_LM_TEMPLATES_FULFILLED,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: FETCH_LM_TEMPLATES_REJECTED,
        payload: err,
      });
    });
};

export const setActiveLMDashboard = (obj) => ({
  type: SET_ACTIVE_DASH,
  payload: obj,
});

export const clearActiveLMDashboard = (id) => ({
  type: CLEAR_ACTIVE_LM_DASH,
});

export const setSaveLMDashModal = (bool) => (dispatch) => {
  dispatch({
    type: SET_SAVE_LM_TEMPLATE_MODAL,
    payload: bool,
  });
};

export const createLMTemplate = (accountCode, submissionObject) => (
  dispatch,
) => {
  dispatch({ type: CREATE_LM_TEMPLATE_START });
  axiosAuthed
    .post(
      `/accounts/${accountCode}/reports/load-monitor/templates`,
      submissionObject,
    )
    .then((response) => {
      toast.success('Dashboard Created!');
      dispatch({
        type: CREATE_LM_TEMPLATE_FULFILLED,
        payload: response.data,
      });
    })
    .catch((err) => {
      toast.error(err?.response?.headers?.message || 'An error has occurred');
      dispatch({
        type: CREATE_LM_TEMPLATE_REJECTED,
        payload: err,
      });
    });
};

export const setEditLMDashModal = (bool, template = {}) => ({
  type: SET_EDIT_LM_DASH_MODAL,
  payload: {
    bool,
    template,
  },
});

export const editLMTemplate = (
  accountCode,
  submissionObject,
  templateId,
  index,
) => (dispatch) => {
  dispatch({ type: EDIT_LM_TEMPLATE_START });
  axiosAuthed
    .put(
      `/accounts/${accountCode}/reports/load-monitor/templates/${templateId}?resetGroups=false&resetItems=false`,
      submissionObject,
    )
    .then((response) => {
      toast.success('Edit Successful!');
      dispatch({
        type: EDIT_LM_TEMPLATE_FULFILLED,
        payload: {
          data: response.data,
          index,
        },
      });
    })
    .catch((err) => {
      toast.error(err?.response?.headers?.message || 'An error has occurred');
      dispatch({
        type: EDIT_LM_TEMPLATE_REJECTED,
        payload: err,
      });
    });
};

export const setDeleteLMDashboardModal = (bool, template = {}) => ({
  type: SET_DELETE_LM_TEMPLATE_MODAL,
  payload: {
    bool,
    template,
  },
});

export const deleteLMTemplate = (accountCode, templateId, templateIndex) => (
  dispatch,
) => {
  dispatch({ type: DELETE_LM_TEMPLATE_START });
  axiosAuthed
    .delete(
      `/accounts/${accountCode}/reports/load-monitor/templates/${templateId}`,
    )
    .then((response) => {
      toast.success('Template Deleted!');
      dispatch({
        type: DELETE_LM_TEMPLATE_FULFILLED,
        payload: { data: response.data, index: templateIndex },
      });
    })
    .catch((err) => {
      toast.error(err?.response?.headers?.message || 'An error has occurred');
      dispatch({
        type: DELETE_LM_TEMPLATE_REJECTED,
        payload: err,
      });
    });
};

export const setShareLMDashModal = (bool, template = {}) => ({
  type: SET_SHARE_LM_DASH_MODAL,
  payload: {
    bool,
    template,
  },
});

// Create a shareable view-only link for the current dashboard
export const fetchShareLMDashLink = (accountCode, submissionObj) => (
  dispatch,
) => {
  dispatch({ type: FETCH_SHARE_LM_DASH_LINK_START });
  axiosAuthed
    .post(`/accounts/${accountCode}/reports/load-monitor/link`, submissionObj)
    .then((response) => {
      dispatch({
        type: FETCH_SHARE_LM_DASH_LINK_FULFILLED,
        payload: response.data.url,
      });
    })
    // TO-DO: WILL ERROR COME THROUGH HERE? CHANGE PAYLOAD TO ERROR MESSAGE?
    .catch((err) => {
      dispatch({
        type: FETCH_SHARE_LM_DASH_LINK_REJECTED,
        payload: true,
      });
    });
};

export const clearShareLMDashLink = () => (dispatch) => {
  dispatch({
    type: CLEAR_SHARE_LM_DASH_LINK,
  });
};

export const fetchPublicLMQuestionnaireReportMultiDay = (token, userId) => (
  dispatch,
) => {
  const userIdQuery = `&userId=${userId}`;
  dispatch({ type: FETCH_PUBLIC_LM_QUESTIONNAIRE_REPORT_MULTIDAY_START });
  axiosUnauthed
    .get(
      `/public/reports/load-monitor/table/athlete?token=${token}${userIdQuery}`,
    )
    .then((apiResponse) => {
      const apiResponseData = apiResponse.data.tableObj;
      const hasData = apiResponseData[0].responses.some((responseObj) => responseObj.questions.some(
        (questionObj) => questionObj.response.value !== '-',
      ));

      const formatData = () => {
        /** to get total score for each athlete object we need to map through
         * the total scores array and return score date object that matches the date of
         * the question at index [i];
         */

        const totalScoreArraysForEachAthlete = apiResponseData.map(
          (athleteObject, i) => athleteObject.totals.scores,
        );

        const responsesArraysForEachAthlete = apiResponseData.map(
          (athleteObject, i) => athleteObject.responses,
        );

        const averagesArraysForEachAthlete = apiResponseData.map(
          (athleteObject) => athleteObject.averages,
        );

        const getResponseArray = (athleteObject, athleteIndex) => {
          const getNewQuestionsArray = (response) => {
            const questionsArrayForResponse = response.questions;

            const newQuestionsArrayForResponse = questionsArrayForResponse.map(
              (questionsObject, Qindex) => ({
                id: questionsObject.id,
                title: questionsObject.title,
                abbr: questionsObject.abbr,
                value: questionsObject.response.value,
                backgroundColor: questionsObject.response.backgroundColor,
                textColor: questionsObject.response.textColor,
                average: averagesArraysForEachAthlete[athleteIndex].filter(
                  (averagesObj, averagesObjectIndex) => averagesObjectIndex === Qindex,
                )[0].response.value,
                averageTextColor: averagesArraysForEachAthlete[
                  athleteIndex
                ].filter(
                  (averagesObj, averagesObjectIndex) => averagesObjectIndex === Qindex,
                )[0].response.textColor,
              }),
            );
            return newQuestionsArrayForResponse;
          };

          const newResponseArray = responsesArraysForEachAthlete[
            athleteIndex
          ].map((response, resonseIndex) => ({
            date: response.date,
            //* * map through questions, averages indexes should map with question indexes */
            questions: getNewQuestionsArray(response),
            totalScore: totalScoreArraysForEachAthlete[athleteIndex].filter(
              (scoreObject) => scoreObject.date === response.date,
            )[0].value,
            totalScoreBackgroundColor: totalScoreArraysForEachAthlete[
              athleteIndex
            ].filter((scoreObject) => scoreObject.date === response.date)[0]
              .backgroundColor,
            totalScoreTextColor: totalScoreArraysForEachAthlete[
              athleteIndex
            ].filter((scoreObject) => scoreObject.date === response.date)[0]
              .textColor,
            totalScoreAverage: athleteObject.totals.average.value,
            totalScoreAverageTextColor: athleteObject.totals.average.textColor,
          }));

          return newResponseArray;
        };
        const formattedData = apiResponseData.map(
          (athleteObject, athleteIndex) => ({
            name: athleteObject.name,
            id: athleteObject.id,
            responses: getResponseArray(athleteObject, athleteIndex),
          }),
        );
        return formattedData;
      };

      const formattedDataToPassToTable = formatData();

      dispatch({
        type: FETCH_PUBLIC_LM_QUESTIONNAIRE_REPORT_MULTIDAY_FULFILLED,
        payload: {
          dashboardInfo: apiResponse.data,
          data: apiResponseData,
          dataRows: formattedDataToPassToTable,
          hasData,
        },
      });
    })
    .catch((err) => {
      dispatch({
        type: FETCH_PUBLIC_LM_QUESTIONNAIRE_REPORT_MULTIDAY_REJECTED,
        payload: err,
      });
    });
};

export const fetchPublicLMQuestionnaireReportSingleDay = (token) => (
  dispatch,
) => {
  dispatch({ type: FETCH_PUBLIC_LM_QUESTIONNAIRE_REPORT_SINGLE_DAY_START });
  axiosUnauthed
    .get(`/public/reports/load-monitor/table/group?token=${token}`)
    .then((response) => {
      const apiResponseData = response.data.athletes;

      const dataRows = apiResponseData.filter(
        (obj) => obj.name !== 'Team Average',
      );

      const hasData = dataRows.some((athleteObj) => athleteObj.questions.some(
        (questionObj) => questionObj.response.value !== '-'
            || questionObj.weekAverage.value !== '-'
            || questionObj.monthAverage.value !== '-',
      ));

      /** array to loop through for the footer accessors to display team averages info */
      const teamAverageTotals = apiResponseData.filter(
        (obj) => obj.name === 'Team Average',
      )[0];

      const arrayOfAllQuestions = [];
      const getArrayOfAllQuestions = () => dataRows.map((athlete) => arrayOfAllQuestions.push(...athlete.questions));
      getArrayOfAllQuestions();

      const groupByCategory = arrayOfAllQuestions.reduce((group, question) => {
        const { id } = question;
        // eslint-disable-next-line no-param-reassign
        group[id] = group[id] ?? [];
        group[id].push(question);
        return group;
      }, {});

      const arrayOfGroupArrays = Object.values(groupByCategory);

      const donutChartData = arrayOfGroupArrays.map((groupArray) => ({
        title: groupArray[0].title,
        id: groupArray[0].id,
        groupQuestionResponsesFromEachAthlete: groupArray,
        red: groupArray.filter(
          (question) => question.response.backgroundColor === '#FF3737',
        ),
        yellow: groupArray.filter(
          (question) => question.response.backgroundColor === '#FFCB47',
        ),
        green: groupArray.filter(
          (question) => question.response.backgroundColor === '#0DCC8A',
        ),
        noColor: groupArray.filter(
          (question) => question.response.backgroundColor !== '#0DCC8A'
            || '#FFCB47'
            || '#FF3737',
        ),
      }));

      const totalScoresDonutChartDataRed = dataRows.filter(
        (athleteObj) => athleteObj.totalScores.value.backgroundColor === '#FF3737',
      );
      const totalScoresDonutChartDataGreen = dataRows.filter(
        (athleteObj) => athleteObj.totalScores.value.backgroundColor === '#0DCC8A',
      );
      const totalScoresDonutChartDataYellow = dataRows.filter(
        (athleteObj) => athleteObj.totalScores.value.backgroundColor === '#FFCB47',
      );

      dispatch({
        type: FETCH_PUBLIC_LM_QUESTIONNAIRE_REPORT_SINGLE_DAY_FULFILLED,
        payload: {
          dashboardInfo: response.data,
          data: apiResponseData,
          dataRows,
          hasData,
          teamAverageTotals,
          arrayOfAllQuestions,
          donutChartData,
          totalScoresDonutChartDataGreen,
          totalScoresDonutChartDataRed,
          totalScoresDonutChartDataYellow,
        },
      });
    })
    .catch((err) => {
      dispatch({
        type: FETCH_PUBLIC_LM_QUESTIONNAIRE_REPORT_SINGLE_DAY_REJECTED,
        payload: err,
      });
    });
};

export const fetchPublicLMLineChartData = (token, userId = null) => (
  dispatch,
) => {
  const userIdQuery = userId ? `&userId=${userId}` : '';
  dispatch({ type: FETCH_PUBLIC_LM_LINE_CHART_START });
  axiosUnauthed
    .get(`/public/reports/load-monitor/line?token=${token}${userIdQuery}`)
    .then((response) => {
      const hasData = response.data.dashboardData.some((dateObj) => dateObj.values.some((valuesObj) => valuesObj.value !== 0));
      dispatch({
        type: FETCH_PUBLIC_LM_LINE_CHART_FULFILLED,
        payload: {
          data: response.data,
          hasData,
        },
      });
    })
    .catch((err) => {
      dispatch({
        type: FETCH_PUBLIC_LM_LINE_CHART_REJECTED,
        payload: err,
      });
    });
};

export const setDuplicateLMDashModal = (bool, template = {}) => (dispatch) => {
  dispatch({
    type: SET_DUPLICATE_LM_DASH_MODAL,
    payload: {
      bool,
      template,
    },
  });
};

// Create a duplicate dashboard/template based on the ID
export const duplicateLMDashboard = (
  accountCode,
  templateId,
  submissionObject,
) => (dispatch) => {
  dispatch({ type: DUPLICATE_LM_DASH_START });
  axiosAuthed
    .post(
      `/accounts/${accountCode}/reports/load-monitor/templates/${templateId}/duplicate`,
      submissionObject,
    )
    .then((response) => {
      toast.success('Template Duplicated!');
      dispatch({
        type: DUPLICATE_LM_DASH_FULFILLED,
        payload: response.data,
      });
    })
    .catch((err) => {
      toast.error(err?.response?.headers?.message || 'An error has occurred');
      dispatch({
        type: DUPLICATE_LM_DASH_REJECTED,
        payload: err,
      });
    });
};

export const setLMTriOptionModal = (bool) => ({
  type: SET_LM_TRI_OPTION_MODAL,
  payload: bool,
});

export const setAboutLMDashModal = (bool, template = null) => ({
  type: SET_ABOUT_LM_DASH_MODAL,
  payload: {
    bool,
    template,
  },
});

export const setUnsavedLMDashModal = (bool) => (dispatch) => {
  dispatch({
    type: SET_IS_UNSAVED_LM_DASH_MODAL,
    payload: bool,
  });
};

export const setNextLocation = (location) => (dispatch) => {
  dispatch({
    type: SET_NEXT_LOCATION,
    payload: location,
  });
};

export const clearLMDashData = () => (dispatch) => {
  dispatch({
    type: CLEAR_LM_DASH_DATA,
  });
};

export const setChartDayRange = (days) => (dispatch) => {
  dispatch({
    type: SET_CHART_DAY_RANGE,
    payload: days,
  });
};

export const setIsDashboardDisplayed = (bool) => (dispatch) => {
  dispatch({
    type: SET_IS_DASHBOARD_DISPLAYED,
    payload: bool,
  });
};

export const setGroups = (groups) => (dispatch) => {
  dispatch({
    payload: groups,
    type: SET_GROUPS,
  });
};

export const clearLMDashModals = () => (dispatch) => {
  dispatch({
    type: CLEAR_LM_DASH_MODALS,
  });
};
