/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';
import Logo from '../../../../../shared/images/t-logo-xl.png';

import iconSet from '../../../../../shared/images/teambuildr-selection.json';
import { BoxShadow } from '../../../../../shared/GlobalStyles';
import { dashboardTracker } from '../../../../../shared/utils/amplitudeHelper';
import { clearFPDashData } from '../../../../force-plate-dashboard/ducks/forcePlateDashboardActions';
import { clearLMDashData } from '../../../../load-monitoring-dashboard/ducks/loadMonitoringDashboardActions';

const TemplateContainer = styled('div')`
  width: 100%;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  max-width: 1200px;
`;

const NoResultsContainer = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NoResultsText = styled('div')`
  font-family: "Nunito Sans";
  font-weight: 700;
  font-size: 34px;
`;

const Template = styled('div')`
  width: 373px;
  height: 322px;
  background: #ffffff;
  gap: 32px;
  padding: 32px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  font-family: "Nunito Sans";
`;

const TemplatePadder = styled('div')`
  padding: 10px;
`;

const TempName = styled('div')`
  font-size: 20px;
  font-weight: 600;
`;

const TempOwner = styled('div')`
  font-size: 14px;
`;

const Image = styled('img')`
  width: 75px;
  height: 75px;
  background-color: ${(props) => props.bgColor};
  border-radius: 50%;
  padding: 5px;
  object-fit: contain;
`;

const TempEdgeBox = styled('div')`
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const TopTempEdgeBox = styled('div')`
  height: 60px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  width: 100%;
`;

const RelativeContainer = styled('div')`
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const IconContainer = styled('div')`
  padding: 2px 0;
  transform: rotate(90deg);
  cursor: pointer;
`;

const PlusIconContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 75px;
  height: 75px;
  background: rgba(23, 135, 251, 0.6);
`;

const ViewProgramsMenu = styled('div')`
  position: absolute;
  width: 250px;
  background: white;
  box-shadow: ${BoxShadow};
  right: 0;
  display: flex;
  flex-direction: column;
  z-index: 100;
  top: 30px;
`;

const ViewProgramsMenuOption = styled('div')`
  width: 100%;
  height: 40px;
  background: white;
  display: flex;
  align-items: center;
  padding-left: 20px;
  border-bottom: ${(props) => (props.addBorderBottom ? '1px solid black' : 'none')};
  cursor: pointer;
  :hover {
    background: #f5f5f5;
  }
`;

const ViewProgramsMenuText = styled('div')`
  font-size: 14px;
  font-family: "Nunito Sans";
  margin-left: 15px;
  color: "lightgrey";
`;

const DropdownContainer = ({
  template,
  dispatch,
  idx,
  dropdownManager,
  setDropdownManager,
  handleTemplateView,
  setDeleteModal,
  setAboutModal,
  setEditModal,
  setDuplicateModal,
  setShareModal,
}) => (
  <RelativeContainer id='dropdown-container'>
    <IconContainer
      id='dropdown-container'
      onClick={() => {
        const oldValue = dropdownManager[template.templateId];
        const newId = template.templateId;
        const newObject = {};
        newObject[newId] = !oldValue;
        setDropdownManager(newObject);
      }}
    >
      <IcomoonReact
        iconSet={iconSet}
        size={25}
        id='dropdown-container'
        icon='dots'
        key={idx}
        color='grey'
        onClick={() => {
          const oldValue = dropdownManager[template.templateId];
          const newId = template.templateId;
          const newObject = {};
          newObject[newId] = !oldValue;
          setDropdownManager(newObject);
        }}
      />
    </IconContainer>
    {dropdownManager[template.templateId] ? (
      <ViewProgramsMenu id='dropdown-programs-menu'>
        <ViewProgramsMenuOption
          id='dropdown-programs-menu-option'
          onClick={() => handleTemplateView(template)}
        >
          <IcomoonReact
            iconSet={iconSet}
            size={20}
            icon='visible'
            color='lightgrey'
          />
          <ViewProgramsMenuText>View</ViewProgramsMenuText>
        </ViewProgramsMenuOption>
        <ViewProgramsMenuOption
          id='dropdown-programs-menu-option'
          onClick={() => {
            dispatch(setEditModal(true, template));
            // KPI tracking
            dashboardTracker(
              'Sport Coach Dashboard',
              'Saved Dashboard Dropdown Menu',
              'Edit dashboard clicked',
            );
          }}
        >
          <IcomoonReact
            iconSet={iconSet}
            size={20}
            icon='pencil'
            color='lightgrey'
          />
          <ViewProgramsMenuText>Edit</ViewProgramsMenuText>
        </ViewProgramsMenuOption>
        <ViewProgramsMenuOption
          id='dropdown-programs-menu-option'
          onClick={() => {
            dispatch(setShareModal(true, template));
            // KPI tracking
            dashboardTracker(
              'Sport Coach Dashboard',
              'Saved Dashboard Dropdown Menu',
              'Share dashboard clicked',
            );
          }}
        >
          <IcomoonReact
            iconSet={iconSet}
            size={20}
            icon='share'
            color='lightgrey'
          />
          <ViewProgramsMenuText>Share</ViewProgramsMenuText>
        </ViewProgramsMenuOption>
        <ViewProgramsMenuOption
          id='dropdown-programs-menu-option'
          onClick={() => {
            dispatch(setDuplicateModal(true, template));
            // KPI tracking
            dashboardTracker(
              'Sport Coach Dashboard',
              'Saved Dashboard Dropdown Menu',
              'Copy dashboard clicked',
            );
          }}
        >
          <IcomoonReact
            iconSet={iconSet}
            size={20}
            icon='copy'
            color='lightgrey'
          />
          <ViewProgramsMenuText>Duplicate</ViewProgramsMenuText>
        </ViewProgramsMenuOption>
        <ViewProgramsMenuOption
          id='dropdown-programs-menu-option'
          onClick={() => {
            dispatch(setAboutModal(true, template));
            // KPI tracking
            dashboardTracker(
              'Sport Coach Dashboard',
              'Saved Dashboard Dropdown Menu',
              'About dashboard clicked',
            );
          }}
        >
          <IcomoonReact
            iconSet={iconSet}
            size={20}
            icon='info'
            color='lightgrey'
          />
          <ViewProgramsMenuText>About</ViewProgramsMenuText>
        </ViewProgramsMenuOption>
        <ViewProgramsMenuOption
          id='dropdown-programs-menu-option'
          onClick={() => {
            dispatch(setDeleteModal(true, template));
            // KPI tracking
            dashboardTracker(
              'Sport Coach Dashboard',
              'Saved Dashboard Dropdown Menu',
              'Delete dashboard clicked',
            );
          }}
        >
          <IcomoonReact
            iconSet={iconSet}
            size={20}
            icon='trashcan'
            color='lightgrey'
          />
          <ViewProgramsMenuText>Delete</ViewProgramsMenuText>
        </ViewProgramsMenuOption>
      </ViewProgramsMenu>
    ) : null}
  </RelativeContainer>
);

const SharedTemplatesGrid = ({
  searchTerm, dashboard, templates, actions,
}) => {
  const {
    handleCreateNewDashboard,
    handleTemplateView,
    setDeleteModal,
    setAboutModal,
    setEditModal,
    setDuplicateModal,
    setShareModal,
    clearActiveTemplate,
  } = actions;
  const dispatch = useDispatch();
  const primary = useSelector(
    (state) => state.auth.data.currentUser?.userSkin?.primary,
  );

  const [dropdownManager, setDropdownManager] = useState({});
  const [searchedList, setSearchedList] = useState([]);
  const [isSearchFocused, setIsSearchFocused] = useState(false);

  useEffect(() => {
    const searchedArray = [];
    templates.forEach((template) => {
      if (template.title.toLowerCase().includes(searchTerm.toLowerCase())) {
        searchedArray.push(template);
      }
    });
    if (searchedArray.length) {
      setSearchedList(searchedArray);
    } else {
      setSearchedList([]);
    }
  }, [searchTerm, templates]);

  useEffect(() => {
    dispatch(clearActiveTemplate());
    if (dashboard === 'Force Plate') {
      dispatch(clearFPDashData());
    }
    if (dashboard === 'Load Monitoring') {
      dispatch(clearLMDashData());
    }
  }, []);

  const templateSorter = () => {
    if (searchTerm.length && searchedList.length) {
      return searchedList.map((template, idx) => (
        <TemplatePadder id='template-padder'>
          <Template
            id='template'
            onClick={(e) => {
              if (
                e.target.id === 'template'
                || e.target.id === 'image'
                || e.target.id === 'temp-name'
                || e.target.id === 'top-edge-box'
                || e.target.id === 'temp-owner'
              ) {
                handleTemplateView(template);
              }
            }}
          >
            <TopTempEdgeBox id='top-edge-box'>
              <DropdownContainer
                dispatch={dispatch}
                template={template}
                idx={idx}
                dropdownId='dropdown-container'
                dropdownManager={dropdownManager}
                setDropdownManager={setDropdownManager}
                handleTemplateView={handleTemplateView}
                setDeleteModal={setDeleteModal}
                setAboutModal={setAboutModal}
                setEditModal={setEditModal}
                setDuplicateModal={setDuplicateModal}
                setShareModal={setShareModal}
              />
            </TopTempEdgeBox>
            <Image
              alt='dashboard logo'
              bgColor={primary}
              id='image'
              src={template.teamImage || Logo}
            />
            <TempEdgeBox id='temp-edge-box'>
              <TempName id='temp-name'>{template.title}</TempName>
              <TempOwner id='temp-owner'>{`${template.firstName} ${template.lastName}`}</TempOwner>
            </TempEdgeBox>
          </Template>
        </TemplatePadder>
      ));
    }

    if (!searchTerm.length) {
      return (
        <>
          <TemplatePadder onClick={handleCreateNewDashboard}>
            <Template id='template'>
              <TopTempEdgeBox id='top-edge-box' />
              <PlusIconContainer>
                <IcomoonReact
                  iconSet={iconSet}
                  size={25}
                  id='dropdown-container'
                  icon='plus'
                  color='white'
                />
              </PlusIconContainer>
              <TempEdgeBox id='temp-edge-box'>
                <div />
                <TempName id='temp-name'>Create New Dashboard</TempName>
              </TempEdgeBox>
            </Template>
          </TemplatePadder>
          {templates.map((template, idx) => (
            <TemplatePadder id='template-padder'>
              <Template
                id='template'
                onClick={(e) => {
                  if (
                    e.target.id === 'template'
                    || e.target.id === 'image'
                    || e.target.id === 'temp-name'
                    || e.target.id === 'top-edge-box'
                    || e.target.id === 'temp-owner'
                  ) {
                    handleTemplateView(template);
                  }
                }}
              >
                <TopTempEdgeBox id='top-edge-box'>
                  <DropdownContainer
                    dispatch={dispatch}
                    template={template}
                    idx={idx}
                    dropdownId='dropdown-container'
                    dropdownManager={dropdownManager}
                    setDropdownManager={setDropdownManager}
                    handleTemplateView={handleTemplateView}
                    setDeleteModal={setDeleteModal}
                    setAboutModal={setAboutModal}
                    setEditModal={setEditModal}
                    setDuplicateModal={setDuplicateModal}
                    setShareModal={setShareModal}
                  />
                </TopTempEdgeBox>
                <Image
                  alt='dashboard logo'
                  bgColor={primary}
                  id='image'
                  src={template.teamImage || Logo}
                />
                <TempEdgeBox id='temp-edge-box'>
                  <TempName id='temp-name'>{template.title}</TempName>
                  <TempOwner id='temp-owner'>{`${template.firstName} ${template.lastName}`}</TempOwner>
                </TempEdgeBox>
              </Template>
            </TemplatePadder>
          ))}
        </>
      );
    }
    return null;
  };

  if (searchTerm.length && !searchedList.length) {
    return (
      <NoResultsContainer>
        <NoResultsText>No search results found</NoResultsText>
      </NoResultsContainer>
    );
  }

  return (
    <TemplateContainer
      id='wrapper'
      onClick={(e) => {
        if (
          e.target.id === 'wrapper'
          || e.target.id === 'template-padder'
          || e.target.id === 'template'
          || e.target.id === 'top-edge-box'
          || e.target.id === 'image'
          || e.target.id === 'temp-edge-box'
          || e.target.id === 'temp-name'
          || e.target.id === 'temp-owner'
          || e.target.id === 'dropdown-programs-menu-option'
          || e.target.id === 'dropdown-programs-menu'
        ) {
          setDropdownManager({});
        }
        if (
          e.target.id !== 'search-container'
          && e.target.id !== 'search-icon-container'
          && e.target.id !== 'search-container-input'
          && e.target.id !== 'mag-icon'
          && isSearchFocused
          && searchTerm.length === 0
        ) {
          setIsSearchFocused(false);
        }
      }}
    >
      {templateSorter()}
    </TemplateContainer>
  );
};

export default SharedTemplatesGrid;
