/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import Helmet from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import tinycolor from 'tinycolor2';
import ResizeObserver from 'react-resize-observer';
import { Global, css } from '@emotion/core';

import Spinner from '../../shared/components/Spinner/Spinner';
import LightLogo from '../../shared/images/poweredByTBWhite.png';
import DarkLogo from '../../shared/images/poweredByTBBlack.png';
import Button from '../../shared/components/Button/Button';
import HeaderText from '../../shared/components/HeaderText/HeaderText';
import InvalidTokenModal from '../sport-coach-dashboard/components/presentational/InvalidTokenModal';
import SharedTemplateAboutModal from '../sports-science/components/presentational/sharedTemplateComponents/SharedTemplateAboutModal';

import {
  setAboutLMDashModal,
  fetchPublicLMQuestionnaireReportSingleDay,
  fetchPublicLMLineChartData,
} from './ducks/loadMonitoringDashboardActions';
import ViewOnlyGeneratedData from './components/presentational/ViewOnlyGeneratedData';

const Container = styled('div')`
  width: 100%;
`;

const Wrapper = styled('div')`
  height: 100%;
`;

const PageWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  background: #f5f5f5;
`;

const DashboardBanner = styled('div')`
  background-color: ${(props) => props.bgColor};
  height: 100px;
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
`;

const UserLogo = styled('img')`
  height: 75px;
`;

const TBuildrLogo = styled('div')`
  background-image: ${(props) => (tinycolor(props.bgColor).isLight()
    ? `url(${DarkLogo})`
    : `url(${LightLogo})`)};
  background-repeat: no-repeat;
  height: 46px;
  width: 200px;
`;

const DashboardHeader = styled('div')`
  display: flex;
  width: 100%;
  background-color: #ffffff;
  padding-top: 30px;
  padding-left: 20px;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 10px;
`;

const TitleAndInfo = styled('div')`
  display: flex;

  button {
    margin-left: 5px;
  }

  svg {
    border: 1px solid #808080;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    padding: 3px;
  }
`;

const ViewOnlyLMDash = () => {
  const aboutModalShowing = useSelector(
    (state) => state.loadMonitoringDashboard.ui.aboutModalShowing,
  );
  const dashBGColor = useSelector(
    (state) => state.loadMonitoringDashboard.data.dashBGColor,
  );
  const dashLogo = useSelector(
    (state) => state.loadMonitoringDashboard.data.dashLogo,
  );
  const dashTitle = useSelector(
    (state) => state.loadMonitoringDashboard.data.dashTitle,
  );
  const questionnaireReportError = useSelector(
    (state) => state.loadMonitoringDashboard.data.questionnaireReportError,
  );
  const loadMonitoringLineChartDataError = useSelector(
    (state) => state.loadMonitoringDashboard.data.loadMonitoringLineChartDataError,
  );
  const currentAthleteInAthletePicker = useSelector(
    (state) => state.loadMonitoringDashboard.data.currentAthleteInAthletePicker,
  );
  const questionnaireReportHasData = useSelector(
    (state) => state.loadMonitoringDashboard.data.questionnaireReportHasData,
  );
  const lineChartHasData = useSelector(
    (state) => state.loadMonitoringDashboard.data.lineChartHasData,
  );
  const isLoadingQuestionnaireReportMultiday = useSelector(
    (state) => state.loadMonitoringDashboard.ui.isLoadingQuestionnaireReportMultiday,
  );
  const isLoadingQuestionnaireReportSingleDay = useSelector(
    (state) => state.loadMonitoringDashboard.ui.isLoadingQuestionnaireReportSingleDay,
  );
  const isLoadingLineChartData = useSelector(
    (state) => state.loadMonitoringDashboard.ui.isLoadingLineChartData,
  );
  const pageLoadingGroup = !currentAthleteInAthletePicker.isAthlete
    && isLoadingQuestionnaireReportSingleDay
    && isLoadingLineChartData;
  const pageLoadingAthlete = currentAthleteInAthletePicker.isAthlete
    && isLoadingQuestionnaireReportMultiday
    && isLoadingLineChartData;
  const hasData = questionnaireReportHasData || lineChartHasData;

  const lineChartNoDataError = loadMonitoringLineChartDataError
    && loadMonitoringLineChartDataError === 'No data found before date selected';

  const questionnaireReportNoDataError = questionnaireReportError
    && questionnaireReportError === 'No data found before date selected';

  const noDataError = lineChartNoDataError && questionnaireReportNoDataError;

  const lmDashError = !!Object.keys(questionnaireReportError).length
    || !!Object.keys(loadMonitoringLineChartDataError).length;

  const invalidReport = lmDashError && !noDataError;

  const dispatch = useDispatch();

  const location = useLocation();
  const token = new URLSearchParams(location.search).get('token');

  const [containerWidth, setContainerWidth] = useState({});

  // Fetch data on page load with token
  useEffect(() => {
    dispatch(fetchPublicLMQuestionnaireReportSingleDay(token));
    dispatch(fetchPublicLMLineChartData(token));
  }, []);

  return (
    <Container containerWidth={containerWidth}>
      <Global
        styles={css`
          body {
            overflow-y: hidden;
          }
        `}
      />
      <Helmet>
        <title>Load Monitoring Dashboard | TeamBuildr</title>
      </Helmet>
      <ResizeObserver
        onResize={(rect) => {
          setContainerWidth(rect?.width);
        }}
      />
      {invalidReport && <InvalidTokenModal />}
      {(pageLoadingGroup || pageLoadingAthlete) && !hasData && (
        <Wrapper>
          <Spinner />
        </Wrapper>
      )}
      <PageWrapper>
        <DashboardBanner bgColor={dashBGColor || '#444444'}>
          {dashLogo ? (
            <UserLogo src={dashLogo} alt='user logo' id='userLogo' />
          ) : (
            <div />
          )}
          <TBuildrLogo bgColor={dashBGColor || '#444444'} />
        </DashboardBanner>
        {(!lmDashError || noDataError) && (
          <>
            <DashboardHeader>
              <TitleAndInfo>
                <HeaderText fontWeight='300' letterSpacing='normal'>
                  {dashTitle}
                </HeaderText>
                <Button
                  iconOnly
                  icon='info'
                  customColor='#00000000'
                  noBorder
                  onClick={() => dispatch(setAboutLMDashModal(true))}
                  rounded
                />
              </TitleAndInfo>
            </DashboardHeader>
            <ViewOnlyGeneratedData
              containerWidth={containerWidth}
              token={token}
            />
            {aboutModalShowing && (
              <SharedTemplateAboutModal dashboard='Load Monitoring' />
            )}
          </>
        )}
      </PageWrapper>
    </Container>
  );
};

export default ViewOnlyLMDash;
