import styled from '@emotion/styled';
import {
  space, color, layout, flexbox, position,
} from 'styled-system';

const Wrapper = styled('div')`
  align-items: center;
  ${color}
  display: flex;
  ${flexbox}
  height: 100%;
  justify-content: center;
  ${layout}
  ${position}
  ${space}
  width: 100%;
`;

export default Wrapper;
