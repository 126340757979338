import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styled from '@emotion/styled';
import Select from 'react-select';

import ReportDatePicker from '../QuestionnaireReport/QuestionnaireReportDatePicker';
import Text from '../../../../shared/_TBComponents/Text';
import Toggle from '../../../../shared/_TBComponents/Toggle';

const Wrapper = styled('div')`
  margin-bottom: 30px;
`;

const dateOptions = [
  {
    id: 1,
    label: 'Today',
  },
  {
    id: 2,
    label: '30 Days',
  },
  {
    id: 3,
    label: 'Custom',
  },
];

const exerOptions = [
  {
    id: 1,
    label: 'All Exercise Types',
  },
  {
    id: 2,
    label: 'Lifts',
  },
  {
    id: 3,
    label: 'SAQ+C',
  },
  {
    id: 4,
    label: 'Circuits',
  },
  {
    id: 5,
    label: 'Questionnaires',
  },
];

const SelectDateTypeText = styled('div')`
  font-family: 'Nunito Sans';
  font-size: 12pt;
  color: #444444;
  margin-bottom: 15px;
  font-size: 14px;
  font-weight: 700;
`;

const ToggleRow = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 10px;
  margin: 20px 0px;

  label {
    font-size: 13px;
    font-weight: unset;
  }
`;

const CompletionReportFormContent = ({ formProps }) => {
  const setStartDate = (date) => {
    formProps.setFieldValue('startDate', {
      date,
      queryFormat: moment(date).format('YYYY-MM-DD'),
    });
    formProps.setFieldValue('endDate', {
      date: null,
      queryFormat: '',
    });
  };

  const setEndDate = (date) => {
    formProps.setFieldValue('endDate', {
      date,
      queryFormat: moment(date).format('YYYY-MM-DD'),
    });
  };

  const customStyles = {
    option: (styles) => ({
      ...styles,
      cursor: 'pointer',
    }),
    control: (styles) => ({
      ...styles,
      cursor: 'pointer',
    }),
    menuPortal: (base) => ({
      ...base, zIndex: 9999,
    }),
  };

  return (
    <Wrapper>
      <SelectDateTypeText>Select Date Type</SelectDateTypeText>
      <Select
        className='item-select'
        defaultValue={formProps?.values?.dateOption || null}
        options={dateOptions}
        menuPortalTarget={document.body}
        styles={customStyles}
        getOptionLabel={(option) => option.label}
        getOptionValue={(option) => option.id}
        onChange={(selectedType) => {
          formProps.setFieldValue('rangeType', selectedType.id);
          formProps.setFieldValue('dateOption', selectedType);
        }}
      />
      {formProps.values.rangeType === 3 ? (
        <ReportDatePicker
          formProps={formProps}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          startDate={formProps.values.startDate.date}
          endDate={formProps.values.endDate.date}
          reportType='multiDay'
          capDateRange
        />
      ) : null}
      <ToggleRow>
        <Text styledTextProps={{ fontSize: '14px', fontWeight: 700 }}>Report Options</Text>
        <Toggle
          defaultChecked={formProps.values.showAverages}
          offColor='#4D4D4D'
          offLabel='Show Averages'
          onChange={() => {
            formProps.setFieldValue(
              'showAverages',
              formProps.values.showAverages === false && true,
            );
          }}
        />
      </ToggleRow>
      <SelectDateTypeText>Select Exercise Type</SelectDateTypeText>
      <Select
        className='item-select'
        defaultValue={exerOptions[formProps?.values?.exerType - 1] || exerOptions[0]}
        options={exerOptions}
        menuPortalTarget={document.body}
        styles={customStyles}
        getOptionLabel={(option) => option.label}
        getOptionValue={(option) => option.id}
        onChange={(selectedType) => {
          formProps.setFieldValue('exerType', selectedType.id);
        }}
      />
    </Wrapper>
  );
};

CompletionReportFormContent.propTypes = {
  formProps: PropTypes.instanceOf(Object).isRequired,
};

export default CompletionReportFormContent;
