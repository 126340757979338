import styled from '@emotion/styled';
import {
  space, color, layout, flexbox, typography, position, border, shadow,
} from 'styled-system';

/**
 * adding color to div from styled-system gives access to a slew of
 * color-based props that just get automatically added to the
 * styled div (so, for instance, passing color in as below, allows 'bg'
 * to be defined on the wrapper component)
 *
 * https://github.com/styled-system/styled-system/blob/master/docs/api.md
 *
 * Go to the above link to see which props expose which css-based properties
 * on the component
 */

const Wrapper = styled('button')`
  align-items: center;
  align-self: ${(props) => (props.bottom ? 'flex-end' : '')};
  background-color: ${(props) => (props.bgColor)};
  border: ${(props) => (props.noBorder ? '0px' : `1px solid ${props.borderColor}`)};
  border-radius: ${(props) => {
    const isRounded = props.rounded ? '4px' : '20px';
    const borderRadius = props.customBorderRadius || isRounded;
    return (
      props.square ? '0px' : borderRadius
    );
  }};
  ${border}
  ${color}
  cursor: ${(props) => (props.disabled ? 'cursor' : 'pointer')};
  display: flex;
  ${flexbox}
  flex-direction: row;
  height: ${(props) => (props.large && '48px')};
  justify-content: center;
  ${layout}
  min-width: ${(props) => (props.isLoading ? '100px' : '')};
  opacity: ${(props) => (props.disabled ? '.4' : '1')};
  padding: ${(props) => (props.iconOnly ? '5px' : '8px 18px')};
  ${position}
  ${space}
  ${shadow}
  transition: all 250ms ease-in-out;
  ${typography}
  width: ${(props) => (props.fullWidth ? '100%' : 'auto')};
  
  &:hover {
    opacity: ${(props) => (props.noHover ? '0.4' : '0.7')};
  }
  .icomoon-svg {
    display: ${(props) => (props.icon || props.isLoading ? 'block' : 'none')}!important;
    align-self: center;
    margin-right: ${(props) => (props.iconOnly || props.isLoading ? '0px' : '10px')};
    margin-top: ${(props) => (props.iconMarginTop)};
    path {
      transition: all 250ms ease-in-out;
      fill: ${(props) => (props.spinnerColor)};
    }
  }
`;

export default Wrapper;
