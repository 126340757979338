import React, { useEffect } from 'react';
import styled from '@emotion/styled';

import useMaxReportModal from '../hooks/useMaxReportModal';
import ReportingModal from '../ReportingModal';

const MainContainer = styled('main')`
  flex: ${(props) => (props.isReportOpen ? 50 : 10)};
  margin: 0px 30px;
  display: flex;
  flex-direction: column;
  overflow-x: scroll;
  
  @media screen and (max-width: 540px) {
    width: 100%;
    padding: 0px 15px;
    margin: 0px;
  }
`;

const RawDataReport = () => {
  const {
    handleSetIsModalOpen,
  } = useMaxReportModal();

  useEffect(() => {
    handleSetIsModalOpen(true, 'rawDataReportModal');
  }, []);

  return (
    <MainContainer>
      <ReportingModal />
    </MainContainer>
  );
};

export default RawDataReport;
