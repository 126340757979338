// authored by Joshua Beedle
import styled from '@emotion/styled';
import { typography, color } from 'styled-system';

/**
 * @param {Bool} allCaps determines whether the link should be uppercased
 * @param {String} display determines which display CSS property to use
 * @returns
*/

const Link = styled('a')`
  color: #444444;
  ${color}
  display: ${(props) => (props.display ? props.display : 'inline-block')};
  font-family: 'Nunito Sans';
  font-size: 12px;
  ${typography}

  ${(props) => props.allCaps && (`
    letter-spacing: 1.3px;
    text-transform: uppercase;
    font-weight: 900;
  `)}
`;

export default Link;
