/* eslint-disable max-len */
import React, { useState, useLayoutEffect } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const TextOverflowEllipse = styled('h3')`
overflow: hidden;
white-space: nowrap;
text-overflow: ellipsis;
width: 100%;
font-family: 'Nunito Sans';
font-size:  ${(props) => (props.fontSize ? props.fontSize : '16px')};
color: ${(props) => (props.fontColor ? props.fontColor : '#444444')};
margin-bottom: 0px;
font-weight: ${(props) => (props.fontWeight ? props.fontWeight : '700')};
cursor: inherit;
transition: all 250ms ease-in-out;
&:hover {
  opacity: ${(props) => props.addHoverOpacity && '0.7'};
}
`;

const TextContainer = styled('div')`
position: relative;
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
height:  ${(props) => (props.textContainerHeight ? props.textContainerHeight : 'none')};
min-width: ${(props) => (props.textContainerMinWidth ? props.textContainerMinWidth : '')};
max-width: ${(props) => (props.textContainerMaxWidth ? props.textContainerMaxWidth : '200px')};
border-radius: 12px;
padding: ${(props) => (props.textContainerPadding ? props.textContainerPadding : '8px 16px 8px 16px')};
background-color: ${(props) => (props.textContainerBackgroundColor ? props.textContainerBackgroundColor : 'none')};
opacity: 1;
cursor: ${(props) => (props.addToolTip ? 'pointer' : 'mouse')};


 .tooltiptext {
  visibility: hidden;
  word-wrap: break-word;
  max-width: 250px;
  width: 150px;
  background-color: #FFFFFF;
  box-shadow: 0px 0px 1px rgb(48 49 51 / 5%), 0px 4px 8px rgb(48 49 51 / 10%);
  color: #444444;
  font-family: 'Nunito Sans';
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  border-radius: 6px;
  padding: 8px;
  position: absolute;
  z-index: 1;
  bottom: 105%;
  left: 0%;
  right: 0%;
}

 .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: white transparent transparent transparent;
}

:hover .tooltiptext {
  visibility: visible;
}

.tooltiptextBottom {
  visibility: hidden;
  word-wrap: break-word;
  max-width: 280px;
  width: fit-content;
  background-color: #FFFFFF;
  box-shadow: 0px 0px 1px rgb(48 49 51 / 5%), 0px 4px 8px rgb(48 49 51 / 10%);
  color: #444444;
  font-family: 'Nunito Sans';
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  border-radius: 6px;
  padding: 8px;
  position: absolute;
  z-index: 1;
  top: 105%;
  left: 0%;
  right: 0%;
}

 .tooltiptextBottom::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent white transparent;
}

:hover .tooltiptextBottom {
  visibility: visible;
}

`;

/**
 *
 * @param {Object} textToDisplay the text that will be wrapped with a tooltip when it overflows the container
 * @returns
 */
const TBTooltip = ({
  alwaysDisplay,
  textToDisplay,
  textToHover,
  textContainerBackgroundColor,
  textContainerPadding,
  textContainerMinWidth,
  textContainerMaxWidth,
  textContainerHeight,
  fontColor,
  fontSize,
  fontWeight,
  cursor,
  CustomComponentToHover,
  toolTipFontColor,
  toolTipFontSize,
  toolTipFontWeight,
  addHoverOpacity,
}) => {
  const [addToolTip, setAddToolTip] = useState(false);

  const [isHovering, setIsHovering] = useState(false);

  const [isToolTipOnBottom, setIsToolTipOnBottom] = useState(false);

  const ref = React.createRef();

  const toolTipRef = React.createRef();

  const handleMouseEnter = () => {
    setIsHovering(true);
    if (addToolTip && toolTipRef.current?.getBoundingClientRect().top < 45) {
      setIsToolTipOnBottom(true);
    }
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
    if (addToolTip) {
      setIsToolTipOnBottom(false);
    }
  };

  useLayoutEffect(() => {
    if (alwaysDisplay || (ref?.current.childNodes[0].clientWidth < ref?.current.childNodes[0].scrollWidth)) {
      setAddToolTip(true);
    }
  }, [ref]);

  return (
    <>
      <TextContainer
        cursor={cursor}
        addToolTip={addToolTip}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        ref={ref}
        textContainerPadding={textContainerPadding}
        textContainerMinWidth={textContainerMinWidth}
        textContainerMaxWidth={textContainerMaxWidth}
        textContainerHeight={textContainerHeight}
        textContainerBackgroundColor={textContainerBackgroundColor}
      >
        {addToolTip && isHovering && (
          <span
            className={isToolTipOnBottom ? 'tooltiptextBottom' : 'tooltiptext'}
            ref={toolTipRef}
            style={{
              fontColor: toolTipFontColor,
              fontWeight: toolTipFontWeight,
              fontSize: toolTipFontSize,
              zIndex: 10,
            }}
          >
            {textToHover}
          </span>
        )}
        {CustomComponentToHover ? (
          <CustomComponentToHover />
        ) : (
          <TextOverflowEllipse fontSize={fontSize} fontColor={fontColor} fontWeight={fontWeight} addHoverOpacity={addHoverOpacity}>
            {textToDisplay}
          </TextOverflowEllipse>
        )}

      </TextContainer>
    </>
  );
};

TBTooltip.propTypes = {
  alwaysDisplay: PropTypes.bool,
  textToDisplay: PropTypes.string.isRequired,
  textToHover: PropTypes.string.isRequired,
  textContainerBackgroundColor: PropTypes.string,
  fontColor: PropTypes.string,
  fontSize: PropTypes.string,
  fontWeight: PropTypes.number,
  textContainerPadding: PropTypes.string,
  textContainerMinWidth: PropTypes.string,
  textContainerMaxWidth: PropTypes.string,
  textContainerHeight: PropTypes.string,
  cursor: PropTypes.string,
  CustomComponentToHover: PropTypes.elementType,
  toolTipFontColor: PropTypes.string,
  toolTipFontSize: PropTypes.string,
  toolTipFontWeight: PropTypes.number,
  addHoverOpacity: PropTypes.bool,
};

TBTooltip.defaultProps = {
  alwaysDisplay: false,
  textContainerBackgroundColor: 'none',
  fontColor: '#444444',
  fontSize: '16px',
  fontWeight: 700,
  textContainerPadding: '8px 16px 8px 16px',
  textContainerMinWidth: '',
  textContainerMaxWidth: '200px',
  textContainerHeight: 'none',
  cursor: 'pointer',
  CustomComponentToHover: null,
  toolTipFontColor: '',
  toolTipFontSize: '',
  toolTipFontWeight: 700,
  addHoverOpacity: false,
};

export default TBTooltip;
