/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';
import { useTheme } from 'emotion-theming';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import iconSet from '../../images/teambuildr-selection.json';
import Text from '../Text/Text';
import generateUniqueId from '../../utils/generateUniqueId';
import { navigationTracker } from '../../utils/amplitudeHelper';

/** when you are importing LayoutWithsidebarNav...
 * you should pass in a prop that is an array of objects
 * that will reference your your NavLinks for the SideBar,
 * each object contains the info for a specific navLink,
 * you need to pass in the name of the route and the text to be displayed
 * for example: navLinkArrayOfObjects =

  [ {routeName: settings, text: General Settings},
    {routeName: settings/account, text: Account Settings}
  ]
* you will pass navLinkArrayOfObjects as a prop to the LayoutWithSidebarNav component
* with prop drilling you will then pass it to the SideBar component
  that is a child of the LayoutWithSideBarNav Compnent
* so you will define this array of objects in the file where you are importing LayoutWithSideBarNav
* or you can define it in the constants file
*/

/** sideBarTitle and sideBarTitleIcon are optional props if you want the sideBar to have a title */

const Sidebar = ({
  navLinkArrayOfObjects, sideBarTitle, sideBarTitleIcon, flags,
}) => {
  const theme = useTheme();
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const organizationSettings = useSelector((state) => state.auth.data.organizationSettings);
  const primaryAdmin = currentUser?.primaryAdmin;
  const admin = currentUser?.admin;
  const organizationResellerAccountEnabled = currentUser?.organizationResellerAccountEnabled;
  const dodStatus = organizationSettings?.generalSettings?.dodStatus;
  const { resellerSubscriptions } = currentUser;
  const { canAccessGymStudioHub } = currentUser;

  const SidebarContainer = styled('aside')`
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    padding: 40px 30px;
    width: 315px;
    height: 100%;
    background-color: ${theme.colors.background[0]};
    box-shadow: 0px 0px 1px rgba(48, 49, 51, 0.05), 0px 8px 16px rgba(48, 49, 51, 0.1);

    .sidebar-item {
      display: flex;
      flex-direction: row;
      padding-left: 10px;
      align-items: center;
      color: ${theme.colors.text};
      font-family: 'Nunito Sans';
      font-weight: 600;
      font-size: 16px;
      margin-bottom: 15px;
    }
    .icon-style {
      margin-right: 15px;
    }
    .active-sidebar-item {
      display: flex;
      flex-direction: row;
      padding-left: 10px;
      align-items: center;
      color: ${theme.colors.orange};
      font-family: 'Nunito Sans';
      font-weight: 600;
      font-size: 17px;
    }
  `;

  const SideBarTitleContainer = styled('div')`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 15px;
  `;

  const SettingsSideBarContent = () => (
    <SidebarContainer>
      <SideBarTitleContainer>
        <IcomoonReact
          iconSet={iconSet}
          size={24}
          icon={sideBarTitleIcon}
          className='icon-style'
        />
        <Text fontWeight='400' fontSize={24}>{sideBarTitle}</Text>
      </SideBarTitleContainer>
      {navLinkArrayOfObjects.map((navLinkObject) => {
        if (navLinkObject.routeName.includes('/settings/organization')) {
          if (primaryAdmin > 0) {
            return (
              <NavLink key={generateUniqueId()} exact to={navLinkObject.routeName} activeClassName='active-sidebar-item' className='sidebar-item'>
                {' '}
                <IcomoonReact
                  iconSet={iconSet}
                  size={18}
                  icon={navLinkObject.icon}
                  className='icon-style'
                />
                {navLinkObject.text}
              </NavLink>

            );
          }
          return (
            null
          );
        }
        if (navLinkObject.routeName.includes('/settings/subscription')) {
          if (!admin && (organizationResellerAccountEnabled || (resellerSubscriptions && resellerSubscriptions.length))) {
            return (
              <a key={generateUniqueId()} href={`${process.env.PHP_APP_URL || 'https://app.teambuildr.com'}/settings/subscription`} className='sidebar-item'>
                {' '}
                <IcomoonReact
                  iconSet={iconSet}
                  size={18}
                  icon={navLinkObject.icon}
                  className='icon-style'
                />
                {navLinkObject.text}
              </a>
            );
          }
          return (
            null
          );
        }
        if (navLinkObject.routeName.includes('/settings/tactical')) {
          if (primaryAdmin > 0 && dodStatus) {
            return (
              <NavLink key={generateUniqueId()} exact to={navLinkObject.routeName} activeClassName='active-sidebar-item' className='sidebar-item'>
                {' '}
                <IcomoonReact
                  iconSet={iconSet}
                  size={18}
                  icon={navLinkObject.icon}
                  className='icon-style'
                />
                {navLinkObject.text}
              </NavLink>
            );
          }
          return (
            null
          );
        }
        if (navLinkObject.routeName.includes('/settings/integrations')) {
          if (primaryAdmin > 0) {
            return (
              <a key={generateUniqueId()} href={`${process.env.PHP_APP_URL || 'https://app.teambuildr.com'}/settings/integrations`} className='sidebar-item'>
                {' '}
                <IcomoonReact
                  iconSet={iconSet}
                  size={18}
                  icon={navLinkObject.icon}
                  className='icon-style'
                />
                {navLinkObject.text}
              </a>
            );
          }
          return (
            null
          );
        }
        if (navLinkObject.routeName === '/gymstudio') {
          if (canAccessGymStudioHub && flags.gymStudioIntegration) {
            return (
              <NavLink
                key={generateUniqueId()}
                exact
                to={navLinkObject.routeName}
                activeClassName='active-sidebar-item'
                className='sidebar-item'
                onClick={() => {
                  navigationTracker('GymStudio');
                }}
              >
                <IcomoonReact
                  iconSet={iconSet}
                  size={18}
                  icon={navLinkObject.icon}
                  className='icon-style'
                />
                {navLinkObject.text}
              </NavLink>
            );
          }
          return null;
        }
        return (
          <NavLink key={generateUniqueId()} exact to={navLinkObject.routeName} activeClassName='active-sidebar-item' className='sidebar-item'>
            {' '}
            <IcomoonReact
              iconSet={iconSet}
              size={18}
              icon={navLinkObject.icon}
              className='icon-style'
            />
            {navLinkObject.text}
          </NavLink>
        );
      })}
    </SidebarContainer>
  );

  return (
    <>
      {sideBarTitle === 'Settings' ? (
        SettingsSideBarContent()
      ) : (
        <SidebarContainer>
          <SideBarTitleContainer>
            <IcomoonReact
              iconSet={iconSet}
              size={24}
              icon={sideBarTitleIcon}
              className='icon-style'
            />
            <Text fontWeight='400' fontSize={24}>{sideBarTitle}</Text>
          </SideBarTitleContainer>
          {navLinkArrayOfObjects.map((navLinkObject) => (
            <NavLink key={generateUniqueId()} exact to={navLinkObject.routeName} activeClassName='active-sidebar-item' className='sidebar-item'>
              {' '}
              <IcomoonReact
                iconSet={iconSet}
                size={18}
                icon={navLinkObject.icon}
                className='icon-style'
              />
              {navLinkObject.text}
            </NavLink>
          ))}
        </SidebarContainer>
      )}
    </>
  );
};

Sidebar.propTypes = {
  navLinkArrayOfObjects: PropTypes.arrayOf(PropTypes.object).isRequired,
  sideBarTitle: PropTypes.string,
  sideBarTitleIcon: PropTypes.string,
};

Sidebar.defaultProps = {
  sideBarTitle: '',
  sideBarTitleIcon: '',
};

export default withLDConsumer()(Sidebar);
