// a relatively complex component that displays a different modal depending on which
// document type the user intends to create

import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import IcomoonReact from 'icomoon-react';
import Text from '../../../../../shared/components/Text/Text';
import iconSet from '../../../../../shared/images/teambuildr-selection.json';

const ModalWrapper = styled('div')`
  display: flex;
  width: 100%;
  .modal-button {
    display: flex;
    align-items: center;
    background-color: #0dcc8a;
  }
`;

const AnotherWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const NavigationWrapperFolder = styled('div')`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  z-index: 1;
  .back-arrow-div {
    cursor: pointer;
  }
`;

const FolderTextWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
  margin-top: -10px;

  .calendar-icon-text {
    min-width: 300px;
    display: flex;
    justify-content: center;
    font-size: 26px;
    font-weight: 300;
  }
`;

const InnerFolderFormWrapper = styled('div')`
  display: flex;
  width: 80%;
  justify-content: left;
  align-items: center;
  margin-top: 5px;
  .thanks-text {
    text-align: center;
  }
  .formik-form {
    width: 100%;
  }
`;

const OuterFolderFormWrapper = styled('div')`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .divider-line {
    display: flex;
    width: 100%;
    border-top: 2px solid rgb(232,232,232);
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .schedule-call-button {
    margin-top: 30px;
  }
`;

const ModalVerifyReseller = ({
  onRequestClose,
}) => (
  <ModalWrapper>
    <AnotherWrapper>
      <NavigationWrapperFolder>
        <Text
          className='back-arrow-div'
          onClick={() => {
            onRequestClose();
          }}
        >
          <IcomoonReact
            iconSet={iconSet}
            size={10}
            icon='remove'
          />
        </Text>
      </NavigationWrapperFolder>
      <OuterFolderFormWrapper>
        <FolderTextWrapper>
          <Text className='calendar-icon-text'>Online Payments Account</Text>
        </FolderTextWrapper>
        <div className='divider-line' />
        <InnerFolderFormWrapper>
          <Text className='thanks-text'>Our internal team is reviewing your account and will approve it shortly. If we have any questions about your account, we will reach out and notify you. You will also receive notification when your account is active.</Text>
        </InnerFolderFormWrapper>
      </OuterFolderFormWrapper>
    </AnotherWrapper>
  </ModalWrapper>
);

ModalVerifyReseller.propTypes = {
  onRequestClose: PropTypes.func.isRequired,
};

export default ModalVerifyReseller;
