import styled from '@emotion/styled';
import {
  space, color, layout, flexbox, typography, position,
} from 'styled-system';

const Wrapper = styled('div')`
  color: ${(props) => props.color};
  ${color}
  ${flexbox}
  ${layout}
  overflow: hidden;
  ${position}
  ${space}
  text-overflow: ellipsis;
  ${typography}
  white-space: nowrap;
`;

export default Wrapper;
