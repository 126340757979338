/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled from 'styled-components';

const Icon = styled.svg`
  fill: none;
  stroke: #FF6600;
  stroke-width: 4px;
`;
/** Hide checkbox visually but remain accessible to screen readers.
Source: https://polished.js.org/docs/#hidevisually */
const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledCheckbox = styled.div`
  width: 16px;
  height: 16px;
  background: ${(props) => (props.checked ? 'white' : 'white')};
  border-radius: 3px;
  transition: all 150ms;
  border: 2px solid #FF6600;
  display: flex;

  ${HiddenCheckbox}:focus + & {
    // box-shadow: 0 0 0 3px pink;
  }

  ${Icon} {
    visibility: ${(props) => (props.checked ? 'visible' : 'hidden')}
  }
`;

const Checkbox = ({
  className, checked, ...props
}) => (
  <>
    <HiddenCheckbox checked={checked} {...props} />
    <StyledCheckbox checked={checked}>
      <Icon viewBox='0 0 24 24'>
        <polyline points='20 6 9 17 4 12' />
      </Icon>
    </StyledCheckbox>
  </>
);

export default Checkbox;
