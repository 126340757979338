import {
  FETCH_REPORTABLE_GROUPS_LM_DASH_START,
  FETCH_REPORTABLE_GROUPS_LM_DASH_FULFILLED,
  FETCH_REPORTABLE_GROUPS_LM_DASH_REJECTED,
  FETCH_QUESTIONNAIRES_FOR_ACCOUNT_LM_DASH_START,
  FETCH_QUESTIONNAIRES_FOR_ACCOUNT_LM_DASH_FULFILLED,
  FETCH_QUESTIONNAIRES_FOR_ACCOUNT_LM_DASH_REJECTED,
  FETCH_QUESTIONS_FOR_ACCOUNT_LM_DASH_START,
  FETCH_QUESTIONS_FOR_ACCOUNT_LM_DASH_FULFILLED,
  FETCH_QUESTIONS_FOR_ACCOUNT_LM_DASH_REJECTED,
  FETCH_QUESTIONNAIRE_REPORT_SINGLE_DAY_LM_DASH_START,
  FETCH_QUESTIONNAIRE_REPORT_SINGLE_DAY_LM_DASH_FULFILLED,
  FETCH_QUESTIONNAIRE_REPORT_SINGLE_DAY_LM_DASH_REJECTED,
  FETCH_QUESTIONNAIRE_REPORT_MULTIDAY_LM_DASH_START,
  FETCH_QUESTIONNAIRE_REPORT_MULTIDAY_LM_DASH_FULFILLED,
  FETCH_QUESTIONNAIRE_REPORT_MULTIDAY_LM_DASH_REJECTED,
  FETCH_LOAD_MONITORING_LINE_CHART_START,
  FETCH_LOAD_MONITORING_LINE_CHART_FULFILLED,
  FETCH_LOAD_MONITORING_LINE_CHART_REJECTED,
  SET_NEW_LM_DASH,
  FETCH_LM_TEMPLATES_START,
  FETCH_LM_TEMPLATES_FULFILLED,
  FETCH_LM_TEMPLATES_REJECTED,
  SET_SAVE_LM_TEMPLATE_MODAL,
  CREATE_LM_TEMPLATE_START,
  CREATE_LM_TEMPLATE_FULFILLED,
  CREATE_LM_TEMPLATE_REJECTED,
  SET_IS_UNSAVED_LM_DASH_MODAL,
  SET_EDIT_LM_DASH_MODAL,
  SET_DELETE_LM_TEMPLATE_MODAL,
  DELETE_LM_TEMPLATE_START,
  DELETE_LM_TEMPLATE_FULFILLED,
  DELETE_LM_TEMPLATE_REJECTED,
  SET_ABOUT_LM_DASH_MODAL,
  SET_SHARE_LM_DASH_MODAL,
  FETCH_SHARE_LM_DASH_LINK_START,
  FETCH_SHARE_LM_DASH_LINK_FULFILLED,
  FETCH_SHARE_LM_DASH_LINK_REJECTED,
  FETCH_PUBLIC_LM_QUESTIONNAIRE_REPORT_SINGLE_DAY_START,
  FETCH_PUBLIC_LM_QUESTIONNAIRE_REPORT_SINGLE_DAY_FULFILLED,
  FETCH_PUBLIC_LM_QUESTIONNAIRE_REPORT_SINGLE_DAY_REJECTED,
  FETCH_PUBLIC_LM_QUESTIONNAIRE_REPORT_MULTIDAY_START,
  FETCH_PUBLIC_LM_QUESTIONNAIRE_REPORT_MULTIDAY_FULFILLED,
  FETCH_PUBLIC_LM_QUESTIONNAIRE_REPORT_MULTIDAY_REJECTED,
  FETCH_PUBLIC_LM_LINE_CHART_START,
  FETCH_PUBLIC_LM_LINE_CHART_FULFILLED,
  FETCH_PUBLIC_LM_LINE_CHART_REJECTED,
  SET_DUPLICATE_LM_DASH_MODAL,
  DUPLICATE_LM_DASH_START,
  DUPLICATE_LM_DASH_FULFILLED,
  DUPLICATE_LM_DASH_REJECTED,
  SET_LM_TRI_OPTION_MODAL,
  SET_IS_DASHBOARD_DISPLAYED,
  CLEAR_LM_DASH_MODALS,
} from './actionTypes';

const initialState = {
  isLoadingQuestionnairesForAccount: false,
  isLoadingQuestionsForAccount: false,
  isLoadingQuestionnaireReportSingleDay: false,
  isLoadingQuestionnaireReportMultiday: false,
  isLoadingReportableGroups: false,
  isLoadingLineChartData: false,
  isNewDash: false,
  lmTemplatesLoading: false,
  saveModalShowing: false,
  isSavingTemplateLoading: false,
  unsavedModalShowing: false,
  editModalShowing: false,
  isEditTemplateLoading: false,
  deleteModalShowing: false,
  isDeletingTemplateLoading: false,
  shareModalShowing: false,
  isShareDashLinkLoading: false,
  isPublicDataLoading: false,
  duplicateModalShowing: false,
  isDuplicatingTemplateLoading: false,
  aboutModalShowing: false,
  triOptionModalShowing: false,
  isReadOnlyView: false,
  isDashboardDisplayed: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_REPORTABLE_GROUPS_LM_DASH_START: {
      return {
        ...state,
        isLoadingReportableGroups: true,
      };
    }
    case FETCH_REPORTABLE_GROUPS_LM_DASH_FULFILLED: {
      return {
        ...state,
        isLoadingReportableGroups: false,
      };
    }
    case FETCH_REPORTABLE_GROUPS_LM_DASH_REJECTED: {
      return {
        ...state,
        isLoadingReportableGroups: false,
      };
    }
    case FETCH_QUESTIONNAIRES_FOR_ACCOUNT_LM_DASH_START: {
      return {
        ...state,
        isLoadingQuestionnairesForAccount: true,
      };
    }
    case FETCH_QUESTIONNAIRES_FOR_ACCOUNT_LM_DASH_FULFILLED: {
      return {
        ...state,
        isLoadingQuestionnairesForAccount: false,
      };
    }
    case FETCH_QUESTIONNAIRES_FOR_ACCOUNT_LM_DASH_REJECTED: {
      return {
        ...state,
        isLoadingQuestionnairesForAccount: false,
      };
    }
    case FETCH_QUESTIONS_FOR_ACCOUNT_LM_DASH_START: {
      return {
        ...state,
        isLoadingQuestionsForAccount: true,
      };
    }
    case FETCH_QUESTIONS_FOR_ACCOUNT_LM_DASH_FULFILLED: {
      return {
        ...state,
        isLoadingQuestionsForAccount: false,
      };
    }
    case FETCH_QUESTIONS_FOR_ACCOUNT_LM_DASH_REJECTED: {
      return {
        ...state,
        isLoadingQuestionsForAccount: false,
      };
    }
    case FETCH_QUESTIONNAIRE_REPORT_SINGLE_DAY_LM_DASH_START: {
      return {
        ...state,
        isLoadingQuestionnaireReportSingleDay: true,
        isReadOnlyView: false,
      };
    }
    case FETCH_QUESTIONNAIRE_REPORT_SINGLE_DAY_LM_DASH_FULFILLED: {
      return {
        ...state,
        isLoadingQuestionnaireReportSingleDay: false,
      };
    }
    case FETCH_QUESTIONNAIRE_REPORT_SINGLE_DAY_LM_DASH_REJECTED: {
      return {
        ...state,
        isLoadingQuestionnaireReportSingleDay: false,
      };
    }
    case FETCH_QUESTIONNAIRE_REPORT_MULTIDAY_LM_DASH_START: {
      return {
        ...state,
        isLoadingQuestionnaireReportMultiday: true,
        isReadOnlyView: false,
      };
    }
    case FETCH_QUESTIONNAIRE_REPORT_MULTIDAY_LM_DASH_FULFILLED: {
      return {
        ...state,
        isLoadingQuestionnaireReportMultiday: false,
      };
    }
    case FETCH_QUESTIONNAIRE_REPORT_MULTIDAY_LM_DASH_REJECTED: {
      return {
        ...state,
        isLoadingQuestionnaireReportMultiday: false,
      };
    }
    case FETCH_LOAD_MONITORING_LINE_CHART_START: {
      return {
        ...state,
        isLoadingLineChartData: true,
        isReadOnlyView: false,
      };
    }
    case FETCH_LOAD_MONITORING_LINE_CHART_FULFILLED: {
      return {
        ...state,
        isLoadingLineChartData: false,
      };
    }
    case FETCH_LOAD_MONITORING_LINE_CHART_REJECTED: {
      return {
        ...state,
        isLoadingLineChartData: false,
      };
    }
    case SET_NEW_LM_DASH: {
      return {
        ...state,
        isNewDash: action.payload,
      };
    }
    case FETCH_LM_TEMPLATES_START: {
      return {
        ...state,
        lmTemplatesLoading: true,
      };
    }
    case FETCH_LM_TEMPLATES_FULFILLED: {
      return {
        ...state,
        lmTemplatesLoading: false,
      };
    }
    case FETCH_LM_TEMPLATES_REJECTED: {
      return {
        ...state,
        lmTemplatesLoading: false,
      };
    }
    case SET_SAVE_LM_TEMPLATE_MODAL: {
      return {
        ...state,
        saveModalShowing: action.payload,
      };
    }
    case CREATE_LM_TEMPLATE_START: {
      return {
        ...state,
        isSavingTemplateLoading: true,
      };
    }
    case CREATE_LM_TEMPLATE_FULFILLED: {
      return {
        ...state,
        isNewDash: false,
        isSavingTemplateLoading: false,
      };
    }
    case CREATE_LM_TEMPLATE_REJECTED: {
      return {
        ...state,
        isSavingTemplateLoading: true,
      };
    }
    case SET_IS_UNSAVED_LM_DASH_MODAL: {
      return {
        ...state,
        unsavedModalShowing: action.payload,
      };
    }
    case SET_EDIT_LM_DASH_MODAL: {
      return {
        ...state,
        editModalShowing: action.payload.bool,
      };
    }
    case SET_DELETE_LM_TEMPLATE_MODAL: {
      return {
        ...state,
        deleteModalShowing: action.payload.bool,
      };
    }
    case DELETE_LM_TEMPLATE_START: {
      return {
        ...state,
        isDeletingTemplateLoading: true,
      };
    }
    case DELETE_LM_TEMPLATE_FULFILLED: {
      return {
        ...state,
        isDeleteTemplateLoading: false,
      };
    }
    case DELETE_LM_TEMPLATE_REJECTED: {
      return {
        ...state,
        isDeletingTemplateLoading: false,
      };
    }
    case SET_ABOUT_LM_DASH_MODAL: {
      return {
        ...state,
        aboutModalShowing: action.payload.bool,
      };
    }
    case SET_SHARE_LM_DASH_MODAL: {
      return {
        ...state,
        shareModalShowing: action.payload.bool,
      };
    }
    case FETCH_SHARE_LM_DASH_LINK_START: {
      return {
        ...state,
        isShareDashLinkLoading: true,
      };
    }
    case FETCH_SHARE_LM_DASH_LINK_FULFILLED: {
      return {
        ...state,
        isShareDashLinkLoading: false,
      };
    }
    case FETCH_SHARE_LM_DASH_LINK_REJECTED: {
      return {
        ...state,
        isShareDashLinkLoading: false,
      };
    }
    case FETCH_PUBLIC_LM_QUESTIONNAIRE_REPORT_SINGLE_DAY_START: {
      return {
        ...state,
        isLoadingQuestionnaireReportSingleDay: true,
        isReadOnlyView: true,
      };
    }
    case FETCH_PUBLIC_LM_QUESTIONNAIRE_REPORT_SINGLE_DAY_FULFILLED: {
      return {
        ...state,
        isLoadingQuestionnaireReportSingleDay: false,
      };
    }
    case FETCH_PUBLIC_LM_QUESTIONNAIRE_REPORT_SINGLE_DAY_REJECTED: {
      return {
        ...state,
        isLoadingQuestionnaireReportSingleDay: false,
      };
    }
    case FETCH_PUBLIC_LM_QUESTIONNAIRE_REPORT_MULTIDAY_START: {
      return {
        ...state,
        isLoadingQuestionnaireReportMultiday: true,
        isReadOnlyView: true,
      };
    }
    case FETCH_PUBLIC_LM_QUESTIONNAIRE_REPORT_MULTIDAY_FULFILLED: {
      return {
        ...state,
        isLoadingQuestionnaireReportMultiday: false,
      };
    }
    case FETCH_PUBLIC_LM_QUESTIONNAIRE_REPORT_MULTIDAY_REJECTED: {
      return {
        ...state,
        isLoadingQuestionnaireReportMultiday: false,
      };
    }
    case FETCH_PUBLIC_LM_LINE_CHART_START: {
      return {
        ...state,
        isLoadingLineChartData: true,
        isReadOnlyView: true,
      };
    }
    case FETCH_PUBLIC_LM_LINE_CHART_FULFILLED: {
      return {
        ...state,
        isLoadingLineChartData: false,
      };
    }
    case FETCH_PUBLIC_LM_LINE_CHART_REJECTED: {
      return {
        ...state,
        isLoadingLineChartData: false,
      };
    }
    case SET_DUPLICATE_LM_DASH_MODAL: {
      return {
        ...state,
        duplicateModalShowing: action.payload.bool,
      };
    }
    case DUPLICATE_LM_DASH_START: {
      return {
        ...state,
        isDuplicatingTemplateLoading: true,
      };
    }
    case DUPLICATE_LM_DASH_FULFILLED: {
      return {
        ...state,
        isDuplicatingTemplateLoading: false,
      };
    }
    case DUPLICATE_LM_DASH_REJECTED: {
      return {
        ...state,
        isDuplicatingTemplateLoading: false,
      };
    }
    case SET_LM_TRI_OPTION_MODAL: {
      return {
        ...state,
        triOptionModalShowing: action.payload,
      };
    }
    case SET_IS_DASHBOARD_DISPLAYED: {
      return {
        ...state,
        isDashboardDisplayed: action.payload,
      };
    }
    case CLEAR_LM_DASH_MODALS: {
      return {
        ...state,
        aboutModalShowing: false,
        deleteModalShowing: false,
        duplicateModalShowing: false,
        editModalShowing: false,
        saveModalShowing: false,
        shareModalShowing: false,
        triOptionModalShowing: false,
        unsavedModalShowing: false,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
