/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import moment from 'moment';
import TableToExcel from '@linways/table-to-excel';

import { BoxShadow } from '../../../shared/GlobalStyles';
import SelectBox from '../../../shared/_TBComponents/SelectBox';
import { dashboardTracker } from '../../../shared/utils/amplitudeHelper';
import {
  clearAthleteHistoryData,
  fetchGroupAnalysisData,
  fetchOverviewData,
  fetchUserHistoryData,
  fetchUsersAssociatedWithGroup,
  setGroup,
  setPrintCurrentPage,
  setRecoveryRadarActiveModal,
  setSelectedAthlete,
  setSelectedDateCal,
  setSelectedDateData,
} from '../ducks/RecoveryRadarActions';
import DatePicker from '../../../shared/_TBComponents/DatePicker';
import Filter from '../../../shared/_TBComponents/DashboardFilter';
import Spinner from '../../../shared/_TBComponents/Spinner';
import RRAnalysisHistoryTableNoData from './RRAnalysisHistoryTableNoData';
import RRAnalysisHistoryTable from './RRAnalysisHistoryTable';
import Button from '../../../shared/_TBComponents/Button';
import FilterHelper from './filterHelper';

const DashboardContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin: 15px;
  background-color: #FFFFFF;
  border-radius: 10px;
  padding: 16px;
  box-shadow: ${BoxShadow};
  position: relative;
`;

const OptionsHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 10px;
`;

const MenuOptionsContainer = styled('div')`
  display: flex;
  flex-wrap: wrap;
`;

const DateOptionsContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;

  .downloadBtn {
    border: 1px solid #808080;
    border-radius: 50%;
    height: 35px;
    width: 35px;
    padding: 3px;
    margin-left: 10px;
  }
`;

const ColorNote = styled('div')`
  color: #444444;
  font-family: 'Nunito Sans';
  font-size: 14px;
  font-weight: normal;
`;

const NoDataModal = styled('div')`
  position: absolute;
  text-align: center;
  top: 50%;
  left: 50%;
  margin: -45px 0px 0px -182px;
  height: 119px;
  width: 400px;
  background-color: #FFFFFF;
  border-radius: 8px;
  box-shadow: ${BoxShadow};
  font-family: 'Nunito Sans';
  font-size: 19px;
  color: #424242;
  padding: 15px;
`;

const ModalTitle = styled('div')`
  font-weight: bold;
`;

const ModalMessage = styled('div')`
  font-weight: 300;
`;

const Analysis = () => {
  const accountCode = useSelector((state) => state.auth.data.currentUser.accountCode);
  const activeFilters = useSelector((state) => state.recoveryRadar.data.activeFilters);
  const activeTypeFilters = useSelector((state) => state.recoveryRadar.data.activeTypeFilters);
  const alertAverageData = useSelector((state) => state.recoveryRadar.data.alertAverageData);
  const athleteList = useSelector((state) => state.recoveryRadar.data.athleteList);
  const athleteRadioValue = useSelector((state) => state.recoveryRadar.data.athleteRadioValue);
  const athletesToReview = useSelector((state) => state.recoveryRadar.data.athletesToReview);
  const dateRange = useSelector((state) => state.recoveryRadar.data.dateRange);
  const group = useSelector((state) => state.recoveryRadar.data.group);
  const groupAverageData = useSelector((state) => state.recoveryRadar.data.groupAverageData);
  const groups = useSelector((state) => state.recoveryRadar.data.groups);
  const isAnalysisDataLoading = useSelector(
    (state) => state.recoveryRadar.ui.isAnalysisDataLoading,
  );
  const isFilterOpen = useSelector((state) => state.recoveryRadar.ui.isFilterOpen);
  const printCurrentPage = useSelector((state) => state.recoveryRadar.data.printCurrentPage);
  const selectedAthlete = useSelector((state) => state.recoveryRadar.data.selectedAthlete);
  const selectedDateCal = useSelector((state) => state.recoveryRadar.data.selectedDateCal);
  const selectedDateData = useSelector((state) => state.recoveryRadar.data.selectedDateData);
  const tableData = useSelector((state) => state.recoveryRadar.data.tableData);

  const hasAthletes = athleteList?.length > 0;
  const hasData = tableData?.length > 0;
  const hasFlaggedData = athletesToReview?.length > 0;

  // Pull out the active filter IDs
  const filterIds = activeFilters.map((obj) => obj.id);
  const typeIds = activeTypeFilters.map((obj) => obj.id);

  const {
    handleActionButton,
    handleChangeAnalysis,
    handleChangeType,
    handleClear,
    handleClose,
    handleFilter,
    handleRadioChange,
    options,
    searchTerm,
    setSearchTerm,
    typeOptions,
  } = FilterHelper();

  let selectedAthleteData = [];
  selectedAthleteData = (selectedAthlete?.name && hasData)
  && tableData.filter((item) => item.name === selectedAthlete.name);

  let selectedReviewAthleteData = [];
  selectedReviewAthleteData = (selectedAthlete?.name && hasFlaggedData)
  && athletesToReview.filter((item) => item.name === selectedAthlete.name);

  const dispatch = useDispatch();

  const setSelectedGroup = (option) => {
    // Amplitude tracking
    dashboardTracker('Recovery Radar', 'Group Analysis - Group Picker', 'Group selected');
    batch(() => {
      // Set group
      dispatch(setGroup(option));
      // Clear any athlete data
      dispatch(setSelectedAthlete(null));
      dispatch(clearAthleteHistoryData());
      // Fetch athlete list
      dispatch(fetchUsersAssociatedWithGroup(accountCode, option?.id));
      // Fetch all the data
      dispatch(fetchOverviewData(
        accountCode,
        option?.id,
        moment(selectedDateData).subtract(dateRange - 1, 'd').format('YYYY-MM-DD'),
        selectedDateData,
        [],
        [],
      ));
      dispatch(fetchGroupAnalysisData(
        accountCode,
        option?.id,
        moment(selectedDateData).subtract(27, 'd').format('YYYY-MM-DD'),
        selectedDateData,
        typeIds,
        filterIds,
      ));
    });
  };

  const setAthlete = (option) => {
    // Amplitude tracking
    dashboardTracker('Recovery Radar', 'Group Analysis - Athlete Picker', 'Athlete selected');
    batch(() => {
      dispatch(setSelectedAthlete(option));
      if (option?.id) {
        dispatch(fetchUserHistoryData(
          accountCode,
          option?.id,
          moment(selectedDateData).subtract(27, 'd').format('YYYY-MM-DD'),
          selectedDateData,
          typeIds,
          filterIds,
        ));
      }
    });
  };

  const setDate = (date) => {
    // Amplitude tracking
    dashboardTracker('Recovery Radar', 'Group Analysis - Date Picker', 'Start date selected');
    batch(() => {
      dispatch(setSelectedDateCal(moment(date)));
      dispatch(setSelectedDateData(moment(date).format('YYYY-MM-DD')));
      dispatch(fetchOverviewData(
        accountCode,
        group.id,
        moment(date).subtract(dateRange - 1, 'd').format('YYYY-MM-DD'),
        moment(date).format('YYYY-MM-DD'),
        [],
        [],
      ));
      dispatch(fetchGroupAnalysisData(
        accountCode,
        group.id,
        moment(date).subtract(27, 'd').format('YYYY-MM-DD'),
        moment(date).format('YYYY-MM-DD'),
        typeIds,
        filterIds,
      ));
      if (selectedAthlete?.id) {
        dispatch(fetchUserHistoryData(
          accountCode,
          selectedAthlete?.id,
          moment(date).subtract(27, 'd').format('YYYY-MM-DD'),
          moment(date).format('YYYY-MM-DD'),
          typeIds,
          filterIds,
        ));
      }
    });
  };

  const formatAthleteList = () => athleteList?.map((athleteObject) => ({
    name: `${athleteObject?.first} ${athleteObject?.last}`,
    id: athleteObject.id,
  }));

  const handleDownload = () => {
    // Amplitude tracking
    dashboardTracker('Recovery Radar', 'Group Analysis - Download', 'Table downloaded');
    const tb = document.getElementById('reportTable');
    TableToExcel.convert(tb, {
      name: 'Group_Analysis.xlsx',
      sheet: {
        name: 'Sheet 1',
      },
    });
  };

  useEffect(() => {
    if (printCurrentPage) {
      handleDownload();
      batch(() => {
        dispatch(setRecoveryRadarActiveModal(null));
        dispatch(setPrintCurrentPage(false));
      });
    }
  }, [printCurrentPage]);

  const [columnHeaderHeight, setColumnHeaderHeight] = useState(null);
  const containerWidth = 1000;

  return (
    <>
      <DashboardContainer>
        <OptionsHeader>
          <MenuOptionsContainer>
            <SelectBox
              defaultValue={group}
              options={groups}
              onChangeFunction={setSelectedGroup}
              placeholder='Select a Group'
              removeSeparator
              styledProps={{
                position: 'relative',
                margin: '5px 10px 5px 0px',
                height: null,
              }}
              width='175px'
            />
            {hasAthletes && (
              <SelectBox
                defaultValue={selectedAthlete}
                options={formatAthleteList()}
                onChangeFunction={setAthlete}
                placeholder='Select an Athlete'
                removeSeparator
                styledProps={{
                  position: 'relative',
                  margin: '5px 10px 5px 0px',
                  height: null,
                }}
                width='175px'
              />
            )}
            <Filter
              actionButtonName='Update'
              activeFilters={activeFilters}
              activeTypeFilters={activeTypeFilters}
              athleteRadio
              athleteRadioValue={athleteRadioValue}
              filterTitle='Athletes'
              handleActionButton={handleActionButton}
              handleChange={handleChangeAnalysis}
              handleChangeType={handleChangeType}
              handleClear={handleClear}
              handleClose={handleClose}
              handleFilter={handleFilter}
              handleRadioChange={handleRadioChange}
              isArrayOfObjects
              isOpen={isFilterOpen}
              isRecoveryRadar
              isSearchable
              options={options}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              styledPropsWrapper={{
                marginTop: '6px',
              }}
              subheader='Area Selected'
              typeOptions={typeOptions}
            />
          </MenuOptionsContainer>
          <DateOptionsContainer>
            <DatePicker
              placeholderText='Select Date'
              setStartDate={setDate}
              startDate={selectedDateCal.toDate()}
            />
            <Button
              disabled={!hasData}
              icon='download'
              iconOnly
              name='download btn'
              noHover={!hasData}
              onClick={() => dispatch(setRecoveryRadarActiveModal('PHI-warning'))}
              styledWrapperProps={{
                height: '35px',
                marginLeft: '10px',
                padding: '3px',
                width: '35px!important',
              }}
            />
          </DateOptionsContainer>
        </OptionsHeader>
        <ColorNote>
          <span style={{ fontWeight: 'bold' }}>NOTE: </span>
          The column background color is determined by the amount of percent change over the
          last 28 days. The percent change column will be red if the percent change
          is greater than 0%.
        </ColorNote>
        {isAnalysisDataLoading ? (
          <Spinner />
        ) : !hasData ? (
          <>
            <RRAnalysisHistoryTableNoData
              containerWidth={containerWidth}
              dataRows={['', '', '', '', '']}
              isHistory={false}
              rowIsScrollable
              setColumnHeaderHeight={setColumnHeaderHeight}
            />
            <NoDataModal>
              <ModalTitle>
                {!group && !hasData ? 'Select a Group' : 'No Data Available'}
              </ModalTitle>
              <ModalMessage>
                {!group && !hasData
                  ? 'Select a group using the dropdown above to get started'
                  : 'There is no data available, please adjust your selection above'}
              </ModalMessage>
            </NoDataModal>
          </>
        ) : athleteRadioValue === 'alerts' && !hasFlaggedData ? (
          <>
            <RRAnalysisHistoryTableNoData
              containerWidth={containerWidth}
              dataRows={['', '', '', '', '']}
              isHistory={false}
              rowIsScrollable
              setColumnHeaderHeight={setColumnHeaderHeight}
            />
            <NoDataModal>
              <ModalTitle>
                {!group && !hasData ? 'Select a Group' : 'No Data Available'}
              </ModalTitle>
              <ModalMessage>
                {!group && !hasData
                  ? 'Select a group using the dropdown above to get started'
                  : 'There is no data available, please adjust your selection above'}
              </ModalMessage>
            </NoDataModal>
          </>
        ) : (
          <>
            {/* Alerts only table view w/o athlete selected */}
            {athleteRadioValue === 'alerts' && !selectedReviewAthleteData ? (
              <RRAnalysisHistoryTable
                averageData={alertAverageData}
                containerWidth={containerWidth}
                dataRows={athletesToReview}
                isHistory={false}
                rowIsScrollable
                setColumnHeaderHeight={setColumnHeaderHeight}
              />
            // Alerts only table view w/ athlete selected
            ) : athleteRadioValue === 'alerts' && selectedReviewAthleteData ? (
              <RRAnalysisHistoryTable
                averageData={[]}
                containerWidth={containerWidth}
                dataRows={selectedReviewAthleteData}
                isHistory={false}
                rowIsScrollable
                setColumnHeaderHeight={setColumnHeaderHeight}
              />
            // Normal table view w/ athlete selected
            ) : selectedAthleteData ? (
              <RRAnalysisHistoryTable
                averageData={[]}
                containerWidth={containerWidth}
                dataRows={selectedAthleteData}
                isHistory={false}
                rowIsScrollable
                setColumnHeaderHeight={setColumnHeaderHeight}
              />
            // Normal table view w/o athlete selected
            ) : (
              <RRAnalysisHistoryTable
                averageData={groupAverageData}
                containerWidth={containerWidth}
                dataRows={tableData}
                isHistory={false}
                rowIsScrollable
                setColumnHeaderHeight={setColumnHeaderHeight}
              />
            )}
          </>
        )}
      </DashboardContainer>
    </>
  );
};

export default Analysis;
