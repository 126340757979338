import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useTheme } from 'emotion-theming';
import TableToExcel from '@linways/table-to-excel';
import IcomoonReact from 'icomoon-react';
import { useDispatch, useSelector } from 'react-redux';

import Text from '../../../../shared/_TBComponents/Text';
import iconSet from '../../../../shared/images/teambuildr-selection.json';
import { reportViewTracker } from '../../../../shared/utils/amplitudeHelper';
import Button from '../../../../shared/_TBComponents/Button';
import { downloadCompletionCSV } from '../../ducks/reportingActions';

const NavigationWrapper = styled('div')`
  display: flex;
  width: 100%;
  margin-bottom: -20px;
  z-index: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  padding: 20px;
  justify-content: end;

  p {
    opacity: .5;
    :hover {
      opacity: 1;
    }
  }

`;

const TextContainer = styled('div')`
  padding: 40px;
`;

const DownloadCompletionReport = ({
  closeModal,
}) => {
  const accountCode = useSelector((state) => state.auth.data.currentUser.accountCode);
  const completionReportForm = useSelector((state) => state.reporting.data.completionReportForm);
  const isDownloadingCompletionReport = useSelector(
    (state) => state.reporting.ui.isDownloadingCompletionReport,
  );
  const sidebarFilter = useSelector((state) => state.reporting.data.sidebarFilter);

  const theme = useTheme();

  const dispatch = useDispatch();

  const exportFile = () => {
    const tb = document.getElementById('completionReportTable');
    TableToExcel.convert(tb, {
      name: 'completion-report.xlsx',
      sheet: {
        name: 'Sheet 1',
      },
    });

    // Amplitude tracking
    reportViewTracker('Completion Report', true);

    closeModal();
  };

  const exportCSV = () => {
    dispatch(downloadCompletionCSV(sidebarFilter, accountCode, completionReportForm));
  };

  return (
    <>
      <NavigationWrapper>
        <Text onClick={closeModal} styledTextProps={{ lineHeight: 1 }}>
          <IcomoonReact
            iconSet={iconSet}
            size={13}
            icon='remove'
          />
        </Text>
      </NavigationWrapper>
      <TextContainer>
        <Text styledTextProps={{ fontSize: '18px', textAlign: 'center' }}>
          Would you like to download this completion report as an excel or CSV file?
        </Text>
      </TextContainer>
      <Button
        fullWidth
        large
        name='Download Excel'
        onClick={exportFile}
        styledWrapperProps={{ border: '.5px solid #444444;' }}
        square
        upperCase
      />
      <Button
        bgColor={theme.colors.green}
        fullWidth
        isLoading={isDownloadingCompletionReport}
        large
        name='Download CSV'
        noBorder
        onClick={exportCSV}
        square
        txtColor='#FFFFFF'
        upperCase
      />
    </>
  );
};

DownloadCompletionReport.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default DownloadCompletionReport;
