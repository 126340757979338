// // Authored by Ozan Sozuoz
/** edited my Kristy Street - moved wrapper in scope so that when multiple toggles are on
he same page the styles won't interfere with eachother */
import React from 'react';
import PropTypes from 'prop-types';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';
import styled from '@emotion/styled';

const CustomStyledToggle = styled.div`
  display: flex;

  .react-toggle {
    background-color: #fff;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    display: inline-block;
    padding: 0;
    position: relative;
    touch-action: pan-x;
    user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
  }
  .react-toggle--checked .react-toggle-thumb {
    left: 26px;
  }
  .react-toggle--checked .react-toggle-track {
    background-color: ${(props) => props.onColor || '#4caf50'};
  }
  .react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: ${(props) => props.onHoverColor || props.onColor || '#4caf50'};
  }
  .react-toggle-thumb {
    background-color: ${(props) => props.thumbColor || '#ffffff'};
    border: 1px solid ${(props) => props.thumbBorderColor || '#ccc'};
    border-radius: 50%;
    height: 20px;
    left: 2px;
    position: absolute;
    top: 2px;
    transition: left 0.25s ease;
    width: 20px;
  }
  .react-toggle-track {
    background-color: ${(props) => props.offColor || '#dcdcdc'};
    border-radius: 30px;
    height: 24px;
    padding: 0;
    transition: all 0.2s ease;
    width: 50px;
  }
  .react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: ${(props) => props.offHoverColor || props.offColor || '#dcdcdc'};
  }
`;

const Wrapper = styled.div`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  display: flex;
  align-items: center;

  .toggle-label {
    display: flex;
    align-items: center;
  }
  .toggle-on-label,
  .toggle-off-label {
    margin: 0 8px;
  }
  
  .custom-toggle .react-toggle-track {
    background-color: ${({ offColor }) => offColor};
  }
  .custom-toggle.react-toggle--checked .react-toggle-track {
    background-color: ${({ onColor }) => onColor};
  }
  .custom-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: ${({ hoverColor }) => hoverColor};
  } 
  .custom-toggle.react-toggle--checked .react-toggle-thumb {
    border: ${({ thumbBorderOn }) => thumbBorderOn};
    background-color: ${({ thumbColorOn }) => thumbColorOn};
  } 
  .custom-toggle .react-toggle-thumb {
    border: ${({ thumbBorderOff }) => thumbBorderOff};
    background-color: ${({ thumbColorOff }) => thumbColorOff};
  }
  .custom-toggle:hover .react-toggle-thumb {
    border-color: ${({ hoverColor }) => hoverColor};
  }
  .custom-toggle.react-toggle--focus .react-toggle-thumb {
    box-shadow: none;
  }
`;

/**
 * @param {Bool} defaultChecked default checked state of the toggle
 * @param {Bool} disabled whether the toggle is disabled
 * @param {String} height the height of the wrapper containing the toggle
 * @param {String} offColor background color when the toggle is off
 * @param {String} offHoverColor background color on hover when the toggle is off
 * @param {String} offLabel label for the off state
 * @param {String} onColor background color when the toggle is on
 * @param {String} onHoverColor background color on hover when the toggle is on
 * @param {String} onLabel label for the on state
 * @param {Func} onChange function that is run when the toggle state changes
 * @param {Object} styledProps accepts an object with all optional styles
 * @param {String} thumbBorderColor border color of the toggle thumb
 * @param {String} thumbColor color of the toggle thumb
 * @param {String} width the width of the wrapper containing the toggle
 * @returns
 */

const ToggleComponent = ({
  defaultChecked,
  disabled,
  height,
  hoverColor = '#ededed',
  offColor,
  offHoverColor,
  offLabel,
  onChange,
  onColor,
  onHoverColor,
  onLabel = '',
  styledProps,
  thumbBorderColor,
  thumbBorderOn = '1px solid white',
  thumbBorderOff = '1px solid white',
  thumbColor,
  thumbColorOn = 'white',
  thumbColorOff = 'white',
  width,
}) => (
  <Wrapper
    width={width}
    height={height}
    onColor={onColor}
    offColor={offColor}
    hoverColor={hoverColor}
    thumbBorderOn={thumbBorderOn}
    thumbBorderOff={thumbBorderOff}
    thumbColorOn={thumbColorOn}
    thumbColorOff={thumbColorOff}
    {...styledProps}
  >
    <label className='toggle-label' htmlFor='toggle-control'>
      {onLabel && <span className='toggle-on-label'>{onLabel}</span>}
      <CustomStyledToggle
        offColor={offColor}
        offHoverColor={offHoverColor}
        onColor={onColor}
        onHoverColor={onHoverColor}
        thumbBorderColor={thumbBorderColor}
        thumbColor={thumbColor}
      >
        <Toggle
          aria-label='Toggle switch'
          className='toggle-component'
          defaultChecked={defaultChecked}
          disabled={disabled}
          icons={false}
          onChange={onChange}
        />
      </CustomStyledToggle>
      {offLabel && <span className='toggle-off-label'>{offLabel}</span>}
    </label>
    <style>
      {`
      .toggle-label {
        align-items: center;
        display: flex;
      }
      .toggle-on-label,
      .toggle-off-label {
        margin: 0 8px;
      }
    `}
    </style>
  </Wrapper>
);

ToggleComponent.propTypes = {
  defaultChecked: PropTypes.bool,
  disabled: PropTypes.bool,
  height: PropTypes.string,
  hoverColor: PropTypes.string,
  offColor: PropTypes.string,
  offHoverColor: PropTypes.string,
  offLabel: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onColor: PropTypes.string,
  onHoverColor: PropTypes.string,
  onLabel: PropTypes.string,
  styledProps: PropTypes.instanceOf(Object),
  thumbColorOn: PropTypes.string,
  thumbBorderOn: PropTypes.string,
  thumbBorderOff: PropTypes.string,
  thumbColorOff: PropTypes.string,
  thumbBorderColor: PropTypes.string,
  thumbColor: PropTypes.string,
  width: PropTypes.string,
};

ToggleComponent.defaultProps = {
  defaultChecked: false,
  disabled: false,
  height: '100%',
  hoverColor: '#ededed',
  offColor: null,
  offHoverColor: null,
  offLabel: '',
  onColor: null,
  onHoverColor: null,
  onLabel: '',
  styledProps: {},
  thumbColorOn: 'white',
  thumbColorOff: 'white',
  thumbBorderOn: '1px solid white',
  thumbBorderOff: '1px solid white',
  thumbBorderColor: null,
  thumbColor: null,
  width: '100%',
};

export default ToggleComponent;
