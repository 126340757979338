/**
 * I copied this file mostly from modal; the biggest difference is that the size
 * of the modal is increased to hold more content
 */

import React, {
  Fragment, useState, useEffect, useRef,
} from 'react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Global, css } from '@emotion/core';

import {
  BorderRadius,
  BoxShadow,
  TransitionGlobal,
} from '../GlobalStyles';

const ModalStyle = (customModalHeight) => ({
  overlay: {
    display: 'flex',
    height: '100vh',
    width: '100vw',
    backgroundColor: 'rgba(216, 216, 216, 0.5)',
    alignItems: 'center',
    position: 'fixed',
    zIndex: '100',
    top: '0',
    overflowY: 'auto',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    width: '40vw',
    minHeight: customModalHeight || '400px',
    minWidth: '500px',
    backgroundColor: 'white',
    borderRadius: `${BorderRadius}`,
    boxShadow: `${BoxShadow}`,
  },
});

export const ModalContentWrapper = styled('div')`
  display: flex;
  padding: 40px;
  flex-grow: 1;
  background-color: ${(props) => props.theme.backgroundColorL2};
  form {
    width: 100%;
    padding-right: 40px;
    padding-left: 40px;
    padding-bottom: 30px;
    padding-top: 30px;
    .divider-line {
      width: calc(100% + 160px);
      margin-left: -80px;
      border-top: 2px solid rgb(232,232,232);
      margin-bottom: 30px;
    }
    label {
      color: ${(props) => props.theme.textColor};
    }
    input, textarea {
      background-color: ${(props) => props.theme.backgroundColorL3};
    }
  }
  .select__control, .select__menu, textarea {
    background-color: ${(props) => props.theme.backgroundColorL3};
    color: ${(props) => props.theme.textColor};
    border-color: ${(props) => props.theme.noBorderColorForm};
  }
  .select__single-value {
    color: ${(props) => props.theme.textColor};
  }
  .spinner-container {
    min-height: 300px;
  }
  .form-control {
    min-height: 150px;
  }
  .react-pdf__Page__canvas {
    margin: 0 auto;
  }
  .modal-button {
    position: absolute;
    margin-left: -80px;
    bottom: 0;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
  }
`;

const BigModal = ({
  children,
  isOpen,
  onRequestClose,
  currentRow,
  setCurrentRow,
  customModalHeight,
}) => {
  const [modalDimensions, setModalDimensions] = useState({});
  const modalContentRef = useRef(null);
  // const { isDarkThemeEnabled } = useWeightroomSettings();
  const modalClose = () => {
    onRequestClose();
    if (currentRow) {
      setCurrentRow(null);
    }
  };

  // To make the modal scrollable when the window height is small, we save the
  // modal dimensions on isOpen, and set the height dynamically in a css media query
  // where we make the modal absolute position, and overflow auto.
  useEffect(() => {
    setTimeout(() => {
      // Logger.debug('Modal height:', modalContentRef.current.clientHeight);
      setModalDimensions(modalContentRef?.current?.clientHeight);
    }, 800);
  }, [isOpen]);

  return (
    <>
      <Global
        styles={css`
          .ReactModal__Content {
            opacity: 0;
            transform: translateY(50%);
            &:focus {
              outline: -webkit-focus-ring-color auto 0px!important;
            }
          }

          .ReactModal__Overlay {
            background-color: rgba(216, 216, 216, 0.5);
            /* background-color: ${isDarkThemeEnabled ? 'rgba(30, 30, 30, 0.7)' : 'rgba(216, 216, 216, 0.5)'}!important; */
            opacity: 1;
            z-index: 1000!important;
            justify-content: center;
          }

          .ReactModal__Overlay--after-open {
            opacity: 1;
            transition: ${TransitionGlobal};
          }

          .ReactModal__Overlay--before-close {
            opacity: 0;
          }

          .ReactModal__Content--after-open {
            opacity: 1;
            transform: translateY(0);
            transition: ${TransitionGlobal};
          }

          .ReactModal__Content--before-close {
            opacity: 0;
            transform: translateY(50%);
          }

          @media screen and (max-height: ${modalDimensions}px) {
            .ReactModal__Content {
              position: absolute!important;
              top: 15px!important;
            }
          }

          @media only screen and (max-device-width: 767px) {
            .ReactModal__Content {
              min-width: 95%!important;
            }
          }
        `}
      />
      <ReactModal
        closeTimeoutMS={250}
        isOpen={!!isOpen}
        onRequestClose={modalClose}
        style={ModalStyle(customModalHeight)}
        className='global-modal'
        overlayClassName='overlay'
      >
        <ModalContentWrapper
          ref={modalContentRef}
        >
          {children}
        </ModalContentWrapper>
      </ReactModal>
    </>
  );
};

if (process.env.STORYBOOK_RUNNING === undefined) {
  ReactModal.setAppElement('#app');
}

BigModal.propTypes = {
  children: PropTypes.node,
  customButton: PropTypes.bool,
  customModalHeight: PropTypes.instanceOf(String),
  onRequestClose: PropTypes.func.isRequired,
  isOpen: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]).isRequired,
};

BigModal.defaultProps = {
  children: '',
  customButton: false,
  customModalHeight: null,
};

export default BigModal;
