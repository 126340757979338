/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';
import PropTypes from 'prop-types';

import iconSet from '../../../../../shared/images/teambuildr-selection.json';

import { BoxShadow } from '../../../../../shared/GlobalStyles';
import Button from '../../../../../shared/components/Button/Button';
import SharedTemplateModalContainer from './SharedTemplateModalContainer';
import { setAboutLMDashModal } from '../../../../load-monitoring-dashboard/ducks/loadMonitoringDashboardActions';
import { setAboutFPDashModal } from '../../../../force-plate-dashboard/ducks/forcePlateDashboardActions';
import { setAboutDashboardModalShowing } from '../../../../sport-coach-dashboard/ducks/sportCoachDashboardActions';

const ModalContainer = styled('div')`
  display: flex;
  z-index: 1003;
  justify-content: center;
  max-width: 600px;
  height: auto;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  transition: all 1s ease;
  width: 60vw;
  position: absolute;
  left: 50%;
  top: 50%;
  right: 50%;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  @media only screen and (max-width: 768px) {
    width: 90vw;
  }
  .remove-div {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 20px;
    background: white;
    z-index: 1001;
    .remove-div-icon {
      cursor: pointer;
    }
  }
  overflow: hidden;
  transition: all 0.3s linear;
  transform: ${(props) => (props.isActive ? 'translate(-50%,-50%)' : 'translate(-50%, 300%)')};
  .opt-out-button {
    background: #424242;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }
`;

const ContentContainer = styled('div')`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  background: white;
  box-shadow: ${BoxShadow};
  scroll-behavior: smooth;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 10px;
  font-family: "Nunito Sans";
  font-weight: normal;
`;

const TopBox = styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-bottom: solid 1px #eeeeee;
  padding-bottom: 25px;
  width: 120%;
`;

const Image = styled('img')`
  width: 75px;
  height: 75px;
  margin-bottom: 10px;
`;

const TempName = styled('div')`
  font-size: 23px;
  font-weight: normal;
`;

const TempOwner = styled('div')`
  font-size: 16px;
  color: #9e9e9e;
`;

const TempEdgeBox = styled('div')`
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const BottomBox = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Description = styled('div')`
  margin: 25px 0px 10px 0px;
  font-size: 16px;
  text-align: left;
`;

const DashOptionsContainer = styled('div')``;

const DashOptionsTitle = styled('div')`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
`;

const ColumnsContainer = styled('div')`
  display: flex;
  width: 100%;
`;

const DashOptions = styled('ol')`
  padding-left: 25px;
  width: ${(props) => (props.secondCol ? '50%' : '100%')};
  li + li {
    margin-top: 5px;
  }
`;

const DashOptionItem = styled('li')`
  font-size: 16px;
  color: #616161;
`;

const SharedTemplateAboutModal = ({ dashboard }) => {
  const dispatch = useDispatch();
  let aboutTemplate = null;
  let isModalShowing = false;
  let setAboutModalShowing = () => {};
  let dashCreator = '';
  let dashDescription = '';
  let dashLogo = '';
  let dashTitle = '';
  let isReadOnlyView = false;
  let exercises = [];
  let readOnlyQuestionnaireTitle = '';
  let readOnlyQuestions = [];
  let chartForcePlateMetrics = [];
  let keyPerformanceMetrics = [];

  if (dashboard === 'Load Monitoring') {
    aboutTemplate = useSelector(
      (state) => state.loadMonitoringDashboard.data.aboutTemplate,
    );
    isModalShowing = useSelector(
      (state) => state.loadMonitoringDashboard.ui.aboutModalShowing,
    );
    setAboutModalShowing = setAboutLMDashModal;
    dashCreator = useSelector(
      (state) => state.loadMonitoringDashboard.data.dashCreator,
    );
    dashDescription = useSelector(
      (state) => state.loadMonitoringDashboard.data.dashDescription,
    );
    dashLogo = useSelector(
      (state) => state.loadMonitoringDashboard.data.dashLogo,
    );
    dashTitle = useSelector(
      (state) => state.loadMonitoringDashboard.data.dashTitle,
    );
    isReadOnlyView = useSelector(
      (state) => state.loadMonitoringDashboard.ui.isReadOnlyView,
    );
    readOnlyQuestionnaireTitle = useSelector(
      (state) => state.loadMonitoringDashboard.data.readOnlyQuestionnaire.title,
    );
    readOnlyQuestions = useSelector(
      (state) => state.loadMonitoringDashboard.data.readOnlyQuestions,
    );
  }

  if (dashboard === 'Force Plate') {
    aboutTemplate = useSelector(
      (state) => state.forcePlateDashboard.data.aboutTemplate,
    );
    isModalShowing = useSelector(
      (state) => state.forcePlateDashboard.ui.aboutModalShowing,
    );
    setAboutModalShowing = setAboutFPDashModal;
    dashCreator = useSelector(
      (state) => state.forcePlateDashboard.data.dashCreator,
    );
    dashDescription = useSelector(
      (state) => state.forcePlateDashboard.data.dashDescription,
    );
    dashLogo = useSelector((state) => state.forcePlateDashboard.data.dashLogo);
    dashTitle = useSelector(
      (state) => state.forcePlateDashboard.data.dashTitle,
    );
    isReadOnlyView = useSelector(
      (state) => state.forcePlateDashboard.ui.isReadOnlyView,
    );
    chartForcePlateMetrics = useSelector(
      (state) => state.forcePlateDashboard.data.metricFiltersPerformance,
    );
    keyPerformanceMetrics = useSelector(
      (state) => state.forcePlateDashboard.data.keyPerformanceMetrics,
    );
  }

  if (dashboard === 'Sport Coach') {
    aboutTemplate = useSelector(
      (state) => state.sportCoachDashboard.data.aboutTemplate,
    );
    isModalShowing = useSelector(
      (state) => state.sportCoachDashboard.ui.aboutDashboardModalShowing,
    );
    setAboutModalShowing = setAboutDashboardModalShowing;
    dashCreator = useSelector(
      (state) => state.sportCoachDashboard.data.dashCreator,
    );
    dashDescription = useSelector(
      (state) => state.sportCoachDashboard.data.dashDescription,
    );
    dashLogo = useSelector((state) => state.sportCoachDashboard.data.dashLogo);
    dashTitle = useSelector(
      (state) => state.sportCoachDashboard.data.dashTitle,
    );
    isReadOnlyView = useSelector(
      (state) => state.sportCoachDashboard.ui.isReadOnlyView,
    );
    exercises = useSelector(
      (state) => state.sportCoachDashboard.data.exercises,
    );
  }

  // Handle setting about dash info based on read-only view vs template view
  const logo = isReadOnlyView ? dashLogo : aboutTemplate?.teamImage;
  const title = isReadOnlyView ? dashTitle : aboutTemplate?.title;
  const creator = isReadOnlyView
    ? dashCreator
    : `${aboutTemplate?.firstName} ${aboutTemplate?.lastName}`;
  const description = isReadOnlyView
    ? dashDescription
    : aboutTemplate?.description;
  const exers = isReadOnlyView ? exercises : aboutTemplate?.exercises || [];

  const questionnaireTitle = () => {
    if (dashboard === 'Load Monitoring') {
      if (isReadOnlyView) return `Selected Questionniare: ${readOnlyQuestionnaireTitle}`;
      return aboutTemplate && aboutTemplate.questionnaire
        ? `Selected Questionnaire: ${aboutTemplate?.questionnaire?.title}`
        : 'Selected Questions';
    }
    return null;
  };
  const questions = () => {
    if (dashboard === 'Load Monitoring') {
      if (isReadOnlyView) return readOnlyQuestions;
      // if (aboutTemplate?.questionnaire.title) return aboutTemplate?.questionnaire.questions;
      return aboutTemplate?.questions;
    }
    return [];
  };
  const uniqueFilters = (filterArray) => {
    if (!filterArray) return [];
    const uniqueArr = [];
    filterArray.forEach((filterObj) => {
      if (!uniqueArr.some((obj) => obj.name === filterObj.name)) uniqueArr.push(filterObj);
    });
    return uniqueArr;
  };
  const fpChartMetrics = isReadOnlyView
    ? chartForcePlateMetrics.map((metric, index) => ({
      id: index,
      name: metric,
    }))
    : uniqueFilters(aboutTemplate?.chartForcePlateMetrics) || [];

  const fpKeyMetrics = isReadOnlyView
    ? keyPerformanceMetrics.map((metric, index) => ({
      id: index,
      name: metric,
    }))
    : uniqueFilters(aboutTemplate?.kpiForcePlateMetrics) || [];

  // Splitting the exercises array into two separate arrays to render two cols
  const firstExerCol = exers?.slice(0, 5);
  const secondExerCol = exers?.slice(5, 10);
  const hasSecondCol = secondExerCol?.length > 0;

  const firstQuestCol = questions()?.slice(0, 5);
  const secondQuestCol = questions()?.slice(5, 10);
  const hasSecondQuestCol = secondQuestCol?.length > 0;

  const firstFilterCol = fpChartMetrics || [];
  const secondFilterCol = fpKeyMetrics || [];
  const hasSecondFilterCol = secondFilterCol?.length > 0;

  const handleClose = () => {
    dispatch(setAboutModalShowing(false));
  };

  return (
    <>
      <ModalContainer isActive={isModalShowing}>
        <div className='remove-div' onClick={handleClose}>
          <div className='remove-div-icon'>
            <IcomoonReact
              iconSet={iconSet}
              size={15}
              icon='remove'
              color='black'
            />
          </div>
        </div>
        <ContentContainer>
          <TopBox>
            <Image src={logo} alt='' id='image' />
            <TempEdgeBox id='temp-edge-box'>
              <TempName id='temp-name'>{title}</TempName>
              <TempOwner id='temp-owner'>{creator}</TempOwner>
            </TempEdgeBox>
          </TopBox>
          <BottomBox>
            <Description>{description}</Description>
            {dashboard === 'Load Monitoring' && (
              <DashOptionsContainer>
                <DashOptionsTitle>{questionnaireTitle()}</DashOptionsTitle>
                <ColumnsContainer>
                  <DashOptions secondCol={hasSecondCol}>
                    {firstQuestCol?.map((question) => (
                      <DashOptionItem key={question.id}>
                        {question.title}
                      </DashOptionItem>
                    ))}
                  </DashOptions>
                  {hasSecondQuestCol && (
                    <DashOptions start='6' secondCol={hasSecondQuestCol}>
                      {secondQuestCol?.map((question) => (
                        <DashOptionItem key={question.id}>
                          {question.title}
                        </DashOptionItem>
                      ))}
                    </DashOptions>
                  )}
                </ColumnsContainer>
              </DashOptionsContainer>
            )}
            {dashboard === 'Force Plate' && (
              <DashOptionsContainer>
                <ColumnsContainer>
                  <DashOptions>
                    <DashOptionsTitle style={{ marginLeft: '-25px' }}>
                      Chart Metrics
                    </DashOptionsTitle>
                    {firstFilterCol?.map((filter) => (
                      <DashOptionItem key={filter.id}>
                        {filter.name}
                      </DashOptionItem>
                    ))}
                  </DashOptions>
                  <DashOptions>
                    <DashOptionsTitle style={{ marginLeft: '-25px' }}>
                      Key Performance Metrics
                    </DashOptionsTitle>
                    {hasSecondFilterCol
                      && secondFilterCol?.map((filter) => (
                        <DashOptionItem key={filter.id}>
                          {filter.name}
                        </DashOptionItem>
                      ))}
                  </DashOptions>
                </ColumnsContainer>
              </DashOptionsContainer>
            )}
            {dashboard === 'Sport Coach' && (
              <DashOptionsContainer>
                <DashOptionsTitle>Exercises</DashOptionsTitle>
                <ColumnsContainer>
                  <DashOptions>
                    {firstExerCol?.map((exer) => (
                      <DashOptionItem key={exer.id}>{exer.name}</DashOptionItem>
                    ))}
                  </DashOptions>
                  {hasSecondCol && (
                    <DashOptions start='6' style={{ marginLeft: '50px' }}>
                      {secondExerCol?.map((exer) => (
                        <DashOptionItem key={exer.id}>
                          {exer.name}
                        </DashOptionItem>
                      ))}
                    </DashOptions>
                  )}
                </ColumnsContainer>
              </DashOptionsContainer>
            )}
          </BottomBox>
        </ContentContainer>
        <Button
          bottom
          fullWidth
          cta='Close'
          className='opt-out-button'
          large
          square
          noBorder
          primary
          customColor='#444444'
          customTextColor='#FFFFFF'
          onClick={() => {
            dispatch(setAboutModalShowing(false));
          }}
        />
      </ModalContainer>
      <SharedTemplateModalContainer
        isModalShowing={isModalShowing}
        onClose={handleClose}
      />
    </>
  );
};

SharedTemplateAboutModal.defaultProps = {
  dashboard: 'Wearables',
};

SharedTemplateAboutModal.propTypes = {
  dashboard: PropTypes.string,
};

export default SharedTemplateAboutModal;
