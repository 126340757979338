/* eslint-disable react/prop-types */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import React from 'react';
import styled from '@emotion/styled';
import { useSelector, useDispatch } from 'react-redux';
import FadeIn from 'react-fade-in';
import * as Yup from 'yup';
import moment from 'moment';
import { Form } from 'formik';

import Text from '../../../../shared/components/Text/Text';
import FormHandler from '../../../../shared/components/FormHandler/FormHandler';
import {
  fetchQuestionnaireReportSingleDay,
  fetchLoadMonitoringLineChartData,
  setCurrentGroupInGroupPicker,
  setCurrentAthleteInAthletePicker,
  setCurrentDateInDashDatePicker,
  setIsDashboardDisplayed,
} from '../../ducks/loadMonitoringDashboardActions';

import FormPage1 from './FormPage1';
import FormPage2 from './FormPage2';
import GeneratedDataPage from './GeneratedDataPage';
import { dashboardTracker } from '../../../../shared/utils/amplitudeHelper';

const PageContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  max-width: ${(props) => (props.activePage !== 3 ? '1500px' : '100%')};
  margin-top: ${(props) => (props.activePage === 3 ? '15px' : '0px')};
`;

const ButtonContainer = styled('div')`
  display: flex;
  gap: 25px;
  align-self: ${(props) => (props.containerWidth && props.containerWidth < 620
    ? 'flex-start'
    : 'flex-end')};
  border: none;
  flex-wrap: wrap;
  margin-bottom: 50px;
`;

const Button = styled('button')`
  border: none;
  border-radius: 10px;
  min-width: 175px;
  padding: 10px;
`;

const QuestionnaireReportSchema = Yup.object().shape({
  arrayOfQuestions: Yup.string().when('selectionType', {
    is: 'selectIndividualQuestions',
    then: Yup.string().required('Please select at least one question'),
  }),
});

const LoadMonitoringForm = ({
  formikRef,
  containerWidth,
  activePage,
  setActivePage,
  isLoadingQuestionnairesForAccount,
  isLoadingQuestionsForAccount,
}) => {
  const reportableGroups = useSelector(
    (state) => state.loadMonitoringDashboard.data.reportableGroups,
  );
  const defaultReportableGroupId = reportableGroups?.length
    ? reportableGroups[0].id
    : null;
  const questions = useSelector(
    (state) => state.loadMonitoringDashboard.data.questionsForAccount,
  );
  const questionnaires = useSelector(
    (state) => state.loadMonitoringDashboard.data.questionnairesForAccount,
  );
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const { accountCode } = currentUser;

  const dispatch = useDispatch();

  const fetchReport = (values) => {
    /** fetch questionnaire report for single day on default load */
    if (defaultReportableGroupId) {
      /** we are always going to default to fetch the first group in the users reportable groups array */
      const filterType = '2';
      const filterIds = [defaultReportableGroupId];
      const firstGroupInreportableGroupArray = reportableGroups.length && reportableGroups[0];
      dispatch(
        setCurrentGroupInGroupPicker({
          ...firstGroupInreportableGroupArray,
          displayText: firstGroupInreportableGroupArray.name,
        }),
      );
      dispatch(
        setCurrentAthleteInAthletePicker({
          ...firstGroupInreportableGroupArray,
          displayText: firstGroupInreportableGroupArray.name,
          isAthlete: false,
        }),
      );
      dispatch(setCurrentDateInDashDatePicker(values.date));
      dispatch(
        fetchQuestionnaireReportSingleDay(
          accountCode,
          filterType,
          filterIds,
          values,
        ),
      );
      dispatch(
        fetchLoadMonitoringLineChartData(
          accountCode,
          filterType,
          filterIds,
          values,
        ),
      );
      dispatch(setIsDashboardDisplayed(true));
    }
  };

  // const saveFile = (e, formProps) => {
  //   // console.log(formProps);
  //   formProps.setTouched({
  //     arrayOfQuestions: true,
  //     reportName: true,
  //   });
  //   e.preventDefault();
  //   formProps.validateForm().then(() => {
  //     if (formProps.isValid) {
  //       // console.log('hit api to save file');
  //       // console.log('current values saved:', formProps.values);
  //     }
  //   });
  // };

  const currentDate = new Date();

  return (
    <>
      <FormHandler
        innerRef={formikRef}
        initialValues={
          {
            filterType: '',
            filterIds: [],
            reportName: '',
            reportType: 'singleDay',
            selectionType: '',
            date: {
              date: currentDate,
              queryFormat: moment(currentDate).format('YYYY-MM-DD'),
            },
            arrayOfQuestions: [],
            /** pre load with 5 question containers */
            questionIds: [
              { title: '', id: '' },
              { title: '', id: '' },
              { title: '', id: '' },
              { title: '', id: '' },
              { title: '', id: '' },
            ],
            questionnaire: null,
            questionnaireIds: [],
            showRangeAverages: true,
            showTotal: true,
            dayRange: 7,
          }
          // )
        }
        validationSchema={QuestionnaireReportSchema}
        onSubmit={(values, { setSubmitting }) => {
          // Amplitude feature tracker
          dashboardTracker(
            'Load Monitoring',
            'Run Individual Questions Report',
            'Report submission',
          );
          /** clear out old picker values */
          dispatch(setCurrentGroupInGroupPicker({}));
          dispatch(setCurrentAthleteInAthletePicker({}));
          dispatch(setCurrentDateInDashDatePicker({}));
          /** ----  */
          setTimeout(() => {
            setSubmitting(false);
            fetchReport(values);
            setActivePage(3);
          }, 200);
        }}
      >
        {(formProps) => (
          <>
            <Form
              style={{
                width: activePage === 3 ? '100%' : '96%',
                height: '100%',
                position: 'relative',
              }}
            >
              {activePage === 1 && (
                <FadeIn>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      height: '100vh',
                    }}
                  >
                    <PageContainer
                      style={{ paddingBottom: '30px' }}
                      activePage={activePage}
                    >
                      <FormPage1
                        activePage={activePage}
                        setActivePage={setActivePage}
                        formProps={formProps}
                        containerWidth={containerWidth}
                        isLoadingQuestionnairesForAccount={
                          isLoadingQuestionnairesForAccount
                        }
                        isLoadingQuestionsForAccount={
                          isLoadingQuestionsForAccount
                        }
                      />
                    </PageContainer>
                  </div>
                </FadeIn>
              )}
              {activePage === 2 && (
                <FadeIn>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      height: '100vh',
                    }}
                  >
                    <PageContainer
                      style={{ paddingBottom: '30px' }}
                      activePage={activePage}
                    >
                      <>
                        <FormPage2
                          activePage={activePage}
                          setActivePage={setActivePage}
                          formProps={formProps}
                          questions={questions}
                          questionnaires={questionnaires}
                          containerWidth={containerWidth}
                        />
                        {formProps.values.selectionType
                          === 'selectIndividualQuestions' && (
                          <ButtonContainer containerWidth={containerWidth}>
                            {/* <Button
                          type='button'
                          key='notsubmit'
                          style={{
                            display: formProps.values.selectionType === 'selectAllQuestions' && 'none',
                            background: '#424242',
                          }}
                          onClick={(e) => saveFile(e, formProps)}
                        >
                          <Text fontSize='14px' fontWeight={900} style={{ color: 'white' }}>SAVE FILE</Text>
                        </Button> */}
                            <Button
                              type='submit'
                              key='submit'
                              style={{ background: '#5EC98E' }}
                            >
                              <Text
                                fontSize='14px'
                                fontWeight={900}
                                style={{ color: 'white' }}
                              >
                                RUN REPORT
                              </Text>
                            </Button>
                          </ButtonContainer>
                        )}
                      </>
                    </PageContainer>
                  </div>
                </FadeIn>
              )}
              {activePage === 3 && (
                <>
                  <FadeIn>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100vh',
                      }}
                    >
                      <PageContainer activePage={activePage}>
                        <GeneratedDataPage
                          containerWidth={containerWidth}
                          setActivePage={setActivePage}
                          formProps={formProps}
                        />
                      </PageContainer>
                    </div>
                  </FadeIn>
                </>
              )}
            </Form>
          </>
        )}
      </FormHandler>
    </>
  );
};

export default LoadMonitoringForm;
