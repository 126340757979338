/* eslint-disable no-tabs */
import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import DatePicker from 'react-datepicker';
import IcomoonReact from 'icomoon-react';
import moment from 'moment';

import iconSet from '../../images/teambuildr-selection.json';
import {
  BoxShadow,
} from '../../GlobalStyles';

const DateSelectorContainer = styled('div')`
	margin-top: ${(props) => (props.additionalStyles.margin ? props.additionalStyles.margin : '30px')};
  width: 100%;
  max-width: ${(props) => (props.additionalStyles.maxWidth ? props.additionalStyles.maxWidth : '100%')};
  min-width: ${(props) => (props.additionalStyles.minWidth ? props.additionalStyles.minWidth : '170px')};
	display: flex;
	justify-content: ${(props) => (props.additionalStyles.justifyContent ? props.additionalStyles.justifyContent : 'center')};
	height: 48px;
	align-items: center;
	border-radius: 6px;
	font-family: 'Nunito Sans';
	.react-datepicker__header {
		background: white;
		border-bottom: none;
	}
	.date-picker {
		border: none;
		height: 40px;
		font-weight: 400;
    width: 100%;
  }
	.react-datepicker__month-container {
		padding: 15px;
		font-family: 'Nunito Sans';
		font-size: 15px;
	}
	.react-datepicker {
		border: none;
		box-shadow: ${BoxShadow};
	}
	.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
		border-bottom-color: white;
	}
	.react-datepicker__day-names, .react-datepicker__week {
		margin-bottom: 15px;
	}
	.react-datepicker__day--outside-month {
		color: #BDBDBD;
	}
	.react-datepicker__day--inside-month {
		color: #444444;
	}
	.react-datepicker__day--selected {
		background-color: #FF6600;
		color: white;
	}
	.react-datepicker__day--keyboard-selected {
		background: none;
		color: #444444;
	}
  
  label {
    margin-bottom: 0px;
  }
`;

const CalendarIconContainer = styled('div')`
  border-radius: 6px;
  border: none;
  // font-size: 12px;
  height: 40px;
  padding-left: 10px;
  font-weight: 400;
  box-shadow: ${BoxShadow};
  width: 100%;
  background: white;
  display: flex;
  align-items: center;
`;

const IconContainer = styled('div')`
  background: #444444;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  height: 100%;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

/**
 * @param {Object} startDate a Date or moment object indicating the default state of the picker
 * @param {Function} setStartDate function for setting a new date
 * @param {Function} reduxFunction function that fires passing in the selected date
 * @returns a DatePicker component fully styled in the TeamBuildr theme
 */
const TBDatePicker = ({
  setActiveDate,
  activeDate,
  disabled,
  additionalStyles,
}) => (
  <DateSelectorContainer additionalStyles={additionalStyles}>
    <label>
      <CalendarIconContainer>
        <DatePicker
          name='workouts-date-picker'
          className='date-picker'
          selected={activeDate ? moment(activeDate).toDate() : moment().toDate()}
          popperPlacement='top-start'
          onChange={(date) => {
            setActiveDate(date);
          }}
          disabled={disabled}
          maxDate={moment().toDate()}
          renderCustomHeader={({
            decreaseMonth,
            increaseMonth,
            date,
          }) => (
            <div style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '20px',
            }}
            >
              <button
                type='button'
                onClick={decreaseMonth}
                style={{
                  border: 'none',
                  marginLeft: '10px',
                  background: 'rgba(95, 96, 100, 0.05)',
                  borderRadius: '5px',
                  width: '30px',
                  height: '30px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <IcomoonReact
                  iconSet={iconSet}
                  size={15}
                  icon='left-arrow'
                  color='#FF6600'
                />
              </button>
              <span style={{
                display: 'flex',
                alignItems: 'center',
                fontFamily: 'Nunito Sans',
                fontWeight: '700',
                fontSize: '16px',
              }}
              >
                {date?.toLocaleString('en-US', {
                  month: 'long',
                  year: 'numeric',
                })}
              </span>
              <button
                type='button'
                onClick={increaseMonth}
                style={{
                  border: 'none',
                  marginRight: '10px',
                  background: 'rgba(95, 96, 100, 0.05)',
                  borderRadius: '5px',
                  width: '30px',
                  height: '30px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <IcomoonReact
                  iconSet={iconSet}
                  size={15}
                  icon='right-arrow'
                  color='#FF6600'
                />
              </button>
            </div>
          )}
        />
        <IconContainer>
          <IcomoonReact
            iconSet={iconSet}
            size={15}
            icon='calendar'
            color='white'
          />
        </IconContainer>
      </CalendarIconContainer>
    </label>
  </DateSelectorContainer>
);

TBDatePicker.propTypes = {
  activeDate: PropTypes.string.isRequired,
  setActiveDate: PropTypes.func.isRequired,
  additionalStyles: PropTypes.objectOf(PropTypes.string),
  disabled: PropTypes.bool,
};

TBDatePicker.defaultProps = {
  additionalStyles: {},
  disabled: false,
};

export default TBDatePicker;
