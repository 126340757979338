import { toast } from 'react-toastify';

import {
  FETCH_GROUPS_START,
  FETCH_GROUPS_FULFILLED,
  FETCH_GROUPS_REJECTED,
  FETCH_PERFORMANCE_OVERVIEW_START,
  FETCH_PERFORMANCE_OVERVIEW_FULFILLED,
  FETCH_PERFORMANCE_OVERVIEW_REJECTED,
  SET_IS_METRIC_INFO_MODAL_SHOWING,
  SET_IS_FP_LEGEND_INFO_MODAL_SHOWING,
  SET_SELECTED_ATHLETE_PERFORMANCE,
  SET_START_DATE_DATA,
  SET_END_DATE_DATA,
  SET_START_DATE_CAL,
  SET_END_DATE_CAL,
  SET_DAY_RANGE,
  FETCH_METRIC_CARD_DATA_START,
  FETCH_METRIC_CARD_DATA_FULFILLED,
  FETCH_METRIC_CARD_DATA_REJECTED,
  SET_FORCE_PLATE_VIEW,
  FETCH_GROUP_ANALYSIS_START,
  FETCH_GROUP_ANALYSIS_FULFILLED,
  FETCH_GROUP_ANALYSIS_REJECTED,
  SET_SELECTED_ATHLETE_GROUP,
  SET_GROUP,
  SET_GROUPS,
  SET_METRIC_FILTERS_PERFORMANCE,
  SET_METRIC_FILTERS_GROUP,
  SET_GROUP_FILTER_RADIO,
  SET_IS_METRIC_FILTERS_PERFORMANCE_SHOWING,
  SET_IS_METRIC_FILTERS_GROUP_SHOWING,
  SET_KEY_PERFORMANCE_METRICS,
  SET_SHOW_FLAGGED_ATHLETES_MODAL_PERFORMANCE,
  SET_SHOW_FLAGGED_ATHLETES_MODAL_GROUP,
  SET_FLAGGED_ATHLETES_VIEW,
  SET_FLAGGED_ATHLETES,
  SET_FLAGGED_ATHLETES_GROUP,
  SET_FLAGGED_ATHLETES_PERFORMANCE,
  SET_NEW_FP_DASH,
  FETCH_FP_TEMPLATES_START,
  FETCH_FP_TEMPLATES_FULFILLED,
  FETCH_FP_TEMPLATES_REJECTED,
  SET_ACTIVE_FP_DASH,
  CLEAR_ACTIVE_FP_DASH,
  SET_SAVE_FP_TEMPLATE_MODAL,
  CREATE_FP_TEMPLATE_START,
  CREATE_FP_TEMPLATE_FULFILLED,
  CREATE_FP_TEMPLATE_REJECTED,
  SET_IS_UNSAVED_FP_DASH_MODAL,
  SET_EDIT_FP_DASH_MODAL,
  EDIT_FP_TEMPLATE_START,
  EDIT_FP_TEMPLATE_FULFILLED,
  EDIT_FP_TEMPLATE_REJECTED,
  SET_DELETE_FP_TEMPLATE_MODAL,
  DELETE_FP_TEMPLATE_START,
  DELETE_FP_TEMPLATE_FULFILLED,
  DELETE_FP_TEMPLATE_REJECTED,
  SET_SHARE_FP_DASH_MODAL,
  FETCH_SHARE_FP_DASH_LINK_START,
  FETCH_SHARE_FP_DASH_LINK_FULFILLED,
  FETCH_SHARE_FP_DASH_LINK_REJECTED,
  CLEAR_SHARE_FP_DASH_LINK,
  FETCH_PUBLIC_FP_PERFORMANCE_CHART_DATA_START,
  FETCH_PUBLIC_FP_PERFORMANCE_CHART_DATA_FULFILLED,
  FETCH_PUBLIC_FP_PERFORMANCE_CHART_DATA_REJECTED,
  FETCH_PUBLIC_FP_PERFORMANCE_CARD_DATA_START,
  FETCH_PUBLIC_FP_PERFORMANCE_CARD_DATA_FULFILLED,
  FETCH_PUBLIC_FP_PERFORMANCE_CARD_DATA_REJECTED,
  FETCH_PUBLIC_FP_GROUP_ANALYSIS_DATA_START,
  FETCH_PUBLIC_FP_GROUP_ANALYSIS_DATA_FULFILLED,
  FETCH_PUBLIC_FP_GROUP_ANALYSIS_DATA_REJECTED,
  SET_DUPLICATE_FP_DASH_MODAL,
  DUPLICATE_FP_DASH_START,
  DUPLICATE_FP_DASH_FULFILLED,
  DUPLICATE_FP_DASH_REJECTED,
  SET_FP_TRI_OPTION_MODAL,
  SET_ABOUT_FP_DASH_MODAL,
  SET_NEXT_LOCATION,
  CLEAR_FP_DASH_DATA,
  SET_IS_FP_DASH_DISPLAYED,
  CLEAR_FP_DASH_MODALS,
} from './actionTypes';

import {
  axiosAuthed,
  axiosUnauthed,
} from '../../../shared/utils/setCommonHeaders';

export const fetchGroups = (accountCode) => (dispatch) => {
  dispatch({ type: FETCH_GROUPS_START });
  axiosAuthed
    .get(`/accounts/${accountCode}/groups?reporting=1`)
    .then((response) => {
      dispatch({
        payload: response.data,
        type: FETCH_GROUPS_FULFILLED,
      });
    })
    .catch((err) => {
      dispatch({
        payload: err,
        type: FETCH_GROUPS_REJECTED,
      });
    });
};

/*
Params:
userGroupId - id of group a user wants metric results for (integer) *Optional*
calendarId - id of calendar the user wants metric results for (integer) *Optional*
startDate - start date for metric results (string) Ex: 1694543961
endDate - end date of metric results range (string) Ex: 1694543961
metrics - list of metrics to return test results for (string) Ex: ‘Peak Power, Stiffness, mRSI’
*/
// Fetch the performance overview data with any of the above params
export const fetchPerformanceOverview = (
  accountCode,
  startDate,
  endDate,
  groupId,
  metrics,
) => (dispatch) => {
  const metricsQuery = metrics.length > 0 ? `&metrics=${metrics}` : '';

  dispatch({ type: FETCH_PERFORMANCE_OVERVIEW_START });
  axiosAuthed
    .get(
      `/accounts/${accountCode}/reports/force-plate/performance-overview?userGroupId=${groupId}&startDate=${startDate}&endDate=${endDate}${metricsQuery}`,
    )
    .then((response) => {
      dispatch({
        payload: response.data,
        type: FETCH_PERFORMANCE_OVERVIEW_FULFILLED,
      });
    })
    .catch((err) => {
      dispatch({
        payload: err,
        type: FETCH_PERFORMANCE_OVERVIEW_REJECTED,
      });
    });
};

// Same function as above, but w/o filters to use on metric cards
export const fetchMetricCardData = (
  accountCode,
  startDate,
  endDate,
  groupId,
  metrics,
) => (dispatch) => {
  dispatch({ type: FETCH_METRIC_CARD_DATA_START });
  const metricString = metrics.join(',');
  axiosAuthed
    .get(
      `/accounts/${accountCode}/reports/force-plate/performance-overview?userGroupId=${groupId}&startDate=${startDate}&endDate=${endDate}&metrics=${metricString}`,
    )
    .then((response) => {
      dispatch({
        payload: response.data,
        type: FETCH_METRIC_CARD_DATA_FULFILLED,
      });
    })
    .catch((err) => {
      dispatch({
        payload: err,
        type: FETCH_METRIC_CARD_DATA_REJECTED,
      });
    });
};

// Same params as above
// Fetch the group analysis data with any of the above params
export const fetchGroupAnalysis = (
  accountCode,
  startDate,
  endDate,
  groupId,
  metrics,
) => (dispatch) => {
  const metricsQuery = metrics.length > 0 ? `&metrics=${metrics}` : '';

  dispatch({ type: FETCH_GROUP_ANALYSIS_START });
  axiosAuthed
    .get(
      `/accounts/${accountCode}/reports/force-plate/group-analysis?userGroupId=${groupId}&startDate=${startDate}&endDate=${endDate}${metricsQuery}`,
    )
    .then((response) => {
      dispatch({
        payload: {
          athlete: response.data.athleteData,
          average: response.data.groupMetricsData,
          flagged: response.data.flaggedAthleteData,
        },
        type: FETCH_GROUP_ANALYSIS_FULFILLED,
      });
    })
    .catch((err) => {
      dispatch({
        payload: err,
        type: FETCH_GROUP_ANALYSIS_REJECTED,
      });
    });
};

export const setIsMetricInfoModalShowing = (bool, metric) => (dispatch) => {
  dispatch({
    payload: {
      bool,
      metric,
    },
    type: SET_IS_METRIC_INFO_MODAL_SHOWING,
  });
};

export const setIsLegendInfoModalShowing = (bool) => (dispatch) => {
  dispatch({
    payload: bool,
    type: SET_IS_FP_LEGEND_INFO_MODAL_SHOWING,
  });
};

export const setSelectedAthletePerformance = (athlete, bool) => (dispatch) => {
  dispatch({
    payload: {
      athlete,
      bool,
    },
    type: SET_SELECTED_ATHLETE_PERFORMANCE,
  });
};

export const setSelectedAthleteGroup = (athlete, bool) => (dispatch) => {
  dispatch({
    payload: {
      athlete,
      bool,
    },
    type: SET_SELECTED_ATHLETE_GROUP,
  });
};

export const setIsMetricFiltersPerformanceShowing = (bool) => (dispatch) => {
  dispatch({
    payload: bool,
    type: SET_IS_METRIC_FILTERS_PERFORMANCE_SHOWING,
  });
};

export const setIsMetricFiltersGroupShowing = (bool) => (dispatch) => {
  dispatch({
    payload: bool,
    type: SET_IS_METRIC_FILTERS_GROUP_SHOWING,
  });
};

export const setMetricFiltersPerformance = (metrics) => (dispatch) => {
  dispatch({
    payload: metrics,
    type: SET_METRIC_FILTERS_PERFORMANCE,
  });
};

export const setMetricFiltersGroup = (metrics) => (dispatch) => {
  dispatch({
    payload: metrics,
    type: SET_METRIC_FILTERS_GROUP,
  });
};

export const setKeyPerformanceMetrics = (metrics) => (dispatch) => {
  dispatch({
    payload: metrics,
    type: SET_KEY_PERFORMANCE_METRICS,
  });
};

export const setGroupFilterRadio = (option) => (dispatch) => {
  dispatch({
    payload: option,
    type: SET_GROUP_FILTER_RADIO,
  });
};

export const setGroup = (group) => (dispatch) => {
  dispatch({
    payload: group,
    type: SET_GROUP,
  });
};

export const setGroups = (groups) => (dispatch) => {
  dispatch({
    payload: groups,
    type: SET_GROUPS,
  });
};

export const setStartDateData = (startDate) => (dispatch) => {
  dispatch({
    payload: startDate,
    type: SET_START_DATE_DATA,
  });
};

export const setEndDateData = (endDate) => (dispatch) => {
  dispatch({
    payload: endDate,
    type: SET_END_DATE_DATA,
  });
};

export const setStartDateCal = (startDate) => (dispatch) => {
  dispatch({
    payload: startDate,
    type: SET_START_DATE_CAL,
  });
};

export const setEndDateCal = (endDate) => (dispatch) => {
  dispatch({
    payload: endDate,
    type: SET_END_DATE_CAL,
  });
};

export const setDayRange = (days) => (dispatch) => {
  dispatch({
    payload: days,
    type: SET_DAY_RANGE,
  });
};

export const setForcePlateView = (view) => (dispatch) => {
  dispatch({
    payload: view,
    type: SET_FORCE_PLATE_VIEW,
  });
};

export const setShowFlaggedAthletesModalPerformance = (bool) => (dispatch) => {
  dispatch({
    payload: bool,
    type: SET_SHOW_FLAGGED_ATHLETES_MODAL_PERFORMANCE,
  });
};

export const setShowFlaggedAthletesModalGroup = (bool) => (dispatch) => {
  dispatch({
    payload: bool,
    type: SET_SHOW_FLAGGED_ATHLETES_MODAL_GROUP,
  });
};

export const setFlaggedAthletesView = (bool) => (dispatch) => {
  dispatch({
    payload: bool,
    type: SET_FLAGGED_ATHLETES_VIEW,
  });
};

export const setFlaggedAthletes = (flaggedAthletes) => (dispatch) => {
  dispatch({
    payload: flaggedAthletes,
    type: SET_FLAGGED_ATHLETES,
  });
};

export const setFlaggedAthletesGroup = (flaggedAthletes) => (dispatch) => {
  dispatch({
    payload: flaggedAthletes,
    type: SET_FLAGGED_ATHLETES_GROUP,
  });
};

export const setFlaggedAthletesPerformance = (flaggedAthletes) => (
  dispatch,
) => {
  dispatch({
    payload: flaggedAthletes,
    type: SET_FLAGGED_ATHLETES_PERFORMANCE,
  });
};

export const setIsNewFPDash = (bool) => ({
  type: SET_NEW_FP_DASH,
  payload: bool,
});

export const fetchFPTemplates = (accountCode) => (dispatch) => {
  dispatch({ type: FETCH_FP_TEMPLATES_START });
  axiosAuthed
    .get(`/accounts/${accountCode}/reports/force-plate/templates`)
    .then((response) => {
      dispatch({
        type: FETCH_FP_TEMPLATES_FULFILLED,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: FETCH_FP_TEMPLATES_REJECTED,
        payload: err,
      });
    });
};

export const setActiveFPTemplate = (obj) => ({
  type: SET_ACTIVE_FP_DASH,
  payload: obj,
});

export const clearActiveFPDashboard = () => ({
  type: CLEAR_ACTIVE_FP_DASH,
});

export const setSaveFPDashModal = (bool) => (dispatch) => {
  dispatch({
    type: SET_SAVE_FP_TEMPLATE_MODAL,
    payload: bool,
  });
};

export const createFPTemplate = (accountCode, submissionObject) => (
  dispatch,
) => {
  dispatch({ type: CREATE_FP_TEMPLATE_START });
  axiosAuthed
    .post(
      `/accounts/${accountCode}/reports/force-plate/templates`,
      submissionObject,
    )
    .then((response) => {
      toast.success('Dashboard Created!');
      dispatch({
        type: CREATE_FP_TEMPLATE_FULFILLED,
        payload: response.data,
      });
    })
    .catch((err) => {
      toast.error(err?.response?.headers?.message || 'An error has occurred');
      dispatch({
        type: CREATE_FP_TEMPLATE_REJECTED,
        payload: err,
      });
    });
};

export const setEditFPDashModal = (bool, template = {}) => ({
  type: SET_EDIT_FP_DASH_MODAL,
  payload: {
    bool,
    template,
  },
});

export const editFPTemplate = (
  accountCode,
  submissionObject,
  templateId,
  index,
) => (dispatch) => {
  dispatch({ type: EDIT_FP_TEMPLATE_START });
  axiosAuthed
    .put(
      `/accounts/${accountCode}/reports/force-plate/templates/${templateId}?resetGroups=false&resetMetrics=false`,
      submissionObject,
    )
    .then((response) => {
      toast.success('Edit Successful!');
      dispatch({
        type: EDIT_FP_TEMPLATE_FULFILLED,
        payload: {
          data: response.data,
          index,
        },
      });
    })
    .catch((err) => {
      toast.error(err?.response?.headers?.message || 'An error has occurred');
      dispatch({
        type: EDIT_FP_TEMPLATE_REJECTED,
        payload: err,
      });
    });
};

export const setDeleteFPDashboardModal = (bool, template = {}) => ({
  type: SET_DELETE_FP_TEMPLATE_MODAL,
  payload: {
    bool,
    template,
  },
});

export const deleteFPTemplate = (accountCode, templateId, templateIndex) => (
  dispatch,
) => {
  dispatch({ type: DELETE_FP_TEMPLATE_START });
  axiosAuthed
    .delete(
      `/accounts/${accountCode}/reports/force-plate/templates/${templateId}`,
    )
    .then((response) => {
      toast.success('Template Deleted!');
      dispatch({
        type: DELETE_FP_TEMPLATE_FULFILLED,
        payload: { data: response.data, index: templateIndex },
      });
    })
    .catch((err) => {
      toast.error(err?.response?.headers?.message || 'An error has occurred');
      dispatch({
        type: DELETE_FP_TEMPLATE_REJECTED,
        payload: err,
      });
    });
};

export const setShareFPDashModal = (bool, template = {}) => ({
  type: SET_SHARE_FP_DASH_MODAL,
  payload: {
    bool,
    template,
  },
});

// Create a shareable view-only link for the current dashboard
export const fetchShareFPDashLink = (accountCode, submissionObj) => (
  dispatch,
) => {
  dispatch({ type: FETCH_SHARE_FP_DASH_LINK_START });
  axiosAuthed
    .post(`/accounts/${accountCode}/reports/force-plate/link`, submissionObj)
    .then((response) => {
      dispatch({
        type: FETCH_SHARE_FP_DASH_LINK_FULFILLED,
        payload: response.data.url,
      });
    })
    .catch(() => {
      // TO-DO: WILL ERROR COME THROUGH HERE FROM BACKEND?
      dispatch({
        type: FETCH_SHARE_FP_DASH_LINK_REJECTED,
        payload: true,
      });
    });
};

export const clearShareFPDashLink = () => (dispatch) => {
  dispatch({
    type: CLEAR_SHARE_FP_DASH_LINK,
  });
};

// Fetch FP Dash data from public route using token for view only dashboard
export const fetchPublicFPPerformanceChartData = (token) => (dispatch) => {
  dispatch({ type: FETCH_PUBLIC_FP_PERFORMANCE_CHART_DATA_START });
  axiosUnauthed
    .get(
      `/public/reports/force-plate/performance-overview-chart?token=${token}`,
    )
    .then((response) => {
      dispatch({
        type: FETCH_PUBLIC_FP_PERFORMANCE_CHART_DATA_FULFILLED,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: FETCH_PUBLIC_FP_PERFORMANCE_CHART_DATA_REJECTED,
        payload: err?.response?.headers?.message,
      });
    });
};

export const fetchPublicFPPerformanceCardData = (token) => (dispatch) => {
  dispatch({ type: FETCH_PUBLIC_FP_PERFORMANCE_CARD_DATA_START });
  axiosUnauthed
    .get(
      `/public/reports/force-plate/performance-overview-cards?token=${token}`,
    )
    .then((response) => {
      dispatch({
        type: FETCH_PUBLIC_FP_PERFORMANCE_CARD_DATA_FULFILLED,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: FETCH_PUBLIC_FP_PERFORMANCE_CARD_DATA_REJECTED,
        payload: err?.response?.headers?.message,
      });
    });
};

export const fetchPublicFPGroupAnalysisData = (token) => (dispatch) => {
  dispatch({ type: FETCH_PUBLIC_FP_GROUP_ANALYSIS_DATA_START });
  axiosUnauthed
    .get(`/public/reports/force-plate/group-analysis?token=${token}`)
    .then((response) => {
      dispatch({
        type: FETCH_PUBLIC_FP_GROUP_ANALYSIS_DATA_FULFILLED,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: FETCH_PUBLIC_FP_GROUP_ANALYSIS_DATA_REJECTED,
        payload: err?.response?.headers?.message,
      });
    });
};

export const setDuplicateFPDashModal = (bool, template = {}) => (dispatch) => {
  dispatch({
    type: SET_DUPLICATE_FP_DASH_MODAL,
    payload: {
      bool,
      template,
    },
  });
};

// Create a duplicate dashboard/template based on the ID
export const duplicateFPDashboard = (
  accountCode,
  templateId,
  submissionObject,
) => (dispatch) => {
  dispatch({ type: DUPLICATE_FP_DASH_START });
  axiosAuthed
    .post(
      `/accounts/${accountCode}/reports/force-plate/templates/${templateId}/duplicate`,
      submissionObject,
    )
    .then((response) => {
      toast.success('Template Duplicated!');
      dispatch({
        type: DUPLICATE_FP_DASH_FULFILLED,
        payload: response.data,
      });
    })
    .catch((err) => {
      toast.error(err?.response?.headers?.message || 'An error has occurred');
      dispatch({
        type: DUPLICATE_FP_DASH_REJECTED,
        payload: err,
      });
    });
};

export const setFPTriOptionModal = (bool) => ({
  type: SET_FP_TRI_OPTION_MODAL,
  payload: bool,
});

export const setAboutFPDashModal = (bool, template = null) => ({
  type: SET_ABOUT_FP_DASH_MODAL,
  payload: {
    bool,
    template,
  },
});

export const setUnsavedFPDashModal = (bool) => (dispatch) => {
  dispatch({
    type: SET_IS_UNSAVED_FP_DASH_MODAL,
    payload: bool,
  });
};

export const setNextLocation = (location) => (dispatch) => {
  dispatch({
    type: SET_NEXT_LOCATION,
    payload: location,
  });
};

export const clearFPDashData = () => (dispatch) => {
  dispatch({
    type: CLEAR_FP_DASH_DATA,
  });
};

export const setIsDashboardDisplayed = (bool) => (dispatch) => {
  dispatch({
    type: SET_IS_FP_DASH_DISPLAYED,
    payload: bool,
  });
};

export const clearFPDashModals = () => (dispatch) => {
  dispatch({
    type: CLEAR_FP_DASH_MODALS,
  });
};
