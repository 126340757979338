/* eslint-disable react/prop-types */
/* eslint-disable array-callback-return */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ResizeObserver from 'react-resize-observer';
import styled from '@emotion/styled';

import Text from '../../../../shared/_TBComponents/Text';
import Picker from '../../../../shared/components/SportsHub/Picker';
import DonutChart from './DonutChart';
import AveragesCard from './AveragesCard';
import { setCurrentQuestionInQuestionnairePicker } from '../../ducks/reportingActions';

const AveragesAndDonutChartComponent = () => {
  const dispatch = useDispatch();

  const [containerWidth, setContainerWidth] = useState({});

  const dataRows = useSelector((state) => state.reporting.data.questionnaireReport.dataRows);
  const donutChartData = useSelector((state) => state.reporting.data.questionnaireReport.donutChartData);
  const totalScoresDonutChartDataGreen = useSelector((state) => state.reporting.data.questionnaireReport.totalScoresDonutChartDataGreen);
  const totalScoresDonutChartDataYellow = useSelector((state) => state.reporting.data.questionnaireReport.totalScoresDonutChartDataYellow);
  const totalScoresDonutChartDataRed = useSelector((state) => state.reporting.data.questionnaireReport.totalScoresDonutChartDataRed);
  const totalScoresDonutChartDataGreenCount = useSelector((state) => state.reporting.data.questionnaireReport.totalScoresDonutChartDataGreen)?.length;
  const totalScoresDonutChartDataYellowCount = useSelector((state) => state.reporting.data.questionnaireReport.totalScoresDonutChartDataYellow)?.length;
  const totalScoresDonutChartDataRedCount = useSelector((state) => state.reporting.data.questionnaireReport.totalScoresDonutChartDataRed)?.length;
  const questionToFilterBy = useSelector((state) => state.reporting.data.currentQuestionInQuestionnairePicker?.displayText);
  const redCountForSelectedQuestion = donutChartData?.filter((questionObject) => (questionObject.title === questionToFilterBy))[0]?.red.length || 0;
  const greenCountForSelectedQuestion = donutChartData?.filter((questionObject) => (questionObject.title === questionToFilterBy))[0]?.green?.length || 0;
  const yellowCountForSelectedQuestion = donutChartData?.filter((questionObject) => (questionObject.title === questionToFilterBy))[0]?.yellow?.length || 0;
  const listOfQuestionsForPicker = donutChartData?.map((questionGroup) => ({
    id: questionGroup.id,
    displayText: questionGroup.title,
  }));
  const totalScoresObjectToAddToPickerQuestionsArray = [{ id: 'Total Scores', displayText: 'Total Scores?' }];

  const teamAverageTotalsQuestionsArray = useSelector((state) => state.reporting.data.questionnaireReport?.teamAverageTotals?.questions);
  const currentQuestionInQuestionnairePickerId = useSelector((state) => state.reporting.data.currentQuestionInQuestionnairePicker.id);
  const currentQuestionText = useSelector((state) => state.reporting.data.currentQuestionInQuestionnairePicker.displayText);
  const totalScoreTeamAverageForCurrentDay = useSelector((state) => state.reporting.data.questionnaireReport?.teamAverageTotals?.totalScores?.value);
  const totalScoreTeamAverageForWeek = useSelector((state) => state.reporting.data.questionnaireReport?.teamAverageTotals?.totalScores?.pastWeekAverageTotal);
  const totalScoreTeamAverageForMonth = useSelector((state) => state.reporting.data.questionnaireReport?.teamAverageTotals?.totalScores?.pastMonthAverageTotal);
  const currentDayTeamAverage = currentQuestionText === 'Total Scores?' ? totalScoreTeamAverageForCurrentDay : teamAverageTotalsQuestionsArray.filter((question) => question.id === currentQuestionInQuestionnairePickerId)[0]?.response?.value;
  const weekTeamAverage = currentQuestionText === 'Total Scores?' ? totalScoreTeamAverageForWeek : teamAverageTotalsQuestionsArray.filter((question) => question.id === currentQuestionInQuestionnairePickerId)[0]?.weekAverage?.value;
  const monthTeamAverage = currentQuestionText === 'Total Scores?' ? totalScoreTeamAverageForMonth : teamAverageTotalsQuestionsArray.filter((question) => question.id === currentQuestionInQuestionnairePickerId)[0]?.monthAverage?.value;
  const questionnaireReportFormResponses = useSelector((state) => state.reporting.data.questionnaireReportForm);
  const showTotals = questionnaireReportFormResponses?.showTotal;

  useEffect(() => {
    /** on page load set the first question in the picker
     * to load donut chart data for the first quesiton in the array instead
     * of lading empty placeholder donut chart
     */
    if (listOfQuestionsForPicker?.length) {
      dispatch(setCurrentQuestionInQuestionnairePicker(listOfQuestionsForPicker[0]));
    }
  }, [donutChartData]);

  const reduxFunction = (item) => {
    dispatch(setCurrentQuestionInQuestionnairePicker(item));
  };

  const arrayToPassToDonutChartForSelectedQuestion = [{
    name: 'red',
    value: redCountForSelectedQuestion,
  },
  {
    name: 'green',
    value: greenCountForSelectedQuestion,

  },
  {
    name: 'yellow',
    value: yellowCountForSelectedQuestion,
  }];

  const totalsArrayToPassToDonutChartIfQuestionSelected = [{
    name: 'red',
    value: totalScoresDonutChartDataRedCount,
  },
  {
    name: 'green',
    value: totalScoresDonutChartDataGreenCount,

  },
  {
    name: 'yellow',
    value: totalScoresDonutChartDataYellowCount,
  }];

  const yellowAthletesArrayForChosenQuestion = [];
  const redAthletesArrayForChosenQuestion = [];
  const greenAthletesForChosenQuestion = [];

  const pushAthletesToColorArrayBasedOnCurrentQuestion = () => {
    dataRows?.map((athlete) => athlete.questions.map((question) => {
      if (question.title === questionToFilterBy && question.response.backgroundColor === '#FFCB47') {
        yellowAthletesArrayForChosenQuestion.push(athlete);
      }
      if (question.title === questionToFilterBy && question.response.backgroundColor === '#FF3737') {
        redAthletesArrayForChosenQuestion.push(athlete);
      }
      if (question.title === questionToFilterBy && question.response.backgroundColor === '#0DCC8A') {
        greenAthletesForChosenQuestion.push(athlete);
      }
    }));
  };

  pushAthletesToColorArrayBasedOnCurrentQuestion();

  const PickerAndCardsContainer = styled('div')`
    display: flex;
    flex-direction: row;
    min-height: 259px;
    background: #FFFFFF;
    box-shadow: 0px 0px 1px rgba(48, 49, 51, 0.05), 0px 8px 16px rgba(48, 49, 51, 0.1);
    border-radius: 24px;
    padding: 30px;
    justify-content: space-between;
    width: 100%;
    max-width: 1100px;
    flex-wrap:  ${(props) => (props.containerWidth <= 820 && 'wrap')};
    gap: 30px;
  `;

  const PickerAndTextContainer = styled('div')`
    display: flex;
    flex-direction: column;
    gap: 10px;
  `;

  const Wrapper = styled('div')`
    display: flex;
    padding: 0px 15px;
    width: 100%;
    justify-content: center;
  `;

  const CardContainer = styled('div')`
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1100px;
    width: ${(props) => (props.containerWidth <= 820 && '100%')};
    margin-top: 15px;
  `;

  const InnerCardContainer = styled('div')`
    display: flex;
    flex-wrap: ${(props) => (props.containerWidth <= 600 && 'wrap')};
    gap: 30px;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
  `;

  return (
    <Wrapper>
      <PickerAndCardsContainer containerWidth={containerWidth}>
        <ResizeObserver
          onResize={(rect) => {
            setContainerWidth(rect?.width);
          }}
        />
        <CardContainer containerWidth={containerWidth}>
          <PickerAndTextContainer>
            <Text styledTextProps={{ fontSize: '14px', fontWeight: 700 }}>Currently Viewing:</Text>
            <Picker list={!showTotals ? listOfQuestionsForPicker : [...listOfQuestionsForPicker, ...totalScoresObjectToAddToPickerQuestionsArray]} reduxFunction={reduxFunction} visibleContainerItem={questionToFilterBy} icon={{ name: 'swap', size: 16, color: '#444444' }} pickerButtonColor='#e8e8e8' pickerFontColor='#444444' small minWidth={containerWidth <= 275 ? 185 : 220} />
          </PickerAndTextContainer>
          <InnerCardContainer containerWidth={containerWidth}>
            <AveragesCard textToDisplay='TEAM AVERAGE' value={currentDayTeamAverage} containerWidth={containerWidth} />
            <AveragesCard textToDisplay='7 DAY TEAM AVERAGE' value={weekTeamAverage} containerWidth={containerWidth} />
            <AveragesCard textToDisplay='28 DAY TEAM AVERAGE' value={monthTeamAverage} containerWidth={containerWidth} />
          </InnerCardContainer>
        </CardContainer>
        <DonutChart
          containerWidth={containerWidth}
          arrayForDonutChartCount={questionToFilterBy === 'Total Scores?' ? totalsArrayToPassToDonutChartIfQuestionSelected : arrayToPassToDonutChartForSelectedQuestion}
          greenAthletesForCurrentQuestion={questionToFilterBy === 'Total Scores?' ? totalScoresDonutChartDataGreen : greenAthletesForChosenQuestion}
          redAthletesForCurrentQuestion={questionToFilterBy === 'Total Scores?' ? totalScoresDonutChartDataRed : redAthletesArrayForChosenQuestion}
          yellowAthletesForCurrentQuestion={questionToFilterBy === 'Total Scores?' ? totalScoresDonutChartDataYellow : yellowAthletesArrayForChosenQuestion}
        />
      </PickerAndCardsContainer>
    </Wrapper>
  );
};

export default AveragesAndDonutChartComponent;
